import {Outlet} from "react-router-dom";
import {useState, useEffect} from "react";
import useRefreshToken from "hooks/useRefreshToken";
import { useMaterialUIController, setAuth } from "context";

const PersistLogin = () =>{
    const [isLoading, setIsLoading] = useState(true)
    const refresh = useRefreshToken();
    const [controller, dispatch] = useMaterialUIController();
    const { auth } = controller;

    useEffect(() => {
        const verifyRefreshToken = async () =>{
            //console.log("verifyRefreshToken")
            try{
                await refresh();
            } catch(err){
                console.error(err);
            } finally{
                setIsLoading(false);
            }
        }

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    },[])

    // useEffect(() => {
    //     console.log(`isLoading: ${isLoading}`)
    //     console.log(`token: ${JSON.stringify(auth?.accessToken)}`)
    // }, [isLoading])

    return(
        <>
            {/* {isLoading ? <p style={{display:'flex', justifyContent:'center'}}>Loading...</p> : <Outlet/>} */}
            {isLoading ? <p style={{display:'flex', justifyContent:'center'}}></p> : <Outlet/>}
        </>
    )
}

export default PersistLogin;

