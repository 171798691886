/**
=========================================================
* Material Dashboard 2  React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useMemo, useState } from "react";
// porp-types is a library for typechecking of props
import PropTypes from "prop-types";
import useAxiosPriavte from "hooks/useAxiosPrivate";

import { styled } from "@mui/material/styles";
import useLogout from "hooks/useLogout";
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MUIToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { useMaterialUIController, setOpenConfigurator, setTimeframe } from "context";
import { LineChart } from '@mui/x-charts/LineChart';
import { Grid } from "@mui/material";
import constants from "../../../../constants";

function DryerLineChart({ index }) {
  const [controller, dispatch] = useMaterialUIController();
  const [chartData, setchartData] = useState([]);
  const {
    dryerData,
    dryerDataIndex,
    darkMode,
    auth,
    timeframe,
    serviceLogin,
  } = controller;

  const axiosPrivate = useAxiosPriavte();

  let op_mode = dryerData[index]?.runData ? dryerData[index]?.runData?.Op_Mode : 'N/A';

  const [tf, setTf] = useState('180');
  const generateTimeSeries = (interval) => {
    let today = new Date();
    //console.log(today)
    // set first timestamp
    // Get current minute
    // if m < 15 round to 0
    // if 15 < m < 30 round to 15
    // if 30 < m < 45 round to 30
    // if 45 < m < 59 round to 45
    // months are 0-11 not 1-12

    let dateArray = [];

    let m = today.getMinutes();
    let first = '';
    //console.log(today.getMonth() + ' ' + today.getDate() + ', ' + today.getFullYear() + ' ' + today.getHours()+':00:00')
    if (m < 15) {
      //first = today.getMonth()+1 + '/' + today.getDate() + " " + today.getHours() + ":" + '00'
      first = new Date(today.getMonth() + ' ' + today.getDate() + ', ' + today.getFullYear() + ' ' + today.getHours() + ':00:00')
    } else if (m >= 15 && m < 30) {
      //first = today.getMonth()+1 + '/' + today.getDate() + " " + today.getHours() + ":" + '15' 
      first = new Date(today.getMonth() + ' ' + today.getDate() + ', ' + today.getFullYear() + ' ' + today.getHours() + ':15:00')
    } else if (m >= 30 && m < 45) {
      //first = today.getMonth()+1 + '/' + today.getDate() + " " + today.getHours() + ":" + '30' 
      first = new Date(today.getMonth() + ' ' + today.getDate() + ', ' + today.getFullYear() + ' ' + today.getHours() + ':30:00')
    } else {
      //first = today.getMonth()+1 + '/' + today.getDate() + " " + today.getHours() + ":" + '45' 
      first = new Date(today.getMonth() + ' ' + today.getDate() + ', ' + today.getFullYear() + ' ' + today.getHours() + ':45:00')
    }

    //console.log('first: ' + first)

    // now loop 15 time with last date -15m and save to array
    var MS_PER_MINUTE = 60000;
    let second = new Date(first - 15 * MS_PER_MINUTE)

    dateArray.push(first);

    let count = 0;
    // 15 for 3 hours
    // 30 for 6 hours
    // 60 for 12 hours
    // 120 for 24 hours
    while (dateArray.length < 15) {
      let date = new Date(dateArray[count] - 15 * MS_PER_MINUTE)
      dateArray.push(date);
      count++;
    }

    let timeSeries = []

    dateArray.forEach((date) => {
      let hour = date.getHours();
      let min = date.getMinutes() == 0 ? '00' : date.getMinutes();
      let time = hour + ':' + min;
      //console.log(time);
      timeSeries.unshift(time);
    })

    return timeSeries;
  }
  const generateData = (type, timeframe) => {
    let data = [];

    switch (type) {
      case 'Grain_Infeed_Moisture':
        //console.log('Grain_Infeed_Moisture')
        chartData.forEach((element) => {
          data.push(element.Grain_Infeed_Moisture);
        });
        break;
      case 'Grain_Discharge_Moisture':
        chartData.forEach((element) => {
          data.push(element.Grain_Discharge_Moisture);
        });
        break;
      case 'Discharge_Commanded':
        chartData.forEach((element) => {
          data.push(element.Discharge_Commanded);
        });
        break;
      case 'Disch_Moist_SP':
        chartData.forEach((element) => {
          data.push(element.Disch_Moist_SP);
        });
        break;
      case 'time':
        chartData.forEach((element) => {
          data.push(element.time);
        });
        break;
      case 'Mid_Tmp_SP':
        chartData.forEach((element) => {
          data.push(element.Mid_Tmp_SP);
        });
        break;
      case 'Grain_Average_Mid_Temp':
        chartData.forEach((element) => {
          data.push(element.Grain_Average_Mid_Temp);
        });
        break;
      default:
        break;

    }
    return data;
  }

  function get_time_offset() {
    var d = new Date();
    var n = d.getTimezoneOffset();
    return n / 60;
  }

  const get_date_tf = () => {

    let today = new Date();
    var MS_PER_MINUTE = 60000;

    //last 3 hours
    //let date = new Date(today - 180 * MS_PER_MINUTE);

    //last 24 hours
    //let date = new Date(today - 1440 * MS_PER_MINUTE);

    let date = new Date(today - tf * MS_PER_MINUTE);

    // let year = date.getFullYear();
    // let month = date.getMonth() + 1;
    // let day = date.getDate();
    // let hour = date.getHours();
    // let min = date.getMinutes();
    // let sec = date.getSeconds();
    let year = date.getUTCFullYear();
    let month = date.getUTCMonth() + 1;
    let day = date.getUTCDate();
    let hour = date.getUTCHours();
    let min = date.getUTCMinutes();
    let sec = date.getUTCSeconds();

    if (month < 10) {
      month = '0' + month;
    }

    if (min < 10) {
      min = '0' + min;
    }

    if (hour < 10) {
      hour = '0' + hour;
    }

    if (sec < 10) {
      sec = '0' + sec;
    }

    let range = year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;
    return range;
  }
  const logout = useLogout();
  const getChartData = async (newAlignment) => {

    let record_id = dryerData[index].Dryer_Record_ID;

    if (newAlignment == undefined) {
      newAlignment = "180"
    }

    try {
      if (auth?.accessToken) {

        const response = await axiosPrivate.post('/getChartData', JSON.stringify({ record_id: record_id, timeframe: newAlignment, }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          });
        //console.log(response.status)
        setchartData(response.data)
      }

    } catch (e) {
      if (e.response.status == 403 || e.response.status == 401) {
        await logout();
      } else {
        console.log(e);
      }
    }
  };


  useEffect(() => {
    if (!document.hidden) {
      if (op_mode != "Stopped" && op_mode != null && op_mode != 'N/A') {
        if (sub == 1 || auth.USER_TYPE_ID >= serviceLogin) {
          getChartData(timeframe);
        }

      } else {
        setchartData("")
      }
    }

  }, [index, dryerData])

  const chartsParams = {
    margin: { bottom: 30, left: 40, right: 40 },
    height: 240,
  };

  let online = dryerData[index]?.Online_Status;
  let sub = dryerData[index]?.Sub_Status

  const handleTimeframe = (event, newAlignment) => {
    if (newAlignment !== null) {
      setTimeframe(dispatch, newAlignment);
      getChartData(newAlignment)
    }
  };

  let buttonColor = darkMode ? 'white' : 'black';

  const ToggleButton = styled(MUIToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white !important",
      backgroundColor: '#862633'
    }
  });

  // chart update gets all data from the last 24h slice the data in the chart comp
  let text_color = "#BEBEBE"
  let text_tf = timeframe == "180" ? "3h" : timeframe == "360" ? "6h" : timeframe == "720" ? "12h" : "24h"
  // "& .MuiChartsLegend-series text": { fontSize: "0.5em !important",fill: darkMode ? "white !important" : "black !important"  },
  return (
    <MDBox className="negative-margin" justifyContent="space-between">
      <Grid container spacing={0}>

        {chartData.length <= 1 || (sub == 0 && auth.USER_TYPE_ID < serviceLogin) ?
          <>
            <Grid item xs={12}>
              <Card style={{ paddingBottom: '12px', marginBottom: '12px' }}>
                <MDTypography style={{ width: '100%', height: '240px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                  {(sub == 0 && auth.USER_TYPE_ID < serviceLogin) ? 'Subscription Expired' : online == 0 ? 'Dryer Offline' : 'Dryer stopped no data to display'}
                </MDTypography>
              </Card>
              <Card style={{ paddingBottom: '12px', marginBottom: '12px' }}>
                <MDTypography style={{ width: '100%', height: '240px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                  {(sub == 0 && auth.USER_TYPE_ID < serviceLogin) ? 'Subscription Expired' : online == 0 ? 'Dryer Offline' : 'Dryer stopped no data to display'}
                </MDTypography>
              </Card>
              <Card style={{ paddingBottom: '12px' }}>
                <MDTypography style={{ width: '100%', height: '240px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                  {(sub == 0 && auth.USER_TYPE_ID < serviceLogin) ? 'Subscription Expired' : online == 0 ? 'Dryer Offline' : 'Dryer stopped no data to display'}
                </MDTypography>
              </Card>
            </Grid>
          </>
          :
          <>
            <Grid item xs={12}>
              <Card style={{ paddingBottom: '12px', marginBottom: '12px' }}>
                <MDBox style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <MDTypography style={{ paddingTop: '12px', paddingLeft: '15px', marginBottom: '-24px' }}>Moisture Trending - {text_tf} history</MDTypography>
                  <ToggleButtonGroup
                    color="primary"
                    value={timeframe}
                    exclusive
                    onChange={handleTimeframe}
                    aria-label="timeframe"
                  >
                    <ToggleButton id="tf1" value="180" style={{ color: buttonColor, borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}>3h</ToggleButton>
                    <ToggleButton id="tf2" value="360" style={{ color: buttonColor }}>6h</ToggleButton>
                    <ToggleButton id="tf3" value="720" style={{ color: buttonColor }}>12h</ToggleButton>
                    <ToggleButton id="tf4" value="1440" style={{ color: buttonColor, borderTopRightRadius: '8px', borderBottomRightRadius: '0' }}>24h</ToggleButton>
                  </ToggleButtonGroup>
                </MDBox>
                <LineChart skipAnimation
                  {...chartsParams}

                  sx={{
                    // y axis tick label color
                    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                      fill: darkMode ? "white" : "black"
                    },
                    // x axis tick lable color
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                      fill: darkMode ? "white" : "black"
                    },
                    // y axis tick color
                    "& .MuiChartsAxis-left .MuiChartsAxis-tick": {
                      stroke: darkMode ? "white" : "black"
                    },
                    // x axis tick color
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tick": {
                      stroke: darkMode ? "transparent" : "transparent"
                    },
                    // x axis line color
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                      stroke: darkMode ? "white" : "black"
                    },
                    // y axis line color
                    "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                      stroke: darkMode ? "white" : "black"
                    },
                    "& .MuiChartsAxisHighlight-root": {
                      stroke: darkMode ? "white" : "black"
                    }
                  }}
                  yAxis={[
                    {
                      min: 0,
                    },
                  ]}
                  xAxis={[{ scaleType: 'point', data: generateData('time') }]}
                  slotProps={{
                    legend: { hidden: true },
                    popper: {
                      placement: "top",
                      sx: {
                        //border: '1px solid white',
                        // borderRadius: '20px',
                        // backgroundColor: 'red',
                        '& .MuiChartsTooltip-root': {
                          backgroundColor: darkMode ? '#232323' : '#D2D2D2',
                          border: darkMode ? '1px solid white' : '',
                          '& .MuiTypography-root': {
                            color: darkMode ? "#ffffff" : "#000000",
                          },
                        },
                      },
                    },
                  }}
                  series={[
                    {
                      label: 'Infeed Moisture',
                      data: generateData('Grain_Infeed_Moisture'),
                      showMark: false,
                      color: '#3D81C7'
                    },
                    {
                      label: 'Discharge Moisture',
                      data: generateData('Grain_Discharge_Moisture'),
                      showMark: false,
                      color: "#CC3E3E"
                    },
                    {
                      label: '(SP)',
                      data: generateData('Disch_Moist_SP'),
                      showMark: false,
                      color: "#7D7D7D"
                    },
                  ]}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ paddingBottom: '12px', marginBottom: '12px' }}>
                <MDBox style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <MDTypography style={{ paddingTop: '12px', paddingLeft: '15px', marginBottom: '-24px' }}>Discharge Speed - {text_tf} history</MDTypography>
                </MDBox>
                <LineChart skipAnimation
                  {...chartsParams}

                  sx={{
                    // y axis tick label color
                    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                      fill: darkMode ? "white" : "black"
                    },
                    // x axis tick lable color
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                      fill: darkMode ? "white" : "black"
                    },
                    // y axis tick color
                    "& .MuiChartsAxis-left .MuiChartsAxis-tick": {
                      stroke: darkMode ? "white" : "black"
                    },
                    // x axis tick color
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tick": {
                      stroke: darkMode ? "transparent" : "transparent"
                    },
                    // x axis line color
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                      stroke: darkMode ? "white" : "black"
                    },
                    // y axis line color
                    "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                      stroke: darkMode ? "white" : "black"
                    },
                    "& .MuiChartsAxisHighlight-root": {
                      stroke: darkMode ? "white" : "black"
                    }
                  }}
                  yAxis={[
                    {
                      min: 0,
                      max: 100
                    },
                  ]}
                  xAxis={[{ scaleType: 'point', data: generateData('time') }]}
                  slotProps={{
                    legend: { hidden: true },
                    popper: {
                      placement: "top",
                      sx: {
                        //border: '1px solid white',
                        // borderRadius: '20px',
                        // backgroundColor: 'red',
                        '& .MuiChartsTooltip-root': {
                          backgroundColor: darkMode ? '#232323' : '#D2D2D2',
                          border: darkMode ? '1px solid white' : '',
                          '& .MuiTypography-root': {
                            color: darkMode ? "#ffffff" : "#000000",
                          },
                        },
                      },
                    },
                  }}
                  series={[
                    {
                      label: 'Discharge Speed',
                      data: generateData('Discharge_Commanded'),
                      showMark: false,
                      color: "#41C330"
                    },
                  ]}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={{ paddingBottom: '15px' }}>
                <MDTypography style={{ paddingTop: '15px', paddingLeft: '15px', marginBottom: '-24px' }}>Mid Grain Trending - {text_tf} history</MDTypography>
                <LineChart skipAnimation
                  {...chartsParams}
                  sx={{
                    // y axis tick label color
                    "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                      fill: darkMode ? "white" : "black"
                    },
                    // x axis tick lable color
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                      fill: darkMode ? "white" : "black"
                    },
                    // y axis tick color
                    "& .MuiChartsAxis-left .MuiChartsAxis-tick": {
                      stroke: darkMode ? "white" : "black"
                    },
                    // x axis tick color
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-tick": {
                      stroke: darkMode ? "transparent" : "transparent"
                    },
                    // x axis line color
                    "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                      stroke: darkMode ? "white" : "black"
                    },
                    // y axis line color
                    "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                      stroke: darkMode ? "white" : "black"
                    },
                    "& .MuiChartsAxisHighlight-root": {
                      stroke: darkMode ? "white" : "black"
                    },
                  }}
                  yAxis={[
                    {
                      min: 0,
                    },
                  ]}
                  xAxis={[{ scaleType: 'point', data: generateData('time') }]}
                  slotProps={{
                    legend: { hidden: true },
                    popper: {
                      placement: "top",
                      sx: {
                        //border: '1px solid white',
                        // borderRadius: '20px',
                        // backgroundColor: 'red',
                        '& .MuiChartsTooltip-root': {
                          backgroundColor: darkMode ? '#232323' : '#D2D2D2',
                          border: darkMode ? '1px solid white' : '',
                          '& .MuiTypography-root': {
                            color: darkMode ? "#ffffff" : "#000000",

                          },
                        },
                      },
                    },
                  }}
                  series={[
                    {
                      label: 'Mid Grain Avg Temp',
                      data: generateData('Grain_Average_Mid_Temp'),
                      showMark: false,
                      color: '#EEEB18'
                    },
                    {
                      label: 'Mid Grain SP',
                      data: generateData('Mid_Tmp_SP'),
                      showMark: false,
                      color: "#7D7D7D"
                    },
                  ]}
                />
              </Card>
            </Grid>
          </>
        }

      </Grid>
    </MDBox>
  );
}

// Setting default values for the props of ReportsLineChart
DryerLineChart.defaultProps = {
  color: "info",
  description: "",
};

// Typechecking props for the ReportsLineChart
DryerLineChart.propTypes = {
  index: PropTypes.number.isRequired,
  // color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  // title: PropTypes.string.isRequired,
  // description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // date: PropTypes.string.isRequired,
  // chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default DryerLineChart;
