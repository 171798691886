/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import Chip from '@mui/material/Chip';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography"
import { Virtuoso, VirtuosoGrid } from 'react-virtuoso'
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';

import { useMaterialUIController, setMultiDryer } from "context";

import DryerCardSmall from 'examples/Cards/DryerCards/DryerCardSmall'
import MDButton from "components/MDButton";
import constants from "../../../constants";

function MultiView() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    multiDryer,
    dryerData,
    darkMode
  } = controller;

  const navigate = useNavigate();
  useEffect(() => {
    if (dryerData.length < 2) {
      navigate('/dashboard/' + constants.SINGLE_VIEW, { replace: false });
    }
  }, [])

  useEffect(() => {
    Object.keys(multiDryer).forEach(function (key) {
      // each serial in multiDryer
      const serial = multiDryer[key].serial;

      // check to see if the serial is still in dryerData
      const index = dryerData.findIndex(e => e.Dryer_Serial_Name === serial);

      // if not remove from multidryer and remove card
      if (dryerData[index].Operating_Mode == 0) {
        const multiIndex = multiDryer.findIndex(e => e.serial === serial)
        const newSerial = multiDryer[multiIndex].serial;
        const newModel = multiDryer[multiIndex].model;
        const newName = multiDryer[multiIndex].name;

        // call handleMultiDryer with it's own index should remove it from the array
        removeCard(newSerial, newModel, newName);
      }
    });
  }, [dryerData])

  const removeCard = (serial, model, name) => {
    let dryer = {
      serial: serial,
      model: model,
      name: name
    }

    if (!multiDryer.some(e => e.serial === dryer.serial)) {
      setMultiDryer(dispatch, (multiDryer) => [...multiDryer, dryer]);
    } else {
      var array = [...multiDryer]; // make a separate copy of the array
      const index = array.findIndex(e => e.serial === dryer.serial);
      if (index > -1) {
        array.splice(index, 1);
        setMultiDryer(dispatch, array);
      }
    }
  }

  const getDryerIndex = (serial) => {
    const index = dryerData.findIndex(e => e.Dryer_Serial_Name === serial);
    //console.log(index)
    return index;
  }

  let runningArray = []

  Object.keys(dryerData).forEach(function (key) {
    if (dryerData[key].Operating_Mode != 0) {
      let dryer = {
        serial: dryerData[key].Dryer_Serial_Name,
        model: dryerData[key].Dryer_Model_Name,
        name: dryerData[key].BUSINESS
      }
      runningArray.push(dryer)
    }
  });

  let message = runningArray.length == 0 ? 'No running dryers' : 'No dryers selected'
  const gridComponents = {
    List: React.forwardRef(({ style, children, ...props }, ref) => (
      <div
        ref={ref}
        {...props}
        style={{
          display: "flex",
          flexWrap: "wrap",
          paddingRight: '12px',
          ...style,
        }}
      >
        {children}
      </div>
    )),
    Item: ({ children, ...props }) => (
      <div
        {...props}
        style={{
          paddingLeft: "12px",

          paddingBottom: "3px",
          paddingTop: "3px",
          width: "50%",
          display: "flex",
          flex: "none",
          alignContent: "stretch",
          boxSizing: "border-box",
          overflowX: 'hidden'
        }}
      >
        {children}
      </div>
    )
  }

  const getMultiDryer = () => {
    let arr = [];
    multiDryer?.map((item, key) => {
      arr.push(
        <div key={key}>{item.serial}</div>

        // <Grid item xs={12} md={6} lg={6} xl={6} xxl={4} key={key} style={{ paddingTop: '12px', paddingLeft: '0px' }} >
        //   <MDBox mb={0} style={{ paddingLeft: '12px' }}>
        //     <DryerCardSmall

        //       icon="home"
        //       dryer={item}
        //       count="0"
        //       removeCard={removeCard}
        //       index={getDryerIndex(item.serial)}
        //       data={{
        //         color: "success",
        //       }}

        //     />
        //   </MDBox>
        // </Grid>


      );
    })
    //console.log(arr)
    return arr;
  }

  const styles2 = {

    chipDark: {
      display: "flex",
      justifyContent: 'space-between',
      marginBottom: '6px',
      marginTop: '6px',

    },
    chipLight: {

    }
  };


  return (
    <MDBox mt={0}>
      {multiDryer.length == 0 ? <MDTypography style={{ width: '100%', textAlign: 'center', paddingTop: '24px' }}>{message}</MDTypography> : ''}

      {multiDryer.length < 7 ?
        <>
          <MDBox id="chip-container" style={{ display: 'block', marginTop: '6px' }}>
            {
              multiDryer.map((item, key) => {
                return (

                  <Chip
                    className={darkMode ? "multi-chip-dark" : "multi-chip-light"}
                    key={key}
                    style={{
                      color: darkMode ? 'white' : 'black',
                      marginRight: '12px',
                      marginTop: '6px',
                      backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.16)' : '#ffffff91'
                    }}
                    label={item.serial}
                    variant="outlined"
                    onClick={() => { removeCard(item.serial, item.model, item.name) }}
                    onDelete={() => { removeCard(item.serial, item.model, item.name) }}
                  />

                  // <MDButton key={key} color="primary" style={{marginRight:'12px', borderRadius:'25%'}} onClick={()=>{removeCard(item.serial, item.model, item.name)}}>{item.serial}</MDButton>

                );
              })
            }
          </MDBox>
        </>
        :
        <></>
      }
      <Grid container spacing={3} style={{ display: 'flex', paddingLeft: '12px', marginTop: '0' }}>
        {multiDryer.length < 7 ?
          <>              {
            multiDryer.map((item, key) => {
              return (
                <Grid item xs={12} md={6} lg={6} xl={6} xxl={4} key={key} style={{ paddingTop: '12px', paddingLeft: '0px' }} >
                  <MDBox mb={0} style={{ paddingLeft: '12px' }}>
                    <DryerCardSmall

                      icon="home"
                      dryer={item}
                      count="0"
                      removeCard={removeCard}
                      index={getDryerIndex(item.serial)}
                      data={{
                        color: "success",
                      }}

                    />
                  </MDBox>
                </Grid>
              );
            })
          }
          </>
          :
          <></>
        }
      </Grid>
    </MDBox>
  );
}

MultiView.propTypes = {
  children: PropTypes.any,
  style: PropTypes.any
};

export default MultiView;
