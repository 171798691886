/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import '../../../App.css'

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useMaterialUIController, setDryerData, setDryerDataIndex, setGlobalAlertMessage, setGlobalAlertColor, setGlobalAlertActive } from "context";
import React, { useState, useEffect } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import MDInput from 'components/MDInput';
import Icon from "@mui/material/Icon";
import NotificationItem from "examples/Items/NotificationItem";
import MDButton from 'components/MDButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import validator from "validator"
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import { setView } from 'context';

import constants from "../../../constants";

function AlertCard({ dryer }) {
    const [controller, dispatch] = useMaterialUIController();
    const axiosPrivate = useAxiosPrivate();

    const {
        dryerData,
        dryerDataIndex,
        auth,
        darkMode,
        view
    } = controller;

    const [alertData, setAlertData] = useState()

    const [data, setData] = useState({
        phone1: '',
        email1: '',
        email2: '',
        email3: '',
        email4: '',
        enablePhone1: false,
        enableEmail1: false,
        enableEmail2: false,
        enableEmail3: false,
        enableEmail4: false,
    });

    const [oldState, setOldState] = useState({
        oldPhone1: '',
        oldEmail1: '',
        oldEmail2: '',
        oldEmail3: '',
        oldEmail4: '',
        oldEnablePhone1: false,
        oldEnableEmail1: false,
        oldEnableEmail2: false,
        oldEnableEmail3: false,
        oldEnableEmail4: false,
    });

    const validatePhoneNumber = (number) => {
        // only allow us and canada phone numbers
        const isValidPhoneNumber = validator.isMobilePhone(number, ['en-US', 'en-CA'])
        return (isValidPhoneNumber)
    }

    const getUserAlertRecipients = async () => {

        let record_id = dryerData[dryerDataIndex]?.Dryer_Record_ID;
        let user_id = dryerData[dryerDataIndex]?.USER_ID;

        try {
            if (auth?.accessToken) {

                const response = await axiosPrivate.post('/getUserAlertRecipients', JSON.stringify({ record_id, user_id }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                let phoneData1 = response?.data?.smsData?.RECIPIENT_CELL_NO;

                let emailData1 = response?.data?.emailData[0]?.CONTACT;
                let emailData2 = response?.data?.emailData[1]?.CONTACT;
                let emailData3 = response?.data?.emailData[2]?.CONTACT;
                let emailData4 = response?.data?.emailData[3]?.CONTACT;

                let phoneActive1 = response?.data?.smsData?.ACTIVE_STATUS;

                let emailActive1 = response?.data?.emailData[0]?.ACTIVE_STATUS;
                let emailActive2 = response?.data?.emailData[1]?.ACTIVE_STATUS;
                let emailActive3 = response?.data?.emailData[2]?.ACTIVE_STATUS;
                let emailActive4 = response?.data?.emailData[3]?.ACTIVE_STATUS;



                setOldState({
                    ...oldState,
                    oldPhone1: phoneData1,
                    oldEmail1: emailData1,
                    oldEmail2: emailData2,
                    oldEmail3: emailData3,
                    oldEmail4: emailData4,
                    oldEnablePhone1: phoneActive1 == 1 ? true : false,
                    oldEnableEmail1: emailActive1 == 1 ? true : false,
                    oldEnableEmail2: emailActive2 == 1 ? true : false,
                    oldEnableEmail3: emailActive3 == 1 ? true : false,
                    oldEnableEmail4: emailActive4 == 1 ? true : false,
                });

                response.data.Dryer_Record_ID = dryerData[dryerDataIndex]?.Dryer_Record_ID;

                setData({
                    ...data,
                    phone1: phoneData1,
                    email1: emailData1,
                    email2: emailData2,
                    email3: emailData3,
                    email4: emailData4,
                    enablePhone1: phoneActive1 == 1 ? true : false,
                    enableEmail1: emailActive1 == 1 ? true : false,
                    enableEmail2: emailActive2 == 1 ? true : false,
                    enableEmail3: emailActive3 == 1 ? true : false,
                    enableEmail4: emailActive4 == 1 ? true : false,
                });

                response.data.Dryer_Record_ID = dryerData[dryerDataIndex]?.Dryer_Record_ID;

                setAlertData(response.data)

            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {

        getUserAlertRecipients();

    }, [dryerDataIndex]);

    let id = dryerData[dryerDataIndex]?.USER_ID;

    const [checked, setChecked] = React.useState(true);

    const handleChange = (event, emailNumber) => {
        switch (emailNumber) {
            case "0":
                setData({ ...data, enablePhone1: event.target.checked })
                break;
            case "1":
                setData({ ...data, enableEmail1: event.target.checked })
                break;
            case "2":
                setData({ ...data, enableEmail2: event.target.checked })
                break;
            case "3":
                setData({ ...data, enableEmail3: event.target.checked })
                break;
            case "4":
                setData({ ...data, enableEmail4: event.target.checked })
                break;
            default:
                // code block
                break;
        }
    };

    const outerTheme = createTheme({
        palette: {
            primary: {
                main: '#862633',
            },
        },
    });


    const handleSMSUpdate = async (type) => {

        try {

            let id = dryerData[dryerDataIndex]?.USER_ID;

            if (auth?.accessToken) {
                //let serial = dryerData[index].Dryer_Serial_Name
                const response = await axiosPrivate.post('/handleSMSUpdate',
                    JSON.stringify({
                        SMS_ACTION: oldState?.oldPhone1 == undefined ? 'NEW' : 'UPDATE',
                        INDEX_NO: '1',
                        RECIPIENT_ID: oldState?.oldPhone1 == undefined ? '0' : alertData?.smsData?.RECIPIENT_ID,
                        Dryer_Record_ID: alertData?.Dryer_Record_ID,
                        RECIPIENT_TYPE: '1',
                        USER_TYPE_ID: alertData?.smsData?.USER_TYPE_ID == undefined ? '1' : alertData?.smsData?.USER_TYPE_ID,
                        USER_ID: alertData?.smsData?.USER_ID == undefined ? id : alertData?.smsData?.USER_ID,
                        RECIPIENT_CELL_NO: data.phone1 == undefined ? '' : data.phone1,
                        ENABLE_SMS: data.enablePhone1 ? 1 : 0,

                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                if (response.status == 200) {
                    displaySuccess();
                    getUserAlertRecipients();
                } else {
                    displayFail();
                }

            }
            //}
        } catch (e) {
            //setRemoteError('Unable to load remote data, please try again later');
            console.log(e);
        }
    };
    const handleEmailUpdate = async (type) => {

        try {

            let id = dryerData[dryerDataIndex]?.USER_ID;

            if (auth?.accessToken) {
                //let serial = dryerData[index].Dryer_Serial_Name
                const response = await axiosPrivate.post('/handleEmailUpdate',
                    JSON.stringify({
                        EMAIL_ACTION_1: oldState?.oldEmail1 == undefined ? 'NEW' : 'UPDATE',
                        INDEX_NO_1: '1',
                        RECIPIENT_ID_1: oldState?.oldEmail1 == undefined ? '0' : alertData?.emailData[0]?.RECIPIENT_ID,
                        Dryer_Record_ID_1: alertData?.Dryer_Record_ID,
                        RECIPIENT_TYPE_1: '0',
                        USER_TYPE_ID_1: alertData?.emailData[0]?.USER_TYPE_ID == undefined ? '1' : alertData?.emailData[0]?.USER_TYPE_ID,
                        USER_ID_1: alertData?.emailData[0]?.USER_ID == undefined ? id : alertData?.emailData[0]?.USER_ID,
                        RECIPIENT_EMAIL_ADDRESS_1: data.email1 == undefined ? '' : data.email1,
                        ENABLE_Email_1: data.enableEmail1 ? 1 : 0,
                        EMAIL_ACTION_2: oldState?.oldEmail2 == undefined ? 'NEW' : 'UPDATE',
                        INDEX_NO_2: '2',
                        RECIPIENT_ID_2: oldState?.oldEmail2 == undefined ? '0' : alertData?.emailData[1]?.RECIPIENT_ID,
                        Dryer_Record_ID_2: alertData?.Dryer_Record_ID,
                        RECIPIENT_TYPE_2: '0',
                        USER_TYPE_ID_2: alertData?.emailData[1]?.USER_TYPE_ID == undefined ? '1' : alertData?.emailData[1]?.USER_TYPE_ID,
                        USER_ID_2: alertData?.emailData[1]?.USER_ID == undefined ? id : alertData?.emailData[1]?.USER_ID,
                        RECIPIENT_EMAIL_ADDRESS_2: data.email2 == undefined ? '' : data.email2,
                        ENABLE_Email_2: data.enableEmail2 ? 1 : 0,
                        EMAIL_ACTION_3: oldState?.oldEmail3 == undefined ? 'NEW' : 'UPDATE',
                        INDEX_NO_3: '3',
                        RECIPIENT_ID_3: oldState?.oldEmail3 == undefined ? '0' : alertData?.emailData[2]?.RECIPIENT_ID,
                        Dryer_Record_ID_3: alertData?.Dryer_Record_ID,
                        RECIPIENT_TYPE_3: '0',
                        USER_TYPE_ID_3: alertData?.emailData[2]?.USER_TYPE_ID == undefined ? '1' : alertData?.emailData[2]?.USER_TYPE_ID,
                        USER_ID_3: alertData?.emailData[2]?.USER_ID == undefined ? id : alertData?.emailData[2]?.USER_ID,
                        RECIPIENT_EMAIL_ADDRESS_3: data.email3 == undefined ? '' : data.email3,
                        ENABLE_Email_3: data.enableEmail3 ? 1 : 0,
                        EMAIL_ACTION_4: oldState?.oldEmail4 == undefined ? 'NEW' : 'UPDATE',
                        INDEX_NO_4: '4',
                        RECIPIENT_ID_4: oldState?.oldEmail4 == undefined ? '0' : alertData?.emailData[3]?.RECIPIENT_ID,
                        Dryer_Record_ID_4: alertData?.Dryer_Record_ID,
                        RECIPIENT_TYPE_4: '0',
                        USER_TYPE_ID_4: alertData?.emailData[3]?.USER_TYPE_ID == undefined ? '1' : alertData?.emailData[3]?.USER_TYPE_ID,
                        USER_ID_4: alertData?.emailData[3]?.USER_ID == undefined ? id : alertData?.emailData[3]?.USER_ID,
                        RECIPIENT_EMAIL_ADDRESS_4: data.email4 == undefined ? '' : data.email4,
                        ENABLE_Email_4: data.enableEmail4 ? 1 : 0,

                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                if (response.status == 200) {
                    displaySuccess();
                    getUserAlertRecipients();
                } else {
                    displayFail();
                }

            }
            //}
        } catch (e) {
            console.log(e);
        }
    };

    const displaySuccess = () => {
        setGlobalAlertActive(dispatch, true);
        setGlobalAlertColor(dispatch, "success");
        setGlobalAlertMessage(dispatch, "Alert recipients updated successfully");
    }
    const displayFail = () => {
        setGlobalAlertActive(dispatch, true);
        setGlobalAlertColor(dispatch, "error");
        setGlobalAlertMessage(dispatch, "Unable to update alert recipients, please try again later");
    }

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const [phoneError1, setPhoneError1] = useState(false);

    const [emailError1, setEmailError1] = useState(false);
    const [emailError2, setEmailError2] = useState(false);
    const [emailError3, setEmailError3] = useState(false);
    const [emailError4, setEmailError4] = useState(false);

    const validateEmail = (email, i) => {
        if (email && email.match(isValidEmail)) {
            switch (i) {
                case "1":
                    setEmailError1(false)
                    break;
                case "2":
                    setEmailError2(false)
                    break;
                case "3":
                    setEmailError3(false)
                    break;
                case "4":
                    setEmailError4(false)
                    break;
                default:
                    // code block
                    break;
            }
        } else {
            switch (i) {
                case "1":
                    setEmailError1(true)
                    break;
                case "2":
                    setEmailError2(true)
                    break;
                case "3":
                    setEmailError3(true)
                    break;
                case "4":
                    setEmailError4(true)
                    break;
                default:
                    // code block
                    break;
            }
        }
    }

    useEffect(() => {
        if (data.phone1 != "" && data.phone1 != undefined) {
            let validPhone = validatePhoneNumber(data.phone1);
            if (validPhone) {
                setPhoneError1(false);
            } else {
                setPhoneError1(true);
            }
        } else {
            setPhoneError1(false);
        }
        if (data.email1 != "" && data.email1 != undefined) {
            validateEmail(data.email1, "1");
        } else {
            setEmailError1(false);
        }
        if (data.email2 != "" && data.email2 != undefined) {
            validateEmail(data.email2, "2");
        } else {
            setEmailError2(false);
        }
        if (data.email3 != "" && data.email3 != undefined) {
            validateEmail(data.email3, "3");
        } else {
            setEmailError3(false);
        }
        if (data.email4 != "" && data.email4 != undefined) {
            validateEmail(data.email4, "4");
        } else {
            setEmailError4(false);
        }

    }, [data])

    const disableEmailButton = () => {
        if (emailError1 || emailError2 || emailError3 || emailError4) {
            return true;
        } else {
            if (data.email1 == oldState.oldEmail1 && data.email2 == oldState.oldEmail2 && data.email3 == oldState.oldEmail3 && data.email4 == oldState.oldEmail4
                && data.enableEmail1 == oldState.oldEnableEmail1 && data.enableEmail2 == oldState.oldEnableEmail2 && data.enableEmail3 == oldState.oldEnableEmail3 && data.enableEmail4 == oldState.oldEnableEmail4) {
                return true;
            } else {
                return false;
            }
        }
    }
    const disableEmailErrorMsg = () => {
        if (emailError1 || emailError2 || emailError3 || emailError4) {
            return true;
        } else {
            return false;
        }
    }

    const disablePhoneButton = () => {
        if (phoneError1) {
            return true;
        } else {
            if (data.phone1 == oldState.oldPhone1 && data.enablePhone1 == oldState.oldEnablePhone1) {
                return true;
            } else {
                return false;
            }
        }
    }
    const disablePhoneErrorMsg = () => {
        if (phoneError1) {
            return true;
        } else {
            return false;
        }
    }

    const handleCancel = (event, index) => {
        switch (index) {
            case "phone":
                setData({ ...data, phone1: oldState.oldPhone1, enablePhone1: oldState.oldEnablePhone1 })

                break;
            case "email":
                setData({
                    ...data,
                    email1: oldState.oldEmail1,
                    email2: oldState.oldEmail2,
                    email3: oldState.oldEmail3,
                    email4: oldState.oldEmail4,
                    enableEmail1: oldState.oldEnableEmail1,
                    enableEmail2: oldState.oldEnableEmail2,
                    enableEmail3: oldState.oldEnableEmail3,
                    enableEmail4: oldState.oldEnableEmail4
                })
                break;
            default:
                // code block
                break;
        }
    }

    let disableEmail = disableEmailButton();
    let disablePhone = disablePhoneButton();

    let disablePhoneError = disablePhoneErrorMsg();
    let disableEmailError = disableEmailErrorMsg();

    let emailButtonColor = disableEmail ? 'gray' : '#862633'
    let phoneButtonColor = disablePhone ? 'gray' : '#862633'

    let serial = dryerData[dryerDataIndex].Dryer_Serial_Name;

    const styles = {
        input: {
            //WebkitTextFillColor: darkMode ? 'white' : 'black'
        },
    }

    const handleBack = () => {
        setView(dispatch, constants.SINGLE_VIEW)
    }

    return (
        <Card className="full shadow">
            <Grid container spacing={1} style={{ justifyContent: 'space-between', marginTop: '16px' }}>
                <Grid item xs={4}>
                    <IconButton
                        style={{ fontSize: '20px', paddingLeft: '16px' }}
                        size="small"
                        disableRipple
                        variant="contained"
                        onClick={handleBack}
                        className="hover-back"
                    >
                        <Icon style={{ color: darkMode ? 'white' : 'black' }}>arrow_back_ios_new</Icon>
                        <MDTypography component={'p'} style={{ fontSize: '14px', marginLeft: '8px' }}>Dashboard</MDTypography>
                    </IconButton>
                </Grid>
                <Grid item xs={8}>
                    <MDTypography style={{ fontSize: '14px', textAlign: 'right', paddingRight: '24px', paddingTop: '4px' }}>
                        Dryer Serial: {serial}
                    </MDTypography>
                </Grid>
            </Grid>
            {/* {alertActive ? <MDAlert color={alertColor} style={{ fontSize: '16px', marginBottom: '0', position: 'absolute', width: '100%' }}>{alertMessage}</MDAlert> : null} */}
            <MDBox pt={4} pb={3} px={3} component="form" role="form" >
                <Grid container spacing={1} style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} md={6} >
                        <Grid container style={{ display: 'flex', width: '100%' }}>
                            <Grid item xs={12} mb={4}>
                                <MDTypography style={{ fontSize: '14px' }}>
                                    To receive alarm notifications, enter your cell phone number for SMS text messages* or your E-Mail address for E-Mail notifications,
                                    and then check the enable box.
                                </MDTypography>
                            </Grid>
                        </Grid>
                        <Grid container mb={1} style={{ display: 'flex', width: '100%' }}>
                            <Grid item xs={10}>
                                <MDTypography style={{ fontSize: '14px' }}>
                                    Cell Phone Number*
                                </MDTypography>
                            </Grid>
                            <Grid item xs={2}>
                                <MDTypography style={{ fontSize: '14px', textAlign: 'center' }}>
                                    Enable
                                </MDTypography>
                            </Grid>
                        </Grid>
                        <Grid container mb={4} tyle={{ display: 'flex', width: '100%' }}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <MDInput className={phoneError1 ? 'error-style' : ''} style={styles.input} error={phoneError1} autoComplete="off" type="tel" label="Phone" fullWidth value={data.phone1 || ''} onChange={(e) => setData({ ...data, phone1: e.target.value })} />
                                    </Grid>
                                    <Grid item xs={2} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                        <ThemeProvider theme={outerTheme}>
                                            <Checkbox
                                                className="checkbox-style"
                                                checked={data.enablePhone1 ? data.enablePhone1 : false}
                                                //style={{ marginLeft: '18px' }}
                                                onChange={(e) => handleChange(e, '0')}
                                            />
                                        </ThemeProvider>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <MDButton disabled={disablePhone} style={{ width: '100%', marginTop: '12px', backgroundColor: phoneButtonColor, color: 'white' }} color="primary" onClick={handleSMSUpdate}>UPDATE SMS</MDButton>
                                        {disablePhoneError ? <MDTypography color="error" fontSize="16px" style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px' }}>Please enter a valid phone number</MDTypography> : null}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {data.phone1 != oldState.oldPhone1 || data.enablePhone1 != oldState.oldEnablePhone1 ?
                                            <><MDButton onClick={(e) => handleCancel(e, 'phone')} variant="text" color="error" style={{ marginTop: '12px', width: '100%' }}>Cancel</MDButton></>
                                            :
                                            <></>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container mb={1} style={{ display: 'flex', width: '100%' }}>
                            <Grid item xs={10}>
                                <MDTypography style={{ fontSize: '14px' }}>
                                    E-Mail Address
                                </MDTypography>
                            </Grid>
                            <Grid item xs={2}>
                                <MDTypography style={{ fontSize: '14px', textAlign: 'center' }}>
                                    Enable
                                </MDTypography>
                            </Grid>
                        </Grid>
                        <Grid container mb={2} style={{ display: 'flex', width: '100%' }}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <MDInput className={emailError1 ? 'error-style' : ''} style={styles.input} error={emailError1} autoComplete="off" type="email" label="Email #1" fullWidth value={data.email1 || ''} onChange={(e) => setData({ ...data, email1: e.target.value })} />
                                </Grid>
                                <Grid item xs={2} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                    <ThemeProvider theme={outerTheme}>
                                        <Checkbox
                                            className="checkbox-style"
                                            checked={data.enableEmail1 ? data.enableEmail1 : false}
                                            //style={{ marginLeft: '18px' }}
                                            onChange={(e) => handleChange(e, '1')}
                                        />
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container mb={2} style={{ display: 'flex', width: '100%' }}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <MDInput className={emailError2 ? 'error-style' : ''} style={styles.input} error={emailError2} autoComplete="off" type="email" label="Email #2" fullWidth value={data.email2 || ''} onChange={(e) => setData({ ...data, email2: e.target.value })} />
                                </Grid>
                                <Grid item xs={2} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                    <ThemeProvider theme={outerTheme}>
                                        <Checkbox
                                            className="checkbox-style"
                                            checked={data.enableEmail2 ? data.enableEmail2 : false}
                                            //style={{ marginLeft: '18px' }}
                                            onChange={(e) => handleChange(e, '2')}
                                        />
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container mb={2} style={{ display: 'flex', width: '100%' }}>
                            <Grid container>
                                <Grid item xs={10} >
                                    <MDInput className={emailError3 ? 'error-style' : ''} style={styles.input} error={emailError3} autoComplete="off" type="email" label="Email #3" fullWidth value={data.email3 || ''} onChange={(e) => setData({ ...data, email3: e.target.value })} />
                                </Grid>
                                <Grid item xs={2} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                    <ThemeProvider theme={outerTheme}>
                                        <Checkbox
                                            className="checkbox-style"
                                            checked={data.enableEmail3 ? data.enableEmail3 : false}
                                            //style={{ marginLeft: '18px' }}
                                            onChange={(e) => handleChange(e, '3')}
                                        />
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container mb={4} style={{ display: 'flex', width: '100%' }}>
                            <Grid container>
                                <Grid item xs={10} >
                                    <MDInput className={emailError4 ? 'error-style' : ''} style={styles.input} error={emailError4} autoComplete="off" type="email" label="Email #4" fullWidth value={data.email4 || ''} onChange={(e) => setData({ ...data, email4: e.target.value })} />
                                </Grid>
                                <Grid item xs={2} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                    <ThemeProvider theme={outerTheme}>
                                        <Checkbox
                                            className="checkbox-style"
                                            checked={data.enableEmail4 ? data.enableEmail4 : false}
                                            //style={{ marginLeft: '18px' }}
                                            onChange={(e) => handleChange(e, '4')}
                                        />
                                    </ThemeProvider>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={10}>
                                    <MDButton disabled={disableEmail} style={{ width: '100%', marginTop: '12px', backgroundColor: emailButtonColor, color: 'white' }} onClick={handleEmailUpdate}>UPDATE E-Mail</MDButton>
                                    {disableEmailError ? <MDTypography color="error" fontSize="16px" style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px' }}>Please enter a valid email address</MDTypography> : null}
                                </Grid>
                                <Grid item xs={2}>
                                    {data.email1 != oldState.oldEmail1 ||
                                        data.email2 != oldState.oldEmail2 ||
                                        data.email3 != oldState.oldEmail3 ||
                                        data.email4 != oldState.oldEmail4 ||
                                        data.enableEmail1 != oldState.oldEnableEmail1 ||
                                        data.enableEmail2 != oldState.oldEnableEmail2 ||
                                        data.enableEmail3 != oldState.oldEnableEmail3 ||
                                        data.enableEmail4 != oldState.oldEnableEmail4 ?
                                        <><MDButton onClick={(e) => handleCancel(e, 'email')} variant="text" color="error" style={{ marginTop: '12px', width: '100%' }}>Cancel</MDButton></>
                                        :
                                        <></>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container style={{ display: 'flex', width: '100%' }}>
                            <Grid item xs={12} mb={2}>
                                <MDTypography style={{ fontSize: '14px' }}>
                                    *Note: Standard text messaging rates may apply based on your mobile phone carrier&apos;s plan.
                                    As text message deliveries subject to mobile carrier network availability, such delivery is not guaranteed.
                                    You may opt out of SMS text message delivery at any time by removing your cell phone number above or unchecking the enable box.
                                </MDTypography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
        </Card >
    );
}

// Typechecking props for the DryerCard3
AlertCard.propTypes = {
    dryer: PropTypes.object,
};


export default AlertCard;
