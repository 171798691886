/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import '../../../App.css'

import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import useAxiosPriavte from "hooks/useAxiosPrivate";
import React, { useState, useEffect } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from 'components/MDInput';
import Modal from '@mui/material/Modal';
import MDButton from 'components/MDButton';

import {
    useMaterialUIController,
    setGlobalAlertMessage,
    setGlobalAlertActive,
    setGlobalAlertColor,
} from "context";

function FeedbackCard({ handleModal, open }) {
    const [controller, dispatch] = useMaterialUIController();
    const axiosPrivate = useAxiosPriavte();

    const {
        darkMode,
        auth,
    } = controller;

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        //height: 500,
        bgcolor: darkMode ? '#141414' : '#E6E6E6',
        border: darkMode ? '2px solid #fff' : '2px solid #000',
        boxShadow: 24,
        borderRadius: '8px',
        p: 4,
    };

    const displaySuccess = () => {
        setGlobalAlertActive(dispatch, true);
        setGlobalAlertColor(dispatch, "success");
        setGlobalAlertMessage(dispatch, "Feedback submitted successfully!");
    }
    const displayFail = () => {
        setGlobalAlertActive(dispatch, true);
        setGlobalAlertColor(dispatch, "error");
        setGlobalAlertMessage(dispatch, "Unable to submit feedback, please try again later");
    }

    const submitFeedback = async () => {

        try {
            if (auth?.accessToken) {

                const response = await axiosPrivate.post('/submitFeedback', JSON.stringify({ user_id: auth.USER_ID, user_name: auth.User, user_email: auth.EMail_Address, user_type: auth.USER_TYPE_ID, user_feedback: feedback }),
                    {
                        headers: { 'Content-Type': 'application/json'},
                        withCredentials: true
                    });

                if (response.data == 'success') {
                    displaySuccess();
                    setFeedback('');
                    setFeedbackLength(0);
                } else {
                    displayFail();
                    setFeedback('');
                    setFeedbackLength(0);
                }

                handleModal();
            }
        } catch (e) {
            console.log(e)
        }

    }

    const [feedback, setFeedback] = useState('');
    const [feedBackLength, setFeedbackLength] = useState(0);
    let maxLen = 500;

    let disabledButton = feedback == '' ? true : false;

    const onSetFeedback = (e) => {

        const message = e.target.value.slice(0, maxLen);

        setFeedback(message);
        setFeedbackLength(message.length);

    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <MDBox className="feedback-form" sx={style}>
                    <MDBox
                        display={{ xs: "block" }}
                        position="absolute"
                        top={0}
                        right={0}
                        p={1.625}
                        onClick={handleModal}
                        sx={{ cursor: "pointer" }}
                    >
                        <MDTypography variant="h6" color="secondary">
                            <Icon className="button-hover" sx={{ fontWeight: "bold" }} style={{ color: darkMode ? "white" : "black" }}>close</Icon>
                        </MDTypography>
                    </MDBox>
                    <MDTypography style={{ fontSize: "24px", fontWeight: "500" }}>Tell us what you think!</MDTypography>
                    <MDBox style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MDTypography style={{ fontSize: "16px", marginTop: '12px' }}>Required*</MDTypography>
                        <MDTypography style={{ fontSize: "16px", marginTop: '12px' }}>{feedBackLength}/{maxLen}</MDTypography>
                    </MDBox>

                    {/* <MDBox display="flex" className={darkMode ? "feedback-light" : "feedback-dark"}>
                        <MDInput label="Type here..." style={{ width: '100%', marginTop: '12px' }} />
                        <sub style={{ color: darkMode ? 'white' : 'black' }}>*</sub>
                    </MDBox> */}
                    <MDBox display="flex" className={darkMode ? "feedback-light" : "feedback-dark"}>
                        <MDInput type="text" maxLength={10} onChange={(e) => onSetFeedback(e)} value={feedback} label="Type here..." multiline rows={10} style={{ width: '100%', marginTop: '12px' }} />
                        <sub style={{ color: darkMode ? 'white' : 'black' }}>*</sub>
                    </MDBox>
                    <MDBox style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
                        <MDButton disabled={disabledButton} style={{ marginTop: '12px', color: 'white', backgroundColor: disabledButton ? "gray" : "#862633" }} onClick={submitFeedback}>Submit</MDButton>
                    </MDBox>
                </MDBox>

            </Modal>
        </>
    );
}

// Typechecking props for the DryerCard3
FeedbackCard.propTypes = {
    open: PropTypes.bool,
    handleModal: PropTypes.func,
};


export default FeedbackCard;
