/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

import { useNavigate } from "react-router-dom"

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import useLogout from "hooks/useLogout";

// Images
import bgImage from "assets/images/mc/login-background.jpg";
import mctrax_logo_white from 'assets/images/mc/mctrax-logo-white.svg'
import mctrax_logo from 'assets/images/mc/mctrax-logo.svg'

import axios from '../../../api/axios';
import {
  useMaterialUIController,
  setAuth,
} from "context";

function Basic() {

  const navigate = useNavigate()

  const [controller, dispatch] = useMaterialUIController();

  const { auth, darkMode, globalError } = controller;

  const [rememberMe, setRememberMe] = useState(false);

  const [loginError, setLoginError] = useState('');

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [data, setData] = useState({
    username: '',
    password: '',
  });

  const handleLogout = async () => {
    // if used in more components, this should be in context 
    // axios to /logout endpoint 
    setAuth(dispatch, {});
  }

  const logout = useLogout();
  const signOut = async () => {
    await logout();

  }

  useEffect(() => {
    if (globalError != '') {
      setLoginError(globalError)
    }
  }, [globalError])

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/login', JSON.stringify({ user: data.username, pass: data.password }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        });

      //const accessToken = response?.data?.accessToken;
      //const roles = response?.data?.roles;
      setLoginError('');
      setAuth(dispatch, response.data);
      //navigate(`/dashboard`)
      window.location.href = "/dashboard"
    } catch (err) {
      if (!err?.response) {
        setLoginError('Unable to login at this time, plesae try again later');
      } else if (err.response?.status === 400) {
        setLoginError('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setLoginError('Incorrect username or password')
      } else {
        setLoginError('Login Failed')
      }
    }
  }
  const buttonRef = useRef(null);
  useEffect(() => {
    const onEnter = (event) => {
      if (event.key === 'Enter') {
        buttonRef.current?.click()
      }
    }

    window.addEventListener('keyup', onEnter);

    return () => {
      window.removeEventListener('keyup', onEnter);
    }
  }, []);


  const getMode = () => localStorage.getItem('darkMode');

  let mode = getMode();

  if(mode == null){
    mode = darkMode;
  } else if(mode =='true') {
    mode = true;
  } else {
    mode = false;
  }

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          borderRadius="lg"
          mx={2}
          mt={3}
          p={2}
          mb={1}
          textAlign="center"
        >
          {mode ? <MDBox component="img" src={mctrax_logo_white} /> : <MDBox component="img" src={mctrax_logo} />}

        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput id="user-input" type="email" label="username" fullWidth value={data.username} onChange={(e) => setData({ ...data, username: e.target.value })} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput id="pass-input" type="password" label="Password" fullWidth value={data.password} onChange={(e) => setData({ ...data, password: e.target.value })} />
            </MDBox>
            <MDBox style={{ fontSize: '14px', color: 'red', textAlign: 'center' }}>{loginError}</MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton ref={buttonRef} variant="gradient" color="primary" fullWidth onClick={handleLogin} >
                sign in
              </MDButton>
              <MDBox style={{display:'flex', justifyContent:'center'}}>
                <a id="underline" href="https://mctrax.net" style={{ color: '#fff', fontSize: '16px', marginTop: '24px', display: 'block', textDecoration: 'underline' }}>Old interface</a>
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
