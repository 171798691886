/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

import '../../../../App.css';
import moisture_out_svg from '../../../../assets/images/mc/moisture-out.svg';
import setpoint_svg from '../../../../assets/images/mc/setpoint.svg';
import temperature_svg from '../../../../assets/images/mc/temperature.svg';
import setpoint_svg_dark from '../../../../assets/images/mc/setpoint-dark.svg'
import temperature_svg_dark from '../../../../assets/images/mc/temperature-dark.svg'

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";

import outTableData from "../DryerCardData/DryerOutData";
import { useMaterialUIController, setDataLog } from "context";
import CardBackCommingSoon from "./CardBackComingSoon";

import useAxiosPrivate from "hooks/useAxiosPrivate";

import constants from "../../../../constants"

function CardBackMoistureOut({ index, handleFlip, destination, comingSoon, isFlipped }) {

    const axiosPrivate = useAxiosPrivate();

    const [dataLog, setDataLog] = useState([])

    const [testRows, setTestRows] = useState([]);

    const { columns, rows } = outTableData();
    const [controller, dispatch] = useMaterialUIController();
    const {
        dryerData,
        darkMode,
        auth,
        //dataLog
    } = controller;

    let serial = dryerData[index]?.Dryer_Serial_Name;

    let moist_out = dryerData[index]?.runData ? dryerData[index]?.runData?.Grain_Discharge_Moisture : 'N/A';
    let moist_out_sp = dryerData[index]?.runData ? dryerData[index]?.runData?.Disch_Moist_SP : 'N/A';
    let grain_out_temp = dryerData[index]?.runData ? (Math.round(dryerData[index]?.runData?.Grain_Discharge_Temp * 100) / 100).toFixed(0) : 'N/A';

    let online = dryerData[index]?.Online_Status;
    let sub = dryerData[index]?.Sub_Status;

    let op = dryerData[index].Operating_Mode;

    let temperature_svg_color = darkMode ? temperature_svg_dark : temperature_svg;
    let setpoint_svg_color = darkMode ? setpoint_svg_dark : setpoint_svg;

    Date.prototype.stdTimezoneOffset = function () {
        var jan = new Date(this.getFullYear(), 0, 1);
        var jul = new Date(this.getFullYear(), 6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    }

    Date.prototype.isDstObserved = function () {
        return this.getTimezoneOffset() < this.stdTimezoneOffset();
    }

    function get_time_offset() {
        var d = new Date();
        var n = d.getTimezoneOffset();
        return n / 60;
    }

    function conv_local_date_time(a_date) {

        let timezone_offset = get_time_offset();
        var ds = a_date.split(" ");
        var d = new Date(ds[0] + "T" + ds[1] + "-05:00");
        d.setHours(d.getHours() - timezone_offset);
        var hours = d.getHours();
        var minutes = d.getMinutes();
        var seconds = d.getSeconds();
        var date = (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
        var timeString = date;
        timeString += "  " + ((hours > 12) ? hours - 12 : hours);
        timeString += ((minutes < 10) ? ":0" : ":") + minutes;
        timeString += ((seconds < 10) ? ":0" : ":") + seconds;
        timeString += (hours >= 12) ? " P.M." : " A.M.";

        return timeString;
    }

    const getTime = (timestamp) => {

        var today = new Date();
        if (today.isDstObserved()) {
            //console.log("Daylight saving time!");
        }

        let dateLocal = new Date(timestamp)
        // let dateLocal = new Date('2024-10-09T14:50:23.000Z')

        let offset = dateLocal.getTimezoneOffset();

        dateLocal.setHours(dateLocal.getHours() - offset);

        let year = dateLocal.getFullYear();
        let month = dateLocal.getMonth() + 1;
        let day = dateLocal.getDate();
        let hour = dateLocal.getHours();
        let min = dateLocal.getMinutes();
        let sec = dateLocal.getSeconds();

        month = month < 10 ? '0' + month : month;
        min = min < 10 ? '0' + min : min;
        //hour = hour < 10 ? '0' + hour : hour;
        sec = sec < 10 ? '0' + sec : sec;

        let newTimestamp = hour + ':' + min;

        return newTimestamp;
    }

    function convert24to12(time24) {
        // Split the time string into hours and minutes
        const [hours, minutes] = time24.split(':').map(Number);

        // Determine AM/PM
        //const period = hours >= 12 ? 'PM' : 'AM';
        const period = hours >= 12 ? '' : '';

        // Adjust hours for 12-hour format
        const hours12 = hours % 12 || 12; // Handle 00:00 as 12:00 AM

        // Format and return the 12-hour time string
        return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
    }


    const getRows = () => {

        let serial = dryerData[index]?.Dryer_Serial_Name;
        const arrayIndex = dataLog.findIndex(e => e.serial === serial);

        let arr = [];

        if (dataLog.length > 0) {

            Object.keys(dataLog).forEach(function (key) {

                let timestamp = getTime(dataLog[key]['TIMESTAMP_S']);
                let t2 = conv_local_date_time(dataLog[key]['TIMESTAMP_S'])
                // console.log(t2)

                let last = timestamp[timestamp.length - 1]
                if (last == '0') {

                    let time = convert24to12(getTime(dataLog[key]['TIMESTAMP_S']));
                    let speed = dataLog[key]['Discharge_Commanded'];
                    let op = dataLog[key]['Op_Mode'];
                    let disch_moist = dataLog[key]['Grain_Discharge_Moisture'];
                    let disch_moist_sp = dataLog[key]['Disch_Moist_SP'];
                    let disch_temp = dataLog[key]['Grain_Discharge_Temp'];

                    arr.push(
                        {
                            time: (
                                <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                    {time}
                                </MDTypography>
                            ),
                            speed: (
                                <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                    {speed}
                                </MDTypography>
                            ),
                            mode: (
                                <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                    {op}
                                </MDTypography>
                            ),
                            moist: (
                                <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                    {disch_moist}
                                </MDTypography>
                            ),
                            sp: (
                                <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                    {disch_moist_sp}
                                </MDTypography>
                            ),
                            temp: (
                                <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                    {disch_temp}<sub style={{ verticalAlign: 'top' }}>&deg;</sub>
                                </MDTypography>
                            ),
                        }
                    );
                }
            });
        }
        return arr;
    };
    const getDataLogging = async () => {

        let record_id = dryerData[index]?.Dryer_Record_ID;
        let user_id = dryerData[index]?.USER_ID;
        let serial = dryerData[index]?.Dryer_Serial_Name;

        try {
            if (auth?.accessToken && op != 0 && op != 0) {

                let timeoffset = get_time_offset();
                let timeoffset2;
                let today = new Date();
                if (today.isDstObserved()) {
                    timeoffset2 = timeoffset - 1;
                } else {
                    timeoffset2 = timeoffset - 2;
                }

                const response = await axiosPrivate.post('/getDataLogging', JSON.stringify({ serial, timeoffset, timeoffset2, record_id }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                //console.log(response.data);

                let data = {
                    serial: serial,
                    index: index,
                    data: response.data
                }

                // search datalog array for index
                const arrayIndex = dataLog.findIndex(e => e.serial === data.serial);

                setDataLog(response.data)

            }
        } catch (e) {
            console.log(e);
        }
    };

    // once dataLog is set then set rows
    useEffect(() => {

        setTestRows(getRows());

    }, [dataLog])

    // get log on page load
    useEffect(() => {

        //if the card is flipped and the log is visible
        if (isFlipped) {
            getDataLogging();
        }

    }, [index, dryerData]);

    const getTableHeight = (serial, index) => {
        let element_id = serial + '-' + index;
        let card = document.getElementById(element_id);
        let totalHeight = card == null ? 0 : card.offsetHeight;

        let heightMinusNav = totalHeight - 70;

        let header_id = serial + '-header';
        let header = document.getElementById(header_id);
        let header_height = header == null ? 0 : header.offsetHeight;

        let heightMinusHeader = heightMinusNav - header_height;
        // -10 for divider margin
        return (heightMinusHeader - 10);
    }

    return (
        <>
            <MDBox
                display={{ xs: "block" }}
                position="absolute"
                top={0}
                right={0}
                p={1.625}
                onClick={(e) => handleFlip(e, serial, destination, index)}
                sx={{ cursor: "pointer" }}
            >
                <MDTypography variant="h6" color="secondary" style={{fontSize:'22px'}}>
                    <Icon className="button-hover" style={{ color: darkMode ? "white" : "black" }}>undo</Icon>
                </MDTypography>
            </MDBox>
            {online == 0 || sub == 0 && auth.USER_TYPE_ID < 3 ?
                <MDTypography style={{ width: '100%', height: '300px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                    {sub == 0 ? 'Subscription expired' : 'Dryer offline'}
                </MDTypography>
                :
                <>
                    <MDBox id={serial + '-header'} pt={3}>
                        <MDBox px={2} pt={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ display: "flex" }}>
                                    <Grid item xs={8} style={{ display: 'flex' }}>
                                        <Grid item xs={4} style={{ display: 'flex' }}>
                                            <img src={moisture_out_svg} style={{ width: '45px', height: 'auto', objectFit: 'contain' }} />
                                        </Grid>
                                        <Grid item xs={8} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="40px" style={{ color: '#CC3E3E', fontWeight: '1000' }}>
                                                <span style={{ alignSelf: 'center' }}>{moist_out}<sub style={{ alignSelf: 'end', fontWeight: '400' }}>%</sub></span>
                                            </MDTypography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                        <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ whiteSpace: 'nowrap', fontWeight: '400', justifyContent: 'space-evenly', color: '#BEBEBE' }} >
                                            <span>{grain_out_temp}&deg;</span>
                                        </MDTypography>
                                        <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly', color: '#BEBEBE', paddingBottom: '5px' }}>
                                            <span>TEMP</span>
                                        </MDTypography>
                                        <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ whiteSpace: 'nowrap', fontWeight: '400', justifyContent: 'space-evenly', color: '#BEBEBE' }} >
                                            <span>{moist_out_sp}<sub style={{ alignSelf: 'end', fontWeight: '400' }}>%</sub></span>
                                        </MDTypography>
                                        <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly', color: '#BEBEBE' }}>
                                            <span>SETPOINT</span>
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MDBox>
                        <hr className="dryer-card-divider-multi" />
                    </MDBox>
                    {comingSoon ?
                        <>
                            <CardBackCommingSoon />
                        </>
                        :
                        op == 0 ?
                            <>
                                <MDTypography style={{ width: '100%', height: '300px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                    Dryer stopped no data to display
                                </MDTypography>
                            </>
                            :
                            <>
                                <MDBox style={{ overflowY: 'auto', maxHeight: getTableHeight(serial, index) }}>
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr style={{ textAlign: 'center' }}>
                                                {columns.map((column, key) => (
                                                    <th key={key}>
                                                        <MDTypography fontSize={"12px"}>
                                                            {column.Header}
                                                        </MDTypography>
                                                    </th>
                                                ))}
                                            </tr>
                                            {testRows.map((row, key) => (
                                                <tr key={key} style={{ textAlign: 'center' }}>
                                                    <td style={{ fontSize: '12px' }}>{row.time}</td>
                                                    <td style={{ fontSize: '12px' }}>{row.speed}</td>
                                                    <td style={{ fontSize: '12px' }}>{row.mode}</td>
                                                    <td style={{ fontSize: '12px' }}>{row.moist}</td>
                                                    <td style={{ fontSize: '12px' }}>{row.sp}</td>
                                                    <td style={{ fontSize: '12px' }}>{row.temp}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </MDBox>
                            </>
                    }
                </>
            }
        </>
    );
}

// Typechecking props for the CardBackMoistureOut
CardBackMoistureOut.defaultProps = {
    index: 0,
};

CardBackMoistureOut.propTypes = {
    index: PropTypes.number,
    handleFlip: PropTypes.func,
    destination: PropTypes.number,
    comingSoon: PropTypes.bool,
    isFlipped: PropTypes.bool,
};

export default CardBackMoistureOut;
