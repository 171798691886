/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import axios from "../../../../api/axios";
import React, { useEffect, useState } from "react";

export default function data() {

  return {
    columns: [
      { Header: "Time", accessor: "time", align: "left" },
      { Header: "Speed", accessor: "speed", align: "center" },
      { Header: "Mode", accessor: "mode", align: "center" },
      { Header: "Moist (OUT)", accessor: "moist", align: "center" },
      { Header: "SP", accessor: "sp", align: "center" },
      { Header: "Temp", accessor: "temp", align: "center" },
    ],
    
    rows: [
        {
          time: (
            <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
              08:12
            </MDTypography>
          ),
          speed: (
            <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
              50.0
            </MDTypography>
          ),
          mode: (
            <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
              Stopped
            </MDTypography>
          ),
          moist: (
            <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
              54.0
            </MDTypography>
          ),
          sp: (
            <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
              (N/A)
            </MDTypography>
          ),
          temp: (
            <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
              57
            </MDTypography>
          ),
          moist1: (
            <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
              54.0
            </MDTypography>
          ),
          temp1: (
            <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
              57
            </MDTypography>
          ),
        },
        {
            time: (
              <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                07:58
              </MDTypography>
            ),
            speed: (
              <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                50.0
              </MDTypography>
            ),
            mode: (
              <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                Stopped
              </MDTypography>
            ),
            moist: (
              <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                55.9
              </MDTypography>
            ),
            sp: (
              <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                (N/A)
              </MDTypography>
            ),
            temp: (
              <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                54
              </MDTypography>
            ),
            moist1: (
              <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                54.0
              </MDTypography>
            ),
            temp1: (
              <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                57
              </MDTypography>
            ),
          },
    ]
  };
}
