/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Divider from "@mui/material/Divider";

import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Modal from '@mui/material/Modal';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { useNavigate } from "react-router-dom"

import { DarkModeSwitch } from 'react-toggle-dark-mode'
import useLogout from "hooks/useLogout";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setOpenConfigurator,
  setDarkMode,
  setView
} from "context";

import {
  navbarIconButton,
} from "examples/Navbars/DashboardNavbar/styles";
import { setWhiteSidenav } from "context";
import constants from "../../constants";
import { setDryerDataIndex } from "context";

function Configurator(light) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    transparentNavbar,
    darkMode,
    auth,
    dryerData,
    dryerDataIndex,
    whiteSidenav,
    serviceLogin,
  } = controller;

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const handleDarkMode = () => {
  //   setDarkMode(dispatch, !darkMode)
  //   setWhiteSidenav(dispatch, !whiteSidenav)
  // };

  const handleDarkMode = () => {
    setDarkMode(dispatch, !darkMode)
    localStorage.setItem('darkMode', !darkMode);
    //setWhiteSidenav(dispatch, !whiteSidenav)
  };
  const handleOldInterface = () => {
    window.location.href = "https://mctrax.net";
  };

  const signOut = async () => {
    handleCloseConfigurator();
    await logout();
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: darkMode ? '#141414' : '#E6E6E6',
    border: darkMode ? '2px solid #fff' : '2px solid #000',
    boxShadow: 24,
    borderRadius: '8px',
    p: 4,
  };

  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  const logout = useLogout();
  const navigate = useNavigate();
  const handleRemoteClick = () => {
    setView(dispatch, constants.REMOTE_VIEW)
    //navigate('/dashboard/remote', { replace: false });
  }
  const handleAlertClick = () => {
    setView(dispatch, constants.ALERT_VIEW)
    handleCloseConfigurator();
    //navigate('/dashboard/alerts', { replace: false });
  }
  const handleDashClick = () => {
    setView(dispatch, constants.SINGLE_VIEW)
    handleCloseConfigurator();
    //navigate('/dashboard/singleView', { replace: false });
  }
  const handleAccountClick = () => {
    setView(dispatch, constants.ACCOUNT_VIEW)
    handleCloseConfigurator();
    //navigate('/dashboard/singleView', { replace: false });
  }
  const handleFeedbackClick = () => {
    setView(dispatch, constants.FEEDBACK_VIEW)
    handleCloseConfigurator();
    //navigate('/dashboard/feedback', { replace: false });
  }
  const handleReportClick = () => {
    setView(dispatch, constants.REPORT_VIEW)
    handleCloseConfigurator();
    //navigate('/dashboard/feedback', { replace: false });
  }
  const handleAdminClick = () => {
    setView(dispatch, constants.ADMIN_VIEW_1)
    handleCloseConfigurator();
    //navigate('/dashboard/feedback', { replace: false });
  }
  const handleSubClick = () => {
    //setView(dispatch, 'subscription')
  }

  // Only show alerts and remote buttons for single dryer users
  let displayButtons = auth?.USER_TYPE_ID == 1 ? true : false;

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        {/* <MDBox>
          <MDTypography variant="h5">Material UI Configurator</MDTypography>
          <MDTypography variant="body2" color="text">
            See our dashboard options.
          </MDTypography>
        </MDBox> */}
        <IconButton
          size="small"
          disableRipple
          color="inherit"
          sx={navbarIconButton}
          aria-controls="notification-menu"
          aria-haspopup="true"
          variant="contained"
          style={{ alignSelf: 'baseline' }}

        >
          <DarkModeSwitch

            style={{ verticalAlign: 'middle' }}
            checked={darkMode}
            onChange={handleDarkMode}
            className="button-hover"
            size={22}
          />
        </IconButton>
        <Icon
          className="button-hover"
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `24px !important`,
            // fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      <Divider />
      <MDButton variant="text" color="info" onClick={handleDashClick}>Dashboard</MDButton>
      {displayButtons ?
        <>
          {/* <MDButton variant="text" color="info" onClick={handleRemoteClick}>Remote</MDButton> */}
          <MDButton variant="text" color="info" onClick={handleAlertClick}>Alerts</MDButton>
        </>
        :
        <>
          <MDButton variant="text" color="info" onClick={handleOpen}>Alerts</MDButton>
        </>
      }
      {auth.USER_TYPE_ID == 1 && dryerData[dryerDataIndex]?.Dryer_Record_ID == '806' || auth.USER_ID == "1616" ?
        <>
          <MDButton variant="text" color="info" onClick={handleAccountClick}>Account</MDButton>
        </>
        :
        <>
        </>
      }
      {/* reports */}
      {/* {auth.USER_TYPE_ID == 5 ?
        <>
          <MDButton variant="text" color="info" onClick={handleReportClick}>Reports</MDButton>
        </>
        :
        <>
        </>
      } */}
      <MDButton variant="text" color="info" onClick={handleOpen}>Subscription</MDButton>
      <MDButton variant="text" color="info" onClick={handleOldInterface}>Old Interface</MDButton>
      {auth.USER_TYPE_ID == 5 ?
        <>
          <MDButton variant="text" color="info" onClick={handleAdminClick}>Admin</MDButton>
        </>
        :
        <>
        </>
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox sx={style}>
          <MDTypography id="modal-modal-description" style={{ textAlign: 'center' }}>
            Coming soon, visit the <span style={{ display: 'inline' }}>
              <a id="underline" href="https://mctrax.net" style={{ display: 'inline', color: darkMode ? 'white' : 'black' }}>old interface</a>
            </span> to use this feature
          </MDTypography>
        </MDBox>
      </Modal>
      {/* {auth.USER_TYPE_ID >= serviceLogin ?
        <MDButton variant="text" color="info" onClick={handleFeedbackClick}>feedback</MDButton>
        :
        <>
        </>
      } */}
      {/* <MDBox style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
        <MDBox style={{ display: 'flex' }}>
          <MDTypography mr={1}>theme</MDTypography>
          <IconButton
            size="small"
            disableRipple
            color="inherit"
            sx={navbarIconButton}
            aria-controls="notification-menu"
            aria-haspopup="true"
            variant="contained"
            style={{ alignSelf: 'baseline' }}
          >
            <DarkModeSwitch
              style={{ verticalAlign: 'middle' }}
              checked={darkMode}
              onChange={handleDarkMode}
              sunColor="#7b809a"
              size={20}
            />
          </IconButton>
        </MDBox>
      </MDBox> */}
      <MDBox pt={0.5} pb={3} px={3} style={{ height: '100%', display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
        <MDBox style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
          {auth ?
            <Link onClick={signOut}>
              <IconButton sx={navbarIconButton} size="small" disableRipple>
                <MDBox style={{ display: 'flex' }} className="logout-hover">
                  <MDTypography mr={1}>logout</MDTypography>
                  <Icon sx={iconsStyle} style={{ color: darkMode ? "white" : "black", alignSelf: 'center' }}>logout</Icon>
                </MDBox>

              </IconButton>
            </Link>
            :
            <Link to="/authentication/sign-in/">
              <IconButton sx={navbarIconButton} size="small" disableRipple>
                <MDBox style={{ display: 'flex' }}>
                  <MDTypography mr={1}>login</MDTypography>
                  <Icon sx={iconsStyle} style={{ color: darkMode ? "white" : "black", alignSelf: 'center' }}>login</Icon>
                </MDBox>
              </IconButton>
            </Link>
          }
        </MDBox>
      </MDBox>
    </ConfiguratorRoot >
  );
}

export default Configurator;
