/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import React from "react";

import PropTypes from 'prop-types';

import '../../../App.css'

import { useMaterialUIController } from "context";

import AlertCard from "examples/Cards/AlertCards";
import AdminCard3 from "examples/Cards/AdminCards/AdminCard3";

function AdminView() {
  const [controller, dispatch] = useMaterialUIController();
  const {

  } = controller;

  return (
    <MDBox style={{paddingTop: '12px'}}>
      <AdminCard3/>
    </MDBox>
  );
}

AdminView.propTypes = {

};

export default AdminView;
