/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import '../../../App.css'
import grain_temp_svg from '../../../assets/images/mc/grain-temp.svg'
import moisture_in_svg from '../../../assets/images/mc/moisture-in.svg'
import moisture_out_svg from '../../../assets/images/mc/moisture-out.svg'
import plenum_temp_svg from '../../../assets/images/mc/plenum-temp.svg'
import speed_svg from '../../../assets/images/mc/speed.svg'
import CircularProgress from '@mui/material/CircularProgress';
import ReactCardFlip from 'react-card-flip'

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

import CardBacks from './DryerCardBacks/'

import {
    useMaterialUIController,
    setDryerDataIndex,
    setView,
} from "context";
import DryerLineChart from "examples/Charts/LineCharts/DryerLineChartSmall";
import constants from "../../../constants";
import CardIconHeader from "./Components/CardIconHeader";

function DryerCardSmall({ dryer, removeCard, index }) {

    const [controller, dispatch] = useMaterialUIController();
    const {
        dryerData,
        darkMode,
        auth,
    } = controller;

    //const axiosPrivate = useAxiosPrivate();

    const [isFlipped, setIsFlipped] = useState(false)
    const [destination, setDestination] = useState(0)

    const getDryerIndex = (serial) => {
        const index = dryerData.findIndex(e => e.Dryer_Serial_Name === serial);
        return index;
    }

    const handleDestination = (d) => {
        setDestination(d);
    }

    const handleFlip = (e, serial, destination, index) => {
        let height = getHeight(serial, index);
        if (height > 289) {
            setIsFlipped(!isFlipped);
            setDestination(destination);
        } else {
            setView(dispatch, constants.SINGLE_VIEW)
            let index = getDryerIndex(serial)
            setDryerDataIndex(dispatch, index);
        }
    }

    const getHeight = (serial, index) => {
        let element_id = serial + '-' + index;
        var card = document.getElementById(element_id);
        var height = card == null ? 0 : card.offsetHeight;
        return (height);
    }


    let moist_in = dryerData[index]?.runData ? dryerData[index]?.runData?.Grain_Infeed_Moisture : 'N/A';
    let grain_in_temp = dryerData[index]?.runData ? dryerData[index]?.runData?.Grain_Infeed_Temp : 'N/A';
    let moist_out = dryerData[index]?.runData ? dryerData[index]?.runData?.Grain_Discharge_Moisture : 'N/A';
    let moist_out_sp = dryerData[index]?.runData ? (Math.round(dryerData[index]?.runData?.Disch_Moist_SP * 100) / 100).toFixed(1) : 'N/A';
    let grain_out_temp = dryerData[index]?.runData ? (Math.round(dryerData[index]?.runData?.Grain_Discharge_Temp * 100) / 100).toFixed(0) : 'N/A';
    let speed = dryerData[index]?.runData ? dryerData[index]?.runData?.Discharge_Commanded : 'N/A';
    let mid_temp_avg = dryerData[index]?.runData ? dryerData[index]?.runData?.Grain_Mid_Temp_Avg : 'N/A';
    let mid_temp_sp = dryerData[index]?.runData ? dryerData[index]?.runData?.Mid_Tmp_SP : 'N/A';
    let op_mode = dryerData[index]?.runData ? dryerData[index]?.runData?.Op_Mode : 'N/A';

    let name = dryerData[index]?.BUSINESS;
    let serial = dryerData[index]?.Dryer_Serial_Name;
    let model = dryerData[index]?.Dryer_Model_Name;
    // let moist_in = dryerData[index]?.runData?.Grain_Infeed_Moisture;
    // let grain_in_temp = dryerData[index]?.runData?.Grain_Infeed_Temp;
    // let moist_out = dryerData[index]?.runData?.Grain_Discharge_Moisture;
    // let moist_out_sp = dryerData[index]?.runData?.Disch_Moist_SP;
    // let grain_out_temp = dryerData[index]?.runData?.Grain_Discharge_Temp;
    // let speed = (Math.round(dryerData[index]?.runData?.Discharge_Commanded * 100) / 100).toFixed(1);
    // let mid_temp_avg = dryerData[index]?.runData?.Grain_Mid_Temp_Avg;
    // let mid_temp_sp = dryerData[index]?.runData?.Mid_Tmp_SP;
    let plenum_temp_avg = dryerData[index]?.plenumData?.plenum_temp_avg;
    // let op_mode = dryerData[index]?.runData?.Op_Mode;
    let sub = dryerData[index]?.Sub_Status;

    if (isFlipped && window.innerWidth < 576) {
        setIsFlipped(false)
    }

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection={"horizontal"}>

            <Card id={dryer.serial + '-' + index}>
                <MDBox style={{display:'flex', justifyContent:'space-between'}}>
                    <MDBox
                        display={{ xs: "block" }}
                        position="absolute"
                        top={0}
                        right={0}
                        p={1.625}
                        onClick={() => { removeCard(serial, model, name) }}
                        sx={{ cursor: "pointer" }}
                    >
                        <MDTypography variant="h6" color="secondary">
                            <Icon className="button-hover" sx={{ fontWeight: "bold" }} style={{ color: darkMode ? "white" : "black" }}>close</Icon>
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <MDBox justifyContent="space-between" pt={4} px={2}>
                    <div style={{ display: ' flex' }}>
                        <MDBox textAlign="right" lineHeight={1.25} style={{ width: '100%' }}>
                            <Grid container spacing={1} p={'3px'} className="no-flip" onClick={(e) => handleFlip(e, dryer.serial, 0, index)} style={{ cursor: 'pointer' }}>
                                <Grid item xs={9} style={{ display: 'flex' }}>
                                    <MDBox style={{}}>
                                        <MDTypography fontWeight="bold" style={{ textAlign: 'left', fontSize: '12px', whiteSpace: 'nowrap' }}>
                                            {name}
                                        </MDTypography>
                                        <MDTypography fontWeight="bold" style={{ textAlign: 'left', fontSize: '12px' }}>
                                            {serial}&nbsp;&nbsp;|&nbsp;&nbsp;{model}
                                        </MDTypography>
                                    </MDBox>
                                </Grid>
                                <CardIconHeader index={index} />
                            </Grid>
                        </MDBox>
                    </div>
                </MDBox>
                <hr className="dryer-card-divider-multi" />
                {sub == 0 & auth.USER_TYPE_ID < 2 ?
                    <>
                        <MDTypography style={{ width: '100%', height: '300px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                            Subscription Expired
                        </MDTypography>
                    </>
                    :
                    <>
                        <MDBox px={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ display: "flex", cursor: 'pointer' }} >
                                    <div style={{ display: ' flex', width: '100%' }} onClick={(e) => handleFlip(e, dryer.serial, 1, index)}>
                                        <Grid item xs={6} style={{ display: 'flex' }} >
                                            <Grid item xs={12} style={{ display: 'flex' }}>
                                                <div style={{ width: '45px', display: 'flex', justifyContent: 'end' }}>
                                                    <img src={moisture_in_svg} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
                                                </div>
                                                <MDTypography component="p" variant="button" display="flex" fontSize="36px" style={{ color: '#3D81C7', fontWeight: '1000', paddingLeft: '8px' }}>
                                                    {moist_in}{moist_in != 'N/A' ? <sub style={{ display: 'contents', fontWeight: '400' }}>%</sub> : <></>}
                                                </MDTypography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div style={{ display: ' flex', width: '100%' }} onClick={(e) => handleFlip(e, dryer.serial, 2, index)}>
                                        <Grid item xs={6} style={{ display: 'flex' }} >
                                            <Grid item xs={12} style={{ display: 'flex' }}>
                                                <div style={{ width: '45px', display: 'flex', justifyContent: 'end' }}>
                                                    <img src={moisture_out_svg} style={{ width: '35px', height: 'auto', objectFit: 'contain', justifyContent: 'end' }} />
                                                </div>
                                                <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="36px" style={{ color: '#CC3E3E', fontWeight: '1000', paddingLeft: '8px' }}>
                                                    {moist_out}{moist_out != 'N/A' ? <sub style={{ display: 'contents', fontWeight: '400' }}>%</sub> : <></>}
                                                </MDTypography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </MDBox>
                        <hr className="dryer-card-divider-multi" />
                        <MDBox px={2}>
                            <Grid container spacing={1} onClick={(e) => handleFlip(e, dryer.serial, 3, index)}>
                                <Grid item xs={12} style={{ display: "flex", cursor: 'pointer' }}>
                                    <div style={{ display: ' flex', width: '100%' }}>
                                        <Grid item xs={6} style={{ display: 'flex' }} >
                                            <Grid item xs={12} style={{ display: 'flex' }}>
                                                <img src={speed_svg} style={{ width: '45px', height: 'auto', objectFit: 'contain' }} />
                                                <MDTypography component="p" pl={1} variant="button" display="flex" fontWeight="bold" fontSize="36px" style={{ color: '#41C330', fontWeight: '1000' }}>
                                                    {speed}{speed != 'N/A' ? <sub style={{ display: 'contents', fontWeight: '400' }}>%</sub> : <></>}
                                                </MDTypography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6} pb={'10px'} style={{ display: 'flex', alignSelf: 'end' }}>
                                            <div style={{ width: '45px', display: 'flex' }}>&nbsp;</div>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'flex-start' }} >
                                                {op_mode}
                                            </MDTypography>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </MDBox>
                        <hr className="dryer-card-divider-multi" />
                        <MDBox px={2} >
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ display: "flex", cursor: 'pointer' }}>
                                    <div style={{ display: ' flex', width: '100%' }} onClick={(e) => handleFlip(e, dryer.serial, 4, index)}>
                                        <Grid item xs={6} style={{ display: 'flex' }} >
                                            <Grid item xs={12} style={{ display: 'flex' }}>
                                                <div style={{ width: '45px', display: 'flex', justifyContent: 'end' }}>
                                                    <img src={grain_temp_svg} style={{ width: '40px', height: 'auto', objectFit: 'contain' }} />
                                                </div>
                                                <MDTypography component="p" variant="button" display="flex" fontSize="36px" style={{ color: '#EEEB18', fontWeight: '1000', paddingLeft: '8px' }}>
                                                    {mid_temp_avg}{mid_temp_avg != 'N/A' ? <span style={{ fontWeight: '400' }}>&deg;</span> : <></>}
                                                </MDTypography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div style={{ display: ' flex', width: '100%' }} onClick={(e) => handleFlip(e, dryer.serial, 5, index)}>
                                        <Grid item xs={6} style={{ display: 'flex' }} >
                                            <Grid item xs={12} style={{ display: 'flex' }}>
                                                <div style={{ width: '45px', display: 'flex', justifyContent: 'end' }}>
                                                    <img src={plenum_temp_svg} style={{ width: '40px', height: 'auto', objectFit: 'contain' }} />
                                                </div>
                                                {plenum_temp_avg == null ?

                                                    <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="36px" style={{ color: '#E1861A', fontWeight: '1000', paddingLeft: '8px' }}>
                                                        N/A
                                                    </MDTypography>
                                                    :
                                                    <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="36px" style={{ color: '#E1861A', fontWeight: '1000', paddingLeft: '8px' }}>
                                                        {plenum_temp_avg}{plenum_temp_avg != 'N/A' ? <span style={{ fontWeight: '400' }}>&deg;</span> : <></>}
                                                    </MDTypography>
                                                }
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </MDBox>
                        <MDBox px={2} pb={2}>
                            <Grid container spacing={1}>
                                <Grid className="hidden-xs" item xs={12}>
                                    <MDBox mt={2}>
                                        <DryerLineChart index={index} isFlipped={isFlipped} />
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>
                    </>
                }
            </Card>

            <CardBacks index={index} serial={dryer.serial} destination={destination} handleFlip={handleFlip} getHeight={getHeight} handleDestination={handleDestination} isFlipped={isFlipped} />
            {/* <CardBacks index={index} serial={dryer.serial} destination={destination} handleFlip={handleFlip} /> */}

        </ReactCardFlip>
    );
}

// Typechecking props for the DryerCardSmall
DryerCardSmall.propTypes = {
    dryer: PropTypes.object,
    removeCard: PropTypes.func,
    index: PropTypes.number
};

export default DryerCardSmall;