/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import '../../../App.css'

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import validator from "validator"
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useMaterialUIController, setDryerData, setDryerDataIndex, setView, setGlobalAlertMessage, setGlobalAlertColor, setGlobalAlertActive } from "context";
import React, { useState, useEffect, useMemo, useRef } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import MDInput from 'components/MDInput';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Icon from "@mui/material/Icon";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MDButton from 'components/MDButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Virtuoso, VirtuosoGrid, TableVirtuoso } from 'react-virtuoso'
import Fuse from 'fuse.js'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
function AdminCard() {
    const [controller, dispatch] = useMaterialUIController();
    const axiosPrivate = useAxiosPrivate();
    const [mask1, setMask1] = useState('');
    const [mask2, setMask2] = useState('');
    const [adminUserList, setAdminUserList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const handleModal = () => setOpen(!open);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const {
        dryerData,
        dryerDataIndex,
        auth,
        darkMode
    } = controller;

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        const getDryerList = async () => {

            let record_id = dryerData[dryerDataIndex]?.Dryer_Record_ID;
            let user_id = dryerData[dryerDataIndex]?.USER_ID;

            try {
                if (auth?.accessToken) {

                    const response = await axiosPrivate.post('/getMCtraxUsers', JSON.stringify({}),
                        {
                            headers: { 'Content-Type': 'application/json' },
                            withCredentials: true
                        });

                    let arr = [];
                    response.data.forEach((element) => {
                        arr.push(element)
                    });

                    setAdminUserList(arr);
                    setLoading(false);
                }
            } catch (e) {
                if (e.response.status == 403 || e.response.status == 401) {
                    await logout();
                } else {
                    console.log(e);
                }
            }
        };

        getDryerList();

    }, []);

    const [sortOrder, setSortOrder] = useState(false);

    const sortUserId = () => {
        setSortOrder(!sortOrder);
        let arr = [...adminUserList];
        if (sortOrder) {
            arr.sort((a, b) => a.USER_ID - b.USER_ID);
        } else {
            arr.sort((a, b) => b.USER_ID - a.USER_ID);
        }
        setAdminUserList(arr);
    }

    const [userData, setUserData] = useState({
        EMAIL: '',
        ENABLE_ALERTS: '',
        ENABLE_SMS: '',
        FIRST_NAME: '',
        LAST_NAME: '',
        RECEIVE_ALERTS: '',
        USERNAME: '',
        USER_ID: '',
        USER_TYPE: '',
        PASSWORD1: '',
        PASSWORD2: '',

    });
    const [newUserData, setNewUserData] = useState({
        EMAIL: '',
        ENABLE_ALERTS: '',
        ENABLE_SMS: '',
        FIRST_NAME: '',
        LAST_NAME: '',
        RECEIVE_ALERTS: '',
        USERNAME: '',
        USER_ID: '',
        USER_TYPE: '',
        PASSWORD1: '',
        PASSWORD2: '',

    });

    const [associatedDryers, setAssociatedDryers] = useState([]);

    const handleEdit = async (row) => {

        setMask1('')
        setMask2('')

        setPassMatchError(false);

        setUserData({
            ...userData,
            EMAIL: row.EMAIL,
            ENABLE_ALERTS: row.ENABLE_ALERTS,
            ENABLE_SMS: row.ENABLE_SMS,
            FIRST_NAME: row.FIRST_NAME,
            LAST_NAME: row.LAST_NAME,
            RECEIVE_ALERTS: row.RECEIVE_ALERTS,
            USERNAME: row.USERNAME,
            USER_ID: row.USER_ID,
            USER_TYPE: row.USER_TYPE,
            PASSWORD1: '',
            PASSWORD2: '',
        });
        setNewUserData({
            ...newUserData,
            EMAIL: row.EMAIL,
            ENABLE_ALERTS: row.ENABLE_ALERTS,
            ENABLE_SMS: row.ENABLE_SMS,
            FIRST_NAME: row.FIRST_NAME,
            LAST_NAME: row.LAST_NAME,
            RECEIVE_ALERTS: row.RECEIVE_ALERTS,
            USERNAME: row.USERNAME,
            USER_ID: row.USER_ID,
            USER_TYPE: row.USER_TYPE,
            PASSWORD1: '',
            PASSWORD2: '',
        });

        setAssociatedDryers([]);
        handleModal();
        // try {
        //     if (auth?.accessToken) {

        //         const response = await axiosPrivate.post('/getAssociatedDryers', JSON.stringify({ user_id }),
        //             {
        //                 headers: { 'Content-Type': 'application/json' },
        //                 withCredentials: true
        //             });

        //         setUserData(response.data);

        //     }
        // } catch (e) {
        //     if (e.response.status == 403 || e.response.status == 401) {
        //         await logout();
        //     } else {
        //         console.log(e);
        //     }
        // }
    };

    const handlePasswordChange = (e, pass) => {

        if (pass == 1) {
            const value = e;
            let passOneStr = newUserData.PASSWORD1;
            if (value.length == 1) {

                if (value.length > passOneStr.length) {
                    setNewUserData({ ...newUserData, PASSWORD1: value });
                } else {
                    passOneStr = passOneStr.slice(0, -1);
                    setNewUserData({ ...newUserData, PASSWORD1: passOneStr });
                }

            } else {

                if (value.length > passOneStr.length) {
                    let addPassChar = value.substring(value.length - 1,);
                    let newPass = passOneStr + addPassChar;
                    setNewUserData({ ...newUserData, PASSWORD1: newPass });
                } else {
                    passOneStr = passOneStr.slice(0, -1);
                    setNewUserData({ ...newUserData, PASSWORD1: passOneStr });
                }

            }
            let len = value.length;

            let mask1 = "";

            for (let i = 0; i < len; i++) {
                mask1 = mask1 + '•';
            }

            setMask1(mask1);
        } else {
            const value = e;
            let passTwoStr = newUserData.PASSWORD2;
            if (value.length == 1) {

                if (value.length > passTwoStr.length) {
                    setNewUserData({ ...newUserData, PASSWORD2: value });
                } else {
                    let passTwoStr = newUserData.PASSWORD2;
                    passTwoStr = passTwoStr.slice(0, -1);
                    setNewUserData({ ...newUserData, PASSWORD2: passTwoStr });
                }
            } else {

                let passTwoStr = newUserData.PASSWORD2;

                if (value.length > passTwoStr.length) {
                    let addPassChar = value.substring(value.length - 1,);
                    let newPass = passTwoStr + addPassChar;
                    setNewUserData({ ...newUserData, PASSWORD2: newPass });
                } else {
                    passTwoStr = passTwoStr.slice(0, -1);
                    setNewUserData({ ...newUserData, PASSWORD2: passTwoStr });
                }
            }

            let len = value.length;
            let mask2 = "";

            for (let i = 0; i < len; i++) {
                mask2 = mask2 + '•';
            }
            setMask2(mask2);
        }

    }

    const updateUserInfo = async (p1, p2) => {

        let owner_id = dryerData[dryerDataIndex]?.OWNER_ID;
        let user_id = dryerData[dryerDataIndex]?.USER_ID;


        if (newUserData.PASSWORD1 != '' && newUserData.PASSWORD2 != '') {
            if (newUserData.PASSWORD1 != newUserData.PASSWORD2) {
                setPassMatchError(true);
            } else {
                setPassMatchError(false);
            }
        } else {
            setPassMatchError(false);
        }

        // let escape = {
        //     EMAIL: validator.escape(newUserData.EMAIL),
        //     ENABLE_ALERTS: validator.escape(newUserData.ENABLE_ALERTS),
        //     ENABLE_SMS: validator.escape(newUserData.ENABLE_SMS),
        //     FIRST_NAME: validator.escape(newUserData.FIRST_NAME),
        //     LAST_NAME: validator.escape(newUserData.LAST_NAME),
        //     RECEIVE_ALERTS: validator.escape(newUserData.RECEIVE_ALERTS),
        //     USERNAME: validator.escape(newUserData.USERNAME),
        //     USER_ID: validator.escape(newUserData.USER_ID),
        //     USER_TYPE: validator.escape(newUserData.USER_TYPE),
        //     PASSWORD1: newUserData.PASSWORD1,
        //     PASSWORD2: newUserData.PASSWORD2,
        // }

        try {
            console.log("updateOwnerInfo");
            // if (auth?.accessToken) {

            //     const response = await axiosPrivate.post('/updateOwnerInfo', JSON.stringify({ owner_id, user_id, data: escape }),
            //         {
            //             headers: { 'Content-Type': 'application/json' },
            //             withCredentials: true
            //         });

            //     if (response.data == 'success') {
            //         displaySuccess();
            //         getOwnerInfo();

            //     } else {
            //         displayFail();
            //         getOwnerInfo();
            //     }
            // }
        } catch (e) {
            console.log(e);
        }
    };

    const handleUserUpdate = () => {
        if (!emailError && !firstError && !lastError && !usernameError && !userTypeError && !passwordError1 && !passwordError2) {
            updateUserInfo(newUserData.PASSWORD1, newUserData.PASSWORD2);
        }
    }

    const options = [
        { label: "Owner", value: "1" },
        { label: "Dealer", value: "2" },
        { label: "Salesman", value: "3" },
        { label: "Sales Manager", value: "4" },
        { label: "Service", value: "5" },
    ];

    const [emailError, setEmailError] = useState(false);
    const [firstError, setFirstError] = useState(false);
    const [lastError, setLastError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [userTypeError, setUserTypeError] = useState(false);
    const [passwordError1, setPasswordError1] = useState(false)
    const [passwordError2, setPasswordError2] = useState(false)
    const [passMatchError, setPassMatchError] = useState(false)

    const disableUpdateError = () => {
        if (emailError || firstError || lastError || usernameError || userTypeError || passwordError1 || passwordError2) {
            return true;
        } else {
            return false;
        }
    }

    const disableUpdateButton = () => {
        if (emailError || firstError || lastError || usernameError ||
            userTypeError || passwordError1 || passwordError2) {
            return true;
        } else {
            if (userData.EMAIL == newUserData.EMAIL &&
                userData.ENABLE_ALERTS == newUserData.ENABLE_ALERTS &&
                userData.ENABLE_SMS == newUserData.ENABLE_SMS &&
                userData.FIRST_NAME == newUserData.FIRST_NAME &&
                userData.LAST_NAME == newUserData.LAST_NAME &&
                userData.RECEIVE_ALERTS == newUserData.RECEIVE_ALERTS &&
                userData.USERNAME == newUserData.USERNAME &&
                userData.USER_ID == newUserData.USER_ID &&
                userData.USER_TYPE == newUserData.USER_TYPE &&
                userData.PASSWORD1 == newUserData.PASSWORD1 &&
                userData.PASSWORD2 == newUserData.PASSWORD2
            ) {
                return true;
            } else {
                return false;
            }
        }
    }

    const handleCheck = (e, check) => {
        if (check == 1) {
            setNewUserData({ ...newUserData, ENABLE_ALERTS: newUserData.ENABLE_ALERTS == "1" ? "0" : "1" });
        } else if (check == 2) {
            setNewUserData({ ...newUserData, RECEIVE_ALERTS: newUserData.RECEIVE_ALERTS == "1" ? "0" : "1" });
        } else {
            setNewUserData({ ...newUserData, ENABLE_SMS: newUserData.ENABLE_SMS == "1" ? "0" : "1" });
        }
    }

    const handleCancel = () => {

        setMask1('');
        setMask2('');

        setNewUserData({
            ...userData,
            EMAIL: userData.EMAIL,
            ENABLE_ALERTS: userData.ENABLE_ALERTS,
            ENABLE_SMS: userData.ENABLE_SMS,
            FIRST_NAME: userData.FIRST_NAME,
            LAST_NAME: userData.LAST_NAME,
            RECEIVE_ALERTS: userData.RECEIVE_ALERTS,
            USERNAME: userData.USERNAME,
            USER_ID: userData.USER_ID,
            USER_TYPE: userData.USER_TYPE,
            PASSWORD1: '',
            PASSWORD2: '',
        });
    }

    const disableCancelButton = () => {
        if (userData.EMAIL == newUserData.EMAIL &&
            userData.ENABLE_ALERTS == newUserData.ENABLE_ALERTS &&
            userData.ENABLE_SMS == newUserData.ENABLE_SMS &&
            userData.FIRST_NAME == newUserData.FIRST_NAME &&
            userData.LAST_NAME == newUserData.LAST_NAME &&
            userData.RECEIVE_ALERTS == newUserData.RECEIVE_ALERTS &&
            userData.USERNAME == newUserData.USERNAME &&
            userData.USER_ID == newUserData.USER_ID &&
            userData.USER_TYPE == newUserData.USER_TYPE &&
            userData.PASSWORD1 == newUserData.PASSWORD1 &&
            userData.PASSWORD2 == newUserData.PASSWORD2
        ) {
            return true;
        } else {
            return false;
        }
    }
    let disableUpdate = disableUpdateButton();
    let disableCancel = disableCancelButton();

    let disableUpdateButtonError = disableUpdateError();
    let updateButtonColor = disableUpdate ? 'gray' : '#862633';
    let cancelButtonColor = disableCancel ? 'gray' : '#862633';

    const outerTheme = createTheme({
        palette: {
            primary: {
                main: '#862633',
            },
        },
    });

    const styles = {

        tableContainer: {
            width: '100%'
        },
        headerContainer: {
            width: '100%',
            borderRight: '1px solid gray',
            backgroundColor: darkMode ? 'black' : 'white',
            textAlign: 'left'
        },
        td: {
            borderLeft: darkMode ? '1px solid gray' : '1px solid black',
            borderTop: darkMode ? '1px solid gray' : '1px solid black',
            textAlign: 'center',
            fontSize: '12px',
            textAlign: 'left',
            padding: '5px',
            color: darkMode ? 'white' : 'black',
            whiteSpace: 'wrap',
        },
        tdSmall: {
            borderLeft: darkMode ? '1px solid gray' : '1px solid black',
            borderTop: darkMode ? '1px solid gray' : '1px solid black',
            textAlign: 'center',
            fontSize: '12px',
            textAlign: 'left',
            padding: '5px',
            color: darkMode ? 'white' : 'black',
        },
        th: {
            cursor: 'pointer',
            fontSize: '12px',
            padding: '5px',
            //justifyContent:'space-between',
            borderLeft: darkMode ? '1px solid gray' : '1px solid black',
            // borderRight: darkMode ? '1px solid white' : '1px solid black',
            borderTop: darkMode ? '1px solid gray' : '1px solid black',
            color: darkMode ? 'white' : 'black',
        },
        modal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            maxHeight: '80%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: darkMode ? '#141414' : '#E6E6E6',
            border: darkMode ? '2px solid #fff' : '2px solid #000',
            boxShadow: 24,
            borderRadius: '8px',
            overflowY: 'auto',
            p: 4,
        },
    };

    // disable body scoll on load
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            // set back to auto on exit
            document.body.style.overflow = "auto";
        }
    }, []);

    const searchOptions = {
        includeScore: true,
        keys: ['USER_ID', 'USERNAME', 'USER_TYPE']
    }

    const getUserListSearch = (data) => {

        let arr = [];

        data?.map((row, index) => {

            arr.push(
                {
                    "ROW": row,
                    "USER_ID": row.USER_ID,
                    "USERNAME": row.USERNAME,
                    "USER_TYPE": row.USER_TYPE,
                }
            );
        });

        return arr;
    }

    // set initial list on page load
    // works but could be better
    useEffect(() => {
        onSearchChange('');
    }, [adminUserList]);

    const [search, setSearch] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const onSearchChange = (value) => {

        // scroll back to top when after search input

        if (virtuoso.current != null) {
            virtuoso.current.scrollToIndex({
                index: 0,
                behavior: 'auto'
            });
        }

        try {
            if (value == '' || value == undefined) {

                setSearch('');

                let userList = getUserListSearch(adminUserList);

                setFilteredList(userList);

            } else {

                let userList = getUserListSearch(adminUserList);

                const fuse = new Fuse(userList, searchOptions);
                const result = fuse.search(value);

                setFilteredList(getUserListSearch(result));

                let newUserList = [];

                //extract element from filted list then pass to getSearch
                result.forEach((element) => {
                    newUserList.push(element.item);
                });


                setFilteredList(newUserList);

                setSearch(value);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const virtuoso = useRef(null);

    return (

        <>

            {adminUserList.length > 0 ?
                <>
                    <MDBox pb={'16px'} id="search-box">
                        <MDInput
                            autoComplete="off"
                            placeholder="USERNAME, USER_TYPE, USER_ID..."
                            value={search}
                            size="large"
                            fullWidth
                            onChange={({ currentTarget }) => {
                                //setSearch(currentTarget.value);
                                onSearchChange(currentTarget.value);
                            }}
                        />
                    </MDBox>

                    <TableVirtuoso
                        id="virtual-table"
                        data={filteredList}
                        style={{ height: '100dvh', width: '100%' }}
                        ref={virtuoso}
                        fixedHeaderContent={(index, user) => (
                            <tr style={styles.headerContainer}>
                                <th style={styles.th} onClick={() => { sortUserId() }}>ID</th>
                                <th style={styles.th}>USERNAME</th>
                                <th style={styles.th}>NAME</th>
                                <th style={styles.th}>EMAIL</th>
                                <th style={styles.th}>TYPE</th>
                                <th className="hidden-xs" style={styles.th}>ENABLE_ALERTS</th>
                                <th className="hidden-xs" style={styles.th}>RECEIVE_ALERTS</th>
                                <th className="hidden-xs" style={styles.th}>ENABLE_SMS</th>
                                <th style={styles.th}>EDIT</th>
                            </tr>
                        )}
                        itemContent={(index, row) => (
                            <>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.tdSmall}>{row.ROW.USER_ID}</td>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.td}>{row.ROW.USERNAME}</td>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.td}>{row.ROW.FIRST_NAME} {row.ROW.LAST_NAME}</td>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.td}>{row.ROW.EMAIL}</td>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.td}>{row.ROW.USER_TYPE}</td>
                                <td className={index % 2 == 0 ? "table-bg hidden-xs" : "hidden-xs"} style={styles.tdSmall}>{row.ROW.ENABLE_ALERTS}</td>
                                <td className={index % 2 == 0 ? "table-bg hidden-xs" : "hidden-xs"} style={styles.tdSmall}>{row.ROW.RECEIVE_ALERTS}</td>
                                <td className={index % 2 == 0 ? "table-bg hidden-xs" : "hidden-xs"} style={styles.tdSmall}>{row.ROW.ENABLE_SMS}</td>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.tdSmall}>
                                    <button style={{ fontSize: '12px', width: '100%', cursor: 'pointer' }} onClick={() => { handleEdit(row.ROW) }} >EDIT</button>
                                </td>
                            </>
                        )}
                    />
                </>
                :
                <>
                    <MDBox style={{ paddingTop: '12px', width: '100%', textAlign: 'center' }}>
                        <><div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10%' }}><CircularProgress /></div></>
                    </MDBox>
                </>
            }

            <Modal
                open={open}
                onClose={handleModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <MDBox sx={styles.modal} className="admin-user-modal">
                    <MDBox
                        display={{ xs: "block" }}
                        position="sticky"
                        // top={0}
                        // right={0}
                        mt={'-10px'}
                        mr={'-10px'}
                        onClick={handleModal}
                        style={{ float: 'right' }}
                        sx={{ cursor: "pointer" }}
                    >
                        <MDTypography variant="h6" color="secondary" style={{ fontSize: '22px' }}>
                            <Icon className="button-hover" sx={{ fontWeight: "bold" }} style={{ color: darkMode ? "white" : "black" }}>close</Icon>
                        </MDTypography>
                    </MDBox>
                    <MDBox id="modal-modal-description" pt={2} px={1} component="form" role="form" >
                        <Grid container style={{ justifyContent: 'center' }}>
                            <Grid item xs={12}>
                                <Grid item xs={12} mb={2}>
                                    <MDBox style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <MDTypography component="p">User Information</MDTypography>
                                        <MDTypography component="p">Required*</MDTypography>
                                    </MDBox>
                                </Grid>
                                <Grid container spacing={2} mb={2}>
                                    <Grid item xs={12} md={6}>
                                        <MDInput
                                            className={firstError ? 'error-style account-input' : 'account-input'}
                                            style={styles.input}
                                            error={firstError}
                                            autoComplete="new-password"
                                            label="First name *"
                                            name="firstname"
                                            fullWidth
                                            value={newUserData.FIRST_NAME || ''}
                                            onChange={(e) => setNewUserData({ ...newUserData, FIRST_NAME: e.target.value })}
                                            inputProps={{
                                                autoComplete: 'new-password'
                                            }}
                                        />
                                        <MDTypography style={{ display: firstError ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a first name</MDTypography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MDInput
                                            className={lastError ? 'error-style account-input' : 'account-input'}
                                            style={styles.input}
                                            error={lastError}
                                            autoComplete="new-password"
                                            label="Last name *"
                                            name="lastname"
                                            fullWidth
                                            value={newUserData.LAST_NAME || ''}
                                            onChange={(e) => setNewUserData({ ...newUserData, LAST_NAME: e.target.value })}
                                            inputProps={{
                                                autoComplete: 'new-password'
                                            }}
                                        />
                                        <MDTypography style={{ display: lastError ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a last name</MDTypography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} mb={2} >
                                    <MDInput
                                        className={emailError ? 'error-style account-input' : 'account-input'}
                                        style={styles.input}
                                        error={emailError}
                                        autoComplete="new-password"
                                        label="Email"
                                        name="email"
                                        fullWidth
                                        value={newUserData.EMAIL || ''}
                                        onChange={(e) => setNewUserData({ ...newUserData, EMAIL: e.target.value })}
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                    />
                                    <MDTypography style={{ display: emailError ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a valid email</MDTypography>
                                </Grid>
                                <Grid item xs={12} mb={2} >
                                    <MDInput
                                        className={usernameError ? 'error-style account-input' : 'account-input'}
                                        style={styles.input}
                                        error={usernameError}
                                        autoComplete="new-password"
                                        label="Username"
                                        name="email"
                                        fullWidth
                                        value={newUserData.USERNAME || ''}
                                        onChange={(e) => setNewUserData({ ...newUserData, USERNAME: e.target.value })}
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                    />
                                    <MDTypography style={{ display: usernameError ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a valid email</MDTypography>
                                </Grid>
                                <Grid id="type-input" item xs={12} mb={2}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Usertype</InputLabel>
                                        <Select
                                            className={userTypeError ? 'error-style account-input' : 'account-input'}
                                            style={{ height: '50px', borderRadius: '8px' }}
                                            label="Usertype"
                                            value={newUserData.USER_TYPE || ''}
                                            fullWidth
                                            onChange={(e) => setNewUserData({ ...newUserData, USER_TYPE: e.target.value })}
                                        >
                                            {options.map((item, key) => {
                                                return (
                                                    <MenuItem value={item.label} key={key} style={{ display: "flex" }} >
                                                        <MDTypography
                                                            component="p"
                                                            fontSize="14px"
                                                            style={{ color: darkMode ? 'white' : 'rgb(73, 80, 87)' }}
                                                        >
                                                            {item.label}
                                                        </MDTypography>
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} mb={2} >
                                    <MDInput
                                        className={passwordError1 ? 'error-style account-input' : 'account-input'}
                                        style={styles.input}
                                        error={passwordError1}
                                        autoComplete="new-password"
                                        label="Password"
                                        name="password1"
                                        fullWidth
                                        value={mask1}
                                        onChange={(e) => handlePasswordChange(e.target.value, 1)}
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                    />
                                    <MDTypography style={{ display: passwordError1 ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a valid email</MDTypography>
                                </Grid>
                                <Grid item xs={12} mb={2} >
                                    <MDInput
                                        className={passwordError2 ? 'error-style account-input' : 'account-input'}
                                        style={styles.input}
                                        error={passwordError2}
                                        autoComplete="new-password"
                                        label="Password"
                                        name="password2"
                                        //type="password"
                                        fullWidth
                                        value={mask2}
                                        onChange={(e) => handlePasswordChange(e.target.value, 2)}
                                        inputProps={{
                                            autoComplete: 'off',
                                            
                                        }}
                                    />
                                    <MDTypography style={{ display: passwordError2 ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a valid email</MDTypography>
                                    <MDTypography style={{ display: passMatchError ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Passwords do not match</MDTypography>
                                </Grid>

                                {/* check boxes  */}
                                <Grid container mb={2} style={{ display: 'flex', width: '100%', paddingTop: '6px', paddingBottom: '6px' }}>
                                    <Grid container>
                                        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <ThemeProvider theme={outerTheme} >
                                                <FormControlLabel

                                                    label={
                                                        <MDTypography sx={{ fontSize: 12 }}>
                                                            Enable Alerts
                                                        </MDTypography>
                                                    }
                                                    style={{ color: darkMode ? 'white' : 'black', margin: '0', fontSize: '12px' }}
                                                    control={
                                                        <Checkbox
                                                            style={{ marginRight: '12px' }}
                                                            className="checkbox-style"
                                                            checked={newUserData.ENABLE_ALERTS == "1" ? true : false}
                                                            //style={{ marginLeft: '18px' }}
                                                            onChange={(e) => handleCheck(e, 1)}
                                                        />}
                                                />
                                            </ThemeProvider>
                                        </Grid>
                                        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <ThemeProvider theme={outerTheme} >
                                                <FormControlLabel

                                                    label={
                                                        <MDTypography sx={{ fontSize: 12 }}>
                                                            Receieve Alerts
                                                        </MDTypography>
                                                    }
                                                    style={{ color: darkMode ? 'white' : 'black', margin: '0', fontSize: '12px' }}
                                                    control={
                                                        <Checkbox
                                                            style={{ marginRight: '12px' }}
                                                            className="checkbox-style"
                                                            checked={newUserData.RECEIVE_ALERTS == "1" ? true : false}
                                                            //style={{ marginLeft: '18px' }}
                                                            onChange={(e) => handleCheck(e, 2)}
                                                        />}
                                                />
                                            </ThemeProvider>
                                        </Grid>
                                        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <ThemeProvider theme={outerTheme} >
                                                <FormControlLabel

                                                    label={
                                                        <MDTypography sx={{ fontSize: 12 }}>
                                                            Enable SMS
                                                        </MDTypography>
                                                    }
                                                    style={{ color: darkMode ? 'white' : 'black', margin: '0', fontSize: '12px' }}
                                                    control={
                                                        <Checkbox
                                                            style={{ marginRight: '12px' }}
                                                            className="checkbox-style"
                                                            checked={newUserData.ENABLE_SMS == "1" ? true : false}
                                                            //style={{ marginLeft: '18px' }}
                                                            onChange={(e) => handleCheck(e, 3)}
                                                        />}
                                                />
                                            </ThemeProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} mb={2}>
                                        <MDButton
                                            disabled={disableCancel}
                                            style={{ width: '100%', backgroundColor: cancelButtonColor, color: 'white' }}
                                            color="primary"
                                            onClick={handleCancel}>
                                            CANCEL
                                        </MDButton>
                                    </Grid>
                                    <Grid item xs={6} mb={2}>
                                        <MDButton
                                            disabled={disableUpdate}
                                            style={{ width: '100%', backgroundColor: updateButtonColor, color: 'white' }}
                                            color="primary"
                                            onClick={handleUserUpdate}>
                                            SAVE
                                        </MDButton>
                                    </Grid>
                                </Grid>
                                {disableUpdateButtonError ? <MDTypography color="error" fontSize="16px" style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px' }}>Please correct errors to continue</MDTypography> : null}
                            </Grid>
                        </Grid>
                    </MDBox>
                </MDBox>
            </Modal>
        </>
    );
}

// Typechecking props for the DryerCard3
AdminCard.propTypes = {
};


export default AdminCard;
