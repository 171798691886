/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

import '../../../../App.css';
import grain_temp_svg from '../../../../assets/images/mc/grain-temp.svg';
import setpoint_svg from '../../../../assets/images/mc/setpoint.svg';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useMaterialUIController } from "context";

import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";
import CardIconHeader from "../Components/CardIconHeader";

function CardBackInfo({ index, handleFlip, destination, comingSoon }) {

    const [controller, dispatch] = useMaterialUIController();

    const {
        dryerData,
        darkMode
    } = controller;



    let name = dryerData[index]?.BUSINESS;
    let model = dryerData[index]?.Dryer_Model_Name;
    let serial = dryerData[index]?.Dryer_Serial_Name;
    let dealer = dryerData[index]?.DEALER;
    let rsm = dryerData[index]?.SALESMAN;
    let sub = dryerData[index]?.Sub_Status;

    let accudry = dryerData[index]?.runData ? dryerData[index]?.runData?.Dryer_Master_Installed == 1 ? ' (Accudry)' : '' : 'N/A';

    let control = dryerData[index]?.runData ? dryerData[index]?.runData?.control + accudry : 'N/A';
    let plc = dryerData[index]?.runData ? dryerData[index]?.runData?.PLC_Version : 'N/A';
    let hmi = dryerData[index]?.runData ? dryerData[index]?.runData?.HMI_Version : 'N/A';
    let op_mode = dryerData[index]?.runData ? dryerData[index]?.runData?.Op_Mode : 'N/A';




    const rows = {
        Model: model,
        Serial: serial,
        Dealer: dealer,
        RSM: rsm,
        Control: control == 'undefined' ? '' : control,
        PLC: plc,
        HMI: hmi,
        Subscription: sub == 0 ? 'Expired' : 'Valid'
    }

    let arr = []
    return (
        <>
            <MDBox
                className="card-corner"
                display={{ xs: "block" }}
                position="absolute"
                top={0}
                right={0}
                p={1.625}
                onClick={(e) => handleFlip(e, serial, destination, index)}
                sx={{ cursor: "pointer" }}
            >
                <MDTypography variant="h6" color="secondary" style={{ fontSize: '22px' }}>
                    <Icon className="button-hover" style={{ color: darkMode ? "white" : "black" }}>undo</Icon>
                </MDTypography>
            </MDBox>
            <MDBox id={serial + '-header'} justifyContent="space-between" px={2} pt={3}>
                <MDBox textAlign="right" lineHeight={1.25}>
                    <Grid container spacing={1} p={'3px'}>
                        <Grid item xs={9} style={{ display: 'flex' }}>
                            <MDBox>
                                <MDTypography style={{ textAlign: 'left', fontSize: '24px' }}>
                                    <span>{name}</span>
                                </MDTypography>
                                <MDTypography style={{ textAlign: 'left', fontSize: '16px' }}>
                                    <span style={{ whiteSpace: 'nowrap' }}>{serial}&nbsp;&nbsp;|&nbsp;&nbsp;{model}</span>
                                </MDTypography>
                            </MDBox>
                        </Grid>
                        <CardIconHeader index={index} />
                    </Grid>
                </MDBox>
            </MDBox>
            <hr className="dryer-card-divider-multi" />
            <MDBox px={2}>
                <Grid container spacing={1}>
                    {(() => {
                        Object.keys(rows).forEach(function (key) {
                            arr.push(
                                <Grid key={key} item xs={12} style={{ display: "flex", }}>
                                    <Grid item xs={6}>
                                        <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400' }} >
                                            <span>{key}:&nbsp;</span>
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ textAlign: 'left' }}>
                                            <span>{rows[key]}</span>
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            );
                        });
                        return arr;

                    })()}

                </Grid>
            </MDBox>
        </>
    );
}

// Typechecking props for the CardBackMidGrain

CardBackInfo.defaultProps = {
    index: 0,
};

CardBackInfo.propTypes = {
    index: PropTypes.number,
    handleFlip: PropTypes.func,
    destination: PropTypes.number,
    comingSoon: PropTypes.bool,
};

export default CardBackInfo;
