/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import '../../App.css';

// @mui material components
import Grid from "@mui/material/Grid";

import Icon from "@mui/material/Icon";
import Alert from '@mui/material/Alert';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography"

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from "react";

import PropTypes, { object } from 'prop-types';
import Box from '@mui/material/Box';

import Paper from '@mui/material/Paper';

import IconButton from "@mui/material/IconButton";

import CircularProgress from '@mui/material/CircularProgress';
import { experimentalStyled as styled } from '@mui/material/styles';
import useAxiosPrivate from "hooks/useAxiosPrivate";

import {
  navbarIconButton,
} from "examples/Navbars/DashboardNavbar/styles";
import MDAlert from "components/MDAlert";
import useLogout from "hooks/useLogout";
import FeedbackCard from 'examples/Cards/FeedbackCards';
import {
  useMaterialUIController,
  setGlobalAlertMessage,
  setGlobalAlertColor,
  setGlobalAlertActive,
  setActiveAlarms,
  setMaxAlarm
} from "context";
import constants from '../../constants';
import { Outlet } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Divider } from '@mui/material';

function Dashboard() {
  const axiosPrivate = useAxiosPrivate();

  const [controller, dispatch] = useMaterialUIController();
  const {
    auth,
    dryerData,
    dryerDataIndex,
    isLoading,
    view,
    darkMode,
    globalAlertMessage,
    globalAlertActive,
    globalAlertColor,
    activeAlarms,
    maxAlarm
  } = controller;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#000000',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
  }));


  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      //{...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = darkMode ? white.main : white.main;

      return colorValue;
    },
    fontSize: 30,
  });

  function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }
  const logout = useLogout();
  const getUserActiveAlarms = async () => {

    let record_id = dryerData[dryerDataIndex]?.Dryer_Record_ID;
    let user_id = dryerData[dryerDataIndex]?.USER_ID;

    try {
      if (auth?.accessToken) {

        const response = await axiosPrivate.post('/getUserActiveAlarms', JSON.stringify({ record_id }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          });
        let alarmMax = 0;
        response.data.forEach((element) => {

          let dateLocal = new Date(element['AlarmTime'])
          // let dateLocal = new Date('2024-10-09T14:50:23.000Z')

          //let offset = dateLocal.getTimezoneOffset() / 60;

          let offset = dateLocal.getTimezoneOffset(); // /60?

          dateLocal.setHours(dateLocal.getHours() - offset / 60);


          let year = dateLocal.getFullYear();
          let month = dateLocal.getMonth() + 1;
          let day = dateLocal.getDate();
          let hour = dateLocal.getHours();
          let min = dateLocal.getMinutes();
          let sec = dateLocal.getSeconds();

          month = month < 10 ? '0' + month : month;
          day = day < 10 ? '0' + day : day;
          min = min < 10 ? '0' + min : min;
          hour = hour < 10 ? '0' + hour : hour;
          sec = sec < 10 ? '0' + sec : sec;

          let convertTime = hour + ':' + min + ':' + sec

          let convertedTime = tConvert(convertTime);

          let timestamp = month + '/' + day + '/' + year + ' ' + convertedTime;
          // var d = new Date(year, month, day, hour, min, sec);
          // dateLocal.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
          // console.log(dateLocal)

          if (element['Type'] > alarmMax) {
            alarmMax = element['Type'];
          }

          element['AlarmTime'] = timestamp;
        })

        setMaxAlarm(dispatch, alarmMax);

        setActiveAlarms(dispatch, response.data);
      }
    } catch (e) {
      if (e.response.status == 403 || e.response.status == 401) {
        await logout();
      } else {
        console.log(e);
      }
    }
  };

  //get alerts on page load
  useEffect(() => {

    if (!document.hidden) {
      if (dryerData[dryerDataIndex]?.Dryer_Record_ID) {
        if (view == constants.SINGLE_VIEW || view == constants.ALERT_VIEW) {
          getUserActiveAlarms();
        }
      }
    }
  }, [dryerDataIndex, dryerData])


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);
  const handleModal = () => setOpen(!open);

  useEffect(() => {

    if (globalAlertActive) {
      setTimeout(function () {
        setGlobalAlertActive(dispatch, false);
        setGlobalAlertMessage(dispatch, '');
        setGlobalAlertColor(dispatch, '');
      }, 10000);
    }

  }, [globalAlertActive]);
  let alertArr = [];

  const styles = {

    expandedFont: {
      fontSize: '16px',
      color: maxAlarm <= 2 ? 'black' : 'white'
    },

  };

  let errorAlertBgColor = darkMode ? "rgb(22, 11, 11)" : "rgb(253, 237, 237)";
  let successAlertBgColor = darkMode ? "rgb(12, 19, 13)" : "rgb(237, 247, 237)";

  return (
    <DashboardLayout>
      {isLoading ? <div className="center-screen" ><CircularProgress /></div> :
        <>
          <MDAlert style={{ fontSize: '18px', fontWeight: '400', borderRadius: '8px', marginBottom: '12px', display: 'block' }}>
            <Grid container style={{ width: '100%' }}>
              <Grid item xs={10} style={{ alignSelf: 'center' }}>
                <MDTypography style={{ fontSize: '18px', color: 'white' }}>
                  This Beta site is currently under construction. Certain features are not yet available. Please explore and provide your feedback!</MDTypography>
              </Grid>
              <Grid item xs={2}>
                <MDBox style={{ display: 'flex', justifyContent: 'end', height: '100%' }}>
                  <IconButton
                    size="small"
                    color="inherit"
                    sx={navbarIconButton}
                    onClick={handleModal}
                  >
                    <Icon sx={iconsStyle} style={{ color: darkMode ? "white" : "white" }} fontSize="medium">
                      feedback
                    </Icon>
                  </IconButton>
                </MDBox>
                {/* feedback modal */}
                <FeedbackCard handleModal={handleModal} open={open} />
              </Grid>
            </Grid>
          </MDAlert>

          {/* alternate alert style */}
          {/* <Alert
            variant="outlined"
            severity="info"
            style={{
              display: 'flex',
              marginTop: '12px',
              marginBottom: '12px',
              borderRadius: '8px',
              // color: darkMode ? "rgb(184, 231, 251)" : "rgb(1, 67, 97)",
              backgroundColor: darkMode ? '' : 'rgb(229, 246, 253)'

            }}
            action={
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                onClick={handleModal}
              >
                <Icon sx={iconsStyle} style={{ alignSelf: 'center', color: darkMode ? "rgb(184, 231, 251)" : "rgb(1, 67, 97)" , marginRight: '12px' }} fontSize="medium">
                  feedback
                </Icon>
              </IconButton>
            }
          >
            <MDTypography style={{ fontSize: '18px', color: darkMode ? "rgb(184, 231, 251)" : "rgb(1, 67, 97)" }}>
              This Beta site is currently under construction. Certain features are not yet available. Please explore and provide your feedback!
            </MDTypography>
            <FeedbackCard handleModal={handleModal} open={open} />
          </Alert> */}


          {globalAlertActive ?
            <Alert
              variant="outlined"
              severity={globalAlertColor}
              style={{
                marginTop: '12px',
                marginBottom: '12px',
                borderRadius: '8px',
                color: globalAlertColor == "error" ? "rgb(246, 104, 93)" : "#41C330",
                backgroundColor: globalAlertColor == "error" ? errorAlertBgColor : successAlertBgColor
              }}>
              {globalAlertMessage}
            </Alert> : null}
          {/* {globalAlertActive ? <MDAlert color={globalAlertColor} style={{ fontSize: '16px', marginBottom: '12px', width: '100%', borderRadius: '8px' }}>{globalAlertMessage}</MDAlert> : null} */}

          <DashboardNavbar />
          {activeAlarms.length > 0 && view == constants.SINGLE_VIEW ?
            <>
              <MDBox >
                <Accordion id={maxAlarm == 3 ? "alerts-box-color-red" : maxAlarm == 2 ? "alerts-box-color-orange" : "alerts-box-color-yellow"} style={{ marginTop: '12px', padding: '0', borderRadius: '8px' }}>
                  <AccordionSummary
                    style={{ margin: '0', padding: '0', display: 'flex' }}
                    expandIcon={<ExpandMoreIcon style={{ color: maxAlarm <= 2 ? 'black' : 'white', marginRight: '16px' }} />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <MDBox style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <MDTypography style={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '16px', color: maxAlarm <= 2 ? 'black' : 'white' }}>
                        {activeAlarms.length > 1 ? activeAlarms.length + ' Active Alarms' : activeAlarms.length + ' Active Alarm'}
                      </MDTypography>
                      <MDTypography style={{ color: maxAlarm <= 2 ? 'black' : 'white', textAlign: 'right' }}>
                        {activeAlarms[0]['AlarmTime']}
                      </MDTypography>
                    </MDBox>
                  </AccordionSummary>
                  <AccordionDetails style={{ paddingTop: '0' }}>
                    <Divider style={{ marginTop: '0' }} />
                    {(() => {
                      if (activeAlarms) {
                        Object.keys(activeAlarms).forEach(function (key) {
                          alertArr.push(
                            <Grid container key={key} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              <Grid item xs={12}><MDTypography style={styles.expandedFont}>{activeAlarms[key]['Alarm Name']}</MDTypography></Grid>
                              {/* <Grid item xs={8}><MDTypography style={styles.expandedFont}>{activeAlarms[key]['Alarm Message']}</MDTypography></Grid> */}
                            </Grid>
                          );
                        });
                        return alertArr;
                      }
                    })()}
                  </AccordionDetails>
                </Accordion>
              </MDBox>
            </>
            :
            <>
            </>
          }
          <Outlet />
        </>
      }
    </DashboardLayout>
  );
}

Dashboard.propTypes = {

};

export default Dashboard;
