/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

import Skeleton from '@mui/material/Skeleton';
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography"

import React, { useState } from "react";

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import '../../../App.css'

import Paper from '@mui/material/Paper';

import DryerLineChart from 'examples/Charts/LineCharts/DryerLineChart';


import { useMaterialUIController, setOpenConfigurator } from "context";
import { experimentalStyled as styled } from '@mui/material/styles';
import DryerCard3 from 'examples/Cards/DryerCards/DryerCard3';
import AlertCard from "examples/Cards/AlertCards";

function SingleView() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    dryerData,
    dryerDataIndex,
  } = controller;

  const [count, setCount] = useState(0);

  var arr = [];

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      textAlign: "left",
    },
  };


  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#000000',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
  }));

  //const [state, setState] = useState(initialState);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      //{...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            <Box>{children}</Box>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `main-tab-${index}`,
      'aria-controls': `main-tabpanel-${index}`,
    };
  }
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function getTab(tab, active) {
    return (
      <React.Fragment>
        <Grid container spacing={2} style={{ height: '35px', fontSize: '1rem' }}>
          <Grid item xs={12} style={{ color: tabValue == active ? "white" : "" }}>
            {tab}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  const align = 'left'
  function FormRow() {
    return (
      <React.Fragment>
        <Grid item xs={4} md={4} style={{ textAlign: align }}>
          <MDTypography fontWeight="bold" >Serial: <br className="visible-xs" />{dryerData[dryerDataIndex]?.Dryer_Serial_Name}</MDTypography>
        </Grid>
        <Grid item xs={4} md={4} style={{ textAlign: align }}>
          <MDTypography fontWeight="bold" >Model: <br className="visible-xs" />{dryerData[dryerDataIndex]?.Dryer_Model_Name}</MDTypography>
        </Grid>
        <Grid item xs={4} md={4} style={{ display: 'flex', justifyContent: 'center' }}>
          {dryerData[dryerDataIndex]?.Online_Status == 1 ?
            <div className="tooltip">
              <Icon fontSize="default" style={{ color: '#41C330', fontSize: '36px' }}>public</Icon>
              <span className="tooltiptext">Connection: Online</span>
            </div>
            :
            <div className="tooltip">
              <Icon fontSize="default" style={{ color: 'gray', fontSize: '36px' }}>public_off</Icon>
              <span className="tooltiptext">Connection: Offline</span>
            </div>
          }
          {dryerData[dryerDataIndex]?.runData?.Op_Mode != "Stopped" && dryerData[dryerDataIndex]?.runData?.Op_Mode != null ?
            <div className="tooltip">
              <Icon fontSize="default" style={{ color: '#41C330', fontSize: '36px' }}>settings</Icon>
              <span className="tooltiptext">Dryer State: Running</span>
            </div>
            :
            <div className="tooltip">
              <Icon className="" fontSize="default" style={{ color: 'gray', fontSize: '36px' }}>settings</Icon>
              <span className="tooltiptext">Dryer State: Stopped</span>
            </div>
          }
          {dryerData[dryerDataIndex]?.Sub_Status == 1 ?
            <div className="tooltip">
              <Icon className="tooltip" fontSize="default" style={{ color: '#41C330', fontSize: '36px' }}>credit_card</Icon>
              <span className="tooltiptext">Subscription: Valid</span>
            </div>
            :
            <div className="tooltip">
              <Icon className="tooltip" fontSize="default" style={{ color: 'gray', fontSize: '36px' }}>credit_card</Icon>
              <span className="tooltiptext">Subscription: Expired</span>
            </div>
          }
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <MDBox className="overflow-x-hidden box">
      <Grid item xs={12} md={12} lg={12} style={{ overFlow: 'hidden' }}>
        {/* <Grid item xs={12} md={6} lg={6} xl={6} xxl={4}>
          <Card>
          <Box sx={{ flexGrow: 1 }} p={2}>
            <Grid container spacing={1}>
              <Grid container item spacing={3}>
                <FormRow />
              </Grid>
            </Grid>
          </Box>

          <Tabs
            className="singleViewTabs"
            value={tabValue} onChange={handleTabChange} aria-label="tabs"
            style={{ padding: '10px', background: 'transparent' }}
            TabIndicatorProps={{ style: { backgroundColor: "transparent", boxShadow: 'none' } }}
          >
            <Tab className={tabValue == 0 ? "tab-active" : "tab-inactive"} component={NavLink} label={getTab("Dashboard", 0)} {...a11yProps(0)} disableRipple={true} />
            <Tab className={tabValue == 1 ? "tab-active" : "tab-inactive"} component={NavLink} label={getTab("Remote", 1)} {...a11yProps(1)} disableRipple={true} />
            <Tab className={tabValue == 2 ? "tab-active" : "tab-inactive"} component={NavLink} label={getTab("Alerts", 2)} {...a11yProps(2)} disableRipple={true} />
          </Tabs>

        </Card>
        </Grid> */}

        <MDBox py={'12px'} className="full">
          {tabValue == 0 ?
            <MDBox mt={0}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6} xl={4} xxl={4} >
                  {/* <Grid item xs={12}>
                      <Card  style={{marginBottom:'12px'}}>
                        <Box sx={{ flexGrow: 1 }} p={2}>
                          <Grid container spacing={1}>
                            <Grid container item spacing={3}>
                              <FormRow />
                            </Grid>
                          </Grid>
                        </Box>

                        <Tabs
                          className="singleViewTabs"
                          value={tabValue} onChange={handleTabChange} aria-label="tabs"
                          style={{ padding: '10px', background: 'transparent' }}
                          TabIndicatorProps={{ style: { backgroundColor: "transparent", boxShadow: 'none' } }}
                        >
                          <Tab className={tabValue == 0 ? "tab-active" : "tab-inactive"} component={NavLink} label={getTab("Dashboard", 0)} {...a11yProps(0)} disableRipple={true} />
                          <Tab className={tabValue == 1 ? "tab-active" : "tab-inactive"} component={NavLink} label={getTab("Remote", 1)} {...a11yProps(1)} disableRipple={true} />
                          <Tab className={tabValue == 2 ? "tab-active" : "tab-inactive"} component={NavLink} label={getTab("Alerts", 2)} {...a11yProps(2)} disableRipple={true} />
                        </Tabs>

                      </Card>
                    </Grid> */}
                  <Grid item xs={12}>
                    <MDBox >
                      <DryerCard3
                        icon="home"
                        dryer={dryerData[dryerDataIndex]}
                        count="0"
                        data={{
                          color: "success",
                        }}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={8} xxl={8} style={{zIndex:'2'}}>
                  <MDBox>
                    <DryerLineChart index={dryerDataIndex} />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>

            : tabValue == 1 ?

              <MDBox>
                <Skeleton sx={{ height: '200px' }} animation="wave" />
                <Skeleton sx={{ height: '200px' }} animation="wave" />
                <Skeleton sx={{ height: '200px' }} animation="wave" />
              </MDBox>

              :

              <MDBox>
                <AlertCard />
              </MDBox>
          }
        </MDBox>
      </Grid>
    </MDBox>
  );
}

SingleView.propTypes = {
  // dryerList: PropTypes.arrayOf(objects),
  serial: PropTypes.string,
  model: PropTypes.string,
  name: PropTypes.string
};

export default SingleView;
