/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import '../../../App.css'

import PropTypes from "prop-types";

// @mui material components

import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useMaterialUIController, setDryerData, setDryerDataIndex, setView, setGlobalAlertMessage, setGlobalAlertColor, setGlobalAlertActive } from "context";
import React, { useState, useEffect, useRef } from "react";
import CircularProgress from '@mui/material/CircularProgress';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Virtuoso, VirtuosoGrid, TableVirtuoso } from 'react-virtuoso'
import Fuse from 'fuse.js'
import Modal from '@mui/material/Modal';
import MDInput from 'components/MDInput';
import Icon from "@mui/material/Icon";


function AdminCard() {
    const [controller, dispatch] = useMaterialUIController();
    const axiosPrivate = useAxiosPrivate();

    const [adminDryerList, setAdminDryerList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [onlineCount, setOnlineCount] = useState(0);
    const [pollData, setPollData] = useState([]);

    const {
        dryerData,
        dryerDataIndex,
        auth,
        darkMode
    } = controller;

    const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
        color: () => {
            let colorValue = darkMode ? white.main : white.main;

            return colorValue;
        },
        fontSize: 30,
    });

    useEffect(() => {
        const getDryerList = async () => {

            let record_id = dryerData[dryerDataIndex]?.Dryer_Record_ID;
            let user_id = dryerData[dryerDataIndex]?.USER_ID;

            try {
                if (auth?.accessToken) {

                    const response = await axiosPrivate.post('/getDryerList', JSON.stringify({}),
                        {
                            headers: { 'Content-Type': 'application/json' },
                            withCredentials: true
                        });

                    let arr = [];
                    let count = 0;
                    response.data.forEach((element) => {
                        if (element.Online_Status == "1") {
                            count++;
                        }
                        arr.push(element);
                    });
                    setOnlineCount(count);
                    setAdminDryerList(arr);
                    setLoading(false);
                }
            } catch (e) {
                if (e.response.status == 403 || e.response.status == 401) {
                    await logout();
                } else {
                    console.log(e);
                }
            }
        };

        getDryerList();

    }, []);

    const getPoll = async (vpn_address, plc_type) => {
        setPollData([]);
        handleModal();
        try {
            if (auth?.accessToken) {

                const response = await axiosPrivate.post('/getPoll', JSON.stringify({ vpn_address, plc_type }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                //console.log(response.data);
                let split = response.data.split('$$');
                setPollData(split);

            }
        } catch (e) {
            if (e.response.status == 403 || e.response.status == 401) {
                await logout();
            } else {
                console.log(e);
            }
        }
    };

    function tConvert(time) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }

    const formatDate = (date) => {
        let formatDate = new Date(date);

        let offset = formatDate.getTimezoneOffset(); // /60?

        formatDate.setHours(formatDate.getHours() - offset / 60);

        let year = formatDate.getFullYear();
        let month = formatDate.getMonth() + 1;
        let day = formatDate.getDate();
        let hour = formatDate.getHours();
        let min = formatDate.getMinutes();
        let sec = formatDate.getSeconds();

        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        min = min < 10 ? '0' + min : min;
        hour = hour < 10 ? '0' + hour : hour;
        sec = sec < 10 ? '0' + sec : sec;

        let convertTime = hour + ':' + min;

        let convertedTime = tConvert(convertTime);

        let timestamp = month + '-' + day + '-' + year + ' ' + convertedTime;

        return timestamp;
    }

    const [sortOrder, setSortOrder] = useState(false);

    const sortRecordId = () => {
        setSortOrder(!sortOrder);
        let arr = [...adminDryerList];
        if (sortOrder) {
            arr.sort((a, b) => a.Dryer_Record_ID - b.Dryer_Record_ID);
        } else {
            arr.sort((a, b) => b.Dryer_Record_ID - a.Dryer_Record_ID);
        }
        setAdminDryerList(arr);
    }

    const sortSerial = () => {
        setSortOrder(!sortOrder);
        let arr = [...adminDryerList];
        if (sortOrder) {
            arr.sort((a, b) => a.Dryer_Serial_Name - b.Dryer_Serial_Name);
        } else {
            arr.sort((a, b) => b.Dryer_Serial_Name - a.Dryer_Serial_Name);
        }
        setAdminDryerList(arr);
    }

    const sortModel = () => {
        setSortOrder(!sortOrder);
        let arr = [...adminDryerList];
        if (sortOrder) {
            arr.sort((a, b) => a.Dryer_Model_Name - b.Dryer_Model_Name);
        } else {
            arr.sort((a, b) => b.Dryer_Model_Name - a.Dryer_Model_Name);
        }
        setAdminDryerList(arr);
    }

    const sortKey = () => {
        setSortOrder(!sortOrder);
        let arr = [...adminDryerList];
        if (sortOrder) {
            arr.sort((a, b) => a.Commmon_Name.substring(5) - b.Commmon_Name.substring(5));
        } else {
            arr.sort((a, b) => b.Commmon_Name.substring(5) - a.Commmon_Name.substring(5));
        }
        setAdminDryerList(arr);
    }

    const sortRouterOnline = () => {
        setSortOrder(!sortOrder);
        let arr = [...adminDryerList];
        if (sortOrder) {
            arr.sort((a, b) => a.Online_Status - b.Online_Status);
        } else {
            arr.sort((a, b) => b.Online_Status - a.Online_Status);
        }
        setAdminDryerList(arr);
    }

    const sortPLCOnline = () => {
        setSortOrder(!sortOrder);
        let arr = [...adminDryerList];
        if (sortOrder) {
            arr.sort((a, b) => a.PLC_Online_Status - b.PLC_Online_Status);
        } else {
            arr.sort((a, b) => b.PLC_Online_Status - a.PLC_Online_Status);
        }
        setAdminDryerList(arr);
    }

    const sortPLC = () => {
        setSortOrder(!sortOrder);
        let arr = [...adminDryerList];
        if (sortOrder) {
            arr.sort((a, b) => a.PLC_Type_ID - b.PLC_Type_ID);
        } else {
            arr.sort((a, b) => b.PLC_Type_ID - a.PLC_Type_ID);
        }
        setAdminDryerList(arr);
    }

    const sortTime = () => {
        setSortOrder(!sortOrder);
        let arr = [...adminDryerList];
        if (sortOrder) {
            arr.sort((a, b) => new Date(a.Update_Time) - new Date(b.Update_Time));
        } else {
            arr.sort((a, b) => new Date(b.Update_Time) - new Date(a.Update_Time));
        }
        setAdminDryerList(arr);
    }

    const style = {
        style: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            maxHeight: '80%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: darkMode ? '#141414' : '#E6E6E6',
            border: darkMode ? '2px solid #fff' : '2px solid #000',
            boxShadow: 24,
            borderRadius: '8px',
            overflowY: 'auto',
            p: 4,
        },
        poll: {
            fontSize: '12px',
        },
    };

    const styles = {
        tableContainer: {
            width: '100%'
        },
        headerContainer: {
            width: '100%',
            borderRight: '1px solid gray',
            backgroundColor: darkMode ? 'black' : 'white',
            textAlign: 'left'
        },
        td: {
            borderLeft: darkMode ? '1px solid gray' : '1px solid black',
            borderTop: darkMode ? '1px solid gray' : '1px solid black',
            fontSize: '12px',
            textAlign: 'left',
            padding: '5px',
            color: darkMode ? 'white' : 'black',
            //whiteSpace: 'wrap',
        },
        tdSmall: {
            borderLeft: darkMode ? '1px solid gray' : '1px solid black',
            borderTop: darkMode ? '1px solid gray' : '1px solid black',
            textAlign: 'center',
            fontSize: '12px',
            textAlign: 'left',
            padding: '5px',
            color: darkMode ? 'white' : 'black',
        },
        th: {
            cursor: 'pointer',
            fontSize: '12px',
            padding: '5px',
            //justifyContent:'space-between',
            borderLeft: darkMode ? '1px solid gray' : '1px solid black',
            // borderRight: darkMode ? '1px solid white' : '1px solid black',
            borderTop: darkMode ? '1px solid gray' : '1px solid black',
            color: darkMode ? 'white' : 'black',
        },
    };

    const [open, setOpen] = useState(false);
    const handleModal = () => setOpen(!open);

    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        }
    }, []);

    const searchOptions = {
        includeScore: true,
        keys: ['Dryer_Serial_Name', 'Dryer_Model_Name', 'Virtual_Address', 'Commmon_Name', 'Dryer_Record_ID']
    }

    const getUserListSearch = (data) => {

        let arr = [];

        data?.map((row, index) => {

            arr.push(
                {
                    "ROW": row,
                    "Dryer_Serial_Name": row.Dryer_Serial_Name,
                    "Dryer_Model_Name": row.Dryer_Model_Name,
                    "Virtual_Address": row.Virtual_Address,
                    "Commmon_Name": row.Commmon_Name,
                    "Dryer_Record_ID": row.Dryer_Record_ID,
                }
            );
        });

        return arr;
    }

    // set initial list on page load
    // works but could be better
    useEffect(() => {
        onSearchChange('');
    }, [adminDryerList]);

    const [search, setSearch] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const onSearchChange = (value) => {

        if (virtuoso.current != null) {
            virtuoso.current.scrollToIndex({
                index: 0,
                behavior: 'auto'
            });
        }

        try {
            if (value == '' || value == undefined) {

                setSearch('');

                let userList = getUserListSearch(adminDryerList);

                setFilteredList(userList);

            } else {

                let userList = getUserListSearch(adminDryerList);

                const fuse = new Fuse(userList, searchOptions);
                const result = fuse.search(value);

                setFilteredList(getUserListSearch(result));

                let newUserList = [];

                //extract element from filted list then pass to getSearch
                result.forEach((element) => {
                    newUserList.push(element.item);
                });


                setFilteredList(newUserList);

                setSearch(value);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const virtuoso = useRef(null);

    return (
        <MDBox >
            {adminDryerList.length > 0 ?
                <MDBox style={{ overflowY: 'auto' }}>
                    {/* MD Components are heavy use base html or virtual list for faster loading */}
                    <>
                        <MDBox pb={'16px'} id="search-box">
                            <MDInput
                                autoComplete="off"
                                placeholder="Serial, Model, VPN, Key, Record..."
                                value={search}
                                size="large"
                                fullWidth
                                onChange={({ currentTarget }) => {
                                    //setSearch(currentTarget.value);
                                    onSearchChange(currentTarget.value);
                                }}
                            />
                        </MDBox>
                    </>
                    <MDBox style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MDTypography component="p" fontSize={'14px'}>Total: {adminDryerList.length}</MDTypography>
                        <MDTypography component="p" fontSize={'14px'}>Online: {onlineCount} / {adminDryerList.length}</MDTypography>
                    </MDBox>

                    <TableVirtuoso
                        id="virtual-table"
                        data={filteredList}
                        style={{ height: '100dvh', width: '100%' }}
                        ref={virtuoso}
                        //useWindowScroll
                        //totalCount={adminUserList.length}
                        //components={{ tr: ({ style, ...props }) => <tr {...props} style={{ width: 700 }} /> }}
                        fixedHeaderContent={(index, user) => (
                            <tr style={styles.headerContainer}>
                                <th style={styles.th} onClick={() => { sortRecordId() }}>Record</th>
                                <th style={styles.th} onClick={() => { sortSerial() }}>Serial</th>
                                <th style={styles.th} onClick={() => { sortModel() }}>Model</th>
                                <th style={styles.th} onClick={() => { sortKey() }}>Key</th>
                                <th style={styles.th}>Address</th>
                                <th style={styles.th} onClick={() => { sortRouterOnline() }}>Router</th>
                                <th style={styles.th} onClick={() => { sortPLCOnline() }}>PLC</th>
                                <th style={styles.th} onClick={() => { sortPLC() }}>PLC_Type</th>
                                <th className="hidden-xs" style={styles.th} onClick={() => { sortTime() }}>Update_Time</th>
                                <th style={styles.th} onClick={() => { sortTime() }}>Poll</th>
                            </tr>
                        )}
                        itemContent={(index, row) => (
                            <>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.td}>{row.ROW.Dryer_Record_ID}</td>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.td}>{row.ROW.Dryer_Serial_Name}</td>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.td}>{row.ROW.Dryer_Model_Name}</td>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.td}>{row.ROW.Commmon_Name}</td>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.td}>{row.ROW.Virtual_Address}</td>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.td}>
                                    {
                                        row.ROW.Online_Status == 1 ?
                                            <span style={{ color: darkMode ? "chartreuse" : "blue" }}>Online</span> :
                                            <span style={{ color: "gray" }}>Offline</span>
                                    }
                                </td>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.td}>
                                    {
                                        row.ROW.PLC_Online_Status == 1 ?
                                            <span style={{ color: darkMode ? "chartreuse" : "blue" }}>Online</span> :
                                            <span style={{ color: "gray" }}>Offline</span>
                                    }
                                </td>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.td}>{row.ROW.PLC_Type_ID}</td>
                                <td className={index % 2 == 0 ? "table-bg hidden-xs" : "hidden-xs"} style={styles.td}>{formatDate(row.ROW.Update_Time)}</td>
                                <td className={index % 2 == 0 ? "table-bg" : ""} style={styles.td}>
                                    <button style={{ fontSize: '12px', width: '100%', cursor: 'pointer' }} onClick={() => { getPoll(row.ROW.Virtual_Address, row.ROW.PLC_Type_ID) }}>POLL</button>
                                </td>
                            </>
                        )}
                    />

                    <Modal
                        open={open}
                        onClose={handleModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >

                        <MDBox sx={style.style}>
                            <MDBox
                                display={{ xs: "block" }}
                                position="sticky"
                                mt={'-10px'}
                                mr={'-10px'}
                                onClick={handleModal}
                                style={{ float: 'right' }}
                                sx={{ cursor: "pointer" }}
                            >
                                <MDTypography variant="h6" color="secondary" style={{ fontSize: '22px' }}>
                                    <Icon className="button-hover" sx={{ fontWeight: "bold" }} style={{ color: darkMode ? "white" : "black" }}>close</Icon>
                                </MDTypography>
                            </MDBox>
                            <MDBox id="modal-modal-description" style={style.poll}>

                                {pollData.length > 0 ?
                                    <>{pollData.map((row, key) => (
                                        <MDTypography key={key} component="p" style={{ fontSize: '12px' }}>{row}</MDTypography>
                                    ))}</>
                                    :
                                    <><div style={{ display: 'flex', justifyContent: 'center', }}><CircularProgress /></div></>
                                }
                            </MDBox>
                        </MDBox>
                    </Modal>
                </MDBox>
                :
                <>
                    <MDBox style={{ paddingTop: '12px', width: '100%', textAlign: 'center' }}>
                        <><div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10%' }}><CircularProgress /></div></>
                    </MDBox>
                </>
            }
        </MDBox>

    );
}

// Typechecking props for the DryerCard3
AdminCard.propTypes = {
};


export default AdminCard;
