/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import constants from "../constants";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "OPEN_SIDENAV": {
      return { ...state, sidenavOpen: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "MULTI": {
      return { ...state, multiDryer: action.value };
    }
    case "VIEW": {
      return { ...state, view: action.value };
    }
    case "SIDE_VIEW": {
      return { ...state, sideView: action.value };
    }
    case "AUTH": {
      return { ...state, auth: action.value };
    }
    case "DRYER_DATA": {
      return { ...state, dryerData: action.value };
    }
    case "DRYER_DATA_INDEX": {
      return { ...state, dryerDataIndex: action.value };
    }
    case "IS_LOADING": {
      return { ...state, isLoading: action.value };
    }
    case "CHART_TIMEFRAME": {
      return { ...state, timeframe: action.value };
    }
    case "GLOBAL_ERROR": {
      return { ...state, globalError: action.value };
    }
    case "GLOBAL_ALERT_MESSAGE": {
      return { ...state, globalAlertMessage: action.value };
    }
    case "GLOBAL_ALERT_ACTIVE": {
      return { ...state, globalAlertActive: action.value };
    }
    case "GLOBAL_ALERT_COLOR": {
      return { ...state, globalAlertColor: action.value };
    }
    case "SET_VIEW_HISTORY": {
      return { ...state, viewHistory: action.value };
    }
    case "ACTIVE_ALARMS": {
      return { ...state, activeAlarms: action.value };
    }
    case "MAX_ALARM": {
      return { ...state, maxAlarm: action.value };
    }
    case "DATA_LOG": {
      return { ...state, dataLog: action.value };
    }
    case "GLOBAL_FILTER": {
      return { ...state, globalFilter: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const initialState = {
    serviceLogin: 5,
    miniSidenav: false,
    sidenavOpen: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "primary",
    transparentNavbar: true,
    fixedNavbar: false,
    openConfigurator: false,
    layout: "dashboard",
    darkMode: true,
    multiDryer: [],
    //view: constants.SINGLE_VIEW,
    view: '',
    sideView: 'single',
    auth: {},
    dryerData: [],
    dryerDataIndex: 0,
    isLoading: true,
    timeframe: '180',
    globalError: '',
    globalAlertMessage: '',
    globalAlertActive: false,
    glocalAlertColor: '',
    viewHistory: [],
    activeAlarms: [],
    maxAlarm: 0,
    dataLog: [],
    globalFilter: [],
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setSidenavOpen = (dispatch, value) => dispatch({ type: "OPEN_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setMultiDryer = (dispatch, value) => dispatch({ type: "MULTI", value });
const setView = (dispatch, value) => dispatch({ type: "VIEW", value });
const setSideView = (dispatch, value) => dispatch({ type: "SIDE_VIEW", value });
const setAuth = (dispatch, value) => dispatch({ type: "AUTH", value });
const setDryerData = (dispatch, value) => dispatch({ type: "DRYER_DATA", value });
const setDryerDataIndex = (dispatch, value) => dispatch({ type: "DRYER_DATA_INDEX", value });
const setIsLoading = (dispatch, value) => dispatch({ type: "IS_LOADING", value });
const setTimeframe = (dispatch, value) => dispatch({ type: "CHART_TIMEFRAME", value });
const setGlobalError = (dispatch, value) => dispatch({ type: "GLOBAL_ERROR", value });
const setGlobalAlertMessage = (dispatch, value) => dispatch({ type: "GLOBAL_ALERT_MESSAGE", value });
const setGlobalAlertActive = (dispatch, value) => dispatch({ type: "GLOBAL_ALERT_ACTIVE", value });
const setGlobalAlertColor = (dispatch, value) => dispatch({ type: "GLOBAL_ALERT_COLOR", value });
const setViewHistory = (dispatch, value) => dispatch({ type: "SET_VIEW_HISTORY", value });
const setActiveAlarms = (dispatch, value) => dispatch({ type: "ACTIVE_ALARMS", value });
const setMaxAlarm = (dispatch, value) => dispatch({ type: "MAX_ALARM", value });
const setDataLog = (dispatch, value) => dispatch({ type: "DATA_LOG", value });
const setGlobalFilter = (dispatch, value) => dispatch({ type: "GLOBAL_FILTER", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setSidenavOpen,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setLayout,
  setDarkMode,
  setMultiDryer,
  setView,
  setSideView,
  setAuth,
  setDryerData,
  setDryerDataIndex,
  setIsLoading,
  setTimeframe,
  setGlobalError,
  setGlobalAlertMessage,
  setGlobalAlertActive,
  setGlobalAlertColor,
  setViewHistory,
  setActiveAlarms,
  setMaxAlarm,
  setDataLog,
  setGlobalFilter,
};
