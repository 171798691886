/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography"

import useAxiosPrivate from "hooks/useAxiosPrivate";
import React, { useEffect, useState } from "react";

import '../../../App.css'

import { useMaterialUIController } from "context";


import constants from "../../../constants";

function FeedbackView() {
    const [controller, dispatch] = useMaterialUIController();
    const {
        darkMode,
        serviceLogin,
        auth,
    } = controller;

    const axiosPrivate = useAxiosPrivate();

    const [feedback, setFeedback] = useState();

    const getFeedbackData = async () => {


        try {
            if (auth?.accessToken && auth.USER_TYPE_ID >= serviceLogin) {

                const response = await axiosPrivate.post('/getFeedback', JSON.stringify({}),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                setFeedback(response.data)
            }
        } catch (e) {
            console.log(e);
        }
    };

    // get feedback on page load
    useEffect(() => {
        getFeedbackData();
    }, [])

    // triggers get feedback evey 5min after first page load
    useEffect(() => {

        const intervalCall = setInterval(async () => {
            if (!document.hidden) {
                getFeedbackData();
            }
        }, constants.TIMEOUT);

        // 5m: 300000
        // 1m: 60000
        return () => {
            clearInterval(intervalCall);
        }
    }, [feedback])

    const styles = {
        container: {
            display: "flex",
            justifyContent: "space-between",
            textAlign: 'center',
            with: '100%',
            paddingLeft: '12px',
            paddingRight: '12px'
        },
        font: {
            fontSize: '12px',
            width: '100%',
            textAlign: 'left',
            overflowWrap: 'break-word',
            padding: '10px'
        },
        grid: {
            width: '100%',
            maxWidth: '20%',
            borderRight: darkMode ? '1px solid white' : '1px solid black',
            borderBottom: darkMode ? '1px solid white' : '1px solid black'
        },
        gridContainer: {
            borderLeft: darkMode ? '1px solid white' : '1px solid black',
            borderTop: darkMode ? '1px solid white' : '1px solid black',
            marginTop: '12px'
        }
    };

    let arr = [];


    return (
        <MDBox >
            {feedback != [] ?
                <>
                    <MDBox style={styles.gridContainer}>

                        <Grid container >
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Grid item xs={3} style={styles.grid}><MDTypography style={styles.font}>FEEDBACK_ID</MDTypography></Grid>
                                {/* <Grid item xs={2} style={styles.grid}><MDTypography style={styles.font}>USER_ID</MDTypography></Grid> */}
                                <Grid item xs={3} style={styles.grid}><MDTypography style={styles.font}>USERNAME</MDTypography></Grid>
                                <Grid item xs={3} style={styles.grid}><MDTypography style={styles.font}>USER_EMAIL</MDTypography></Grid>
                                <Grid item xs={3} style={styles.grid}><MDTypography style={styles.font}>FEEDBACK</MDTypography></Grid>
                                {/* <Grid item xs={2} style={styles.grid}><MDTypography style={styles.font}>USER_TYPE_ID</MDTypography></Grid> */}
                                <Grid item xs={3} style={styles.grid}><MDTypography style={styles.font}>TIMESTAMP</MDTypography></Grid>
                            </Grid>
                        </Grid>
                        <Grid container >
                            {(() => {
                                if (feedback) {
                                    Object.keys(feedback).forEach(function (key) {
                                        arr.unshift(
                                            <Grid item xs={12} key={key} style={{ display: 'flex', justifyContent: 'space-between', width: '100%',backgroundColor: key % 2 != 0 ? darkMode ? '#ffffff36' : '#ffffffb8' : '', }}>
                                                <Grid item xs={3} style={styles.grid}><MDTypography style={styles.font}>{feedback[key]['FEEDBACK_ID']}</MDTypography></Grid>
                                                {/* <Grid item xs={2} style={styles.grid}><MDTypography style={styles.font}>{feedback[key]['USER_ID']}</MDTypography></Grid> */}
                                                <Grid item xs={3} style={styles.grid}><MDTypography style={styles.font}>{feedback[key]['USERNAME']}</MDTypography></Grid>
                                                <Grid item xs={3} style={styles.grid}><MDTypography style={styles.font}>{feedback[key]['USER_EMAIL']}</MDTypography></Grid>
                                                <Grid item xs={3} style={styles.grid}><MDTypography style={styles.font}>{feedback[key]['FEEDBACK']}</MDTypography></Grid>
                                                {/* <Grid item xs={2} style={styles.grid}><MDTypography style={styles.font}>{feedback[key]['USER_TYPE_ID']}</MDTypography></Grid> */}
                                                <Grid item xs={3} style={styles.grid}><MDTypography style={styles.font}>{feedback[key]['TIMESTAMP']}</MDTypography></Grid>
                                            </Grid>
                                        );
                                    });
                                    return arr;
                                }
                            })()}
                        </Grid>
                    </MDBox>
                </>
                :
                <>
                    <MDBox style={{ paddingTop: '12px', width: '100%', textAlign: 'center' }}>
                        <MDTypography>No feedback to display</MDTypography>
                    </MDBox>
                </>
            }
        </MDBox>
    );
}

FeedbackView.propTypes = {

};

export default FeedbackView;
