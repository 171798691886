/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography"

import DataTable from "examples/Tables/DataTable";

import React, { useEffect, useState } from "react";

import PropTypes, { object } from 'prop-types';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';

import Paper from '@mui/material/Paper';

import { useMaterialUIController, setMultiDryer } from "context";
import { experimentalStyled as styled } from '@mui/material/styles';
import dryerTableData from "./TableData/TableData";
import constants from "../../../constants";

function TableView({ serial, model, name }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    multiDryer,
    dryerData,
    globalFilter,
  } = controller;

  const navigate = useNavigate();
  useEffect(() => {
    if (dryerData.length < 2) {
      navigate('/dashboard/' + constants.SINGLE_VIEW, { replace: false });
    }
  }, [])

  const removeCard = (serial, model, name) => {
    let dryer = {
      serial: serial,
      model: model,
      name: name
    }

    if (!multiDryer.some(e => e.serial === dryer.serial)) {

      setMultiDryer(dispatch, (multiDryer) => [...multiDryer, dryer]);
    } else {
      var array = [...multiDryer]; // make a separate copy of the array
      const index = array.findIndex(e => e.serial === dryer.serial);
      if (index > -1) {
        array.splice(index, 1);
        setMultiDryer(dispatch, array);
      }
    }
  }

  // useEffect(() => {
  //   setMultiDryer(dispatch, multiDryerArray);
  // }, [multiDryerArray])

  const getDryerIndex = (serial) => {
    const index = dryerData.findIndex(e => e.Dryer_Serial_Name === serial);
    //console.log(index)
    return index;
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#000000',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#fff',
  }));

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [checked, setChecked] = useState(true);

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };
  const { columns, rows } = dryerTableData();

  let runningArray = []

  Object.keys(dryerData).forEach(function (key) {
    if (dryerData[key].Operating_Mode != 0) {
      let dryer = {
        serial: dryerData[key].Dryer_Serial_Name,
        model: dryerData[key].Dryer_Model_Name,
        name: dryerData[key].BUSINESS
      }
      runningArray.push(dryer)
    }
  });

  let message = runningArray.length == 0 ? 'No running dryers' : 'No dryers selected'

  return (
    <MDBox py={3} className={checked ? "" : "overflow-x-hidden"}>
      {multiDryer.length == 0 ? <MDTypography style={{ width: '100%', textAlign: 'center' }}>{message}</MDTypography> : ''}
      <Grid container spacing={3} className={checked ? "" : "overflow-hidden"} style={{ display: 'flex' }}>
        {

          multiDryer.length == 0 && globalFilter.length == 0 ? ''

            :

            <Grid container spacing={3} pt={'12px'} ml={0}>
              <Grid item xs={12}>
                <Card>
                  {/* <MDBox
                          mx={2}
                          mt={3}
                          py={3}
                          px={2}
                          variant="gradient"
                          bgColor="info"
                          borderRadius="lg"
                          coloredShadow="info"
                        >
                          <MDTypography variant="h6" color="white">
                            Dryer List
                          </MDTypography>
                        </MDBox> */}
                  <>
                    <MDBox >
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={false}
                        entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100, 1000] }}
                        showTotalEntries={true}
                        noEndBorder
                        canSearch={false}
                        pagination={{ variant: 'contained', color: 'primary' }}
                      />
                    </MDBox>
                  </>
                </Card>
              </Grid>
            </Grid>
        }
      </Grid>
    </MDBox>
  );
}

TableView.propTypes = {
  dryerList: PropTypes.arrayOf(PropTypes.object),
  serial: PropTypes.string,
  model: PropTypes.string,
  name: PropTypes.string
};

export default TableView;
