/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// Material Dashboard 2 React components

import MDTypography from "components/MDTypography";
import { useMaterialUIController, setOpenConfigurator } from "context";
import React, { useEffect, useState } from "react";

export default function data() {
    const [controller, dispatch] = useMaterialUIController();
    const {
        multiDryer,
        dryerData,
        dryerDataIndex,
        auth,
        serviceLogin,
        globalFilter,
    } = controller;

    const getMultiIndex = (serial) => {
        const index = dryerData.findIndex(e => e.Dryer_Serial_Name === serial);
        //console.log(index)
        return index;
    }

    var arr = [];

    var arr2 = [];

    return {
        columns: [
            { Header: "Name", accessor: "name", align: "left" },
            { Header: "Serial", accessor: "serial", align: "left" },
            { Header: "Model", accessor: "model", align: "left" },
            { Header: "Moisture In", accessor: "min", align: "left" },
            { Header: "Moisture Out", accessor: "mout", align: "left" },
            { Header: "Discharge Speed", accessor: "speed", align: "left" },
            { Header: "Temp AVG", accessor: "temp", align: "left" },
            { Header: "Plenum Temp AVG", accessor: "plenum", align: "left" },
            { Header: "Mode", accessor: "mode", align: "left" },
        ],

        rows:
            (() => {
                if (multiDryer) {
                    arr = []

                    Object.keys(multiDryer).forEach(function (key, i) {


                        let index = getMultiIndex(multiDryer[key].serial);


                        let name = dryerData[index]?.BUSINESS;
                        let serial = dryerData[index]?.Dryer_Serial_Name;
                        let model = dryerData[index]?.Dryer_Model_Name;
                        let moist_in = dryerData[index]?.runData?.Grain_Infeed_Moisture;
                        let moist_out = dryerData[index]?.runData?.Grain_Discharge_Moisture;
                        let speed = dryerData[index]?.runData?.Discharge_Commanded;
                        let mid_temp_avg = dryerData[index]?.runData?.Grain_Mid_Temp_Avg;
                        let plenum_temp_avg = dryerData[index]?.plenumData?.plenum_temp_avg;
                        let op_mode = dryerData[index]?.runData?.Op_Mode;
                        let sub_status = dryerData[index]?.Sub_Status;
                        if (sub_status == 1 || (sub_status == 0 && auth.USER_TYPE_ID >= 2)) {


                            arr.push(
                                {
                                    name: (
                                        <MDTypography component="p" variant="caption" fontWeight="medium">
                                            {name}
                                        </MDTypography>
                                    ),
                                    serial: (
                                        <MDTypography component="p" variant="caption" fontWeight="medium">
                                            {serial}
                                        </MDTypography>
                                    ),
                                    model: (
                                        <MDTypography component="p" variant="caption" fontWeight="medium">
                                            {model}
                                        </MDTypography>
                                    ),
                                    min: (
                                        <MDTypography component="p" variant="button" display="flex" style={{ color: '#3D81C7', fontWeight: '1000' }}>
                                            <span>{moist_in}<sub style={{ alignSelf: 'end', fontWeight: '400' }}>%</sub></span>
                                        </MDTypography>
                                    ),
                                    mout: (
                                        <MDTypography component="p" variant="button" display="flex" style={{ color: '#CC3E3E', fontWeight: '1000' }}>
                                            <span>{moist_out}<sub style={{ alignSelf: 'center', fontWeight: '400' }}>%</sub></span>
                                        </MDTypography>
                                    ),
                                    speed: (
                                        <MDTypography component="p" variant="button" display="flex" fontWeight="bold" style={{ color: '#41C330', fontWeight: '1000' }}>
                                            <span>{speed}<sub style={{ alignSelf: 'end', fontWeight: '400' }}>%</sub></span>
                                        </MDTypography>
                                    ),
                                    temp: (
                                        <MDTypography className="outline" component="p" variant="button" display="flex" style={{ color: '#EEEB18', fontWeight: '1000' }}>
                                            {mid_temp_avg}<span style={{ fontWeight: '400' }}>&deg;</span>
                                        </MDTypography>
                                    ),
                                    plenum: (
                                        plenum_temp_avg == null ?
                                            <MDTypography component="p" variant="button" display="flex" fontWeight="bold" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                N/A
                                            </MDTypography>
                                            :
                                            <MDTypography component="p" variant="button" display="flex" fontWeight="bold" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                {plenum_temp_avg}<span style={{ fontWeight: '400' }}>&deg;</span>
                                            </MDTypography>
                                    ),
                                    mode: (
                                        <MDTypography component="p" variant="button" display="flex" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                            <span>{op_mode}</span>
                                        </MDTypography>
                                    ),

                                }
                            );
                        } else {
                            arr.push(
                                {
                                    name: (
                                        <MDTypography component="p" variant="caption" fontWeight="medium">
                                            {name}
                                        </MDTypography>
                                    ),
                                    serial: (
                                        <MDTypography component="p" variant="caption" fontWeight="medium">
                                            {serial}
                                        </MDTypography>
                                    ),
                                    model: (
                                        <MDTypography component="p" variant="caption" fontWeight="medium">
                                            {model}
                                        </MDTypography>
                                    ),
                                    min: (
                                        <MDTypography component="p" variant="button" display="flex" style={{ color: '#3D81C7', fontWeight: '1000' }}>

                                        </MDTypography>
                                    ),
                                    mout: (
                                        <MDTypography component="p" variant="button" display="flex" style={{ fontWeight: '1000' }}>
                                            Subscription
                                        </MDTypography>
                                    ),
                                    speed: (
                                        <MDTypography component="p" variant="button" display="flex" fontWeight="bold" style={{ color: '', fontWeight: '1000' }}>
                                            Expired
                                        </MDTypography>
                                    ),
                                    temp: (
                                        <MDTypography className="outline" component="p" variant="button" display="flex" style={{ color: '#EEEB18', fontWeight: '1000' }}>

                                        </MDTypography>
                                    ),
                                    plenum: (
                                        plenum_temp_avg == null ?
                                            <MDTypography component="p" variant="button" display="flex" fontWeight="bold" style={{ color: '#E1861A', fontWeight: '1000' }}>

                                            </MDTypography>
                                            :
                                            <MDTypography component="p" variant="button" display="flex" fontWeight="bold" style={{ color: '#E1861A', fontWeight: '1000' }}>

                                            </MDTypography>
                                    ),
                                    mode: (
                                        <MDTypography component="p" variant="button" display="flex" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >

                                        </MDTypography>
                                    ),

                                }
                            );
                        }

                    });
                    return arr;
                }
            })(),

        // this works for filtering the table based on search value, but it's really really slow probably to slow to deploy, find a better way    
        // rows:
        //     (() => {
        //         if (multiDryer) {
        //             arr = []
        //             let filter = []
        //             let global = []

        //             // if searching
        //             if(globalFilter.length > 0){
        //                 //probably a better way to do this
        //                 globalFilter.forEach((element) =>{
        //                     //simplify array for index of later

        //                     // now we can check a list of serials for indexOf instead of globalFiliter.item.serial
        //                     global.push(element.item.serial)
        //                 })

        //                 // we want to remove elements from multiDryer that are not in globalFilter
        //                 multiDryer.forEach((element)=>{

        //                     // if index of element in globalFilter that means found
        //                     if(global.indexOf(element.serial) > -1){

        //                         // if found add to filtered array
        //                         filter.push(element);
        //                     }
        //                 })
        //             } else {
        //                 // no search just copy multiDryer
        //                 filter = [...multiDryer];
        //             }

        //             Object.keys(filter).forEach(function (key, i) {


        //                 let index = getMultiIndex(filter[key].serial);


        //                 let name = dryerData[index]?.BUSINESS;
        //                 let serial = dryerData[index]?.Dryer_Serial_Name;
        //                 let model = dryerData[index]?.Dryer_Model_Name;
        //                 let moist_in = dryerData[index]?.runData?.Grain_Infeed_Moisture;
        //                 let moist_out = dryerData[index]?.runData?.Grain_Discharge_Moisture;
        //                 let speed = dryerData[index]?.runData?.Discharge_Commanded;
        //                 let mid_temp_avg = dryerData[index]?.runData?.Grain_Mid_Temp_Avg;
        //                 let plenum_temp_avg = dryerData[index]?.plenumData?.plenum_temp_avg;
        //                 let op_mode = dryerData[index]?.runData?.Op_Mode;
        //                 let sub_status = dryerData[index]?.Sub_Status;
        //                 if (sub_status == 1 || (sub_status == 0 && auth.USER_TYPE_ID >= 2)) {


        //                     arr.push(
        //                         {
        //                             name: (
        //                                 <MDTypography component="p" variant="caption" fontWeight="medium">
        //                                     {name}
        //                                 </MDTypography>
        //                             ),
        //                             serial: (
        //                                 <MDTypography component="p" variant="caption" fontWeight="medium">
        //                                     {serial}
        //                                 </MDTypography>
        //                             ),
        //                             model: (
        //                                 <MDTypography component="p" variant="caption" fontWeight="medium">
        //                                     {model}
        //                                 </MDTypography>
        //                             ),
        //                             min: (
        //                                 <MDTypography component="p" variant="button" display="flex" style={{ color: '#3D81C7', fontWeight: '1000' }}>
        //                                     <span>{moist_in}<sub style={{ alignSelf: 'end', fontWeight: '400' }}>%</sub></span>
        //                                 </MDTypography>
        //                             ),
        //                             mout: (
        //                                 <MDTypography component="p" variant="button" display="flex" style={{ color: '#CC3E3E', fontWeight: '1000' }}>
        //                                     <span>{moist_out}<sub style={{ alignSelf: 'center', fontWeight: '400' }}>%</sub></span>
        //                                 </MDTypography>
        //                             ),
        //                             speed: (
        //                                 <MDTypography component="p" variant="button" display="flex" fontWeight="bold" style={{ color: '#41C330', fontWeight: '1000' }}>
        //                                     <span>{speed}<sub style={{ alignSelf: 'end', fontWeight: '400' }}>%</sub></span>
        //                                 </MDTypography>
        //                             ),
        //                             temp: (
        //                                 <MDTypography className="outline" component="p" variant="button" display="flex" style={{ color: '#EEEB18', fontWeight: '1000' }}>
        //                                     {mid_temp_avg}<span style={{ fontWeight: '400' }}>&deg;</span>
        //                                 </MDTypography>
        //                             ),
        //                             plenum: (
        //                                 plenum_temp_avg == null ?
        //                                     <MDTypography component="p" variant="button" display="flex" fontWeight="bold" style={{ color: '#E1861A', fontWeight: '1000' }}>
        //                                         N/A
        //                                     </MDTypography>
        //                                     :
        //                                     <MDTypography component="p" variant="button" display="flex" fontWeight="bold" style={{ color: '#E1861A', fontWeight: '1000' }}>
        //                                         {plenum_temp_avg}<span style={{ fontWeight: '400' }}>&deg;</span>
        //                                     </MDTypography>
        //                             ),
        //                             mode: (
        //                                 <MDTypography component="p" variant="button" display="flex" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
        //                                     <span>{op_mode}</span>
        //                                 </MDTypography>
        //                             ),

        //                         }
        //                     );
        //                 } else {
        //                     arr.push(
        //                         {
        //                             name: (
        //                                 <MDTypography component="p" variant="caption" fontWeight="medium">
        //                                     {name}
        //                                 </MDTypography>
        //                             ),
        //                             serial: (
        //                                 <MDTypography component="p" variant="caption" fontWeight="medium">
        //                                     {serial}
        //                                 </MDTypography>
        //                             ),
        //                             model: (
        //                                 <MDTypography component="p" variant="caption" fontWeight="medium">
        //                                     {model}
        //                                 </MDTypography>
        //                             ),
        //                             min: (
        //                                 <MDTypography component="p" variant="button" display="flex" style={{ color: '#3D81C7', fontWeight: '1000' }}>

        //                                 </MDTypography>
        //                             ),
        //                             mout: (
        //                                 <MDTypography component="p" variant="button" display="flex" style={{ fontWeight: '1000' }}>
        //                                     Subscription
        //                                 </MDTypography>
        //                             ),
        //                             speed: (
        //                                 <MDTypography component="p" variant="button" display="flex" fontWeight="bold" style={{ color: '', fontWeight: '1000' }}>
        //                                     Expired
        //                                 </MDTypography>
        //                             ),
        //                             temp: (
        //                                 <MDTypography className="outline" component="p" variant="button" display="flex" style={{ color: '#EEEB18', fontWeight: '1000' }}>

        //                                 </MDTypography>
        //                             ),
        //                             plenum: (
        //                                 plenum_temp_avg == null ?
        //                                     <MDTypography component="p" variant="button" display="flex" fontWeight="bold" style={{ color: '#E1861A', fontWeight: '1000' }}>

        //                                     </MDTypography>
        //                                     :
        //                                     <MDTypography component="p" variant="button" display="flex" fontWeight="bold" style={{ color: '#E1861A', fontWeight: '1000' }}>

        //                                     </MDTypography>
        //                             ),
        //                             mode: (
        //                                 <MDTypography component="p" variant="button" display="flex" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >

        //                                 </MDTypography>
        //                             ),

        //                         }
        //                     );
        //                 }

        //             });
        //             return arr;
        //         }
        //     })(),

    };
}
