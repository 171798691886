import { Link } from "react-router-dom"
import PageLayout from "examples/LayoutContainers/PageLayout";
import MDTypography from "./MDTypography";

const Missing = () => {
    return (
        <PageLayout>
            <article style={{ display: 'flex', justifyContent: 'center', paddingTop: '25px' }}>
                <MDTypography>404 Page Not Found</MDTypography>
            </article>
        </PageLayout>
    )
}

export default Missing