/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import '../../../../App.css'

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import grain_temp_svg from '../../../../assets/images/mc/grain-temp.svg'
import moisture_in_svg from '../../../../assets/images/mc/moisture-in.svg'
import moisture_out_svg from '../../../../assets/images/mc/moisture-out.svg'
import plenum_temp_svg from '../../../../assets/images/mc/plenum-temp.svg'
import setpoint_svg from '../../../../assets/images/mc/setpoint.svg'
import setpoint_svg_dark from '../../../../assets/images/mc/setpoint-dark.svg'
import speed_svg from '../../../../assets/images/mc/speed.svg'
import temperature_svg from '../../../../assets/images/mc/temperature.svg'
import temperature_svg_dark from '../../../../assets/images/mc/temperature-dark.svg'
import ReactCardFlip from 'react-card-flip'
import { useMaterialUIController, setDryerData, setDryerDataIndex } from "context";
import React, { useState, useEffect } from "react";
import Tooltip from '@mui/material/Tooltip';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import CardBacks from '../DryerCardBacks'


function CardIconHeader({ dryer, index }) {
    const [controller, dispatch] = useMaterialUIController();

    const {
        dryerData,
        dryerDataIndex,
        auth,
        darkMode,
        serviceLogin,
    } = controller;

    const [isFlipped, setIsFlipped] = useState(false)

    let op_mode = dryerData[index]?.runData ? dryerData[index]?.runData?.Op_Mode : 'N/A';

    return (
        <>
            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'end' }}>
                {dryerData[index]?.Online_Status == 1 ?
                    <MDBox style={{ paddingLeft: '2.5px', paddingRight: '2.5px', alignSelf: 'center' }}>
                        <Tooltip title="Online" placement="bottom" style={{}}>
                            <Icon fontSize="default" style={{ color: '#41C330', fontSize: '18px', alignSelf: 'center', }}>public</Icon>
                        </Tooltip>
                    </MDBox>
                    :
                    <MDBox style={{ paddingLeft: '2.5px', paddingRight: '2.5px', alignSelf: 'center' }}>
                        <Tooltip title="Offline" placement="bottom">
                            <Icon fontSize="default" style={{ color: 'gray', fontSize: '18px', alignSelf: 'center' }}>public_off</Icon>
                        </Tooltip>
                    </MDBox>
                }
                {op_mode != "Stopped" && op_mode != null && op_mode != 'N/A' ?
                    <MDBox style={{ paddingLeft: '2.5px', paddingRight: '2.5px', alignSelf: 'center' }}>
                        <Tooltip title="Dryer State: Running" placement="bottom">
                            <Icon fontSize="default" style={{ color: '#41C330', fontSize: '18px', alignSelf: 'center' }}>settings</Icon>
                        </Tooltip>
                    </MDBox>
                    :
                    <MDBox style={{ paddingLeft: '2.5px', paddingRight: '2.5px', alignSelf: 'center' }}>
                        <Tooltip title="Dryer State: Stopped" placement="bottom">
                            <Icon className="" fontSize="default" style={{ color: 'gray', fontSize: '18px', alignSelf: 'center' }}>settings</Icon>
                        </Tooltip>
                    </MDBox>
                }
                {dryerData[index]?.Sub_Status == 1 ?
                    <MDBox style={{ paddingLeft: '2.5px', paddingRight: '2.5px', alignSelf: 'center' }}>
                        <Tooltip title="Subscription: Valid" placement="bottom">
                            <Icon className="" fontSize="default" style={{ color: '#41C330', fontSize: '18px', alignSelf: 'center' }}>credit_card</Icon>
                        </Tooltip>
                    </MDBox>
                    :
                    <MDBox style={{ paddingLeft: '2.5px', paddingRight: '2.5px', alignSelf: 'center' }}>
                        <Tooltip title="Subscription: Expired" placement="bottom" >
                            <Icon className="" fontSize="default" style={{ color: 'gray', fontSize: '18px', alignSelf: 'center' }}>credit_card</Icon>
                        </Tooltip>
                    </MDBox>
                }
            </Grid>
        </>
    );
}

// Typechecking props for the DryerCard3
CardIconHeader.propTypes = {
    dryer: PropTypes.object,
    index: PropTypes.number,
};


export default CardIconHeader;
