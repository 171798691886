/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import React from "react";

import PropTypes from 'prop-types';

import '../../../App.css'
import { useEffect } from "react";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import UserAccountCard from "examples/Cards/AccountCards/UserAccountCard";
import DealerAccountCard from "examples/Cards/AccountCards/DealerAccountCard";
import constants from "../../../constants";
import Reports from "layouts/reports";

function ReportView() {
    const [controller, dispatch] = useMaterialUIController();
    const {
        auth,
        serviceLogin
    } = controller;

    const navigate = useNavigate();
    useEffect(() => {
        if (auth.USER_ID != "1604" && auth.USER_ID && "1616" && auth.USER_ID != "768") {
            navigate('/dashboard/' + constants.SINGLE_VIEW, { replace: false });
        }
    }, []);

    return (
        <MDBox style={{ paddingTop: '12px' }}>
            <Reports />
        </MDBox>
    );
}

ReportView.propTypes = {
    // dryerList: PropTypes.arrayOf(objects),
    serial: PropTypes.string,
    model: PropTypes.string,
    name: PropTypes.string
};

export default ReportView;
