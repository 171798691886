/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import '../../../App.css'

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import grain_temp_svg from '../../../assets/images/mc/grain-temp.svg'
import moisture_in_svg from '../../../assets/images/mc/moisture-in.svg'
import moisture_out_svg from '../../../assets/images/mc/moisture-out.svg'
import plenum_temp_svg from '../../../assets/images/mc/plenum-temp.svg'
import setpoint_svg from '../../../assets/images/mc/setpoint.svg'
import setpoint_svg_dark from '../../../assets/images/mc/setpoint-dark.svg'
import speed_svg from '../../../assets/images/mc/speed.svg'
import temperature_svg from '../../../assets/images/mc/temperature.svg'
import temperature_svg_dark from '../../../assets/images/mc/temperature-dark.svg'
import ReactCardFlip from 'react-card-flip'
import { useMaterialUIController, setDryerData, setDryerDataIndex } from "context";
import React, { useState, useEffect } from "react";
import Tooltip from '@mui/material/Tooltip';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import CardBacks from './DryerCardBacks/'
import CardIconHeader from './Components/CardIconHeader';


function DryerCard3({ dryer }) {
    const [controller, dispatch] = useMaterialUIController();

    const {
        dryerData,
        dryerDataIndex,
        auth,
        darkMode,
        serviceLogin,
    } = controller;

    const [isFlipped, setIsFlipped] = useState(false)
    const [destination, setDestination] = useState(0)

    const handleFlip = (e, dryer, destination) => {
        //console.log(destination)
        setDestination(destination)
        setIsFlipped(!isFlipped)
    }

    const handleDestination = (d) => {
        setDestination(d);
    }
    const getHeight = (serial, index) => {
        let element_id = serial + '-' + index;
        let card = document.getElementById(element_id);
        let height = card == null ? 0 : card.offsetHeight;
        return (height);
    }

    let online = dryerData[dryerDataIndex]?.Online_Status;
    let sub = dryerData[dryerDataIndex]?.Sub_Status;
    let name = dryerData[dryerDataIndex]?.BUSINESS;
    let serial = dryerData[dryerDataIndex]?.Dryer_Serial_Name;
    let model = dryerData[dryerDataIndex]?.Dryer_Model_Name;

    let moist_in = dryerData[dryerDataIndex]?.runData ? dryerData[dryerDataIndex]?.runData?.Grain_Infeed_Moisture : 'N/A';
    let grain_in_temp = dryerData[dryerDataIndex]?.runData ? dryerData[dryerDataIndex]?.runData?.Grain_Infeed_Temp : 'N/A';
    let moist_out = dryerData[dryerDataIndex]?.runData ? dryerData[dryerDataIndex]?.runData?.Grain_Discharge_Moisture : 'N/A';
    let moist_out_sp = dryerData[dryerDataIndex]?.runData ? (Math.round(dryerData[dryerDataIndex]?.runData?.Disch_Moist_SP * 100) / 100).toFixed(1) : 'N/A';
    let grain_out_temp = dryerData[dryerDataIndex]?.runData ? (Math.round(dryerData[dryerDataIndex]?.runData?.Grain_Discharge_Temp * 100) / 100).toFixed(0) : 'N/A';
    let speed = dryerData[dryerDataIndex]?.runData ? dryerData[dryerDataIndex]?.runData?.Discharge_Commanded : 'N/A';
    let mid_temp_avg = dryerData[dryerDataIndex]?.runData ? dryerData[dryerDataIndex]?.runData?.Grain_Mid_Temp_Avg : 'N/A';
    let mid_temp_sp = dryerData[dryerDataIndex]?.runData ? dryerData[dryerDataIndex]?.runData?.Mid_Tmp_SP : 'N/A';
    let op_mode = dryerData[dryerDataIndex]?.runData ? dryerData[dryerDataIndex]?.runData?.Op_Mode : 'N/A';

    let plenum_temp_avg = dryerData[dryerDataIndex]?.plenumData ? dryerData[dryerDataIndex]?.plenumData?.plenum_temp_avg : 'N/A';

    let temperature_svg_color = darkMode ? temperature_svg_dark : temperature_svg;
    let setpoint_svg_color = darkMode ? setpoint_svg_dark : setpoint_svg;

    let text_color = "#BEBEBE";

    const getModeAvg = () => {

        let modeAvg = '';

        if (dryerData[dryerDataIndex]?.plenumData) {
            // top and bot logic
            if (dryerData[dryerDataIndex]?.plenumData?.plenum_top != undefined) {
                let modeTop = dryerData[dryerDataIndex]?.plenumData?.plenum_top > 125 ? "HEAT" : "COOL"
                let modeBot = dryerData[dryerDataIndex]?.plenumData?.plenum_bot > 125 ? "HEAT" : "COOL"
                if (modeTop == 'HEAT' && modeBot == 'HEAT') {
                    modeAvg = 'HEAT'
                } else if (modeTop == 'COOL' && modeBot == 'COOL') {
                    modeAvg = 'COOL'
                } else {
                    modeAvg = 'HEAT + COOL'
                }
            } else if (dryerData[dryerDataIndex]?.plenumData?.plenum_2 == undefined) {

                if (dryerData[dryerDataIndex]?.plenumData?.plenum_1 != undefined) {
                    modeAvg = dryerData[dryerDataIndex]?.plenumData?.plenum_1 > 125 ? 'HEAT' : 'COOL'
                }

            } else {
                // everything else logic
                let arr = []
                if (dryerData[dryerDataIndex]?.plenumData?.plenum_1 != undefined) {
                    let p1 = dryerData[dryerDataIndex]?.plenumData?.plenum_1 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p1);
                }
                if (dryerData[dryerDataIndex]?.plenumData?.plenum_2 != undefined) {
                    let p2 = dryerData[dryerDataIndex]?.plenumData?.plenum_2 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p2);
                }
                if (dryerData[dryerDataIndex]?.plenumData?.plenum_3 != undefined) {
                    let p3 = dryerData[dryerDataIndex]?.plenumData?.plenum_3 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p3);
                }
                if (dryerData[dryerDataIndex]?.plenumData?.plenum_4 != undefined) {
                    let p4 = dryerData[dryerDataIndex]?.plenumData?.plenum_4 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p4);
                }
                if (dryerData[dryerDataIndex]?.plenumData?.plenum_5 != undefined) {
                    let p5 = dryerData[dryerDataIndex]?.plenumData?.plenum_5 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p5);
                }
                if (dryerData[dryerDataIndex]?.plenumData?.plenum_6 != undefined) {
                    let p6 = dryerData[dryerDataIndex]?.plenumData?.plenum_7 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p6);
                }
                if (dryerData[dryerDataIndex]?.plenumData?.plenum_7 != undefined) {
                    let p7 = dryerData[dryerDataIndex]?.plenumData?.plenum_7 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p7);
                }
                if (dryerData[dryerDataIndex]?.plenumData?.plenum_8 != undefined) {
                    let p8 = dryerData[dryerDataIndex]?.plenumData?.plenum_8 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p8);
                }

                arr.forEach((element) => {
                    if (modeAvg != 'HEAT + COOL') {
                        if (modeAvg == '') {
                            modeAvg = element;
                        } else {
                            if (modeAvg != element) {
                                modeAvg = 'HEAT + COOL'
                            }
                        }
                    }
                })

            }

        } else {
            modeAvg = 'N/A'
        }

        return modeAvg;

    }

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            <Card id={serial + '-' + dryerDataIndex} >
                <MDBox justifyContent="space-between" pt={2} px={2}>
                    <Grid container spacing={1} p={'3px'} onClick={(e) => handleFlip(e, dryer, 0)} style={{ cursor: 'pointer' }}>
                        <Grid item xs={9} style={{ display: 'flex' }}>
                            <div>
                                <MDTypography component="p" variant="button" style={{ textAlign: 'left', fontSize: '24px' }}>
                                    <span>{name}</span>
                                </MDTypography>
                                <MDTypography component="p" variant="button" style={{ textAlign: 'left', fontSize: '16px' }}>
                                    <span style={{ whiteSpace: 'nowrap' }}>{serial}&nbsp;&nbsp;|&nbsp;&nbsp;{model}</span>
                                </MDTypography>
                            </div>
                        </Grid>
                        <CardIconHeader index={dryerDataIndex}/>
                        {/* <Grid item xs={4} style={{ display: 'flex', justifyContent: 'end' }}>
                            {dryerData[dryerDataIndex]?.Online_Status == 1 ?
                                <Tooltip title="Online" placement="top" style={{ alignSelf: 'center' }}>
                                    <Icon fontSize="default" style={{ color: '#41C330', fontSize: '18px', alignSelf: 'center' }}>public</Icon>
                                </Tooltip>
                                :
                                <Tooltip title="Offline" placement="top">
                                    <Icon fontSize="default" style={{ color: 'gray', fontSize: '18px', alignSelf: 'center' }}>public_off</Icon>
                                </Tooltip>
                            }
                            {op_mode != "Stopped" && op_mode != null && op_mode != 'N/A' ?
                                <Tooltip title="Dryer State: Running" placement="top">
                                    <Icon fontSize="default" style={{ color: '#41C330', fontSize: '18px', alignSelf: 'center' }}>settings</Icon>
                                </Tooltip>
                                :
                                <Tooltip title="Dryer State: Stopped" placement="top">
                                    <Icon className="" fontSize="default" style={{ color: 'gray', fontSize: '18px', alignSelf: 'center' }}>settings</Icon>
                                </Tooltip>
                            }
                            {dryerData[dryerDataIndex]?.Sub_Status == 1 ?
                                <Tooltip title="Subscription: Valid" placement="top">
                                    <Icon className="" fontSize="default" style={{ color: '#41C330', fontSize: '18px', alignSelf: 'center' }}>credit_card</Icon>
                                </Tooltip>
                                :
                                <Tooltip title="Subscription: Expired" placement="top" >
                                    <Icon className="" fontSize="default" style={{ color: 'gray', fontSize: '18px', alignSelf: 'center' }}>credit_card</Icon>
                                </Tooltip>
                            }
                        </Grid> */}
                    </Grid>
                </MDBox>
                <hr className="dryer-card-divider" />
                {(online == 0) ?
                    <MDTypography style={{ width: '100%', height: '300px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                        {sub == 0 && auth.USER_TYPE_ID < serviceLogin ? 'Subscription Expired' : 'Dryer Offline'}
                    </MDTypography>
                    : online == 1 && sub == 0 & auth.USER_TYPE_ID < serviceLogin ?
                        <>
                            <MDTypography style={{ width: '100%', height: '300px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                Subscription Expired
                            </MDTypography>
                        </>
                        :
                        <>
                            <MDBox px={2}>
                                <Grid container spacing={1} onClick={(e) => handleFlip(e, dryer, 1)} style={{ cursor: 'pointer' }}>
                                    <Grid item xs={12} style={{ display: "flex" }} >
                                        <Grid item xs={8} style={{ display: 'flex' }}>
                                            <Grid item xs={4} style={{ display: 'flex' }}>
                                                <img src={moisture_in_svg} style={{ width: '45px', height: 'auto', objectFit: 'contain' }} />
                                            </Grid>
                                            <Grid item xs={8} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                <MDTypography component="p" variant="button" display="flex" fontSize="40px" style={{ color: '#3D81C7', fontWeight: '1000' }}>
                                                    <span>{moist_in}{moist_in != 'N/A' ? <sub style={{ alignSelf: 'end', fontWeight: '400' }}>%</sub> : <></>}</span>
                                                </MDTypography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} pb={'10px'} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                <span style={{ color: text_color }}>{grain_in_temp}{grain_in_temp != 'N/A' ? <span style={{ fontWeight: '400' }}>&deg;</span> : <></>}</span>
                                            </MDTypography>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                <span style={{ color: text_color }}>TEMP</span>
                                            </MDTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <hr className="dryer-card-divider" />
                            <MDBox px={2}>
                                <Grid container spacing={1} onClick={(e) => handleFlip(e, dryer, 2)} style={{ cursor: 'pointer' }}>
                                    <Grid item xs={12} style={{ display: "flex" }}>
                                        <Grid item xs={8} style={{ display: 'flex' }}>
                                            <Grid item xs={4} style={{ display: 'flex' }}>
                                                <img src={moisture_out_svg} style={{ width: '45px', height: 'auto', objectFit: 'contain' }} />
                                            </Grid>
                                            <Grid item xs={8} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                <MDTypography component="p" variant="button" display="flex" fontSize="40px" style={{ color: '#CC3E3E', fontWeight: '1000' }}>
                                                    <span style={{ alignSelf: 'center' }}>{moist_out}{moist_out != 'N/A' ? <sub style={{ alignSelf: 'end', fontWeight: '400' }}>%</sub> : <></>}</span>
                                                </MDTypography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ whiteSpace: 'nowrap', fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                <span style={{ color: text_color }}>{grain_out_temp}&deg;</span>
                                            </MDTypography>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly', paddingBottom: '5px' }}>
                                                <span style={{ color: text_color }}>TEMP</span>
                                            </MDTypography>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ whiteSpace: 'nowrap', fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                <span style={{ color: text_color }}>{moist_out_sp}{moist_out_sp != 'N/A' ? <sub style={{ alignSelf: 'end', fontWeight: '400' }}>%</sub> : <></>}</span>
                                            </MDTypography>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                <span style={{ color: text_color }}>SETPOINT</span>
                                            </MDTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <hr className="dryer-card-divider" />
                            <MDBox px={2}>
                                <Grid container spacing={1} onClick={(e) => handleFlip(e, dryer, 3)} style={{ cursor: 'pointer' }}>
                                    <Grid item xs={12} style={{ display: "flex" }}>
                                        <Grid item xs={8} style={{ display: 'flex' }}>
                                            <Grid item xs={4} style={{ display: 'flex' }}>
                                                <img src={speed_svg} style={{ width: '50px', height: 'auto', objectFit: 'contain' }} />
                                            </Grid>
                                            <Grid item xs={8} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="40px" style={{ color: '#41C330', fontWeight: '1000' }}>
                                                    <span>{speed}{speed != 'N/A' ? <sub style={{ alignSelf: 'end', fontWeight: '400' }}>%</sub> : <></>}</span>
                                                </MDTypography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} pb={'10px'} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                <span style={{ color: text_color }}>{op_mode}</span>
                                            </MDTypography>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                <span style={{ color: text_color }}>MODE</span>
                                            </MDTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <hr className="dryer-card-divider" />
                            <MDBox px={2}>
                                <Grid container spacing={1} onClick={(e) => handleFlip(e, dryer, 4)} style={{ cursor: 'pointer' }}>
                                    <Grid item xs={12} style={{ display: "flex" }}>
                                        <Grid item xs={8} style={{ display: 'flex' }}>
                                            <Grid item xs={4} style={{ display: 'flex' }}>
                                                <img src={grain_temp_svg} style={{ width: '50px', height: 'auto', objectFit: 'contain' }} />
                                            </Grid>
                                            <Grid item xs={8} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                <MDTypography component="p" variant="button" display="flex" fontSize="40px" style={{ color: '#EEEB18', fontWeight: '1000' }}>
                                                    <span>{mid_temp_avg != null ? mid_temp_avg : "N/A" }{mid_temp_avg != 'N/A' && mid_temp_avg != null ? <span style={{ alignSelf: 'end', fontWeight: '400' }}>&deg;</span> : <></>}</span>
                                                </MDTypography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} pb={'10px'} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                <span style={{ color: text_color }}>{mid_temp_sp}{mid_temp_sp != 'N/A' ? <span style={{ alignSelf: 'end', fontWeight: '400' }}>&deg;</span> : <></>}</span>
                                            </MDTypography>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                <span style={{ color: text_color }}>SETPOINT</span>
                                            </MDTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <hr className="dryer-card-divider" />
                            <MDBox px={2} pb={2}>
                                <Grid container spacing={1} onClick={(e) => handleFlip(e, dryer, 5)} style={{ cursor: 'pointer' }}>
                                    <Grid item xs={12} style={{ display: "flex" }}>
                                        <Grid item xs={8} style={{ display: 'flex' }}>
                                            <Grid item xs={4} style={{ display: 'flex' }}>
                                                <img src={plenum_temp_svg} style={{ width: '50px', height: 'auto', objectFit: 'contain' }} />
                                            </Grid>
                                            <Grid item xs={8} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                {plenum_temp_avg == null ?
                                                    <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="40px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                        N/A
                                                    </MDTypography>
                                                    :
                                                    <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="40px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                        <span>{plenum_temp_avg}{plenum_temp_avg != 'N/A' ? <span style={{ fontWeight: '400' }}>&deg;</span> : <></>}</span>
                                                    </MDTypography>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} pb={'10px'} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="12px" style={{ whiteSpace: 'nowrap', fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                {plenum_temp_avg != null ? <span style={{ color: text_color }}>{getModeAvg()}</span> : <span style={{ color: text_color }}>N/A</span>}
                                            </MDTypography>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                <span style={{ color: text_color }}>MODE</span>
                                            </MDTypography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </>
                }
            </Card>

            <CardBacks index={dryerDataIndex} serial={dryer?.Dryer_Serial_Name} destination={destination} handleFlip={handleFlip} getHeight={getHeight} handleDestination={handleDestination} isFlipped={isFlipped} />
            {/* <CardBacks index={dryerDataIndex} serial={dryer?.Dryer_Serial_Name} destination={destination} handleFlip={handleFlip} /> */}

        </ReactCardFlip>
    );
}

// Typechecking props for the DryerCard3
DryerCard3.propTypes = {
    dryer: PropTypes.object,
};


export default DryerCard3;
