import axios from "api/axios";
import constants from "../constants";
import {
    useMaterialUIController,
    setAuth,
    setMultiDryer,
    setView,
    setDryerData,
    setDryerDataIndex
} from "context";

const useLogout = () => {

    const [controller, dispatch] = useMaterialUIController();
    const { auth } = controller;

    const logout = async () =>{
        setAuth(dispatch, {});
        setMultiDryer(dispatch, []);
        setView(dispatch, constants.SINGLE_VIEW);
        setDryerData(dispatch, []);
        setDryerDataIndex(dispatch, 0);
        try{
            const response = await axios('/logout',{
                withCredentials: true
            });
        }catch(err){
            console.log(err)
        }
    }

    return logout;

}

export default useLogout;

