/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

import '../../../../App.css';
import grain_temp_svg from '../../../../assets/images/mc/grain-temp.svg';
import setpoint_svg from '../../../../assets/images/mc/setpoint.svg';
import setpoint_svg_dark from '../../../../assets/images/mc/setpoint-dark.svg'
import mid_svg_1 from '../../../../assets/images/mc/mid-1.svg'
import mid_svg_2 from '../../../../assets/images/mc/mid-2.svg'
import mid_svg_3 from '../../../../assets/images/mc/mid-3.svg'
import mid_svg_4 from '../../../../assets/images/mc/mid-4.svg'

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";

import midGrainTableData from "../DryerCardData/DryerMidGrainData";
import { useMaterialUIController, setDataLog } from "context";
import CardBackCommingSoon from "./CardBackComingSoon";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import constants from "../../../../constants"

function CardBackMidGrain({ index, handleFlip, destination, comingSoon, isFlipped }) {
    const axiosPrivate = useAxiosPrivate();
    const [dataLog, setDataLog] = useState([]);
    const [testRows, setTestRows] = useState([]);
    const { columns1, columns2, rows } = midGrainTableData();
    const [controller, dispatch] = useMaterialUIController();
    const [midCount, setMidCount] = useState(4)
    const {
        dryerData,
        darkMode,
        //dataLog,
        auth
    } = controller;

    let serial = dryerData[index]?.Dryer_Serial_Name;

    let mid_temp_avg = dryerData[index]?.runData ? dryerData[index]?.runData?.Grain_Mid_Temp_Avg : 'N/A';
    let mid_temp_sp = dryerData[index]?.runData ? dryerData[index]?.runData?.Mid_Tmp_SP : 'N/A';
    let mid_grain_count = dryerData[index]?.runData ? dryerData[index]?.runData?.Grain_Mid_Count : 'N/A';
    let mid_grain_1 = dryerData[index]?.runData ? dryerData[index]?.runData?.Grain_Mid_Temp_1 : 'N/A';
    let mid_grain_2 = dryerData[index]?.runData ? dryerData[index]?.runData?.Grain_Mid_Temp_2 : 'N/A';
    let mid_grain_3 = dryerData[index]?.runData ? dryerData[index]?.runData?.Grain_Mid_Temp_3 : 'N/A';
    let mid_grain_4 = dryerData[index]?.runData ? dryerData[index]?.runData?.Grain_Mid_Temp_4 : 'N/A';

    const midgrain2 = [
        {
            temp: mid_grain_1,
        },
        {
            temp: mid_grain_2,
        },
    ]
    const midgrain4 = [
        {
            temp: mid_grain_1,
        },
        {
            temp: mid_grain_2,
        },
        {
            temp: mid_grain_3,
        },
        {
            temp: mid_grain_4,
        },
    ]

    let online = dryerData[index]?.Online_Status;
    let sub = dryerData[index]?.Sub_Status;

    let setpoint_svg_color = darkMode ? setpoint_svg_dark : setpoint_svg;

    let op = dryerData[index].Operating_Mode;

    Date.prototype.stdTimezoneOffset = function () {
        var jan = new Date(this.getFullYear(), 0, 1);
        var jul = new Date(this.getFullYear(), 6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    }

    Date.prototype.isDstObserved = function () {
        return this.getTimezoneOffset() < this.stdTimezoneOffset();
    }

    function get_time_offset() {
        var d = new Date();
        var n = d.getTimezoneOffset();
        return n / 60;
    }

    function conv_local_date_time(a_date) {

        let timezone_offset = get_time_offset();
        var ds = a_date.split(" ");
        var d = new Date(ds[0] + "T" + ds[1] + "-05:00");
        d.setHours(d.getHours() - timezone_offset);
        var hours = d.getHours();
        var minutes = d.getMinutes();
        var seconds = d.getSeconds();
        var date = (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
        var timeString = date;
        timeString += "  " + ((hours > 12) ? hours - 12 : hours);
        timeString += ((minutes < 10) ? ":0" : ":") + minutes;
        timeString += ((seconds < 10) ? ":0" : ":") + seconds;
        timeString += (hours >= 12) ? " P.M." : " A.M.";

        return timeString;
    }

    const getTime = (timestamp) => {

        var today = new Date();
        if (today.isDstObserved()) {
            //console.log("Daylight saving time!");
        }

        let dateLocal = new Date(timestamp)
        // let dateLocal = new Date('2024-10-09T14:50:23.000Z')

        let offset = dateLocal.getTimezoneOffset();

        dateLocal.setHours(dateLocal.getHours() - offset);

        let year = dateLocal.getFullYear();
        let month = dateLocal.getMonth() + 1;
        let day = dateLocal.getDate();
        let hour = dateLocal.getHours();
        let min = dateLocal.getMinutes();
        let sec = dateLocal.getSeconds();

        month = month < 10 ? '0' + month : month;
        min = min < 10 ? '0' + min : min;
        //hour = hour < 10 ? '0' + hour : hour;
        sec = sec < 10 ? '0' + sec : sec;

        let newTimestamp = hour + ':' + min;

        return newTimestamp;
    }

    function convert24to12(time24) {
        // Split the time string into hours and minutes
        const [hours, minutes] = time24.split(':').map(Number);

        // Determine AM/PM
        //const period = hours >= 12 ? 'PM' : 'AM';
        const period = hours >= 12 ? '' : '';

        // Adjust hours for 12-hour format
        const hours12 = hours % 12 || 12; // Handle 00:00 as 12:00 AM

        // Format and return the 12-hour time string
        return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
    }


    const getRows = () => {

        let serial = dryerData[index]?.Dryer_Serial_Name;
        const arrayIndex = dataLog.findIndex(e => e.serial === serial);

        let arr = [];

        if (dataLog.length > 0) {

            Object.keys(dataLog).forEach(function (key) {

                let timestamp = getTime(dataLog[key]['TIMESTAMP_S']);
                let t2 = conv_local_date_time(dataLog[key]['TIMESTAMP_S'])
                // console.log(t2)

                let last = timestamp[timestamp.length - 1]
                if (last == '0') {
                    let mid_count = dataLog[key]['Grain_Mid_Count'];
                    let time = convert24to12(getTime(dataLog[key]['TIMESTAMP_S']));
                    let avg = dataLog[key]['Grain_Mid_Temp_Avg'];
                    let sp = dataLog[key]['Mid_Tmp_SP'];
                    let m1 = dataLog[key]['Grain_Mid_Temp_1'];
                    let m2 = dataLog[key]['Grain_Mid_Temp_2'];
                    let m3 = dataLog[key]['Grain_Mid_Temp_3'];
                    let m4 = dataLog[key]['Grain_Mid_Temp_4'];

                    setMidCount(mid_count);

                    if (mid_count == 4) {
                        arr.push(
                            {
                                time: (
                                    <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                        {time}
                                    </MDTypography>
                                ),
                                avg: (
                                    <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                        {avg}<sub style={{ verticalAlign: 'top' }}>&deg;</sub>
                                    </MDTypography>
                                ),
                                sp: (
                                    <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                        {sp}<sub style={{ verticalAlign: 'top' }}>&deg;</sub>
                                    </MDTypography>
                                ),
                                m1: (
                                    <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                        {m1}<sub style={{ verticalAlign: 'top' }}>&deg;</sub>
                                    </MDTypography>
                                ),
                                m2: (
                                    <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                        {m2}<sub style={{ verticalAlign: 'top' }}>&deg;</sub>
                                    </MDTypography>
                                ),
                                m3: (
                                    <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                        {m3}<sub style={{ verticalAlign: 'top' }}>&deg;</sub>
                                    </MDTypography>
                                ),
                                m4: (
                                    <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                        {m4}<sub style={{ verticalAlign: 'top' }}>&deg;</sub>
                                    </MDTypography>
                                ),
                            }
                        );
                    } else {
                        arr.push(
                            {
                                time: (
                                    <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                        {time}
                                    </MDTypography>
                                ),
                                avg: (
                                    <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                        {avg}<sub style={{ verticalAlign: 'top' }}>&deg;</sub>
                                    </MDTypography>
                                ),
                                sp: (
                                    <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                        {sp}<sub style={{ verticalAlign: 'top' }}>&deg;</sub>
                                    </MDTypography>
                                ),
                                m1: (
                                    <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                        {m1}<sub style={{ verticalAlign: 'top' }}>&deg;</sub>
                                    </MDTypography>
                                ),
                                m2: (
                                    <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
                                        {m2}<sub style={{ verticalAlign: 'top' }}>&deg;</sub>
                                    </MDTypography>
                                ),
                            }
                        );
                    }
                }
            });
        }
        return arr;
    };
    const getDataLogging = async () => {

        let record_id = dryerData[index]?.Dryer_Record_ID;
        let user_id = dryerData[index]?.USER_ID;
        let serial = dryerData[index]?.Dryer_Serial_Name;

        try {
            if (auth?.accessToken && op != 0 && op != 0) {

                let timeoffset = get_time_offset();
                let timeoffset2;
                let today = new Date();
                if (today.isDstObserved()) {
                    timeoffset2 = timeoffset - 1;
                } else {
                    timeoffset2 = timeoffset - 2;
                }

                const response = await axiosPrivate.post('/getDataLogging', JSON.stringify({ serial, timeoffset, timeoffset2, record_id }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                //console.log(response.data);

                let data = {
                    serial: serial,
                    index: index,
                    data: response.data
                }

                // search datalog array for index
                const arrayIndex = dataLog.findIndex(e => e.serial === data.serial);
                //if found replace index with new data
                setDataLog(response.data)

            }
        } catch (e) {
            console.log(e);
        }
    };

    // once dataLog is set then set rows
    useEffect(() => {

            setTestRows(getRows());

    }, [dataLog])

    // get log on page load
    useEffect(() => {

        //if the card is flipped and the log is visible
        if(isFlipped){
            getDataLogging();
        }

    }, [index, dryerData]);

    const getTableHeight = (serial, index) => {
        let element_id = serial + '-' + index;
        let card = document.getElementById(element_id);
        let totalHeight = card == null ? 0 : card.offsetHeight;

        let heightMinusNav = totalHeight - 70;

        let header_id = serial + '-header';
        let header = document.getElementById(header_id);
        let header_height = header == null ? 0 : header.offsetHeight;

        let mid_header_id = serial + '-mid-header';
        let mid_header = document.getElementById(mid_header_id);
        let mid_header_height = mid_header == null ? 0 : mid_header.offsetHeight;

        let heightMinusHeader = heightMinusNav - header_height - mid_header_height;
        // mid grain has 2 dividers
        // -10 for divider margin
        // -10 for divider margin
        return (heightMinusHeader - 20);
    }

    return (
        <>
            <MDBox
                display={{ xs: "block" }}
                position="absolute"
                top={0}
                right={0}
                p={1.625}
                onClick={(e) => handleFlip(e, serial, destination, index)}
                sx={{ cursor: "pointer" }}
            >
                <MDTypography variant="h6" color="secondary" style={{fontSize:'22px'}}>
                    <Icon className="button-hover" style={{ color: darkMode ? "white" : "black" }}>undo</Icon>
                </MDTypography>
            </MDBox>
            {online == 0 || sub == 0 ?
                <MDTypography style={{ width: '100%', height: '300px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                    {sub == 0 ? 'Subscription expired' : 'Dryer offline'}
                </MDTypography>
                :
                <>
                    <MDBox id={serial + '-header'} pt={3}>
                        <MDBox px={2} pt={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ display: "flex" }}>
                                    <Grid item xs={8} style={{ display: 'flex' }}>
                                        <Grid item xs={4} style={{ display: 'flex' }}>
                                            <img src={grain_temp_svg} style={{ width: '50px', height: 'auto', objectFit: 'contain' }} />
                                        </Grid>
                                        <Grid item xs={8} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                            <MDTypography component="p" variant="button" display="flex" fontSize="40px" style={{ color: '#EEEB18', fontWeight: '1000' }}>
                                                <span>{mid_temp_avg}{mid_temp_avg != 'N/A' ? <span style={{ alignSelf: 'end', fontWeight: '400' }}>&deg;</span> : <></>}</span>
                                            </MDTypography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} pb={'10px'} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                        <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly', color: '#BEBEBE' }} >
                                            <span>{mid_temp_sp}&deg;</span>
                                        </MDTypography>
                                        <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly', color: '#BEBEBE' }}>
                                            <span>SETPOINT</span>
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MDBox>

                    </MDBox>
                    <hr className="dryer-card-divider-multi" />
                    {comingSoon ?
                        <>
                            <CardBackCommingSoon />
                        </>
                        :
                        op == 0 ?
                            <>
                                <MDTypography style={{ width: '100%', height: '300px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                    Dryer stopped no data to display
                                </MDTypography>
                            </>
                            :
                            <>
                                <MDBox id={serial + '-mid-header'}>
                                    <MDBox px={2}>
                                        <Grid container spacing={3} ml={0}>

                                            {mid_grain_count == 2 ?

                                                <>
                                                    {midgrain2.map((row, key) => (
                                                        <Grid item xs={6} key={key} style={{ display: 'flex', paddingLeft: '0' }}>

                                                            <Grid item xs={4} style={{ display: 'flex', }}>
                                                                <img src={key == 0 ? mid_svg_1 : key == 1 ? mid_svg_2 : key == 2 ? mid_svg_3 : mid_svg_4} style={{ width: '40px', height: 'auto', objectFit: 'contain' }} />
                                                            </Grid>
                                                            <Grid item xs={8} style={{ display: 'flex', paddingLeft: '0' }}>
                                                                <MDTypography component="p" variant="button" display="flex" fontSize="38px" style={{ color: '#EEEB18', fontWeight: '1000' }}>
                                                                    <span>{row.temp}&deg;</span>
                                                                </MDTypography>
                                                            </Grid>

                                                        </Grid>
                                                    ))}
                                                </>

                                                :

                                                <>
                                                    {midgrain4.map((row, key) => (
                                                        <Grid item xs={6} key={key} style={{ display: 'flex', paddingLeft: '0' }}>
                                                            <Grid item xs={4} style={{ display: 'flex', }}>
                                                                <img src={key == 0 ? mid_svg_1 : key == 1 ? mid_svg_2 : key == 2 ? mid_svg_3 : mid_svg_4} style={{ width: '40px', height: 'auto', objectFit: 'contain' }} />
                                                            </Grid>
                                                            <Grid item xs={8} style={{ display: 'flex', paddingLeft: '0' }}>
                                                                <MDTypography component="p" variant="button" display="flex" fontSize="38px" style={{ color: '#EEEB18', fontWeight: '1000' }}>
                                                                    <span>{row.temp}&deg;</span>
                                                                </MDTypography>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </>
                                            }
                                        </Grid>
                                    </MDBox>

                                </MDBox>
                                <hr className="dryer-card-divider-multi" />
                                <MDBox style={{ overflowY: 'auto', maxHeight: getTableHeight(serial, index) }}>
                                    <table style={{ width: '100%' }}>
                                        {midCount == 4 ?
                                            <tbody>
                                                <tr style={{ textAlign: 'center' }}>

                                                    {columns1.map((column, key) => (
                                                        <th key={key}>
                                                            <MDTypography fontSize={"12px"}>
                                                                {column.Header}
                                                            </MDTypography>
                                                        </th>
                                                    ))}
                                                </tr>
                                                {testRows.map((row, key) => (
                                                    <tr key={key} style={{ textAlign: 'center' }}>
                                                        <td style={{ fontSize: '12px' }}>{row.time}</td>
                                                        <td style={{ fontSize: '12px' }}>{row.avg}</td>
                                                        <td style={{ fontSize: '12px' }}>{row.sp}</td>
                                                        <td style={{ fontSize: '12px' }}>{row.m1}</td>
                                                        <td style={{ fontSize: '12px' }}>{row.m2}</td>
                                                        <td style={{ fontSize: '12px' }}>{row.m3}</td>
                                                        <td style={{ fontSize: '12px' }}>{row.m4}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            :
                                            <tbody>
                                                <tr style={{ textAlign: 'center' }}>

                                                    {columns2.map((column, key) => (
                                                        <th key={key}>
                                                            <MDTypography fontSize={"12px"}>
                                                                {column.Header}
                                                            </MDTypography>
                                                        </th>
                                                    ))}
                                                </tr>
                                                {testRows.map((row, key) => (
                                                    <tr key={key} style={{ textAlign: 'center', color: 'gray' }}>
                                                        <td style={{ fontSize: '12px' }}>{row.time}</td>
                                                        <td style={{ fontSize: '12px' }}>{row.avg}</td>
                                                        <td style={{ fontSize: '12px' }}>{row.sp}</td>
                                                        <td style={{ fontSize: '12px' }}>{row.m1}</td>
                                                        <td style={{ fontSize: '12px' }}>{row.m2}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        }
                                    </table>
                                </MDBox>
                            </>
                    }
                </>
            }
        </>

    );
}

// Typechecking props for the CardBackMidGrain
CardBackMidGrain.defaultProps = {
    index: 0,
};

CardBackMidGrain.propTypes = {
    index: PropTypes.number,
    handleFlip: PropTypes.func,
    destination: PropTypes.number,
    comingSoon: PropTypes.bool,
    isFlipped: PropTypes.bool,
};

export default CardBackMidGrain;
