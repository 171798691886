/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import '../../../App.css'

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import validator from "validator"
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useMaterialUIController, setDryerData, setDryerDataIndex, setView, setGlobalAlertMessage, setGlobalAlertColor, setGlobalAlertActive } from "context";
import React, { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import MDInput from 'components/MDInput';

import MDButton from 'components/MDButton';
import Icon from "@mui/material/Icon";
import NotificationItem from "examples/Items/NotificationItem";
import constants from "../../../constants";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from "@mui/material/IconButton";


function UserAccountCard() {
    const [controller, dispatch] = useMaterialUIController();
    const axiosPrivate = useAxiosPrivate();

    const {
        dryerData,
        dryerDataIndex,
        auth,
        darkMode
    } = controller;

    const [dataLoaded, setDataLoaded] = useState(false)

    const [ownerData, setOwnerData] = useState({
        ADDRESS_1: '',
        ADDRESS_2: '',
        BUSINESS: '',
        CITY: '',
        CITY: '',
        POST_CODE: '',
        RECORD_ID: '',
        STATE: '',
        EMAIL: '',
        USERNAME: '',
        FIRST: '',
        LAST: '',
        PHONE: ''
    });
    const [newOwnerData, setNewOwnerData] = useState({
        ADDRESS_1: '',
        ADDRESS_2: '',
        BUSINESS: '',
        CITY: '',
        CITY: '',
        POST_CODE: '',
        RECORD_ID: '',
        STATE: '',
        EMAIL: '',
        USERNAME: '',
        FIRST: '',
        LAST: '',
        PHONE: ''
    });

    const getOwnerInfo = async () => {

        let owner_id = dryerData[dryerDataIndex]?.OWNER_ID;
        let user_id = dryerData[dryerDataIndex]?.USER_ID;

        try {
            if (auth?.accessToken) {

                const response = await axiosPrivate.post('/getOwnerInfo', JSON.stringify({ owner_id, user_id }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                let address1 = response.data[0].ADDRESS_1 ? response.data[0].ADDRESS_1 : '';
                let address2 = response.data[0].ADDRESS_2 ? response.data[0].ADDRESS_2 : '';
                let buisness = response.data[0].BUSINESS ? response.data[0].BUSINESS : '';
                let city = response.data[0].CITY ? response.data[0].CITY : '';
                let post = response.data[0].POST_CODE ? response.data[0].POST_CODE : '';
                let state = response.data[0].STATE ? response.data[0].STATE : '';
                let country = response.data[0].COUNTRY ? response.data[0].COUNTRY : '';
                let email = response.data[0].EMAIL ? response.data[0].EMAIL : '';
                let username = response.data[0].USERNAME ? response.data[0].USERNAME : '';
                let firstname = response.data[0].FIRST_NAME ? response.data[0].FIRST_NAME : '';
                let lastname = response.data[0].LAST_NAME ? response.data[0].LAST_NAME : '';
                let phone = response.data[0].PHONE ? response.data[0].PHONE : '';

                setOwnerData({
                    ...ownerData,
                    ADDRESS_1: validator.escape(address1),
                    ADDRESS_2: validator.escape(address2),
                    BUSINESS: validator.escape(buisness),
                    CITY: validator.escape(city),
                    POST_CODE: validator.escape(post),
                    STATE: validator.escape(state),
                    COUNTRY: validator.escape(country),
                    EMAIL: validator.escape(email),
                    USERNAME: validator.escape(username),
                    FIRST: validator.escape(firstname),
                    LAST: validator.escape(lastname),
                    PHONE: validator.escape(phone)
                });

                setNewOwnerData({
                    ...newOwnerData,
                    ADDRESS_1: validator.escape(address1),
                    ADDRESS_2: validator.escape(address2),
                    BUSINESS: validator.escape(buisness),
                    CITY: validator.escape(city),
                    POST_CODE: validator.escape(post),
                    STATE: validator.escape(state),
                    COUNTRY: validator.escape(country),
                    EMAIL: validator.escape(email),
                    USERNAME: validator.escape(username),
                    FIRST: validator.escape(firstname),
                    LAST: validator.escape(lastname),
                    PHONE: validator.escape(phone)
                });

                setDataLoaded(true)
            }
        } catch (e) {
            console.log(e);
        }
    };
    const updateOwnerInfo = async () => {

        let owner_id = dryerData[dryerDataIndex]?.OWNER_ID;
        let user_id = dryerData[dryerDataIndex]?.USER_ID;

        let escape = {
            ADDRESS_1: validator.escape(newOwnerData.ADDRESS_1),
            ADDRESS_2: validator.escape(newOwnerData.ADDRESS_2),
            BUSINESS: validator.escape(newOwnerData.BUSINESS),
            CITY: validator.escape(newOwnerData.CITY),
            POST_CODE: validator.escape(newOwnerData.POST_CODE),
            STATE: validator.escape(newOwnerData.STATE),
            COUNTRY: validator.escape(newOwnerData.COUNTRY),
            EMAIL: validator.escape(newOwnerData.EMAIL),
            FIRST: validator.escape(newOwnerData.FIRST),
            LAST: validator.escape(newOwnerData.LAST),
            PHONE: validator.escape(newOwnerData.PHONE)
        }

        try {
            if (auth?.accessToken) {

                const response = await axiosPrivate.post('/updateOwnerInfo', JSON.stringify({ owner_id, user_id, data: escape }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                if (response.data == 'success') {
                    displaySuccess();
                    getOwnerInfo();

                } else {
                    displayFail();
                    getOwnerInfo();
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleOwnerUpdate = () => {
        if (!businessError && !addressError1 && !addressError2 && !cityError &&
            !stateError && !zipError && !countryError && !firstError && !lastError && !emailError && !phoneError) {
            updateOwnerInfo();
        }
    }


    // useEffect(() => {
    //     getOwnerInfo();
    // }, []);
    useEffect(() => {
        getOwnerInfo();
    }, [dryerDataIndex]);

    const [businessError, setBusinessError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [firstError, setFirstError] = useState(false);
    const [lastError, setLastError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [addressError1, setAddressError1] = useState(false);
    const [addressError2, setAddressError2] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [stateError, setStateError] = useState(false);
    const [zipError, setZipError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    const validatePhoneNumber = (number) => {
        // only allow us and canada phone numbers
        const isValidPhoneNumber = validator.isMobilePhone(number, ['en-US', 'en-CA'])
        return (isValidPhoneNumber)
    }
    const validateEmail = (email) => {
        const isValidEmail = validator.isEmail(email)
        return (isValidEmail)
    }
    const validatePostCode = (code) => {
        // only allow us and canada phone numbers
        const isValidPostCode = validator.isPostalCode(code, 'any')
        return (isValidPostCode)
    }

    useEffect(() => {
        if (dataLoaded) {
            if (newOwnerData.BUSINESS != "") {
                let pattern = /^[a-zA-Z0-9\s.-]*$/;
                if (pattern.test(newOwnerData.BUSINESS)) {
                    setBusinessError(false);
                } else {
                    setBusinessError(true);
                }
            } else {
                setBusinessError(true);
            }

            if (newOwnerData.FIRST != "") {
                let pattern = /^[a-zA-Z0-9\s.-]*$/;
                if (pattern.test(newOwnerData.FIRST)) {
                    setFirstError(false);
                } else {
                    setFirstError(true);
                }
            } else {
                setFirstError(true);
            }

            if (newOwnerData.LAST != "") {
                let pattern = /^[a-zA-Z0-9\s.-]*$/;
                if (pattern.test(newOwnerData.LAST)) {
                    setLastError(false);
                } else {
                    setLastError(true);
                }
            } else {
                setLastError(true);
            }

            if (newOwnerData.COUNTRY != "") {
                let pattern = /^[a-zA-Z\s]+$/;
                if (pattern.test(newOwnerData.COUNTRY)) {
                    setCountryError(false);
                } else {
                    setCountryError(true);
                }
            } else {
                setCountryError(false);
            }

            if (newOwnerData.ADDRESS_1 != "") {
                let pattern = /^[a-zA-Z0-9\s.,-]*$/;
                if (pattern.test(newOwnerData.ADDRESS_1)) {
                    setAddressError1(false);
                } else {
                    setAddressError1(true);
                }
            } else {
                setAddressError1(false);
            }

            if (newOwnerData.ADDRESS_2 != "") {
                let pattern = /^[a-zA-Z0-9\s.,-]*$/;
                if (pattern.test(newOwnerData.ADDRESS_2)) {
                    setAddressError2(false);
                } else {
                    setAddressError2(true);
                }
            } else {
                setAddressError2(false);
            }

            if (newOwnerData.CITY != "") {
                let pattern = /^[a-zA-Z\s]*$/;
                if (pattern.test(newOwnerData.CITY)) {
                    setCityError(false);
                } else {
                    setCityError(true);
                }
            } else {
                setCityError(false);
            }

            if (newOwnerData.STATE != "") {
                let pattern = /^[a-zA-Z\s]+$/;
                if (pattern.test(newOwnerData.STATE)) {
                    setStateError(false);
                } else {
                    setStateError(true);
                }
            } else {
                setStateError(false);
            }

            if (newOwnerData.POST_CODE != "") {

                // validator only works for US post codes
                //let validPostCode = validatePostCode(newOwnerData.POST_CODE);
                
                let pattern = /^[a-zA-Z0-9\s.-]*$/;
                
                if (pattern.test(newOwnerData.POST_CODE)) {
                    setZipError(false);
                } else {
                    setZipError(true);
                }
            } else {
                setZipError(false);
            }

            if (newOwnerData.PHONE != "") {
                let validPhone = validatePhoneNumber(newOwnerData.PHONE);
                if (validPhone) {
                    setPhoneError(false);
                } else {
                    setPhoneError(true);
                }
            } else {
                setPhoneError(false);
            }

            if (newOwnerData.EMAIL != "") {
                let valid = validateEmail(newOwnerData.EMAIL);
                if (valid) {
                    setEmailError(false);
                } else {
                    setEmailError(true);
                }
            } else {
                setEmailError(false);
            }
        }
    }, [dataLoaded, newOwnerData])

    const displaySuccess = () => {
        setGlobalAlertActive(dispatch, true);
        setGlobalAlertColor(dispatch, "success");
        setGlobalAlertMessage(dispatch, "Account info updated successfully");
    }
    const displayFail = () => {
        setGlobalAlertActive(dispatch, true);
        setGlobalAlertColor(dispatch, "error");
        setGlobalAlertMessage(dispatch, "Unable to update account info, please try again later");
    }

    const disableUpdateButton = () => {
        if (businessError || addressError1 || addressError2 || cityError ||
            stateError || zipError || countryError || phoneError || firstError || lastError || emailError) {
            return true;
        } else {
            if (ownerData.BUSINESS == newOwnerData.BUSINESS &&
                ownerData.ADDRESS_1 == newOwnerData.ADDRESS_1 &&
                ownerData.ADDRESS_2 == newOwnerData.ADDRESS_2 &&
                ownerData.CITY == newOwnerData.CITY &&
                ownerData.STATE == newOwnerData.STATE &&
                ownerData.POST_CODE == newOwnerData.POST_CODE &&
                ownerData.COUNTRY == newOwnerData.COUNTRY &&
                ownerData.EMAIL == newOwnerData.EMAIL &&
                ownerData.FIRST == newOwnerData.FIRST &&
                ownerData.LAST == newOwnerData.LAST &&
                ownerData.PHONE == newOwnerData.PHONE
            ) {
                return true;
            } else {
                return false;
            }
        }
    }

    const disableCancelButton = () => {

        if (ownerData.BUSINESS == newOwnerData.BUSINESS &&
            ownerData.ADDRESS_1 == newOwnerData.ADDRESS_1 &&
            ownerData.ADDRESS_2 == newOwnerData.ADDRESS_2 &&
            ownerData.CITY == newOwnerData.CITY &&
            ownerData.STATE == newOwnerData.STATE &&
            ownerData.POST_CODE == newOwnerData.POST_CODE &&
            ownerData.COUNTRY == newOwnerData.COUNTRY &&
            ownerData.EMAIL == newOwnerData.EMAIL &&
            ownerData.FIRST == newOwnerData.FIRST &&
            ownerData.LAST == newOwnerData.LAST &&
            ownerData.PHONE == newOwnerData.PHONE
        ) {
            return true;
        } else {
            return false;
        }
    }

    const disableUpdateError = () => {
        if (businessError || addressError1 || addressError2 || cityError ||
            stateError || zipError || countryError || firstError || lastError || emailError || phoneError) {
            return true;
        } else {
            return false;
        }
    }

    const handleCancel = () => {

        setNewOwnerData({
            ...ownerData,
            ADDRESS_1: ownerData.ADDRESS_1,
            ADDRESS_2: ownerData.ADDRESS_2,
            BUSINESS: ownerData.BUSINESS,
            CITY: ownerData.CITY,
            POST_CODE: ownerData.POST_CODE,
            STATE: ownerData.STATE,
            COUNTRY: ownerData.COUNTRY,
            EMAIL: ownerData.EMAIL,
            FIRST: ownerData.FIRST,
            LAST: ownerData.LAST,
            PHONE: ownerData.PHONE
        });
    }

    let disableUpdate = disableUpdateButton();
    let disableCancel = disableCancelButton();

    let disableUpdateButtonError = disableUpdateError();
    let updateButtonColor = disableUpdate ? 'gray' : '#862633'
    let cancelButtonColor = disableCancel ? 'gray' : '#862633'

    const styles = {
        input: {
            color: darkMode ? 'white' : 'black',
        },
    }

    const getExpDate = () => {
        let sub_exp = new Date(dryerData[dryerDataIndex].MCTrax_Expires);

        let year = sub_exp.getFullYear();
        let month = sub_exp.getMonth() + 1;
        let day = sub_exp.getDate();

        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;

        let timestamp = month + '-' + day + '-' + year

        return timestamp;
    }

    let serial = dryerData[dryerDataIndex]?.Dryer_Serial_Name;

    let exp_data = getExpDate();

    const handleBack = () => {
        setView(dispatch, constants.SINGLE_VIEW)
    }

    return (
        <Card className="full shadow">
            <Grid container spacing={1} style={{ justifyContent: 'space-between', marginTop: '16px' }}>
                <Grid item xs={3}>
                    <IconButton
                        style={{ fontSize: '20px', paddingLeft: '16px' }}
                        size="small"
                        disableRipple
                        variant="contained"
                        onClick={handleBack}
                        className="hover-back"
                    >
                        <Icon style={{ color: darkMode ? 'white' : 'black' }}>arrow_back_ios_new</Icon>
                        <MDTypography component={'p'} style={{ fontSize: '14px', marginLeft: '8px' }}>Dashboard</MDTypography>
                    </IconButton>
                </Grid>
            </Grid>
            <MDBox pt={4} px={3} component="form" role="form" >
                <Grid container style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={12} mb={2}>
                            <MDBox style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <MDTypography component="p">User Information</MDTypography>
                                <MDTypography component="p">Required*</MDTypography>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} mb={2} >
                            <MDTypography style={{ fontSize: '16px', textAlign: 'left' }}>
                                Username: {newOwnerData.USERNAME}
                            </MDTypography>
                        </Grid>
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} md={6}>
                                <MDInput
                                    className={firstError ? 'error-style account-input' : 'account-input'}
                                    style={styles.input}
                                    error={firstError}
                                    autoComplete="new-password"
                                    label="First name *"
                                    name="firstname"
                                    fullWidth
                                    value={newOwnerData.FIRST || ''}
                                    onChange={(e) => setNewOwnerData({ ...newOwnerData, FIRST: e.target.value })}
                                    inputProps={{
                                        autoComplete: 'new-password'
                                    }}
                                />
                                <MDTypography style={{ display: firstError ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a first name</MDTypography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MDInput
                                    className={lastError ? 'error-style account-input' : 'account-input'}
                                    style={styles.input}
                                    error={lastError}
                                    autoComplete="new-password"
                                    label="Last name *"
                                    name="lastname"
                                    fullWidth
                                    value={newOwnerData.LAST || ''}
                                    onChange={(e) => setNewOwnerData({ ...newOwnerData, LAST: e.target.value })}
                                    inputProps={{
                                        autoComplete: 'new-password'
                                    }}
                                />
                                <MDTypography style={{ display: lastError ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a last name</MDTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} mb={2} >
                            <MDInput
                                className={emailError ? 'error-style account-input' : 'account-input'}
                                style={styles.input}
                                error={emailError}
                                autoComplete="new-password"
                                label="Email"
                                name="email"
                                fullWidth
                                value={newOwnerData.EMAIL || ''}
                                onChange={(e) => setNewOwnerData({ ...newOwnerData, EMAIL: e.target.value })}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                            />
                            <MDTypography style={{ display: emailError ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a valid email</MDTypography>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox pt={4} pb={3} px={3} component="form" role="form" >
                <Grid container style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={12} mb={2}>
                            <MDBox style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <MDTypography component="p">Owner Information</MDTypography>
                                <MDTypography component="p">Required*</MDTypography>
                            </MDBox>
                        </Grid>
                        <Grid container mb={2} >
                            <Grid item xs={12} style={{display:'flex', justifyContent:'space-between'}}>
                                <MDTypography style={{ fontSize: '14px', textAlign: 'left' }}>
                                    Dryer Serial:<br className='visible-xs'/> {serial}
                                </MDTypography>
                                <MDTypography style={{ fontSize: '14px', textAlign: 'right' }}>
                                    Subscription Expires:<br className='visible-xs'/> {exp_data}
                                </MDTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <MDInput
                                className={businessError ? 'error-style account-input' : 'account-input'}
                                style={styles.input}
                                error={businessError}
                                autoComplete="new-password"
                                label="Business *"
                                name="business"
                                fullWidth
                                value={newOwnerData.BUSINESS || ''}
                                onChange={(e) => setNewOwnerData({ ...newOwnerData, BUSINESS: e.target.value })}
                                inputProps={{
                                    autoComplete: 'new-password'
                                }}
                            />
                            <MDTypography style={{ display: businessError ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a business name</MDTypography>
                        </Grid>
                        <Grid id="country-input" item xs={12} mb={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                                <Select
                                    className={countryError ? 'error-style account-input' : 'account-input'}
                                    style={{ height: '50px', borderRadius: '8px' }}
                                    label="Country"
                                    value={newOwnerData.COUNTRY || ''}
                                    fullWidth
                                    onChange={(e) => setNewOwnerData({ ...newOwnerData, COUNTRY: e.target.value })}
                                >
                                    {constants.countries.map((item) => {
                                        return (
                                            <MenuItem value={item.label} key={item.code} style={{ display: "flex" }} >
                                                <MDTypography
                                                    component="p"
                                                    fontSize="14px"
                                                    style={{ color: darkMode ? 'white' : 'rgb(73, 80, 87)' }}
                                                >
                                                    {item.label}
                                                </MDTypography>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <MDInput
                                className={addressError1 ? 'error-style account-input' : 'account-input'}
                                style={styles.input}
                                error={addressError1}
                                autoComplete="new-password"
                                label="Street address, P.O. box"
                                name="address-line1"
                                fullWidth
                                value={newOwnerData.ADDRESS_1 || ''}
                                onChange={(e) => setNewOwnerData({ ...newOwnerData, ADDRESS_1: e.target.value })}
                                inputProps={{
                                    autoComplete: 'new-password'
                                }}
                            />
                            <MDTypography style={{ display: addressError1 ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a valid address</MDTypography>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <MDInput
                                className={addressError2 ? 'error-style account-input web-test' : 'account-input web-test'}
                                style={styles.input}
                                error={addressError2}
                                autoComplete="new-password"
                                label="Apartment, suite, unit, building, floor, etc."
                                name="address-line2"
                                fullWidth
                                value={newOwnerData.ADDRESS_2 || ''}
                                onChange={(e) => setNewOwnerData({ ...newOwnerData, ADDRESS_2: e.target.value })}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                            />
                            <MDTypography style={{ display: addressError2 ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a valid address</MDTypography>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <MDInput
                                className={cityError ? 'error-style account-input' : 'account-input'}
                                style={styles.input}
                                error={cityError}
                                autoComplete="new-password"
                                label="City"
                                name="city"
                                fullWidth
                                value={newOwnerData.CITY || ''}
                                onChange={(e) => setNewOwnerData({ ...newOwnerData, CITY: e.target.value })}
                                inputProps={{
                                    autoComplete: 'new-password'
                                }}
                            />
                            <MDTypography style={{ display: cityError ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a valid city</MDTypography>
                        </Grid>
                        <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} md={6}>
                                <MDInput
                                    className={stateError ? 'error-style account-input' : 'account-input'}
                                    style={styles.input}
                                    error={stateError}
                                    autoComplete="new-password"
                                    label="State/Province/Region"
                                    name="state"
                                    fullWidth
                                    value={newOwnerData.STATE || ''}
                                    onChange={(e) => setNewOwnerData({ ...newOwnerData, STATE: e.target.value })}
                                    inputProps={{
                                        autoComplete: 'new-password'
                                    }}
                                />
                                <MDTypography style={{ display: stateError ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a state</MDTypography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MDInput
                                    className={zipError ? 'error-style account-input' : 'account-input'}
                                    style={styles.input}
                                    error={zipError}
                                    autoComplete="new-password"
                                    label="ZIP/Postal Code"
                                    name="zip"
                                    fullWidth
                                    value={newOwnerData.POST_CODE || ''}
                                    onChange={(e) => setNewOwnerData({ ...newOwnerData, POST_CODE: e.target.value })}
                                    inputProps={{
                                        autoComplete: 'new-password'
                                    }}
                                />
                                <MDTypography style={{ display: zipError ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a ZIP/Postal Code</MDTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <MDInput
                                className={phoneError ? 'error-style account-input' : 'account-input'}
                                style={styles.input} error={phoneError}
                                autoComplete="new-password"
                                label="Phone"
                                name="phone"
                                fullWidth
                                value={newOwnerData.PHONE || ''}
                                onChange={(e) => setNewOwnerData({ ...newOwnerData, PHONE: e.target.value })}
                                inputProps={{
                                    autoComplete: 'new-password'
                                }}
                            />
                            <MDTypography style={{ display: phoneError ? 'block' : 'none', marginTop: '3px' }} color="error" component={'p'} fontSize="12px">Please enter a valid phone number</MDTypography>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6} mb={2}>
                                <MDButton
                                    disabled={disableCancel}
                                    style={{ width: '100%', backgroundColor: cancelButtonColor, color: 'white' }}
                                    color="primary"
                                    onClick={handleCancel}>
                                    CANCEL
                                </MDButton>
                            </Grid>
                            <Grid item xs={6} mb={2}>
                                <MDButton
                                    disabled={disableUpdate}
                                    style={{ width: '100%', backgroundColor: updateButtonColor, color: 'white' }}
                                    color="primary"
                                    onClick={handleOwnerUpdate}>
                                    UPDATE
                                </MDButton>
                            </Grid>
                        </Grid>
                        {disableUpdateButtonError ? <MDTypography color="error" fontSize="16px" style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px' }}>Please correct errors to continue</MDTypography> : null}
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

// Typechecking props for the DryerCard3
UserAccountCard.propTypes = {
};


export default UserAccountCard;
