import { useLocation, Navigate, Outlet } from "react-router-dom";
import {
    useMaterialUIController,
    setAuth,
} from "context";

const RequireAuth = () => {

    const [controller, dispatch] = useMaterialUIController();
    const { auth } = controller;

    const location = useLocation();

    return (
        auth?.accessToken ? <Outlet /> : <Navigate to="/authentication/sign-in/" state={{ from: location }} replace />
    );

}

export default RequireAuth;

