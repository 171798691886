import axios from "axios";


// const BASE_URL = 'http://192.168.123.216:3001'

//let url = 'preview.mctrax.net'

let url ='';
let protocol = '';
let port = '';

// why does this work
if(window.location.host.includes('localhost')){
    url = 'localhost';
    protocol = 'http';
    port = '3001';

} else {
    url = 'preview.mctrax.net';
    protocol = 'https';
    port = '443';
}

const BASE_URL = protocol + '://' + url + ':' + port + '/api/'

export default axios.create({
    baseURL: BASE_URL
})
export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {'Content-Type' : 'application/json'},
    withCredentials: true
})
