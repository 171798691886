import { prefix } from 'stylis';
import axios from '../api/axios'

import {
    useMaterialUIController,
    setAuth,
} from "context";

const useRefreshToken = () => {

    const [controller, dispatch] = useMaterialUIController();

    const { auth } = controller;

    const refresh = async () => {
        //console.log(auth)
        const response = await axios.get('/refresh',
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            });

        let newAuth = {};

        newAuth.accessToken = response.data.accessToken;
        newAuth.EMail_Address = response.data.EMail_Address;
        newAuth.USERNAME = response.data.USERNAME;
        newAuth.USER_ID = response.data.USER_ID;
        newAuth.User = response.data.User;
        newAuth.USER_TYPE_ID = response.data.USER_TYPE_ID;

        setAuth(dispatch, newAuth);

        setAuth(prev => {
            return { ...prev, accessToken: response.data.accessToken }
        });

        return response.data.accessToken;

    }
    return refresh;

}

export default useRefreshToken;