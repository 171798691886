/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import '../../../App.css'
import MDAlert from "components/MDAlert";
import PropTypes from "prop-types";
import CircularProgress from '@mui/material/CircularProgress';
// @mui material components
import Icon from "@mui/material/Icon";
import useAxiosPriavte from "hooks/useAxiosPrivate";
import React, { useState, useEffect } from "react";
import Alert from '@mui/material/Alert';
import mctrax_logo from '../../../assets/images/mc/mctrax-logo.svg'
import mctrax_logo_white from '../../../assets/images/mc/mctrax-logo-white.svg'
import useLogout from "hooks/useLogout";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckIcon from '@mui/icons-material/Check';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from 'components/MDInput';
import Modal from '@mui/material/Modal';
import MDButton from 'components/MDButton';

import {
    useMaterialUIController,

} from "context";
import { Divider, Grid } from '@mui/material';

function ChildModal({ change, childOpen, handleChildOpen, handleChildClose, controlVars, displaySuccess, displayFail, getControlVars }) {

    const [controller, dispatch] = useMaterialUIController();
    const axiosPrivate = useAxiosPriavte();

    const {
        darkMode,
        auth,
        dryerData,
        dryerDataIndex
    } = controller;

    const styles2 = {

        mdtContainer: {
            display: "flex",
            justifyContent: 'space-between',
            marginBottom: '6px',
            marginTop: '6px',

        },
        mdtText: {
            fontSize: '1rem',
        },
        mdtItem: {
            textAlign: 'left',
            alignSelf: 'center'
        },
        input: {
            width: '100%',
            height: '40px',
            borderRadius: '8px',
            border: 'none',
            outlineColor: '#1A73E8',
            outlineWidth: '2px',
            padding: '12px',
            fontFamily: '"Arial", "Arial Black", "Arial Bold", "Gadget", sans-serif',
        },
        modal: {
            //position: 'relative',
            overflow: 'auto',
            //height: '100dvh',
            overscrollBehaviorY: 'contain',
        },
        style2: {
            position: 'absolute',
            display: 'inline-table',
            // top: '50%',
            // left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            //width: '45%',
            bgcolor: darkMode ? '#141414' : '#E6E6E6',
            border: darkMode ? '2px solid #fff' : '2px solid #000',
            boxShadow: 24,
            pt: 5,
            px: 2,
            pb: 4,
            overflow: 'hidden',
            overscrollBehaviorY: 'contain',
        },
        pin: {
            width: '100%'
        },
    };

    let serial = dryerData[dryerDataIndex]?.Dryer_Serial_Name;

    let no_burn = dryerData[dryerDataIndex]?.plenumData?.No_Of_Burners;
    let dryer_mode = dryerData[dryerDataIndex]?.runData?.Op_Mode;
    let dryer_state = controlVars.disch_mode != "0" ? 'Running' : 'Stopped';

    const [pin, setPin] = useState('')
    const [pinMask, setPinMask] = useState('')

    const [newStateSelectValue, setNewStateSelectValue] = useState('');
    const [newStateSelect, setNewStateSelect] = useState('');
    const [newModeSelect, setNewModeSelect] = useState('');
    const [newModeSelectValue, setNewModeSelectValue] = useState('');
    const [newDischSpeedSP, setNewDischSpeedSP] = useState('');
    const [newDischMoistSP, setNewDischMoistSP] = useState('');
    const [newMidgrainSP, setNewMidgrainSP] = useState('');
    const [newPlenumSP, setNewPlenumSP] = useState('');
    const [plenumNumber, setPlenumNumber] = useState();
    const [requestPending, setRequestPending] = useState(false);

    const handleClose = () => {
        //reset state on close
        setPin('');
        setNewStateSelect('');
        setNewModeSelect('');
        setNewDischSpeedSP('');
        setNewDischMoistSP('');
        setNewMidgrainSP('');
        setNewPlenumSP('');
        setPlenumNumber('');
        setPinMask('');

        handleChildClose();
    }

    const handlePinChange = (e) => {
        const value = e.target.value;
        if (value.length == 1) {
            let pinStr = pin;
            if (value.length > pinStr.length) {
                setPin(value);
            } else {
                pinStr = pinStr.slice(0, -1);
                setPin(pinStr);
            }
        } else {

            let pinStr = pin;

            if (value.length > pinStr.length) {
                let addPinChar = value.substring(value.length - 1,);
                let newPin = pinStr + addPinChar;
                setPin(newPin);
            } else {
                pinStr = pinStr.slice(0, -1);
                setPin(pinStr);
            }
        }


        let len = value.length;

        let mask = ""

        for (let i = 0; i < len; i++) {
            mask = mask + '•'
        }

        setPinMask(mask)
    }

    const handleStateChange = (e) => {
        const value = e.target.value;
        if (value == "Shut Down") {
            setNewStateSelectValue(0);
        } else {
            setNewStateSelectValue(10);
        }

        setNewStateSelect(value);
    }

    const handleModeChange = (e) => {
        const value = e.target.value;

        if (value == 'TruDry') {
            setNewModeSelectValue(2);
        } else if (value == "AccuDry") {
            setNewModeSelectValue(3);
        } else {
            setNewModeSelectValue(1);
        }

        setNewModeSelect(value);
    }

    const handleDischSpeedChange = (e) => {
        const value = e.target.value;

        if (value != '') {
            if (parseFloat(value) > controlVars.disch_speed_sp_max / 10) {
                setNewDischSpeedSP(controlVars.disch_speed_sp_max / 10);
            } else {
                if (!value.match(/^\d{[0-9],}(\.\d{0,4})?$/)) {
                    setNewDischSpeedSP(value);
                }
            }
        } else {
            setNewDischSpeedSP('');
        }
    }
    const handleDischMoistChange = (e) => {
        const value = e.target.value;
        if (value != '') {
            if (parseFloat(value) > 100) {
                setNewDischMoistSP(100);
            } else {
                if (!value.match(/^\d{[0-9],}(\.\d{0,4})?$/)) {
                    setNewDischMoistSP(value);
                }
            }
        } else {
            setNewDischMoistSP('');
        }
    }

    const handleMidGrainChange = (e) => {
        const value = e.target.value;
        if (value != '') {
            if (parseInt(value) > 200) {
                setNewMidgrainSP(200);
            } else {
                setNewMidgrainSP(value);
            }
        } else {
            setNewMidgrainSP('');
        }
    }

    const handlePlenumChange = (e, change) => {

        let pNum = change == "Bot Plenum SP" ? "plenum01" : change == "Top Plenum SP" ? "plenum02" : change

        setPlenumNumber(pNum);

        const value = e.target.value;
        if (value != '') {
            if (parseInt(value) > 220) {
                setNewPlenumSP(220);
            } else {
                setNewPlenumSP(value);
            }
        } else {
            setNewPlenumSP('');
        }
    }

    const postUpdate = async (value, pin, remote_type) => {
        setRequestPending(true);
        try {

            //set serial and dataIndex before update
            let record_id = dryerData[dryerDataIndex]?.Dryer_Record_ID;
            let control_type = dryerData[dryerDataIndex]?.runData?.control_type + 1;

            //only update dryer list if browser tab is active / in foreground
            //if (!document.hidden) {
            if (auth?.accessToken) {
                //let serial = dryerData[index].Dryer_Serial_Name
                const response = await axiosPrivate.post('/postUpdate', JSON.stringify({ newValue: value, inputPin: pin, record_id, control_type, remote_type }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                if (response.status == 200) {
                    handleClose();
                    if (response.data == "bad pin") {
                        displayFail("Incorrect PIN")
                    } else {
                        // let  key = Object.keys(response.data[0])

                        // let model = dryerData[dryerDataIndex]?.Dryer_Model_Name;
                        // if(model.charAt(0).toLowerCase() == "d" ){
                        //     key = key == "plenum01" ? "Bot plenum" : key == "plenum02" ? "Top plenum" : key;
                        // }

                        // displaySuccess( key + " update successful");
                        displaySuccess("Remote update successful");
                        getControlVars();
                    }

                    setRequestPending(false);

                } else {
                    handleClose();
                    displayFail("Remote update failed");
                    getControlVars();
                    setRequestPending(false);
                }

            }
            //}
        } catch (e) {
            console.log(e);
        }
    };

    //submits
    const handleStateSubmit = () => { postUpdate(newStateSelectValue, pin, "disch_mode_commanded"); }
    const handleModeSubmit = () => { postUpdate(newModeSelectValue, pin, "disch_mode_commanded"); }
    const handleDischSpeedSubmit = () => { postUpdate(parseFloat(newDischSpeedSP) * 10, pin, "disch_speed_sp"); }
    const handleDischMoistSubmit = () => { postUpdate(newDischMoistSP, pin, "disch_moist_sp"); }
    const handleMidGrainSubmit = () => { postUpdate(newMidgrainSP, pin, "midtemp_SP"); }
    const handlePlenumSubmit = () => { postUpdate(newPlenumSP, pin, plenumNumber); }

    const onlyNumber = (e) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    }
    const onlyNumberDisch = (e) => {
        var charCode = (e.which) ? e.which : e.keyCode;

        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 190 && charCode != 110 && (charCode > 105 || charCode < 96)) {
            e.preventDefault()
        }
    }

    const checkLowerDischSpeedLimit = (e) => {
        if (newDischSpeedSP != '') {
            if (parseInt(newDischSpeedSP) < (controlVars.disch_speed_sp_min / 10)) {
                setNewDischSpeedSP(controlVars.disch_speed_sp_min / 10);
            }
        } else {
            setNewDischSpeedSP('');
        }
    }

    const checkLowerDischMoistLimit = (e) => {
        if (newDischMoistSP != '') {
            if (parseInt(newDischMoistSP) < 0) {
                setNewDischMoistSP('0');
            }
        } else {
            setNewDischMoistSP('');
        }
    }

    const checkLowerMidGrainLimit = (e) => {
        if (newMidgrainSP != '') {
            if (parseInt(newMidgrainSP) < 0) {
                setNewMidgrainSP('0');
            }
        } else {
            setNewMidgrainSP('');
        }
    }
    const checkLowerPlenumLimit = (e) => {
        if (newPlenumSP != '') {
            if (parseInt(newPlenumSP) < 0) {
                setNewPlenumSP('0');
            }
        } else {
            setNewPlenumSP('');
        }
    }

    const noPaste = (e) => {
        e.preventDefault();
        return false;
    }

    const warning = "NOTE: It may take up to 60 seconds for M-C Trax to correctly display the changes made (data on M-C Trax is updated from the dryer every 60 seconds)."

    const stateComponent = (change) => {
        const options1 = ['Shut Down', 'Cool Down'];

        return (
            <>
                <MDBox>
                    {requestPending ?
                        <div className="remote-pending" style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0' }}>
                            <CircularProgress style={{ position: 'absolute', top: '45%', left: '45%' }} />
                        </div>
                        :
                        <></>
                    }
                    <Grid container p={'0'}>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '12px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    Current State:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    {
                                        controlVars.disch_mode != "0" ? "Running" : "Stopped"
                                    }
                                </MDTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '12px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    New State:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label-2"></InputLabel>
                                    <Select
                                        className="radius"
                                        style={{ borderRadius: '8px', backgroundColor: 'white', height: '40px' }}
                                        labelId="remote-select"
                                        value={newStateSelect}
                                        id="remote-select"
                                        //label="Filter"
                                        onChange={handleStateChange}
                                    >
                                        {options1.map((item, key) => {
                                            let color = darkMode == false ? 'black' : 'white';
                                            return (
                                                <MenuItem className={darkMode ? "dropdown-light" : "dropdown-dark"} value={item} key={key} style={{ display: "flex" }} >
                                                    <MDTypography component="p" style={styles2.mdtText}>{item}</MDTypography>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '24px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    Security PIN:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4} id="pin-container">
                                <form autoComplete="off" className="radius" style={{ display: 'flex', justifyContent: 'end' }}>
                                    <MDInput style={styles2.pin} name="myInput" type="text" autoComplete="off" value={pinMask} onChange={(e) => { handlePinChange(e) }} />
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <MDBox style={{ display: 'flex', justifyContent: 'space-between', marginTop: '12px' }}>
                        <MDButton disabled={requestPending ? true : false} style={{ width: '35%', color: 'white', backgroundColor: requestPending ? "gray" : "#862633" }} onClick={handleClose}>Cancel</MDButton>
                        <MDButton disabled={pin == '' || newStateSelect == '' ? true : requestPending ? true : false} style={{ width: '35%', color: 'white', backgroundColor: pin == '' || newStateSelect == '' ? "gray" : requestPending ? "gray" : "#862633" }} onClick={handleStateSubmit}>ok</MDButton>
                    </MDBox>
                </MDBox >
            </>
        )
    }
    const modeComponent = (change) => {

        let options = [];
        let dryer_mode = dryerData[dryerDataIndex]?.runData?.Op_Mode;

        if (controlVars.disch_mode == "1") {
            if (controlVars.accudry_rdy == "1") {
                options.push("AccuDry")
            }
            options.push("TruDry")
        } else {
            options.push("Manual")
        }

        return (
            <>
                <MDBox>
                    {requestPending ?
                        <div className="remote-pending" style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0' }}>
                            <CircularProgress style={{ position: 'absolute', top: '45%', left: '45%' }} />
                        </div>
                        :
                        <></>
                    }
                    <Grid container p={'0'}>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '12px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    Current Mode:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    {
                                        controlVars.disch_mode == "1" ? "Manual" :
                                            controlVars.disch_mode == "2" ? "TruDry" :
                                                controlVars.disch_mode == "3" ? "AccuDry" : "Stopped"
                                    }
                                </MDTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '12px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    New Mode:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label-2"></InputLabel>
                                    <Select
                                        className="radius"
                                        style={{ borderRadius: '8px', backgroundColor: 'white', height: '40px' }}
                                        labelId="remote-select"
                                        value={newModeSelect}
                                        id="remote-select"
                                        //label="Filter"
                                        onChange={handleModeChange}
                                    >
                                        {options.map((item, key) => {
                                            let color = darkMode == false ? 'black' : 'white';
                                            return (
                                                <MenuItem className={darkMode ? "dropdown-light" : "dropdown-dark"} value={item} key={key} style={{ display: "flex" }} >
                                                    <MDTypography component="p" style={styles2.mdtText}>{item}</MDTypography>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '24px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    Security PIN:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4} id="pin-container">
                                <form autoComplete="off" className="radius" style={{ display: 'flex', justifyContent: 'end', height: '40px' }}>
                                    <MDInput style={styles2.pin} name="myInput" type="text" autoComplete="off" value={pinMask} onChange={(e) => { handlePinChange(e) }} />
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <MDBox style={{ display: 'flex', justifyContent: 'space-between', marginTop: '12px' }}>
                        <MDButton disabled={requestPending ? true : false} style={{ width: '35%', color: 'white', backgroundColor: requestPending ? "gray" : "#862633" }} onClick={handleClose}>Cancel</MDButton>
                        <MDButton disabled={pin == '' || newModeSelect == '' ? true : requestPending ? true : false} style={{ width: '35%', color: 'white', backgroundColor: pin == '' || newModeSelect == '' ? "gray" : requestPending ? "gray" : "#862633" }} onClick={handleModeSubmit}>ok</MDButton>
                    </MDBox>
                </MDBox >
            </>
        )
    }

    const dischargeSpeedComponent = (change) => {
        return (
            <>
                <MDBox>
                    {requestPending ?
                        <div className="remote-pending" style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0' }}>
                            <CircularProgress style={{ position: 'absolute', top: '45%', left: '45%' }} />
                        </div>
                        :
                        <></>
                    }
                    <Grid container p={'0'}>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '12px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    Current {change}:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    {change == 'Disch Moist SP' ? controlVars.disch_moist_sp : controlVars.disch_speed_sp / 10}%
                                </MDTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '3px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    New {change}:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4}>
                                <form autoComplete="new-password" className="radius" style={{ display: 'flex', justifyContent: 'end' }}>
                                    <input
                                        className="custom-input"
                                        id="dischInput"
                                        inputMode="decimal"
                                        style={styles2.input}
                                        name="myInput"
                                        type="number"
                                        min="0.00"
                                        step="0.1"
                                        max="100.00"
                                        autoComplete="off"
                                        placeholder=""
                                        value={newDischSpeedSP}
                                        onPaste={(e) => { noPaste(e) }}
                                        onKeyDown={(e) => { onlyNumberDisch(e) }}
                                        onChange={(e) => { handleDischSpeedChange(e) }}
                                        onBlur={(e) => { checkLowerDischSpeedLimit(e) }}
                                    />
                                    {/* <MDInput
                                        name="myInput"
                                        type="number"
                                        min="0.00"
                                        step="0.1"
                                        max="100.00"
                                        autoComplete="off"
                                        placeholder=""
                                        value={newDischSpeedSP}
                                        //onKeyPress={(e) => { onlyNumberDischSpeed(e) }}
                                        onChange={(e) => { handleDischSpeedChange(e) }}
                                        onBlur={(e) => { checkLowerDischSpeedLimit(e) }}
                                    /> */}
                                </form>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: '12px' }}>
                            <MDTypography component="p" style={{ textAlign: 'right', marginBottom: '12px', fontSize: '1rem' }}>({controlVars.disch_speed_sp_min / 10}%-{controlVars.disch_speed_sp_max / 10}%)</MDTypography>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '24px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    Security PIN:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4} id="pin-container">
                                <form autoComplete="off" className="radius" style={{ display: 'flex', justifyContent: 'end', height: '40px' }}>
                                    <MDInput style={styles2.pin} step={'5'} name="myInput" type="text" autoComplete="off" value={pinMask} onChange={(e) => { handlePinChange(e) }} />
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <MDBox style={{ display: 'flex', justifyContent: 'space-between', marginTop: '12px' }}>
                        <MDButton disabled={requestPending ? true : false} style={{ width: '35%', color: 'white', backgroundColor: requestPending ? "gray" : "#862633" }} onClick={handleClose}>Cancel</MDButton>
                        <MDButton disabled={pin == '' || newDischSpeedSP == '' ? true : requestPending ? true : false} style={{ width: '35%', color: 'white', backgroundColor: pin == '' || newDischSpeedSP == '' ? "gray" : requestPending ? "gray" : "#862633" }} onClick={handleDischSpeedSubmit}>ok</MDButton>
                    </MDBox>
                </MDBox >
            </>
        )
    }
    const dischargeMoistComponent = (change) => {
        return (
            <>
                <MDBox>
                    {requestPending ?
                        <div className="remote-pending" style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0' }}>
                            <CircularProgress style={{ position: 'absolute', top: '45%', left: '45%' }} />
                        </div>
                        :
                        <></>
                    }
                    <Grid container p={'0'}>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '12px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    Current {change}:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    {change == 'Disch Moist SP' ? controlVars.disch_moist_sp : controlVars.disch_speed_sp / 10}%
                                </MDTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '3px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    New {change}:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4}>
                                <form autoComplete="new-password" className="radius" style={{ display: 'flex', justifyContent: 'end', height: '40px' }}>
                                    <input
                                        inputMode="decimal"
                                        className="custom-input"
                                        id="moistInput"
                                        style={styles2.input}
                                        name="myInput"
                                        type="number"
                                        min="0.00"
                                        step="0.1"
                                        max="100.00"
                                        autoComplete="off"
                                        placeholder=""
                                        value={newDischMoistSP}
                                        onPaste={(e) => { noPaste(e) }}
                                        onKeyDown={(e) => { onlyNumberDisch(e) }}
                                        onChange={(e) => { handleDischMoistChange(e) }}
                                        onBlur={(e) => { checkLowerDischMoistLimit(e) }}
                                    />
                                    {/* <MDInput
                                        name="myInput"
                                        type="number"
                                        min={0}
                                        max={100}
                                        autoComplete="off"
                                        placeholder=""
                                        value={newDischMoistSP}
                                        onKeyPress={(e) => { onlyNumber(e) }}
                                        onChange={(e) => { handleDischMoistChange(e) }}
                                        onBlur={(e) => { checkLowerDischMoistLimit(e) }}
                                    /> */}
                                </form>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: '12px' }}>
                            <MDTypography component="p" style={{ textAlign: 'right', marginBottom: '12px', fontSize: '1rem' }}>(0%-100%)</MDTypography>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '24px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    Security PIN:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4} id="pin-container">
                                <form autoComplete="off" className="radius" style={{ display: 'flex', justifyContent: 'end', height: '40px' }}>
                                    <MDInput style={styles2.pin} name="myInput" type="text" autoComplete="off" value={pinMask} onChange={(e) => { handlePinChange(e) }} />
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <MDBox style={{ display: 'flex', justifyContent: 'space-between', marginTop: '12px' }}>
                        <MDButton disabled={requestPending ? true : false} style={{ width: '35%', color: 'white', backgroundColor: requestPending ? "gray" : "#862633" }} onClick={handleClose}>Cancel</MDButton>
                        <MDButton disabled={pin == '' || newDischMoistSP == '' ? true : requestPending ? true : false} style={{ width: '35%', color: 'white', backgroundColor: pin == '' || newDischMoistSP == '' ? "gray" : requestPending ? "gray" : "#862633" }} onClick={handleDischMoistSubmit}>ok</MDButton>
                    </MDBox>
                </MDBox >
            </>
        )
    }

    const midGrainComponent = (change) => {
        return (
            <>
                <MDBox>
                    {requestPending ?
                        <div className="remote-pending" style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0' }}>
                            <CircularProgress style={{ position: 'absolute', top: '45%', left: '45%' }} />
                        </div>
                        :
                        <></>
                    }
                    <Grid container p={'0'}>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '12px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    {
                                        change == 'Bot Plenum SP' ? 'Current Bot Plenum SP:' :
                                            change == 'Top Plenum SP' ? 'Current Top Plenum SP:' :
                                                change == 'plenum01' ? 'Current plenum #1 SP:' :
                                                    change == 'plenum02' ? 'Current plenum #2 SP:' :
                                                        change == 'plenum03' ? 'Current plenum #3 SP:' :
                                                            change == 'plenum04' ? 'Current plenum #4 SP:' :
                                                                change == 'plenum05' ? 'Current plenum #5 SP:' :
                                                                    change == 'plenum06' ? 'Current plenum #6 SP:' :
                                                                        change == 'plenum07' ? 'Current plenum #7 SP:' :
                                                                            change == 'plenum08' ? 'Current plenum #8 SP:' : 'Current Mid-Grain SP:'
                                    }
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    {
                                        change == 'Bot Plenum SP' ? controlVars?.plenum02 :
                                            change == 'Top Plenum SP' ? controlVars?.plenum01 :
                                                change == 'plenum01' ? controlVars?.plenum01 :
                                                    change == 'plenum02' ? controlVars?.plenum02 :
                                                        change == 'plenum03' ? controlVars?.plenum03 :
                                                            change == 'plenum04' ? controlVars?.plenum04 :
                                                                change == 'plenum05' ? controlVars?.plenum05 :
                                                                    change == 'plenum06' ? controlVars?.plenum06 :
                                                                        change == 'plenum07' ? controlVars?.plenum07 :
                                                                            change == 'plenum08' ? controlVars?.plenum08 : controlVars?.midtemp_SP
                                    }
                                    &deg;
                                </MDTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '3px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    {
                                        change == 'Bot Plenum SP' ? 'New Bot Plenum SP: ' :
                                            change == 'Top Plenum SP' ? 'New Top Plenum SP: ' :
                                                change == 'plenum01' ? 'New plenum #1 SP: ' :
                                                    change == 'plenum02' ? 'New plenum #2 SP: ' :
                                                        change == 'plenum03' ? 'New plenum #3 SP: ' :
                                                            change == 'plenum04' ? 'New plenum #4 SP: ' :
                                                                change == 'plenum05' ? 'New plenum #5 SP: ' :
                                                                    change == 'plenum06' ? 'New plenum #6 SP: ' :
                                                                        change == 'plenum07' ? 'New plenum #7 SP: ' :
                                                                            change == 'plenum08' ? 'New plenum #8 SP: ' : 'New Mid-Grain SP: '
                                    }
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4}>
                                <form autoComplete="new-password" className="radius" style={{ display: 'flex', justifyContent: 'end', height: '40px', width: '100%' }}>
                                    <MDInput
                                        name="myInput"
                                        style={{ width: '100%' }}
                                        type="number"
                                        min={0}
                                        max={200}
                                        autoComplete="off"
                                        placeholder=""
                                        value={newMidgrainSP}
                                        onKeyPress={(e) => { onlyNumber(e) }}
                                        onChange={(e) => { handleMidGrainChange(e) }}
                                        onBlur={(e) => { checkLowerMidGrainLimit(e) }}
                                    />
                                </form>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: '12px' }}>
                            <MDTypography component="p" style={{ textAlign: 'right', marginBottom: '12px', fontSize: '1rem' }}>(0&deg; - 200&deg;)</MDTypography>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '24px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    Security PIN:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4} id="pin-container">
                                <form autoComplete="off" className="radius" style={{ display: 'flex', justifyContent: 'end', height: '40px' }}>
                                    <MDInput style={styles2.pin} name="myInput" type="text" autoComplete="off" value={pinMask} onChange={(e) => { handlePinChange(e) }} />
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <MDBox style={{ display: 'flex', justifyContent: 'space-between', marginTop: '12px' }}>
                        <MDButton disabled={requestPending ? true : false} style={{ width: '35%', color: 'white', backgroundColor: requestPending ? "gray" : "#862633" }} onClick={handleClose}>Cancel</MDButton>
                        <MDButton disabled={pin == '' || newMidgrainSP == '' ? true : requestPending ? true : false} style={{ width: '35%', color: 'white', backgroundColor: pin == '' || newMidgrainSP == '' ? "gray" : requestPending ? "gray" : "#862633" }} onClick={handleMidGrainSubmit}>ok</MDButton>
                    </MDBox>
                </MDBox >
            </>
        )
    }

    const plenumComponent = (change) => {
        return (
            <>
                <MDBox>
                    {requestPending ?
                        <div className="remote-pending" style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0' }}>
                            <CircularProgress style={{ position: 'absolute', top: '45%', left: '45%' }} />
                        </div>
                        :
                        <></>
                    }
                    <Grid container p={'0'}>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '12px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    {
                                        change == 'Bot Plenum SP' ? 'Current Bot Plenum SP:' :
                                            change == 'Top Plenum SP' ? 'Current Top Plenum SP:' :
                                                change == 'plenum01' ? 'Current plenum #1 SP:' :
                                                    change == 'plenum02' ? 'Current plenum #2 SP:' :
                                                        change == 'plenum03' ? 'Current plenum #3 SP:' :
                                                            change == 'plenum04' ? 'Current plenum #4 SP:' :
                                                                change == 'plenum05' ? 'Current plenum #5 SP:' :
                                                                    change == 'plenum06' ? 'Current plenum #6 SP:' :
                                                                        change == 'plenum07' ? 'Current plenum #7 SP:' :
                                                                            change == 'plenum08' ? 'Current plenum #8 SP:' : 'Current Mid-Grain SP:'
                                    }
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    {
                                        change == 'Bot Plenum SP' ? controlVars?.plenum01 :
                                            change == 'Top Plenum SP' ? controlVars?.plenum02 :
                                                change == 'plenum01' ? controlVars?.plenum01 :
                                                    change == 'plenum02' ? controlVars?.plenum02 :
                                                        change == 'plenum03' ? controlVars?.plenum03 :
                                                            change == 'plenum04' ? controlVars?.plenum04 :
                                                                change == 'plenum05' ? controlVars?.plenum05 :
                                                                    change == 'plenum06' ? controlVars?.plenum06 :
                                                                        change == 'plenum07' ? controlVars?.plenum07 :
                                                                            change == 'plenum08' ? controlVars?.plenum08 : controlVars?.midtemp_SP
                                    }
                                    &deg;
                                </MDTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '3px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    {
                                        change == 'Bot Plenum SP' ? 'New Bot Plenum SP: ' :
                                            change == 'Top Plenum SP' ? 'New Top Plenum SP: ' :
                                                change == 'plenum01' ? 'New plenum #1 SP: ' :
                                                    change == 'plenum02' ? 'New plenum #2 SP: ' :
                                                        change == 'plenum03' ? 'New plenum #3 SP: ' :
                                                            change == 'plenum04' ? 'New plenum #4 SP: ' :
                                                                change == 'plenum05' ? 'New plenum #5 SP: ' :
                                                                    change == 'plenum06' ? 'New plenum #6 SP: ' :
                                                                        change == 'plenum07' ? 'New plenum #7 SP: ' :
                                                                            change == 'plenum08' ? 'New plenum #8 SP: ' : 'New Mid-Grain SP: '
                                    }
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4}>
                                <form autoComplete="new-password" className="radius" style={{ display: 'flex', justifyContent: 'end', height: '40px' }}>
                                    <MDInput
                                        className="plenum-input"
                                        name="myInput"
                                        type="number"
                                        min={0}
                                        max={220}
                                        autoComplete="off"
                                        placeholder=""
                                        value={newPlenumSP}
                                        onKeyPress={(e) => { onlyNumber(e) }}
                                        onChange={(e) => { handlePlenumChange(e, change) }}
                                        onBlur={(e) => { checkLowerPlenumLimit(e) }}
                                    />

                                </form>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: '12px' }}>
                            <MDTypography component="p" style={{ textAlign: 'right', marginBottom: '12px', fontSize: '1rem' }}>(0&deg; - 220&deg;)</MDTypography>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', paddingBottom: '24px' }}>
                            <Grid item xs={8} style={styles2.mdtItem}>
                                <MDTypography component="p" style={styles2.mdtText}>
                                    Security PIN:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={4} id="pin-container">
                                <form autoComplete="off" className="radius" style={{ display: 'flex', justifyContent: 'end', height: '40px' }}>
                                    <MDInput style={styles2.pin} name="myInput" type="text" autoComplete="off" value={pinMask} onChange={(e) => { handlePinChange(e) }} />
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    <MDBox style={{ display: 'flex', justifyContent: 'space-between', marginTop: '12px' }}>
                        <MDButton disabled={requestPending ? true : false} style={{ width: '35%', color: 'white', backgroundColor: requestPending ? "gray" : "#862633" }} onClick={handleClose}>Cancel</MDButton>
                        <MDButton disabled={pin == '' || newPlenumSP == '' ? true : requestPending ? true : false} style={{ width: '35%', color: 'white', backgroundColor: pin == '' || newPlenumSP == '' ? "gray" : requestPending ? "gray" : "#862633" }} onClick={handlePlenumSubmit}>ok</MDButton>
                    </MDBox>
                </MDBox >
            </>
        )
    }

    return (
        <>
            <MDBox>
                <Modal
                    style={styles2.modal}
                    open={childOpen}
                    // onClose={handleChildClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <MDBox className="remote-sub-modal" sx={styles2.style2}>
                        <MDBox>
                            <Grid container p={0} style={{ justifyContent: 'space-between' }}>
                                <Grid item xs={3}>
                                    <MDBox style={{ display: 'flex', maxHeight: '55px' }}>
                                        <MDBox component="img" src={darkMode ? mctrax_logo_white : mctrax_logo} style={{ maxHeight: '55px', objectFit: 'contain', height: '100%' }} />
                                    </MDBox>
                                </Grid>
                                <Grid item xs={9} style={{ display: 'flex', justifyContent: 'right' }}>
                                    <MDBox style={{ alignSelf: 'end' }}>
                                        <MDTypography coponent="p">
                                            Remote Control
                                        </MDTypography>
                                    </MDBox>
                                </Grid>
                            </Grid>
                            <MDBox
                                display={{ xs: "block" }}
                                position="absolute"
                                top={0}
                                right={0}
                                p={1.625}
                                onClick={handleClose}
                                sx={{ cursor: "pointer" }}
                            >
                                <MDTypography variant="h6" color="secondary" style={{ fontSize: '22px' }}>
                                    <Icon className="button-hover" sx={{ fontWeight: "bold", fontSize: '22px' }} style={{ color: darkMode ? "white" : "black" }}>close</Icon>
                                </MDTypography>
                            </MDBox>
                            <Divider />
                        </MDBox>
                        {/* <MDTypography component="h2" id="child-modal-title">{change}</MDTypography> */}
                        {change == 'Dryer State' ? stateComponent(change) :
                            change == 'Disch Mode' ? modeComponent(change) :
                                change == 'Disch Speed SP' ? dischargeSpeedComponent(change) :
                                    change == 'Disch Moist SP' ? dischargeMoistComponent(change) :
                                        change == 'Mid-Grain SP' ? midGrainComponent(change) :
                                            plenumComponent(change)
                        }
                        <MDTypography component="p" style={{ fontSize: '14px', color: 'orange', marginTop: '24px' }}>{warning}</MDTypography>
                    </MDBox>
                </Modal>
            </MDBox>
        </>
    );
}

ChildModal.propTypes = {
    change: PropTypes.string,
    childOpen: PropTypes.bool,
    handleChildOpen: PropTypes.func,
    displaySuccess: PropTypes.func,
    displayFail: PropTypes.func,
    handleChildClose: PropTypes.func,
    getControlVars: PropTypes.func,
    controlVars: PropTypes.object,
};

function remoteCard({ handleModal, open }) {
    const [controller, dispatch] = useMaterialUIController();
    const axiosPrivate = useAxiosPriavte();
    const [childOpen, setChildOpen] = React.useState(false);
    const [change, setChange] = useState();
    const [remoteError, setRemoteError] = useState('');

    const [responseAcitve, setResponseActive] = useState('');
    const [responseColor, setResponseColor] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    const handleChildOpen = (change) => {
        setChange(change);
        setChildOpen(true);
    };
    const handleChildClose = () => {
        setChildOpen(false);
    };

    const {
        darkMode,
        auth,
        dryerData,
        dryerDataIndex
    } = controller;

    const displaySuccess = (message) => {
        setResponseActive(true);
        setResponseColor("success");
        setResponseMessage(message);
    }
    const displayFail = (message) => {
        setResponseActive(true);
        setResponseColor("error");
        setResponseMessage(message);
    }

    useEffect(() => {

        if (responseAcitve) {
            setTimeout(function () {
                setResponseActive(false);
                setResponseMessage('');
                setResponseColor('');
            }, 10000);
        }

    }, [responseAcitve]);

    const [controlVars, setControlVars] = useState({});
    const logout = useLogout();
    const getControlVars = async () => {

        try {

            //set serial and dataIndex before update
            let record_id = dryerData[dryerDataIndex]?.Dryer_Record_ID
            let control_type = dryerData[dryerDataIndex]?.runData?.control_type + 1

            if (auth?.accessToken) {

                const response = await axiosPrivate.post('/getControlVars', JSON.stringify({ record_id, control_type }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: true
                    });

                if (response.status == 200) {

                    setControlVars(response.data[0]);

                } else {

                    setRemoteError('Unable to load remote data, please try again later');
                }

            }

        } catch (e) {
            setRemoteError('Unable to load remote data, please try again later');
            if (e.response.status == 403 || e.response.status == 401) {
                await logout();
            } else {
                console.log(e);
            }
        }
    };

    useEffect(() => {
        if (open) {

            // some of this might not be needed - preventing swipe down to refresh on mobile
            document.getElementsByTagName('html')[0].style.overflow = "hidden";
            document.getElementsByTagName('html')[0].style.overscrollBehaviorY = 'contain';
            document.getElementById('app').style.overflowY = 'hidden';
            document.getElementById('app').style.overflowX = 'hidden';
            document.getElementById('app').style.overscrollBehaviorY = 'contain';
            document.getElementById('app')

            //getControlVarsPHP();
            if (dryerData[dryerDataIndex].Online_Status == 1) {
                getControlVars();
            }

        } else {
            document.getElementsByTagName('html')[0].style.overflow = "auto";
            document.getElementById('app').style.overflowY = 'auto';
            document.getElementById('app').style.overflowX = 'hidden';
            handleChildClose();
        }
    }, [open])

    let online = dryerData[dryerDataIndex]?.Online_Status;
    let sub = dryerData[dryerDataIndex]?.Sub_Status;
    let serial = dryerData[dryerDataIndex]?.Dryer_Serial_Name;
    let model = dryerData[dryerDataIndex]?.Dryer_Model_Name;
    let no_burn = dryerData[dryerDataIndex]?.plenumData?.No_Of_Burners;
    let op_mode = dryerData[dryerDataIndex]?.runData?.Op_Mode;
    let dryer_state = dryerData[dryerDataIndex].Operating_Mode > 0 ? 'Running' : 'Stopped';

    const styles = {

        mdtContainer: {
            display: "flex",
            justifyContent: 'space-between',
            marginBottom: '6px',
            marginTop: '6px',

        },
        mdtText: {
            fontSize: '1rem',
        },
        mdtItem: {
            textAlign: 'left',
            alignSelf: 'center'
        },
        modal: {
            //position: 'relative',
            overflow: 'auto',
            //height: '100dvh',
            overscrollBehaviorY: 'contain',
        },
        style: {
            position: 'absolute',
            // top: '50%',
            // left: '50%',
            transform: 'translate(-50%, -50%)',
            //height: 500,
            bgcolor: darkMode ? '#141414' : '#E6E6E6',
            border: darkMode ? '2px solid #fff' : '2px solid #000',
            boxShadow: 24,
            borderRadius: '8px',
            pt: 5,
            px: 2,
            pb: 4,
            overflow: 'hidden',
            overscrollBehaviorY: 'contain',
        },

    };

    const getPlenums = () => {
        let arr = []
        for (let i = no_burn; i > 0; i--) {
            let plenum_number = 'plenum0' + i;
            let name = controlVars[plenum_number]

            arr.push(
                <MDBox key={plenum_number} style={styles.mdtContainer}>
                    <Grid container p={0}>
                        <Grid item xs={6} style={styles.mdtItem}>
                            <MDTypography style={styles.mdtText} component="p">Plenum #{i} SP: </MDTypography>
                        </Grid>
                        <Grid item xs={3} style={styles.mdtItem}>
                            <MDTypography style={styles.mdtText} component="p">{name}&deg;</MDTypography>
                        </Grid>
                        <Grid item xs={3} style={{ textAlign: 'right', alignSelf: 'center' }} >
                            <MDButton color="primary" size="small" style={{ color: 'white' }} onClick={() => { handleChildOpen(plenum_number) }}>change</MDButton>
                        </Grid>
                    </Grid>
                </MDBox>
            )
        }
        return arr;
    }

    let errorAlertBgColor = darkMode ? "rgb(22, 11, 11)" : "rgb(253, 237, 237)";
    let successAlertBgColor = darkMode ? "rgb(12, 19, 13)" : "rgb(237, 247, 237)";

    return (
        <>
            {controlVars.midtemp_SP != undefined && online == 1 && sub == 1 && dryer_state != 'Stopped' && controlVars.enable_remote_access != "0" && controlVars.enable_remote_access != undefined ?
                <>
                    <Modal
                        open={open}
                        onClose={handleModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={styles.modal}
                    >
                        <MDBox className="remote-modal" sx={styles.style}>
                            <MDBox>
                                <Grid container p={0} style={{ justifyContent: 'space-between' }}>
                                    <Grid item xs={3}>
                                        <MDBox style={{ display: 'flex', maxHeight: '55px' }}>
                                            <MDBox component="img" src={darkMode ? mctrax_logo_white : mctrax_logo} style={{ maxHeight: '55px', objectFit: 'contain', height: '100%' }} />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={9} style={{ display: 'flex', justifyContent: 'right' }}>
                                        <MDBox style={{ alignSelf: 'end' }}>
                                            <MDTypography coponent="p">
                                                Remote Control
                                            </MDTypography>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                                <MDBox
                                    display={{ xs: "block" }}
                                    position="absolute"
                                    top={0}
                                    right={0}
                                    p={1.625}
                                    onClick={handleModal}
                                    sx={{ cursor: "pointer" }}
                                >
                                    <MDTypography variant="h6" color="secondary" style={{ fontSize: '22px' }}>
                                        <Icon className="button-hover" sx={{ fontWeight: "bold" }} style={{ color: darkMode ? "white" : "black" }}>close</Icon>
                                    </MDTypography>
                                </MDBox>
                                <Divider />
                                {/* {responseAcitve ? <MDAlert color={responseColor} style={{ fontSize: '16px', marginBottom: '12px', width: '100%', borderRadius: '8px' }}>{responseMessage}</MDAlert> : null} */}
                                {/* {responseAcitve ? <Alert variant="outlined" severity={responseColor} >
                                    {responseMessage}
                                </Alert> : null} */}
                                {responseAcitve ?
                                    <Alert
                                        variant="outlined"
                                        severity={responseColor}
                                        style={{
                                            marginTop: '12px',
                                            marginBottom: '12px',
                                            borderRadius: '8px',
                                            color: responseColor == "error" ? "#F44335" : "#41C330",
                                            backgroundColor: responseColor == "error" ? errorAlertBgColor : successAlertBgColor
                                        }}>
                                        {responseMessage}
                                    </Alert> : null}
                            </MDBox>
                            <MDBox style={{ textAlign: 'center' }}>
                                <MDBox style={styles.mdtContainer}>
                                    <MDTypography style={styles.mdtText} component="p">Serial: </MDTypography>
                                    <MDTypography style={styles.mdtText} component="p">{serial}</MDTypography>
                                </MDBox>
                                <MDBox style={styles.mdtContainer}>
                                    <Grid container p={0}>
                                        <Grid item xs={6} style={styles.mdtItem}>
                                            <MDTypography style={styles.mdtText} component="p">Dryer State: </MDTypography>
                                        </Grid>
                                        <Grid item xs={3} style={styles.mdtItem}>
                                            <MDTypography style={styles.mdtText} component="p">{dryer_state}</MDTypography>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                                            <MDButton color="primary" size="small" style={{ color: 'white' }} onClick={() => { handleChildOpen("Dryer State") }}>change</MDButton>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                                <MDBox style={styles.mdtContainer}>
                                    <Grid container p={0}>
                                        <Grid item xs={6} style={styles.mdtItem}>
                                            <MDTypography style={styles.mdtText} component="p">Disch Mode: </MDTypography>
                                        </Grid>
                                        <Grid item xs={3} style={styles.mdtItem}>
                                            <MDTypography style={styles.mdtText} component="p">
                                                {
                                                    controlVars.disch_mode == "1" ? "Manual" :
                                                        controlVars.disch_mode == "2" ? "TruDry" :
                                                            controlVars.disch_mode == "3" ? "AccuDry" : "Stopped"
                                                }
                                            </MDTypography>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'right', alignSelf: 'center' }}>
                                            <MDButton color="primary" size="small" style={{ color: 'white' }} onClick={() => { handleChildOpen("Disch Mode") }}>change</MDButton>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                                <MDBox style={styles.mdtContainer}>
                                    <Grid container p={0}>
                                        <Grid item xs={6} style={styles.mdtItem}>
                                            <MDTypography style={styles.mdtText} component="p">Disch Speed SP: </MDTypography>
                                        </Grid>
                                        {op_mode != "TruDry" ?
                                            <>
                                                <Grid item xs={3} style={styles.mdtItem}>
                                                    <MDTypography style={styles.mdtText} component="p">{controlVars.disch_speed_sp / 10}%</MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                    <MDButton color="primary" size="small" style={{ color: 'white' }} onClick={() => { handleChildOpen("Disch Speed SP") }}>change</MDButton>
                                                </Grid>
                                            </>
                                            :
                                            <>
                                                <Grid item xs={6} style={styles.mdtItem}>
                                                    <MDTypography style={styles.mdtText} component="p">N/A</MDTypography>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </MDBox>
                                <MDBox style={styles.mdtContainer}>
                                    <Grid container p={0}>
                                        <Grid item xs={6} style={styles.mdtItem}>
                                            <MDTypography style={styles.mdtText} component="p">Disch Moist SP: </MDTypography>
                                        </Grid>
                                        <Grid item xs={3} style={styles.mdtItem}>
                                            <MDTypography style={styles.mdtText} component="p">{controlVars.disch_moist_sp}%</MDTypography>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'right', alignSelf: 'center' }}>
                                            <MDButton color="primary" size="small" style={{ color: 'white' }} onClick={() => { handleChildOpen("Disch Moist SP") }}>change</MDButton>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                                <MDBox style={styles.mdtContainer}>
                                    <Grid container p={0}>
                                        <Grid item xs={6} style={styles.mdtItem}>
                                            <MDTypography style={styles.mdtText} component="p">Mid-Grain SP: </MDTypography>
                                        </Grid>
                                        <Grid item xs={3} style={styles.mdtItem}>
                                            <MDTypography style={styles.mdtText} component="p">{controlVars.midtemp_SP}&deg;</MDTypography>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'right', alignSelf: 'center' }}>
                                            <MDButton color="primary" size="small" style={{ color: 'white' }} onClick={() => { handleChildOpen("Mid-Grain SP") }}>change</MDButton>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                                {model.charAt(0).toLowerCase() == "d" ?
                                    <>
                                        <MDBox style={styles.mdtContainer}>
                                            <Grid container p={0}>
                                                <Grid item xs={6} style={styles.mdtItem}>
                                                    <MDTypography style={styles.mdtText} component="p">Top Plenum SP: </MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={styles.mdtItem}>
                                                    <MDTypography style={styles.mdtText} component="p">{controlVars.plenum02}&deg;</MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                    <MDButton color="primary" size="small" style={{ color: 'white' }} onClick={() => { handleChildOpen("Top Plenum SP") }}>change</MDButton>
                                                </Grid>
                                            </Grid>
                                        </MDBox>
                                        <MDBox style={styles.mdtContainer}>
                                            <Grid container p={0}>
                                                <Grid item xs={6} style={styles.mdtItem}>
                                                    <MDTypography style={styles.mdtText} component="p">Bot Plenum SP: </MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={styles.mdtItem}>
                                                    <MDTypography style={styles.mdtText} component="p">{controlVars.plenum01}&deg;</MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={{ textAlign: 'right', alignSelf: 'center' }}>
                                                    <MDButton color="primary" size="small" style={{ color: 'white' }} onClick={() => { handleChildOpen("Bot Plenum SP") }}>change</MDButton>
                                                </Grid>
                                            </Grid>
                                        </MDBox>
                                    </>
                                    :
                                    <>
                                        {getPlenums()}

                                    </>
                                }
                            </MDBox>
                            <ChildModal
                                handleChildOpen={handleChildOpen}
                                handleChildClose={handleChildClose}
                                displaySuccess={displaySuccess}
                                displayFail={displayFail}
                                getControlVars={getControlVars}
                                childOpen={childOpen}
                                change={change}
                                controlVars={controlVars} />
                        </MDBox>
                    </Modal >
                </>
                :
                <>
                    <Modal
                        style={styles.modal}
                        open={open}
                        onClose={handleModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <MDBox className="remote-modal" sx={styles.style}>
                            <MDBox>
                                <Grid container p={0} style={{ justifyContent: 'space-between' }}>
                                    <Grid item xs={3}>
                                        <MDBox style={{ display: 'flex', maxHeight: '55px' }}>
                                            <MDBox component="img" src={darkMode ? mctrax_logo_white : mctrax_logo} style={{ maxHeight: '55px', objectFit: 'contain', height: '100%' }} />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={9} style={{ display: 'flex', justifyContent: 'right' }}>
                                        <MDBox style={{ alignSelf: 'end' }}>
                                            <MDTypography coponent="p">
                                                Remote Control
                                            </MDTypography>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                                <MDBox
                                    display={{ xs: "block" }}
                                    position="absolute"
                                    top={0}
                                    right={0}
                                    p={1.625}
                                    onClick={handleModal}
                                    sx={{ cursor: "pointer" }}
                                >
                                    <MDTypography variant="h6" color="secondary" style={{ fontSize: '22px' }}>
                                        <Icon className="button-hover" sx={{ fontWeight: "bold", fontSize: '24px' }} style={{ color: darkMode ? "white" : "black" }}>close</Icon>
                                    </MDTypography>
                                </MDBox>
                                <Divider />
                            </MDBox>
                            <MDBox style={{ textAlign: 'center' }}>
                                {online == 1 && sub == 1 && dryer_state != 'Stopped' && controlVars.enable_remote_access == "1" || controlVars.enable_remote_access == undefined ?
                                    <>
                                        {remoteError ?
                                            <><MDTypography component="p">{remoteError}</MDTypography></>
                                            :
                                            <><div style={{ display: 'flex', justifyContent: 'center', paddingTop: '15px' }}><CircularProgress /></div></>
                                        }

                                    </>
                                    :
                                    <>
                                        {
                                            controlVars.enable_remote_access == "1" ?
                                                <>
                                                    {online == 1 && sub == 0 ?
                                                        <MDTypography component="p">Subscription Expired</MDTypography> :
                                                        online == 1 && dryer_state == 'Stopped' ?
                                                            <MDTypography component="p">Dryer Stopped</MDTypography> :
                                                            <MDTypography component="p">Dryer Offline</MDTypography>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <MDTypography component="p">Remote control is disabled. Please check the remote enable checkbox on the HMI</MDTypography>
                                                </>
                                        }
                                    </>
                                }
                            </MDBox>
                            <ChildModal
                                handleChildOpen={handleChildOpen}
                                handleChildClose={handleChildClose}
                                displaySuccess={displaySuccess}
                                getControlVars={getControlVars}
                                displayFail={displayFail}
                                childOpen={childOpen} change={change}
                                controlVars={controlVars} />
                        </MDBox>
                    </Modal >
                </>
            }
        </>
    );
}

// Typechecking props for the DryerCard3
remoteCard.propTypes = {
    open: PropTypes.bool,
    handleModal: PropTypes.func,
    change: PropTypes.string,
};


export default remoteCard;
