/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import '../../App.css';

// @mui material components
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "examples/Sidenav/styles/sidenavCollapse";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";

function SidenavCollapse({ icon_online, online, icon_running, running, icon_sub, sub, serial, name, model, active, ...rest }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

  // console.log(active)
  let running_color = running ? '#41C330' : active ? 'darkgray' : 'gray';
  let online_color = online ? '#41C330' : active ? 'darkgray' : 'gray';
  let sub_color = sub ? '#41C330' : active ? 'darkgray' : 'gray';

  //console.log(active)

  return (
    <ListItem component="li">


      <MDBox
        {...rest}
        sx={(theme) =>
          collapseItem(theme, {
            active,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            sidenavColor,
          })
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <Grid item xs={12} md={12} lg={12} style={{ display: "flex" }}>
              <ListItemText
                className="listItemText"
                primary={name}
                style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', color:'white'  }}
                sx={(theme) =>
                  collapseText(theme, {
                    miniSidenav,
                    transparentSidenav,
                    whiteSidenav,
                    active,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} style={{ display: "flex" }}>

              <ListItemText
                style={{display: 'flex'}}
                disableTypography
                className="listItemText"
                secondary={<MDTypography style={{fontSize:'11px', color: active ? 'white' : darkMode ? 'white' : 'white'}}> {serial + "\xa0" + ' | ' + "\xa0" + model}</MDTypography>}
                sx={(theme) =>
                  collapseText(theme, {
                    miniSidenav,
                    transparentSidenav,
                    whiteSidenav,
                    active,
                  })
                }
              />
              <MDBox style={{ display: 'flex', alignSelf: 'end', paddingBottom: '3px' }}>
                <ListItemIcon style={{ display: 'flex', justifyContent: 'right', minWidth: 'unset', minHeight: 'unset', color: online ? '#41C330' : 'gray' }}
                  sx={(theme) =>
                    collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active, online })
                  }
                >
                  {typeof icon_online === "string" ? (
                    <Icon sx={(theme) => collapseIcon(theme, { active, online })}>{icon_online}</Icon>
                  ) : (
                    icon_online
                  )}
                </ListItemIcon>
                <ListItemIcon style={{ display: 'flex', justifyContent: 'right', minWidth: 'unset', minHeight: 'unset', color: running ? '#41C330' : 'gray' }}
                  sx={(theme) =>
                    collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active, running })
                  }
                >
                  {typeof icon_running === "string" ? (
                    <Icon sx={(theme) => collapseIcon(theme, { active, running})}>{icon_running}</Icon>
                  ) : (
                    icon_running
                  )}
                </ListItemIcon>
                <ListItemIcon style={{ display: 'flex', justifyContent: 'right', minWidth: 'unset', minHeight: 'unset', color: sub ? '#41C330' : 'gray' }}
                  sx={(theme) =>
                    collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active, sub })
                  }
                >
                  {typeof icon_sub === "string" ? (
                    <Icon sx={(theme) => collapseIcon(theme, { active, sub })}>{icon_sub}</Icon>
                  ) : (
                    icon_sub
                  )}
                </ListItemIcon>
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>

    </ListItem>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
  active: false,
};

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  icon_online: PropTypes.node,
  icon_running: PropTypes.node,
  icon_sub: PropTypes.node,
  online: PropTypes.bool,
  running: PropTypes.bool,
  sub: PropTypes.bool,
  serial: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavCollapse;
