/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard7 from "layouts/dashboard7";
import Admin from "layouts/admin";
import SignIn from "layouts/authentication/sign-in";
import Tooltip from '@mui/material/Tooltip';

// @mui icons
import Icon from "@mui/material/Icon";

import React, { useEffect, useState } from "react";

import Missing from "components/Missing";
import constants from "./constants";

let singleArr = [];
let multiArr = [];

const testCreateSingleRoutes = (data) => {

  let singleArr2 = []

  if (data) {
    let rows = data;
    let count = 0;
    Object.keys(rows).forEach(function (key, i) {

      while (count < rows.length) {

        let sub = rows[count]?.Sub_Status == 1 ? true : false;
        let running = rows[count]?.Operating_Mode != 0  ? true : false;
        let online = rows[count]?.Online_Status == 1 ? true : false;
        //console.log(rows[count])

        let online_tooltip = online ? 'Online' : 'Offline';
        let running_tooltip = running ? 'Running' : 'Stopped'
        let sub_tooltip = sub ? 'Valid' : 'Expired'

        singleArr2.push(
          {
            type: "collapse",
            serial: rows[count].Dryer_Serial_Name,
            name: rows[count].BUSINESS == null ? '' : rows[count].BUSINESS,
            model: rows[count].Dryer_Model_Name.toString(),
            key: rows[count].Dryer_Serial_Name,
            test: rows[count],
            online: online,
            sub: sub,
            running: running,
            icon_online: <Tooltip title={online_tooltip} placement="bottom"><Icon fontSize="10px" style={{ fontSize: '16px' }}>public</Icon></Tooltip>,
            icon_running: <Tooltip title={running_tooltip} placement="bottom"><Icon fontSize="10px" style={{ fontSize: '16px' }}>settings</Icon></Tooltip>,
            icon_sub: <Tooltip title={sub_tooltip} placement="bottom"><Icon fontSize="10px" style={{ fontSize: '16px' }}>credit_card</Icon></Tooltip>,
            route: "/dashboard",
            component: <Dashboard7  />,
          }
        );
        count++;
      }
      //serial={rows[count].Dryer_Serial_Name} model={rows[count].Dryer_Model_Name.toString()} name={rows[count].BUSINESS}
    });
    return singleArr2;
  }
};

const createSingleRoutes = (data) => {

  if (data) {
    let rows = data;
    let count = 0;
    Object.keys(rows).forEach(function (key, i) {

      while (count < rows.length) {

        let sub = rows[count].Sub_Status == 1 ? true : false;
        let running = rows[count].Online_Status == 1 ? false : false
        let online = rows[count].Online_Status == 1 ? true : false;

        singleArr.push(
          {
            type: "collapse",
            serial: rows[count].Dryer_Serial_Name,
            name: rows[count].BUSINESS,
            model: rows[count].Dryer_Model_Name.toString(),
            key: rows[count].Dryer_Serial_Name,
            online: online,
            sub: sub,
            running: running,
            icon_online: <Icon fontSize="10px" style={{ fontSize: '16px' }}>public</Icon>,
            icon_running: <Icon fontSize="10px" style={{ fontSize: '16px' }}>settings</Icon>,
            icon_sub: <Icon fontSize="10px" style={{ fontSize: '16px' }}>credit_card</Icon>,
            route: "/dashboard",
            component: <Dashboard7 serial={rows[count].Dryer_Serial_Name} model={rows[count].Dryer_Model_Name.toString()} name={rows[count].BUSINESS} />,
          }
        );
        count++;
      }
    });
    return singleArr;
  }
};

let routes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Missing",
    key: "missing",
    icon: <Icon fontSize="small">login</Icon>,
    route: "*",
    component: <Missing />,
  },

]

const adminRoutes = [
  {
    type: "collapse",
    name: "Dryer List",
    key: "dryer_list",
    // icon: <Icon fontSize="small">security</Icon>,
    icon: <Icon fontSize="small">security</Icon>,
    route: "/admin/dryer_list",
    component: <Admin />,
  },
  {
    type: "collapse",
    name: "MCTrax Users",
    key: "mctrax_users",
    icon: <Icon fontSize="small">security</Icon>,
    route: "/admin/mctrax_users",
    component: <Admin />,
  },
  // {
  //   type: "collapse",
  //   name: "route 3",
  //   key: "admin_3",
  //   icon: <Icon fontSize="small">security</Icon>,
  //   route: "/admin/admin_3",
  //   component: <Admin />,
  // },
  // {
  //   type: "collapse",
  //   name: "route 4",
  //   key: "admin_4",
  //   icon: <Icon fontSize="small">security</Icon>,
  //   route: "/admin/admin_4",
  //   component: <Admin />,
  // },
  // {
  //   type: "collapse",
  //   name: "route 5",
  //   key: "admin_5",
  //   icon: <Icon fontSize="small">security</Icon>,
  //   route: "/admin/admin_5",
  //   component: <Admin />,
  // },
  {
    type: "collapse",
    name: "Feedback",
    key: "feedback",
    icon: <Icon fontSize="small">security</Icon>,
    route: "/admin/feedback",
    component: <Admin />,
  },

];

export {routes, adminRoutes, testCreateSingleRoutes};
