/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import axios from "../../../../api/axios";
import plenum_temp_svg from '../../../../assets/images/mc/plenum-temp.svg';
import React, { useEffect, useState } from "react";

import { useMaterialUIController } from "context";

export default function data() {

  const [controller, dispatch] = useMaterialUIController();
  const {
    dryerData,
  } = controller;

  return {
    columns1: [
      { Header: "Time", accessor: "time", align: "left" },
      { Header: "Avg", accessor: "avg", align: "center" },
      { Header: "SP", accessor: "sp", align: "center" },
      { Header: "#1", accessor: "m1", align: "center" },
      { Header: "#2", accessor: "m2", align: "center" },
      { Header: "#3", accessor: "m3", align: "center" },
      { Header: "#4", accessor: "m4", align: "center" },
    ],
    columns2: [
      { Header: "Time", accessor: "time", align: "left" },
      { Header: "Avg", accessor: "avg", align: "center" },
      { Header: "SP", accessor: "sp", align: "center" },
      { Header: "#1", accessor: "m1", align: "center" },
      { Header: "#2", accessor: "m2", align: "center" },
    ],


    rows: [
      {
        time: (
          <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
            08:12
          </MDTypography>
        ),
        speed: (
          <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
            50.0
          </MDTypography>
        ),
        mode: (
          <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
            Stopped
          </MDTypography>
        ),
        mid: (
          <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
            54.0
          </MDTypography>
        ),
        sp: (
          <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
            (N/A)
          </MDTypography>
        ),
      },
      {
        time: (
          <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
            07:58
          </MDTypography>
        ),
        speed: (
          <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
            50.0
          </MDTypography>
        ),
        mode: (
          <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
            Stopped
          </MDTypography>
        ),
        mid: (
          <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
            55.9
          </MDTypography>
        ),
        sp: (
          <MDTypography component="div" variant="caption" color="text" fontWeight="medium">
            (N/A)
          </MDTypography>
        ),
      },
    ],
  };
}
