/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import '../../App.css';

import { useEffect, useReducer } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import MDInput from 'components/MDInput';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fuse from 'fuse.js'
import useLogout from "hooks/useLogout";

import Icon from "@mui/material/Icon";
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import React, { useState } from "react";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import constants from '../../constants';
import useAxiosPrivate from 'hooks/useAxiosPrivate';


import { Card, Grid } from '@mui/material';

import multi_view from '../../assets/images/mc/multi-view.svg'
import single_view from '../../assets/images/mc/single-view.svg'
import list_view from '../../assets/images/mc/list-view.svg'

import { Virtuoso, VirtuosoGrid } from 'react-virtuoso'

import { testCreateSingleRoutes, testCreateMultiRoutes } from '../../routes'

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setMultiDryer,
  setView,
  setDryerData,
  setDryerDataIndex,
  setIsLoading,
  setSideView,
  setSidenavOpen,
  setGlobalError,

} from "context";
import { setGlobalFilter } from 'context';

function Sidenav({ color, brand, brandName }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
    sideView,
    multiDryer,
    view,
    auth,
    dryerData,
    dryerDataIndex,
    isLoading,
    sidenavOpen,
    timeframe,
    globalError,
  } = controller;

  /**********************  state and hooks **********************/

  const axiosPrivate = useAxiosPrivate();
  const [testSingleRoutes, setTestSingleRoutes] = useState([]);
  const [disableSideNav, setDisableSideNav] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [select, setSelect] = useState('online');
  const [search, setSearch] = useState('');
  const logout = useLogout();
  const [pageLoad, setPageLoad] = useState(0);
  const location = useLocation();
  //for fuse search don't delete
  const [filteredRoutes, setFilteredRoutes] = useState([])

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const [collapseName, setCollapseName] = useState(0)

  /**********************  state and hooks **********************/


  /********************** other definitions **********************/

  //const collapseName = dryerData[dryerDataIndex]?.Dryer_Serial_Name;

  let textColor = "white";
  let multiLimit = 6;

  const options = [
    { label: "All", icon: "", value: "all" },
    { label: "Online", icon: "public", value: "online" },
    { label: "Running", icon: "settings", value: "running" }
  ];

  const searchOptions = {
    includeScore: true,
    keys: ['serial', 'model', 'name']
  }

  let runningDryerText = sideView == constants.MULTI_VIEW ? 'Running dryers only (max 6)' : sideView == constants.LIST_VIEW ? 'Running dryers only' : '';

  const gridComponents = {
    List: React.forwardRef(({ style, children, ...props }, ref) => (
      <div
        ref={ref}
        {...props}
        style={{
          display: "flex",
          flexWrap: "wrap",
          paddingRight: '12px',
          ...style,
        }}
      >
        {children}
      </div>
    )),
    Item: ({ children, ...props }) => (
      <div
        {...props}
        style={{
          paddingLeft: "12px",

          paddingBottom: "3px",
          paddingTop: "3px",
          width: "50%",
          display: "flex",
          flex: "none",
          alignContent: "stretch",
          boxSizing: "border-box",
          overflowX: 'hidden'
        }}
      >
        {children}
      </div>
    )
  }

  /********************** other definitions **********************/


  /********************** other conditions **********************/
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  // hide side nav for single dryers
  if (dryerData.length < 2) {
    let sideNaveElement = document.getElementById('SidenavId');
    let dashboardElement = document.getElementById('DashboardLayoutId');
    let iconElement = document.getElementById('IconId');
    if (sideNaveElement && dashboardElement && iconElement) {
      sideNaveElement.style.display = 'none';
      dashboardElement.style.marginLeft = '0';
      iconElement.style.display = 'block';
    }
  }

  /********************** other conditions **********************/


  /**********************  effects **********************/

  useEffect(() => {
    let serial = dryerData[dryerDataIndex]?.Dryer_Serial_Name
    setCollapseName(serial);
  }, [dryerDataIndex])

  // useEffect(() => {
  //   let routes = view == constants.SINGLE_VIEW ? getSingleRoutesSearch() : getMultiRoutesSearch();
  //   let arr = [];
  //   routes.forEach((element) => {
  //     arr.push(element.element)
  //   })
  //   setFilteredRoutes(arr);
  // }, [testSingleRoutes]);

  useEffect(() => {
    if (sidenavOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "relative";
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.overflow = "relative";

    } else {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = 'auto';
    }
  }, [sidenavOpen]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
      setSidenavOpen(dispatch, false)
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // run once on page load
  useEffect(() => {
    const getUserDryers = async () => {
      try {
        if (auth?.accessToken) {
          const response = await axiosPrivate.post('/getUserDryers', JSON.stringify({ user: auth.USER_ID }),
            {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
            });

          setDryerData(dispatch, response.data);
          setGlobalError(dispatch, '')
        }
      } catch (e) {
        //setGlobalError(dispatch, "Unable to load dryer data, please try again later");
        if (e.response.status != 200) {
          await logout();
        } else {
          console.log(e);
        }
      }
    };

    getUserDryers();

  }, [auth]);

  useEffect(() => {
    if (dryerData.length != 0) {
      // hide side nav for single dryer users
      if (dryerData.length == 1) {

        let side = document.getElementById("sidenavRoot")
        let dash = document.getElementById('DashboardLayoutId');
        let icon = document.getElementById("IconId");

        if (side) {
          side.style.transform = `translateX(${pxToRem(-320)})`;
        }
        if (dash) {
          dash.style.marginLeft = '0';
        }
        if (icon) {
          icon.style.display = 'block';
        }
      };
      setIsLoading(dispatch, false)
    }

  }, [dryerData]);

  useEffect(() => {
    if (!isLoading) {
      // console.log("isLoading: " + isLoading)
      if(view != constants.SINGLE_VIEW){
        setRunningList(dryerData);
      } else {
        setTestSingleRoutes(testCreateSingleRoutes(dryerData));
      }
      
      setCollapseName(dryerData[dryerDataIndex].Dryer_Serial_Name)
    }
  }, [isLoading]);

  useEffect(() => {
    const updateUserDryers = async () => {
      try {
        //set serial and dataIndex before update
        let oldSerial = dryerData[dryerDataIndex]?.Dryer_Serial_Name
        let oldIndex = dryerData.findIndex(e => e.Dryer_Serial_Name === oldSerial);

        if (auth?.accessToken) {
          //let serial = dryerData[index].Dryer_Serial_Name
          const response = await axiosPrivate.post('/getUserDryers', JSON.stringify({ user: auth.USER_ID, timeframe }),
            {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true
            });
          if (response.status == 200) {
            //check same index for same serial after update
            const newIndex = response.data.findIndex(e => e.Dryer_Serial_Name === oldSerial);

            // if old in != new then the selected dryer either
            // changed positions in the array or 
            // is not in the array at all anymore
            if (oldIndex != newIndex) {

              // -1 means it's not in the array
              if (newIndex == -1) {
                //if so restet index to 0
                setDryerDataIndex(dispatch, 0);
              } else {
                //set dryerData index to the new index
                setDryerDataIndex(dispatch, newIndex);
              }
            }
            setDryerData(dispatch, response.data);
            handleDataChange(response.data);
          } else {
            console.log(response);
          }
        }

      } catch (e) {
        if (e.response.status == 403 || e.response.status == 401) {
          await logout();
        } else {
          console.log(e);
        }
      }
    };

    const intervalCall = setInterval(async () => {
      if (!document.hidden) {
        if (dryerData) {
          updateUserDryers();
        }
      }
    }, constants.TIMEOUT);

    // 5m: 300000
    // 1m: 60000
    return () => {
      clearInterval(intervalCall);
    }
  }, [dryerData, dryerDataIndex]);

  useEffect(() => {
    if (
      view != constants.REMOTE_VIEW &&
      // view != constants.ALERT_VIEW &&
      view != constants.FEEDBACK_VIEW &&
      view != constants.ACCOUNT_VIEW &&
      view != constants.REPORT_VIEW
    ) {
      let tab = view == constants.SINGLE_VIEW ? 0 : tabValue;
      handleViewChange(tab);
      setSideView(dispatch, view)
      setSearch('');
    }
  }, [view]);

  useEffect(() => {
    if (select == 'all') {
      setTestSingleRoutes(testCreateSingleRoutes(dryerData));
    } else if (select == 'running') {
      setRunningList(dryerData);
    } else {
      setOnlineList(dryerData);
    }
  }, [select]);

  // keep tabs in sync with url path
  useEffect(() => {
    let skipView = 'skip';
    switch (location.pathname) {
      case "/dashboard/" + constants.SINGLE_VIEW: {
        // if this useEffect triggers then handleTabChange sets the view
        // it goes into an infinit loop. Set first arg as a flag to stop
        // handleTabChange from setting view only if it was called from this useEffect
        // clicking the tabs can set view without getting stuck in a loop
        handleTabChange(skipView, 0);
        break;
      }
      case "/dashboard/" + constants.MULTI_VIEW: {
        handleTabChange(skipView, 1);
        break;
      }
      case "/dashboard/" + constants.LIST_VIEW: {
        handleTabChange(skipView, 2);
        break;
      }
      default: {

        //handleTabChange(null, 0);
        break;
      }
    }
  }, [location.pathname]);

  useEffect(()=>{
    if(view == constants.ALERT_VIEW){
      let skipView = 'skip'
      handleTabChange(skipView, 0);
    }
  },[view])

  /**********************  effects **********************/

  /************************** functions **************************/

  function pxToRem(number, baseNumber = 16) {
    return `${number / baseNumber}rem`;
  }

  const closeSidenav = () => { setMiniSidenav(dispatch, true); setSidenavOpen(dispatch, !sidenavOpen) };


  const updateMultiDryer = () => {
    // handle multi dryerList if not in dryerData remove from multi list so the card goes away
    Object.keys(multiDryer).forEach(function (key) {
      // each serial in multiDryer
      const serial = multiDryer[key].serial;

      // check to see if the serial is still in dryerData
      const index = dryerData.findIndex(e => e.Dryer_Serial_Name === serial);

      // if not remove from multidryer and remove card -  I don't think this condition will every be true. 
      // Only true if the dryer is not in the list, but we always get the full list even if the dryer is offline
      if (index == -1) {
        const multiIndex = multiDryer.findIndex(e => e.serial === serial)
        const newSerial = multiDryer[multiIndex].serial;
        const newModel = multiDryer[multiIndex].model;
        const newName = multiDryer[multiIndex].name;

        // call handleMultiDryer with it's own index should remove it from the array
        handleMultiDryer(newSerial, newModel, newName);
      } else {
        // if dryer is still in multiDryer and dryerData check to see if it's still running
        if (dryerData[index].Operating_Mode == 0) {

          // if it's not running remove from multi list
          const multiIndex = multiDryer.findIndex(e => e.serial === serial)
          const newSerial = multiDryer[multiIndex].serial;
          const newModel = multiDryer[multiIndex].model;
          const newName = multiDryer[multiIndex].name;

          // call handleMultiDryer with it's own index should remove it from the array
          handleMultiDryer(newSerial, newModel, newName);
        }
      }
    });
  }

  const handleDataChange = (data) => {
    //setDryerData(dispatch, data);

    // THIS IS NOT THE RIGHT WAY TO DO THIS FIX LATER
    // view state is not updating right at this point so workaround
    let tab_0 = document.getElementById('side-tab-0')
    let tab_1 = document.getElementById('side-tab-1')
    let tab_2 = document.getElementById('side-tab-2')

    if (tab_0 != null) {
      tab_0 = tab_0.classList.contains('tab-active');
    }
    if (tab_1 != null) {
      tab_1 = tab_1.classList.contains('tab-active');
    }
    if (tab_2 != null) {
      tab_2 = tab_2.classList.contains('tab-active');
    }

    let element = document.getElementById('dryer-filter');
    let selectText = '';
    let selectValue = '';

    if (element != null) {
      selectText = document.getElementById('dryer-filter').innerText;
      selectValue = selectText.includes('Running') ? 'running' : selectText.includes('Online') ? 'online' : 'all'
    } else {
      selectValue = 'running'
    }

    let active_tab = tab_0 ? 0 : tab_1 ? 1 : 2;

    if (active_tab == 0) {
      // only set the side nav list if there is no search filter
      // otherwise full unfiltered list will show while seach box has a value
      if (search == '') {
        if (selectValue == 'all') {
          setTestSingleRoutes(testCreateSingleRoutes(data));
        } else if (selectValue == 'running') {
          setRunningList(data);
        } else {
          setOnlineList(data);
        }
      }
    } else if (active_tab == 1) {

      // only set the side nav list if there is no search filter
      // otherwise full unfiltered list will show while seach box has a value
      if (search == '') {
        setRunningList(data);
      }

    } else {
      // only set the side nav list if there is no search filter
      // otherwise full unfiltered list will show while seach box has a value
      if (search == '') {
        setRunningList(data);
      }
    }

    // TODO: TEST THIS PART
    updateMultiDryer();
  }

  const handleMultiDryer = (serial, model, name) => {

    // THIS IS NOT THE RIGHT WAY TO DO THIS FIX LATER
    // view state is not updating right at this point so workaround
    let tab_0 = document.getElementById('side-tab-0');
    let tab_1 = document.getElementById('side-tab-1');
    let tab_2 = document.getElementById('side-tab-2');

    if (tab_0 != null) {
      tab_0 = tab_0.classList.contains('tab-active');
    }
    if (tab_1 != null) {
      tab_1 = tab_1.classList.contains('tab-active');
    }
    if (tab_2 != null) {
      tab_2 = tab_2.classList.contains('tab-active');
    }

    let active_tab = tab_0 ? 0 : tab_1 ? 1 : 2

    // if on other page, alerts, sub, remote, feedback and multi dryer clicked
    // move back to dryer view
    if (active_tab == 1 && view != constants.MULTI_VIEW) {
      setView(dispatch, constants.MULTI_VIEW)
    } else if (active_tab == 2 && view != constants.LIST_VIEW) {
      setView(dispatch, constants.LIST_VIEW)
    }

    let dryer = {
      serial: serial,
      model: model,
      name: name
    }

    if (!multiDryer.some(e => e.serial === dryer.serial)) {

      // add dryer to array
      if (sideView == constants.MULTI_VIEW) {

        // if view is multi limit multiDryer to 6
        if (multiDryer.length < multiLimit) {
          let array = [...multiDryer];
          array.unshift(dryer);
          setMultiDryer(dispatch, array);
        }
      } else {

        // no limit for table / list view
        let array = [...multiDryer];
        array.unshift(dryer);
        setMultiDryer(dispatch, array);
      }

    } else {
      // if data passed to handleMultiDryer is already in array remove dryer from array
      var array = [...multiDryer]; // make a separate copy of the array
      const index = array.findIndex(e => e.serial === dryer.serial);
      if (index > -1) {
        array.splice(index, 1);
        setMultiDryer(dispatch, array);
      }
    }
  }

  const getDryerIndex = (serial) => {
    const index = dryerData.findIndex(e => e.Dryer_Serial_Name === serial);
    return index;
  }

  const handleSingleDryer = (serial, model, name, key) => {

    // if (view != constants.SINGLE_VIEW) {
    //   setView(dispatch, constants.SINGLE_VIEW)
    // }
    let index = getDryerIndex(serial);

    setDryerDataIndex(dispatch, index);

    //setSelect(select)

  }

  function a11yProps(index) {
    return {
      id: `side-tab-${index}`,
      'aria-controls': `side-tabpanel-${index}`,
    };
  }

  const setRunningList = (data) => {
    let running = [];
    Object.keys(data).forEach(function (key) {
      if (data[key].Operating_Mode != 0) {
        running.push(data[key])
      }
      //testing multiView if no dryers are running
      //running.push(data[key])
    });
    setTestSingleRoutes(testCreateSingleRoutes(running));
  }

  const setOnlineList = (data) => {
    let online = [];
    Object.keys(data).forEach(function (key) {
      if (data[key]?.Online_Status == 1) {
        online.push(data[key])
      }
    });
    setTestSingleRoutes(testCreateSingleRoutes(online));
  }

  const handleFilter = (e) => {
    const value = e.target.value;
    if (value == 'all') {
      setSelect('all')
      setTestSingleRoutes(testCreateSingleRoutes(dryerData));
    } else if (value == 'running') {
      setSelect('running')
      setRunningList(dryerData);
    } else {
      setSelect('online')
      setOnlineList(dryerData);
    }
  }

  const handleViewChange = (newValue) => {

    setTabValue(newValue);

    if (newValue == 0) {
      setSelect('all');
    } else {
      setSelect('running');
    }
  };

  const handleTabClick = (event, newValue, clickFlag) => {


      console.log("handleTabClick")
      if (newValue == 0 && view != constants.SINGLE_VIEW) {
        setView(dispatch, constants.SINGLE_VIEW)
      }

      if (newValue == 1 && view != constants.MULTI_VIEW) {
        setView(dispatch, constants.SINGLE_VIEW)
      }

      if (newValue == 2 && view != constants.LIST_VIEW) {
        setView(dispatch, constants.SINGLE_VIEW)
      }

  }


  // const navigate = useNavigate();
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue == 0) {

      // don't set view if this was called from useEffect or else infinit loop
      if (event != 'skip') {
        setView(dispatch, constants.SINGLE_VIEW);
      }

      setSelect('all');

    } else if (newValue == 1) {

      // this will reset the list if search has filtered some out
      setRunningList(dryerData);

      let array = [];

      if (multiDryer.length == 0) {

        Object.keys(dryerData).forEach(function (key) {
          if (dryerData[key].Operating_Mode != 0) {
            let dryer = {
              serial: dryerData[key].Dryer_Serial_Name,
              model: dryerData[key].Dryer_Model_Name,
              name: dryerData[key].BUSINESS
            }
            if (array.length < multiLimit) {
              array.push(dryer);
            }
          }
        });

      } else if (multiDryer.length > multiLimit) {
        // is this faster than Object.keys?
        array = multiDryer.slice(0, 6);

      } else {

        array = [...multiDryer];

      }

      // don't set view if this was called from useEffect or else infinit loop
      if (event != 'skip') {
        setView(dispatch, constants.MULTI_VIEW);
      }

      // is this needed?
      // setSelect('running');


      setMultiDryer(dispatch, array);

    } else {

      // this will reset the list if search has filtered some out
      setRunningList(dryerData);

      let array = [];

      // don't set view if this was called from useEffect or else infinit loop
      if (event != 'skip') {
        setView(dispatch, constants.LIST_VIEW);
      }

      // is this needed?
      // setSelect('running');

      Object.keys(dryerData).forEach(function (key) {
        if (dryerData[key].Operating_Mode != 0) {
          let dryer = {
            serial: dryerData[key].Dryer_Serial_Name,
            model: dryerData[key].Dryer_Model_Name,
            name: dryerData[key].BUSINESS
          }
          array.push(dryer);
        }
      });
    }
  };

  const clearMultiDryer = () => {
    if (multiDryer.length > 0) {
      let array = [];
      setMultiDryer(dispatch, array);
    }
  };

  const selectAll = () => {
    let array = []
    Object.keys(dryerData).forEach(function (key) {
      if (dryerData[key]?.Operating_Mode != 0) {
        let dryer = {
          serial: dryerData[key].Dryer_Serial_Name,
          model: dryerData[key].Dryer_Model_Name,
          name: dryerData[key].BUSINESS
        }
        array.push(dryer);
      }
    });
    //navigate('/dashboard/listView', { replace: false });
    setMultiDryer(dispatch, array);
  };

  const removeCard = (serial, model, name) => {
    let dryer = {
      serial: serial,
      model: model,
      name: name
    }

    if (!multiDryer.some(e => e.serial === dryer.serial)) {
      setMultiDryer(dispatch, (multiDryer) => [...multiDryer, dryer]);
    } else {
      var array = [...multiDryer]; // make a separate copy of the array
      const index = array.findIndex(e => e.serial === dryer.serial);
      if (index > -1) {
        array.splice(index, 1);
        setMultiDryer(dispatch, array);
      }
    }
  }

  function getTab(tab, active) {

    let tab_0 = document.getElementById('side-tab-0');
    let tab_1 = document.getElementById('side-tab-1');
    let tab_2 = document.getElementById('side-tab-2');

    if (tab_0 != null) {
      tab_0 = tab_0.classList.contains('tab-active');
    }
    if (tab_1 != null) {
      tab_1 = tab_1.classList.contains('tab-active');
    }
    if (tab_2 != null) {
      tab_2 = tab_2.classList.contains('tab-active');
    }

    let active_tab = tab_0 ? 0 : tab_1 ? 1 : 2

    let single_icon = darkMode ? single_view : active_tab == 0 ? single_view : single_view;
    let multi_icon = darkMode ? multi_view : active_tab == 1 ? multi_view : multi_view;
    let list_icon = darkMode ? list_view : active_tab == 2 ? list_view : list_view;

    let icon = tab == "SINGLE" ? single_icon : tab == "MULTI" ? multi_icon : list_icon;

    return (
      <React.Fragment>
        <Grid item xs={4} >
          <img src={icon} style={{ width: '24px', paddingTop: '5px' }} />
          <br />
          <MDTypography style={{ fontSize: '10px', lineHeight: 'normal', color: active_tab == active ? 'white' : darkMode ? 'white' : 'white' }}>{tab}</MDTypography>

        </Grid>
      </React.Fragment>
    );
  }

  const getSingleRoutes = () => {
    let arr = [];
    testSingleRoutes?.map(({ type, serial, name, model, icon, icon_online, online, icon_running, running, icon_sub, sub, title, noCollapse, key, href, route }) => {

      arr.push(
        <NavLink key={key} onClick={() => { handleSingleDryer(serial, model, name) }}>
          <SidenavCollapse serial={serial} name={name} model={model} active={key === collapseName} icon_online={icon_online} online={online} icon_running={icon_running} running={running} icon_sub={icon_sub} sub={sub} />
        </NavLink>
      );
    });
    return arr;
  }
  const getSingleRoutesSearch = () => {
    let arr = [];
    testSingleRoutes?.map(({ type, serial, name, model, icon, icon_online, online, icon_running, running, icon_sub, sub, title, noCollapse, key, href, route }) => {

      arr.push(
        {
          "serial": serial,
          "model": model,
          "name": name,
          "element": (<NavLink key={key} onClick={() => { handleSingleDryer(serial, model, name, key); }}>
            <SidenavCollapse serial={serial} name={name} model={model} active={key === collapseName} icon_online={icon_online} online={online} icon_running={icon_running} running={running} icon_sub={icon_sub} sub={sub} />
          </NavLink>)
        }
      );
    });

    return arr;
  }

  // search bug work around
  // Sometimes updating state doesn't rerender the component
  // this is a workaround, instead of waiting for state to update
  // just make a whole new list with complete data
  const getSingleRoutesSearch2 = (data) => {
    let arr = [];
    data?.map(({ type, serial, name, model, icon, icon_online, online, icon_running, running, icon_sub, sub, title, noCollapse, key, href, route }) => {

      arr.push(
        {
          "serial": serial,
          "model": model,
          "name": name,
          "element": (<NavLink key={key} onClick={() => { handleSingleDryer(serial, model, name, key); }}>
            <SidenavCollapse serial={serial} name={name} model={model} active={key === collapseName} icon_online={icon_online} online={online} icon_running={icon_running} running={running} icon_sub={icon_sub} sub={sub} />
          </NavLink>)
        }
      );
    });

    return arr;
  }
  const getMultiRoutes = () => {

    let arr = [];
    testSingleRoutes?.map((item, key) => {
      arr.push(

        <Grid item xs={6} key={key} style={{ width: '100%' }}>
          <Card

            id={multiDryer.some(e => e.serial === item.serial) ? "multi-select-active" : "multi-select-inactive"}
            variant={multiDryer.some(e => e.serial === item.serial) ? "contained" : "outlined"}
            color={multiDryer.some(e => e.serial === item.serial) ? "info" : "white"}
            onClick={() => handleMultiDryer(item.serial, item.model, item.name)}>
            <Grid container item spacing={1} >
              <Grid item xs={12} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {item.name}
              </Grid>
              <Grid item xs={12} style={{ paddingTop: '0', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {item.serial}&nbsp;&nbsp;|&nbsp;&nbsp;{item.model}
              </Grid>
            </Grid>
          </Card>
        </Grid>

      );
    })

    return arr;
  }
  const getMultiRoutesSearch = () => {
    let arr = [];
    testSingleRoutes?.map((item, key) => {
      arr.push(
        {
          "serial": item.serial,
          "model": item.model,
          "name": item.name,
          "element": (<Grid item xs={6} key={key} style={{ width: '100%' }}>
            <Card

              id={multiDryer.some(e => e.serial === item.serial) ? "multi-select-active" : "multi-select-inactive"}
              variant={multiDryer.some(e => e.serial === item.serial) ? "contained" : "outlined"}
              color={multiDryer.some(e => e.serial === item.serial) ? "info" : "white"}
              onClick={() => handleMultiDryer(item.serial, item.model, item.name)}>
              <Grid container item spacing={1} >
                <Grid item xs={12} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {item.name}
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '0', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {item.serial}&nbsp;&nbsp;|&nbsp;&nbsp;{item.model}
                </Grid>
              </Grid>
            </Card>
          </Grid>)
        }
      );
    })
    return arr;
  }
  const getMultiRoutesSearch2 = (data) => {
    let arr = [];
    data?.map((item, key) => {
      arr.push(
        {
          "serial": item.serial,
          "model": item.model,
          "name": item.name,
          "element": (<Grid item xs={6} key={key} style={{ width: '100%' }}>
            <Card

              id={multiDryer.some(e => e.serial === item.serial) ? "multi-select-active" : "multi-select-inactive"}
              variant={multiDryer.some(e => e.serial === item.serial) ? "contained" : "outlined"}
              color={multiDryer.some(e => e.serial === item.serial) ? "info" : "white"}
              onClick={() => handleMultiDryer(item.serial, item.model, item.name)}>
              <Grid container item spacing={1} >
                <Grid item xs={12} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {item.name}
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '0', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {item.serial}&nbsp;&nbsp;|&nbsp;&nbsp;{item.model}
                </Grid>
              </Grid>
            </Card>
          </Grid>)
        }
      );
    })
    return arr;
  }

  const onSearchChange = (value) => {
    try {
      if (value == '' || value == undefined) {
        // let routes = view == constants.SINGLE_VIEW ? getSingleRoutesSearch() : getMultiRoutesSearch();
        // let arr = [];
        // routes.forEach((element) => {
        //   arr.push(element.element);
        // });
        // setFilteredRoutes(arr);
        setSearch('');

        if (view == constants.MULTI_VIEW || view == constants.LIST_VIEW) {
          setRunningList(dryerData);
        } else {
          setTestSingleRoutes(testCreateSingleRoutes(dryerData));
        }

      } else {

        let singleRoutes = getSingleRoutesSearch2(testCreateSingleRoutes(dryerData));
        let multiRoutes = getMultiRoutesSearch2(testCreateSingleRoutes(dryerData));

        const fuse = new Fuse(view == constants.SINGLE_VIEW ? singleRoutes : multiRoutes, searchOptions);
        const result = fuse.search(value);

        setGlobalFilter(dispatch, result);

        let newDryerData = [];

        if (view == constants.SINGLE_VIEW) {
          result.forEach((element) => {
            let index = getDryerIndex(element.item.serial);
            newDryerData.push(dryerData[index]);
          });
        } else {
          //for multi and list view filter out stopped dryers before setting search list
          result.forEach((element) => {
            let index = getDryerIndex(element.item.serial);
            if (dryerData[index].Operating_Mode > 0) {
              newDryerData.push(dryerData[index]);
            }
          });
        }

        setTestSingleRoutes(testCreateSingleRoutes(newDryerData));

        setSearch(value);
      }
    } catch (e) {
      console.log(e);
    }
  };

  /************************** functions **************************/

  return (
    <SidenavRoot className="scrollBar"
      id="sidenavRoot"
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={2} pb={2} px={4} textAlign="center" display={{ xs: "block", xl: "none" }}>
        <MDBox
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="white" style={{ fontSize: '22px' }}>
            <Icon className="button-hover" sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox p={'16px'} style={{ display: isLoading ? 'none' : 'block' }}>
        <Tabs
          className="sideTabNav"
          value={tabValue}
          onChange={handleTabChange}

          //onChange={(e) => setTabValue(e.target.value)}
          aria-label="tabs"
          style={{ backgroundColor: 'transparent' }}
          TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
        >
          <Tab className={tabValue == 0 ? "tab-active" : "tab-inactive"} component={NavLink} label={getTab("SINGLE", 0)} {...a11yProps(0)} disableRipple={true} />
          <Tab className={tabValue == 1 ? "tab-active" : "tab-inactive"} component={NavLink} label={getTab("MULTI", 1)} {...a11yProps(1)} disableRipple={true} />
          <Tab className={tabValue == 2 ? "tab-active" : "tab-inactive"} component={NavLink} label={getTab("LIST", 2)} {...a11yProps(2)} disableRipple={true} />
        </Tabs>
      </MDBox>

        <>
          <MDBox pl={2} pr={2} pb={'16px'} id="search-box" style={{ display: isLoading ? 'none' : 'flex' }}>
            <MDInput
              autoComplete="off"
              placeholder="Serial, Model, Name..."
              value={search}
              size="large"
              fullWidth
              onChange={({ currentTarget }) => {
                //setSearch(currentTarget.value);
                onSearchChange(currentTarget.value);
              }}
            />
          </MDBox>
        </>
      
      
      {/* DROPDOWN START */}
      <MDBox pl={2} pr={2} pb={2} style={{ display: isLoading ? 'none' : 'flex' }}>
        {sideView == constants.MULTI_VIEW || sideView == constants.LIST_VIEW ?
          <MDTypography component="p" fontSize="14px" align="center" style={{ width: "100%", color: 'white' }}>{runningDryerText}</MDTypography>

          :

          <FormControl className="dryer-dropdown" fullWidth disabled={tabValue == 0 ? false : true}>
            <InputLabel id="demo-simple-select-label"></InputLabel>
            <Select
              style={{ height: '50px', borderRadius: '8px' }}
              labelId="dryer-filter"
              value={select}
              id="dryer-filter"
              onChange={handleFilter}
            >
              {options.map((item) => {
                let color = darkMode == false ? 'white' : 'black';
                return (
                  item.label != 'All' ?

                    <MenuItem value={item.value} key={item.value} style={{ display: "flex" }} >
                      <Icon className="" fontSize="default" style={{ marginRight: '15px', color: '#41C330', fontSize: '18px' }}>{item.icon}</Icon>
                      <MDTypography component="p" fontSize="18px">{item.label}</MDTypography>
                    </MenuItem>

                    :

                    <MenuItem value={item.value} key={item.value} style={{ display: "flex" }} >
                      <MDTypography component="p" fontSize="18px">{item.label}</MDTypography>
                    </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        }
      </MDBox>

      {/* DROPDOWN END */}
      {

        <>

          {
            isLoading ? <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '50%' }}><CircularProgress /></div> :

              sideView == constants.SINGLE_VIEW || sideView == constants.ALERT_VIEW || sideView == constants.REPORT_VIEW ?

                <>
                  <Virtuoso data={getSingleRoutes()} totalCount={testSingleRoutes.length} itemContent={(_, route) => (
                    <>
                      {route}
                    </>
                  )} />
                </>
                :
                <>
                  <Grid container item spacing={1} p={'12px'} pt={0}>
                    <Grid item xs={6} pb={1}>
                      <MDButton className="clear-btn" variant={multiDryer.length > 0 ? "contained" : "outlined"} color="primary" onClick={clearMultiDryer}
                        style={{
                          height: '40px',
                          width: '100%',
                          color: multiDryer.length > 0 ? 'white' : 'gray',
                          borderColor: multiDryer.length > 0 ? '' : 'gray',
                          padding: '10px 24px 10px 24px'
                        }}
                      >Clear</MDButton>
                    </Grid>
                    {view == constants.LIST_VIEW ?
                      <>
                        <Grid item xs={6} pb={1}>
                          <MDButton className="clear-btn" variant="contained" color="primary" onClick={selectAll}
                            style={{
                              width: '100%',
                              height: '40px',
                              color: 'white',
                              padding: '10px 24px 10px 24px'
                            }}
                          >ALL</MDButton>
                        </Grid>
                      </>
                      :
                      <></>}

                    {/* {testSingleRoutes?.map((item, key) => {
                    return (
                      <Grid item xs={6} key={key}>
                        <Card
                          id={multiDryer.some(e => e.serial === item.serial) ? "multi-select-active" : "multi-select-inactive"}
                          variant={multiDryer.some(e => e.serial === item.serial) ? "contained" : "outlined"}
                          color={multiDryer.some(e => e.serial === item.serial) ? "info" : "white"}
                          onClick={() => handleMultiDryer(item.serial, item.model, item.name)}>
                          <Grid container item spacing={1} >
                            <Grid item xs={12} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                              {item.name}
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: '0', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                              {item.serial}&nbsp;&nbsp;|&nbsp;&nbsp;{item.model}
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  })} */}
                  </Grid>
                  <MDBox id="chip-container-small" display={{ xs: "block", sm: 'none', lg: 'none', xl: "none", xxl: 'none' }} pb={2} style={{ marginTop: '6px', marginRight: '12px', marginLeft: '12px', whiteSpace: 'normal' }}>
                    {
                      multiDryer.map((item, key) => {
                        return (

                          <Chip
                            className={darkMode ? "multi-chip-dark-small" : "multi-chip-dark-small"}
                            key={key}
                            size="small"
                            style={{
                              color: darkMode ? 'white' : 'white',
                              marginRight: '3px',
                              marginLeft: '3px',
                              marginTop: '6px',
                              fontSize: '12px',
                              backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.16)' : 'rgba(255, 255, 255, 0.16)'
                            }}
                            label={item.serial}
                            variant="outlined"
                            onClick={() => { removeCard(item.serial, item.model, item.name) }}
                            onDelete={() => { removeCard(item.serial, item.model, item.name) }}
                          />

                          // <MDButton key={key} color="primary" style={{marginRight:'12px', borderRadius:'25%'}} onClick={()=>{removeCard(item.serial, item.model, item.name)}}>{item.serial}</MDButton>

                        );
                      })
                    }
                  </MDBox>
                  {/* <VirtuosoGrid

                    data={filteredRoutes}
                    components={gridComponents}
                    itemContent={(_, route) => (
                      <>
                        {route}
                      </>
                    )} /> */}
                  <VirtuosoGrid

                    data={getMultiRoutes()}
                    components={gridComponents}
                    itemContent={(_, route) => (
                      <>
                        {route}
                      </>
                    )} />

                </>
          }
        </>
      }
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "primary",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  children: PropTypes.any,
  style: PropTypes.any
  // singleRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
  // multiRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
