/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import '../../../App.css'

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import validator from "validator"
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useMaterialUIController, setDryerData, setDryerDataIndex, setView, setGlobalAlertMessage, setGlobalAlertColor, setGlobalAlertActive } from "context";
import React, { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import MDInput from 'components/MDInput';

import MDButton from 'components/MDButton';
import Icon from "@mui/material/Icon";
import NotificationItem from "examples/Items/NotificationItem";
import constants from "../../../constants";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from "@mui/material/IconButton";


function AdminCard() {
    const [controller, dispatch] = useMaterialUIController();
    const axiosPrivate = useAxiosPrivate();

    const {
        dryerData,
        dryerDataIndex,
        auth,
        darkMode
    } = controller;


    return (
        <Card className="full-admin">
            <MDTypography style={{display:'flex', justifyContent:'center', paddingTop:'16px'}}>ADMIN 3</MDTypography>
        </Card>
    );
}

// Typechecking props for the DryerCard3
AdminCard.propTypes = {
};


export default AdminCard;
