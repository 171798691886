/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";

import '../../../../App.css';

import PropTypes from 'prop-types';

import CardBackDischarge from '../DryerCardBacks/CardBackDischarge';
import CardBackMoistureIn from '../DryerCardBacks/CardBackMoistureIn';
import CardBackMoistureOut from '../DryerCardBacks/CardBackMoistureOut';
import CardBackMidGrain from '../DryerCardBacks/CardBackMidGrain';
import CardBackPlenum from '../DryerCardBacks/CardBackPlenum';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Card from "@mui/material/Card";
import CardBackInfo from "./CardBackInfo";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import constants from "../../../../constants";
import useAxiosPrivate from "hooks/useAxiosPrivate";

function CardBacks({ index, serial, destination, handleFlip, getHeight, handleDestination, isFlipped }) {
  const axiosPrivate = useAxiosPrivate();
  const [controller, dispatch] = useMaterialUIController();
  const {
    dryerData,
    darkMode,
    auth,
  } = controller;

  const moistureInSvg = (fill, padding) => {
    return (
      <svg id="moistureInSvg" style={{ fill: fill, padding: padding, height: '100%', width: '50px' }} data-name="moistureInSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 351.01 345.62">
        <path d="M124.37,345.61c-12.5.19-25-1.79-37.18-5.64-18.58-5.88-34.92-15.6-48.99-29.11-12.43-11.93-22.02-25.85-28.65-41.73C2.53,252.31-.88,234.74.19,216.51c.36-6.07,1.68-12.16,3.2-18.07,2.88-11.23,7.19-21.99,11.91-32.58,7.09-15.91,15.27-31.25,23.88-46.37,17.25-30.28,36.36-59.38,55.74-88.31,5.44-8.12,10.79-16.29,16.42-24.27,6.44-9.14,20.03-9.24,26.5-.1,6.73,9.51,13.07,19.29,19.55,28.96,15.53,23.19,30.79,46.55,45.02,70.56,10.55,17.79,20.62,35.84,29.33,54.62,4.85,10.46,9.15,21.13,12.66,32.11,4.9,15.31,6.13,30.86,3.46,46.77-6.43,38.37-26.3,67.7-59.24,88.18-11.09,6.9-23.17,11.58-35.93,14.49-9.23,2.1-18.54,3.21-28.31,3.11ZM82.03,277.33c2.75-.07,4.56-1.38,6.17-3.15,26.89-29.4,53.78-58.79,80.67-88.19,1.31-1.43,2.67-2.84,3.8-4.41,2.32-3.23,1.39-7.55-1.95-9.71-3.06-1.97-6.4-1.5-9.17,1.48-5.37,5.78-10.68,11.63-16.01,17.45-21.48,23.48-42.97,46.96-64.44,70.44-1.56,1.7-3.16,3.38-4.55,5.21-1.7,2.25-1.72,4.76-.39,7.2,1.3,2.38,3.44,3.5,5.86,3.67ZM93.04,203.16c9.39-.04,16.87-7.57,16.83-16.95-.04-9.22-7.63-16.72-16.91-16.72-9.34,0-16.97,7.6-16.94,16.89.02,9.33,7.62,16.82,17.03,16.78ZM156.27,244.85c-9.37,0-16.89,7.54-16.88,16.92.01,9.19,7.61,16.77,16.85,16.83,9.24.06,17.01-7.66,17-16.89,0-9.31-7.61-16.86-16.98-16.85Z" />
        <g>
          <path d="M238.15,340.11v-48.73h8.04v48.73h-8.04Z" />
          <path d="M254,340.11v-48.73h7.85l16.36,32.54v-32.54h7.51v48.73h-8.11l-16.09-31.78v31.78h-7.51Z" />
        </g>
      </svg>
    )
  }
  const moistureOutSvg = (fill, padding) => {
    return (
      <svg id="moistureOutSvg" style={{ fill: fill, padding: padding, height: '100%', width: '50px' }} data-name="moistureOutSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 351.01 345.62">
        <path d="M124.37,345.61c-12.5.19-25-1.79-37.18-5.64-18.58-5.88-34.92-15.6-48.99-29.11-12.43-11.93-22.02-25.85-28.65-41.73C2.53,252.31-.88,234.74.19,216.51c.36-6.07,1.68-12.16,3.2-18.07,2.88-11.23,7.19-21.99,11.91-32.58,7.09-15.91,15.27-31.25,23.88-46.37,17.25-30.28,36.36-59.38,55.74-88.31,5.44-8.12,10.79-16.29,16.42-24.27,6.44-9.14,20.03-9.24,26.5-.1,6.73,9.51,13.07,19.29,19.55,28.96,15.53,23.19,30.79,46.55,45.02,70.56,10.55,17.79,20.62,35.84,29.33,54.62,4.85,10.46,9.15,21.13,12.66,32.11,4.9,15.31,6.13,30.86,3.46,46.77-6.43,38.37-26.3,67.7-59.24,88.18-11.09,6.9-23.17,11.58-35.93,14.49-9.23,2.1-18.54,3.21-28.31,3.11ZM82.03,277.33c2.75-.07,4.56-1.38,6.17-3.15,26.89-29.4,53.78-58.79,80.67-88.19,1.31-1.43,2.67-2.84,3.8-4.41,2.32-3.23,1.39-7.55-1.95-9.71-3.06-1.97-6.4-1.5-9.17,1.48-5.37,5.78-10.68,11.63-16.01,17.45-21.48,23.48-42.97,46.96-64.44,70.44-1.56,1.7-3.16,3.38-4.55,5.21-1.7,2.25-1.72,4.76-.39,7.2,1.3,2.38,3.44,3.5,5.86,3.67ZM93.04,203.16c9.39-.04,16.87-7.57,16.83-16.95-.04-9.22-7.63-16.72-16.91-16.72-9.34,0-16.97,7.6-16.94,16.89.02,9.33,7.62,16.82,17.03,16.78ZM156.27,244.85c-9.37,0-16.89,7.54-16.88,16.92.01,9.19,7.61,16.77,16.85,16.83,9.24.06,17.01-7.66,17-16.89,0-9.31-7.61-16.86-16.98-16.85Z" />
        <g>
          <path d="M236.75,316.04c0-5.81.86-10.59,2.58-14.34,1.72-3.76,3.96-6.55,6.73-8.39,2.77-1.84,6.13-2.76,10.07-2.76,5.72,0,10.38,2.18,13.98,6.55s5.4,10.58,5.4,18.65-1.91,14.53-5.72,19.11c-3.35,4.06-7.89,6.08-13.63,6.08s-10.35-2.01-13.7-6.02c-3.81-4.59-5.72-10.88-5.72-18.88ZM245.06,315.71c0,5.61,1.07,9.81,3.2,12.62,2.13,2.8,4.77,4.21,7.92,4.21s5.8-1.39,7.89-4.17c2.09-2.78,3.13-7.05,3.13-12.81s-1.02-9.83-3.05-12.53c-2.03-2.7-4.69-4.06-7.97-4.06s-5.96,1.36-8.02,4.09c-2.06,2.73-3.1,6.95-3.1,12.67Z" />
          <path d="M281.76,291.37h8.04v26.39c0,4.1.1,6.77.3,8.01.36,2.15,1.2,3.81,2.55,4.99,1.34,1.18,3.12,1.76,5.31,1.76,1.87,0,3.39-.42,4.56-1.25,1.18-.83,1.98-1.98,2.42-3.46s.65-4.64.65-9.49v-26.96h8.04v25.6c0,6.54-.35,11.26-1.05,14.18s-2.27,5.27-4.72,7.08-5.68,2.71-9.69,2.71-7.42-.75-9.76-2.24-4.03-3.6-5.09-6.32c-1.05-2.71-1.58-7.72-1.58-15.01v-26Z" />
          <path d="M331.13,340.11v-40.49h-11.87v-8.24h31.75v8.24h-11.83v40.49h-8.04Z" />
        </g>
      </svg>
    )
  }

  const speedSvg = (fill, padding) => {
    return (
      <svg id="speedSvg" style={{ fill: fill, padding: padding, height: '100%', width: '50px' }} data-name="speedSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 344.26 209.32">
        <path d="M284.32,77.21c-2.55,2.7-5.01,5.48-7.66,8.07-4.1,4.02-9.61,4.77-14.55,2.17-7.15-3.76-8.78-13.2-3.25-19.16,2.2-2.38,4.51-4.66,6.9-6.86,1.38-1.27,1.21-2.02-.19-3.14-13.57-10.96-28.47-19.5-44.97-25.24-10.89-3.8-22.04-6.36-33.54-7.4-2.52-.23-2.45-.17-2.56,2.46-.16,4.17-.02,8.41-.76,12.49-.93,5.12-6.1,8.65-11.57,8.66-5.45.02-10.7-3.53-11.62-8.6-.74-4.08-.61-8.32-.77-12.49-.1-2.69-.03-2.75-2.7-2.51-9.45.84-18.68,2.82-27.76,5.57-17.25,5.23-32.94,13.43-47.2,24.42-1.28.99-2.5,2.07-3.81,3.02-1.16.84-.99,1.53-.07,2.43,2.37,2.31,4.76,4.61,7.03,7.02,5.81,6.17,4.14,15.67-3.37,19.54-4.58,2.36-10.3,1.48-14.22-2.35-2.65-2.59-5.13-5.36-8-8.37-2.58,3.31-4.92,6.14-7.07,9.11-8.99,12.38-15.91,25.85-20.75,40.36-3.41,10.22-5.51,20.74-6.71,31.45-.19,1.74.39,2.29,2.08,2.25,3.31-.07,6.64-.13,9.94.05,7.17.38,12.2,5.93,11.91,12.96-.24,5.86-5.31,11.29-11.16,11.42-8.93.2-17.86.2-26.79,0-5.99-.13-11.28-5.52-11.11-11.54.21-7.83.79-15.66,1.49-23.46.88-9.72,3.26-19.18,6.15-28.47,3.76-12.08,8.84-23.61,15.2-34.58,13.27-22.89,30.84-41.78,52.68-56.67,13.11-8.95,27.24-15.87,42.32-20.91,11.98-4,24.23-6.65,36.76-7.95,12.68-1.32,25.41-1.44,38.05.5,8,1.23,16.03,2.52,23.87,4.47,12.14,3.02,23.72,7.68,34.83,13.49,23.32,12.19,42.9,28.8,58.69,49.82,10.94,14.57,19.35,30.5,25.21,47.79,3.2,9.43,5.81,19.03,7.07,28.87,1.15,8.97,1.61,18.04,1.93,27.08.22,6.19-5.27,11.51-11.49,11.6-8.66.13-17.32.13-25.98,0-6.18-.1-11.42-5.51-11.6-11.63-.18-6.52,4.57-12.19,10.88-12.67,3.57-.27,7.17-.14,10.75-.19,2.4-.03,2.53-.17,2.26-2.58-2.37-21.66-8.88-41.96-20.3-60.56-4.16-6.77-8.93-13.16-13.42-19.72-.35-.01-.7-.03-1.05-.04Z" />
        <path d="M135.23,174.27c.36-15.25,5.93-25.8,17.1-32.9,15.66-9.94,31.5-19.61,47.46-29.07,11.84-7.02,23.94-13.57,36-20.2,5.12-2.82,10.67-1.93,14.57,1.93,3.85,3.8,4.94,9.66,2.2,14.58-15.63,27.98-30.93,56.16-48.77,82.84-4.06,6.07-9.04,11.08-15.71,14.24-20.4,9.69-44.23-.85-50.9-21.85-1.17-3.69-1.57-7.62-1.96-9.57Z" />
      </svg>
    )
  }
  const plenumSvg = (fill, padding) => {
    return (
      <svg id="plenumSvg" style={{ fill: fill, padding: padding, height: '100%', width: '50px' }} data-name="plenumSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350.85 342.24">
        <g>
          <path d="M277.84,339.17h-8.71l-3.48-10.98h-15.92l-3.29,10.98h-8.53l15.49-48.5h8.5l15.94,48.5ZM263.07,319.98l-5.49-18-5.39,18h10.88Z" />
          <path d="M288.17,339.17l-14.23-48.5h8.7l10.07,35.89,9.74-35.89h8.53l-14.26,48.5h-8.56Z" />
          <path d="M333.55,321.37v-8.2h17.3v19.32c-1.74,2.05-4.21,3.82-7.41,5.29s-6.41,2.22-9.63,2.22c-3.93,0-7.39-.96-10.39-2.89s-5.38-4.82-7.13-8.67-2.63-8.38-2.63-13.58.89-9.9,2.66-13.7,4.08-6.63,6.91-8.5,6.22-2.81,10.17-2.81c4.74,0,8.54,1.21,11.4,3.62s4.69,5.96,5.51,10.64l-7.94,1.82c-.6-2.49-1.69-4.4-3.28-5.72s-3.48-1.98-5.69-1.98c-3.42,0-6.19,1.33-8.3,3.99s-3.18,6.71-3.18,12.16c0,5.84,1.15,10.29,3.44,13.33,1.96,2.62,4.62,3.94,7.97,3.94,1.57,0,3.19-.37,4.88-1.1,1.69-.74,3.19-1.74,4.52-3.02v-6.13h-9.2Z" />
        </g>
        <g>
          <path d="M211.28,119.82c0,27.6.03,55.19-.05,82.79,0,2.55.73,4.11,2.9,5.64,14.74,10.4,24.94,24.2,29.14,41.82,7.07,29.67-.94,55.02-23.98,74.92-16.19,13.99-35.49,19.51-56.79,16.41-27.64-4.02-47-19.3-58.19-44.72-5.47-12.43-6.8-25.6-4.69-39.06,3.36-21.34,14.17-37.96,31.9-50.13,1.49-1.03,1.67-2.2,1.67-3.73-.02-22.83,0-45.66,0-68.5,0-31.29-.02-62.58.02-93.88.01-13.49,5.15-24.54,16.03-32.71,20.76-15.6,51.3-6.09,59.8,18.53,1.75,5.07,2.18,10.24,2.19,15.5.02,25.71,0,51.41,0,77.12h.05ZM192.66,125.25h-.01c0-28.5.04-57-.02-85.5-.02-8.84-6.17-16.77-14.58-19.26-8.54-2.53-17.82.99-22.84,8.5-2.84,4.25-3.31,8.98-3.31,13.88.01,55.52-.02,111.04.06,166.56,0,5.24-1.92,8.84-6.5,11.42-26.33,14.8-35.73,47.3-21.47,73.87,13.76,25.65,45.34,35.96,71.81,23.46,23.2-10.96,35.88-36.84,29.99-61.84-3.73-15.82-12.88-27.81-27.06-35.78-4.28-2.41-6.12-5.69-6.11-10.56.09-28.25.05-56.51.05-84.76Z" />
          <path d="M257.19,116.21c5.41,0,10.83-.17,16.23.04,7.21.29,11.37,7.6,7.97,13.8-1.28,2.33-3.22,3.89-5.88,4.43-.16.03-.32.1-.48.1-11.79.01-23.59.29-35.37-.09-4.93-.16-7.89-4.65-7.63-9.74.23-4.42,3.99-8.29,8.69-8.47,5.48-.2,10.98-.05,16.48-.05,0,0,0-.02,0-.02Z" />
          <path d="M257.21,55.49c-5.41,0-10.82.1-16.23-.03-4.27-.1-8.02-3.32-8.84-7.26-.86-4.14.9-8.18,4.76-10.12,1.48-.75,3.28-1.16,4.94-1.17,10.25-.11,20.49-.15,30.74-.02,5.96.07,9.85,3.76,9.95,9,.1,5.27-3.78,9.48-9.09,9.6-5.41.12-10.82.03-16.23.03,0,0,0,0,0-.01Z" />
          <path d="M247.64,174.93c-3.23-.37-6.57-.36-9.68-1.19-4.04-1.08-6.19-4.94-5.93-9.39.22-3.88,3.09-7.62,7.13-7.95,5.61-.47,11.31-.6,16.9-.08,5.08.47,7.94,4.77,7.52,9.8-.39,4.67-4.14,8.23-9.01,8.44-2.29.1-4.6.02-6.89.02-.01.12-.02.24-.04.35Z" />
          <path d="M248.04,76.68c2.38,0,4.76-.11,7.14.02,4.87.26,8.43,4.17,8.45,9.12.01,4.95-3.5,8.97-8.38,9.17-4.91.2-9.85.19-14.76,0-4.93-.19-8.53-4.34-8.47-9.36.05-4.81,3.77-8.72,8.65-8.96,2.46-.12,4.92-.02,7.38-.02,0,0,0,.02,0,.02Z" />
          <path d="M181.62,147.32c0,25.29.04,50.58-.07,75.87-.01,2.42.9,3.22,2.96,3.86,15.06,4.67,24.93,14.63,29.47,29.7,6.36,21.14-5.21,43.03-23.88,51.55-20.85,9.52-47.1.22-56.87-20.62-10.74-22.92-1.03-48.86,22.13-59.08,1.19-.53,2.43-.98,3.68-1.36,3.89-1.2,3.89-1.18,3.89-5.09.02-49.51.04-99.02.06-148.54,0-1.07-.02-2.14.06-3.2.43-5.64,4.21-9.17,9.62-9,5.08.16,8.88,4.12,8.9,9.54.06,16.09.03,32.19.04,48.28,0,9.36,0,18.72,0,28.08h.01Z" />
        </g>
        <path d="M13.56,32.21c2.26,1.8,4.02,3.69,5.5,5.84,2.76,4.03,3.99,8.57,4.21,13.38.16,3.57.08,7.15.11,10.72.04,5.23.23,10.45,2.18,15.4.48,1.22,1.06,2.39,1.6,3.58.12-.02.25-.04.37-.06.02-.35.07-.71.06-1.06-.2-13.54,2.48-26.39,9.07-38.32,3.73-6.75,8.49-12.74,13.45-18.61,3.49-4.14,6.95-8.32,10.21-12.64,2.2-2.92,3.56-6.28,3.57-10.04,0-.11.07-.21.14-.41.23.13.46.2.62.35,6.84,6.48,12.83,13.6,16.84,22.23,3.33,7.17,4.66,14.7,4.18,22.6-.45,7.32-2.44,14.29-4.47,21.26-1.7,5.86-3.57,11.68-3.87,17.83-.1,2.02.11,4.06.18,6.09.1,0,.2,0,.29,0,.04-.24.11-.48.1-.71-.44-8.72,3.09-15.95,8.58-22.36,7.06-8.24,15.97-13.81,26.06-17.58.11-.04.23-.08.35-.09.07,0,.15.03.32.07-.32,1.54-.8,3.08-.94,4.64-.29,3.31-.78,6.67-.52,9.96.56,7.16,1.4,14.3,2.35,21.42,1.38,10.3,3.19,20.56,3.44,30.98.16,7.06-.37,14.04-2.82,20.76-3.35,9.18-9.89,15.08-18.95,18.38-4.6,1.67-9.37,2.5-14.21,3-.4.04-.8.05-1.2.05-.1,0-.21-.09-.11-.04,2.34-1.15,4.91-2.15,7.2-3.6,5.7-3.6,9.13-9.03,11.42-15.22,2.77-7.49,3.57-15.28,3.22-23.22-.23-5.28-.92-10.48-2.8-15.45-.57-1.5-1.44-2.89-2.17-4.32-.13.05-.27.1-.4.14-.03.25-.11.51-.08.75.99,8.61-2.17,15.71-7.93,21.88-2.09,2.24-2.04,2.27-4.75.8-10.23-5.55-16.65-14.09-19.82-25.2-2.3-8.06-3.03-16.32-3.17-24.65-.08-4.82.05-9.64.09-14.46,0-.56,0-1.11-.13-1.69-.61,1.89-1.15,3.81-1.85,5.67-1.88,4.97-4.92,9.25-8.1,13.46-3.07,4.06-6.23,8.09-8.83,12.45-3.79,6.35-4.88,13.47-4.42,20.78.25,4,.93,7.96,1.42,11.94.02.15.02.3.06.77-13.24-4.76-21.77-13.55-25.34-27.09-3.52,12.4-3.59,24.76.2,37.08,3.8,12.39,11.22,22.25,21.29,30.33-.71-.34-1.42-.66-2.12-1.02-.71-.36-1.42-.75-2.12-1.14-8.57-4.79-15.93-10.93-21.42-19.16-4.5-6.75-7.21-14.19-8.6-22.15-1.73-10.02-1.2-20,.27-29.98,1.62-10.94,4.58-21.56,7.56-32.19,1.71-6.1,3.7-12.13,4.45-18.46.13-1.1.13-2.22.2-3.66Z" />
      </svg>
    )
  }
  const midSvg = (fill, padding) => {
    return (

      <svg id="midSvg" style={{ fill: fill, padding: padding, height: '100%', width: '50px' }} data-name="midSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 351.01 342.93">

        <path d="M58.35,162.16c-5.86.09-11.73-.84-17.44-2.65-8.72-2.76-16.38-7.32-22.98-13.66-5.83-5.6-10.33-12.13-13.44-19.58C1.19,118.38-.41,110.14.09,101.58c.17-2.85.79-5.7,1.5-8.48,1.35-5.27,3.37-10.32,5.59-15.28,3.32-7.47,7.16-14.66,11.21-21.76,8.09-14.21,17.06-27.86,26.15-41.43,2.55-3.81,5.06-7.64,7.7-11.39,3.02-4.29,9.4-4.34,12.43-.05,3.16,4.46,6.13,9.05,9.17,13.59,7.29,10.88,14.45,21.84,21.12,33.11,4.95,8.35,9.67,16.81,13.76,25.63,2.27,4.91,4.29,9.91,5.94,15.07,2.3,7.18,2.88,14.48,1.63,21.94-3.02,18-12.34,31.77-27.79,41.37-5.21,3.24-10.87,5.43-16.86,6.8-4.33.99-8.7,1.51-13.28,1.46Z" />
        <g>
          <path d="M278,339.87h-8.71l-3.48-10.98h-15.92l-3.29,10.98h-8.53l15.49-48.5h8.5l15.94,48.5ZM263.23,320.68l-5.49-18-5.39,18h10.88Z" />
          <path d="M288.32,339.87l-14.23-48.5h8.7l10.07,35.89,9.74-35.89h8.53l-14.26,48.5h-8.56Z" />
          <path d="M333.71,322.07v-8.2h17.3v19.32c-1.74,2.05-4.21,3.82-7.41,5.29s-6.41,2.22-9.63,2.22c-3.93,0-7.39-.96-10.39-2.89s-5.38-4.82-7.13-8.67-2.63-8.38-2.63-13.58.89-9.9,2.66-13.7,4.08-6.63,6.91-8.5,6.22-2.81,10.17-2.81c4.74,0,8.54,1.21,11.4,3.62s4.69,5.96,5.51,10.64l-7.94,1.82c-.6-2.49-1.69-4.4-3.28-5.72s-3.48-1.98-5.69-1.98c-3.42,0-6.19,1.33-8.3,3.99s-3.18,6.71-3.18,12.16c0,5.84,1.15,10.29,3.44,13.33,1.96,2.62,4.62,3.94,7.97,3.94,1.57,0,3.19-.37,4.88-1.1,1.69-.74,3.19-1.74,4.52-3.02v-6.13h-9.2Z" />
        </g>
        <g>
          <path d="M211.44,120.51c0,27.6.03,55.19-.05,82.79,0,2.55.73,4.11,2.9,5.64,14.74,10.4,24.94,24.2,29.14,41.82,7.07,29.67-.94,55.02-23.98,74.92-16.19,13.99-35.49,19.51-56.79,16.41-27.64-4.02-47-19.3-58.19-44.72-5.47-12.43-6.8-25.6-4.69-39.06,3.36-21.34,14.17-37.96,31.9-50.13,1.49-1.03,1.67-2.2,1.67-3.73-.02-22.83,0-45.66,0-68.5,0-31.29-.02-62.58.02-93.88.01-13.49,5.15-24.54,16.03-32.71,20.76-15.6,51.3-6.09,59.8,18.53,1.75,5.07,2.18,10.24,2.19,15.5.02,25.71,0,51.41,0,77.12h.05ZM192.82,125.94h-.01c0-28.5.04-57-.02-85.5-.02-8.84-6.17-16.77-14.58-19.26-8.54-2.53-17.82.99-22.84,8.5-2.84,4.25-3.31,8.98-3.31,13.88.01,55.52-.02,111.04.06,166.56,0,5.24-1.92,8.84-6.5,11.42-26.33,14.8-35.73,47.3-21.47,73.87,13.76,25.65,45.34,35.96,71.81,23.46,23.2-10.96,35.88-36.84,29.99-61.84-3.73-15.82-12.88-27.81-27.06-35.78-4.28-2.41-6.12-5.69-6.11-10.56.09-28.25.05-56.51.05-84.76Z" />
          <path d="M257.34,116.9c5.41,0,10.83-.17,16.23.04,7.21.29,11.37,7.6,7.97,13.8-1.28,2.33-3.22,3.89-5.88,4.43-.16.03-.32.1-.48.1-11.79.01-23.59.29-35.37-.09-4.93-.16-7.89-4.65-7.63-9.74.23-4.42,3.99-8.29,8.69-8.47,5.48-.2,10.98-.05,16.48-.05,0,0,0-.02,0-.02Z" />
          <path d="M257.36,56.18c-5.41,0-10.82.1-16.23-.03-4.27-.1-8.02-3.32-8.84-7.26-.86-4.14.9-8.18,4.76-10.12,1.48-.75,3.28-1.16,4.94-1.17,10.25-.11,20.49-.15,30.74-.02,5.96.07,9.85,3.76,9.95,9,.1,5.27-3.78,9.48-9.09,9.6-5.41.12-10.82.03-16.23.03,0,0,0,0,0-.01Z" />
          <path d="M247.79,175.62c-3.23-.37-6.57-.36-9.68-1.19-4.04-1.08-6.19-4.94-5.93-9.39.22-3.88,3.09-7.62,7.13-7.95,5.61-.47,11.31-.6,16.9-.08,5.08.47,7.94,4.77,7.52,9.8-.39,4.67-4.14,8.23-9.01,8.44-2.29.1-4.6.02-6.89.02-.01.12-.02.24-.04.35Z" />
          <path d="M248.19,77.37c2.38,0,4.76-.11,7.14.02,4.87.26,8.43,4.17,8.45,9.12.01,4.95-3.5,8.97-8.38,9.17-4.91.2-9.85.19-14.76,0-4.93-.19-8.53-4.34-8.47-9.36.05-4.81,3.77-8.72,8.65-8.96,2.46-.12,4.92-.02,7.38-.02,0,0,0,.02,0,.02Z" />
          <path d="M181.77,148.02c0,25.29.04,50.58-.07,75.87-.01,2.42.9,3.22,2.96,3.86,15.06,4.67,24.93,14.63,29.47,29.7,6.36,21.14-5.21,43.03-23.88,51.55-20.85,9.52-47.1.22-56.87-20.62-10.74-22.92-1.03-48.86,22.13-59.08,1.19-.53,2.43-.98,3.68-1.36,3.89-1.2,3.89-1.18,3.89-5.09.02-49.51.04-99.02.06-148.54,0-1.07-.02-2.14.06-3.2.43-5.64,4.21-9.17,9.62-9,5.08.16,8.88,4.12,8.9,9.54.06,16.09.03,32.19.04,48.28,0,9.36,0,18.72,0,28.08h.01Z" />
        </g>
      </svg>
    )
  }
  const infoSvg = (fill, padding) => {
    return (
      <svg id="infoSvg" style={{ fill: fill, padding: padding, width: '50px', scale: '2.5' }} data-name="infoSvg" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
      </svg>
    )
  }

  let comingSoon = true;

  let cardHeight = getHeight(serial, index);
  let height = cardHeight > 425 ? cardHeight : 500;

  return (
    // <Card id={serial + '-' + index} style={{ width: getWidth(serial, index), height: getHeight(serial, index), overFlow: 'hidden' }}>
    <Card id={serial + '-' + index} style={{ overFlow: 'hidden', height: height,  minWidth:'300px' }}>
      <MDBox
        style={{ height: '100%' }}
      //onClick={(e) => handleFlip(e, serial, destination, index)}
      >
        {
          destination == 0 ?
            <CardBackInfo index={index} serial={serial} handleFlip={handleFlip} destination={destination} isFlipped={isFlipped} comingSoon={comingSoon} />

            : destination == 1 ?

              <CardBackMoistureIn index={index} serial={serial} handleFlip={handleFlip} destination={destination} isFlipped={isFlipped} comingSoon={false} />

              : destination == 2 ?

                <CardBackMoistureOut index={index} serial={serial} handleFlip={handleFlip} destination={destination} isFlipped={isFlipped} comingSoon={false} />

                : destination == 3 ?

                  <CardBackDischarge index={index} serial={serial} handleFlip={handleFlip} destination={destination} isFlipped={isFlipped} comingSoon={false} />

                  : destination == 4 ?

                    <CardBackMidGrain index={index} serial={serial} handleFlip={handleFlip} destination={destination} isFlipped={isFlipped} comingSoon={false} />

                    :

                    <CardBackPlenum index={index} serial={serial} handleFlip={handleFlip} destination={destination} isFlipped={isFlipped} comingSoon={false} />
        }

      </MDBox>
      <MDBox style={{ width: '100%', bottom: '0', position: 'absolute' }}>
        <BottomNavigation style={{ borderRadius: '8px', background: 'transparent', height: '70px' }}
          value={destination}
          onChange={(event, newValue) => {
            //setBottomNavLocation(newValue);
            handleDestination(newValue);
          }}
        >
          <BottomNavigationAction
            className={destination == 0 ? "bottom-nav-active-right" : "bottom-nav-inactive"}
            label=""
            icon={infoSvg(destination == 0 ? '#ffffff' : '#7D7D7D', '5px')}
            style={{ paddingLeft: '0', paddingRight: '0' }} />
          <BottomNavigationAction
            className={destination == 1 ? "bottom-nav-active" : "bottom-nav-inactive"}
            label=""
            icon={moistureInSvg(destination == 1 ? '#ffffff' : '#7D7D7D', '5px')}
            style={{ paddingLeft: '0', paddingRight: '0' }} />
          <BottomNavigationAction
            className={destination == 2 ? "bottom-nav-active" : "bottom-nav-inactive"}
            label=""
            icon={moistureOutSvg(destination == 2 ? '#ffffff' : '#7D7D7D', '5px')}
            style={{ paddingLeft: '0', paddingRight: '0' }} />
          <BottomNavigationAction
            className={destination == 3 ? "bottom-nav-active" : "bottom-nav-inactive"}
            label=""
            icon={speedSvg(destination == 3 ? '#ffffff' : '#7D7D7D', '5px')}
            style={{ paddingLeft: '0', paddingRight: '0' }} />
          <BottomNavigationAction
            className={destination == 4 ? "bottom-nav-active" : "bottom-nav-inactive"}
            label=""
            icon={midSvg(destination == 4 ? '#ffffff' : '#7D7D7D', '5px')}
            style={{ paddingLeft: '0', paddingRight: '0' }} />
          <BottomNavigationAction
            className={destination == 5 ? "bottom-nav-active-left" : "bottom-nav-inactive"}
            label=""
            icon={plenumSvg(destination == 5 ? '#ffffff' : '#7D7D7D', '5px')}
            style={{ paddingLeft: '0', paddingRight: '0' }} />
        </BottomNavigation>
      </MDBox>
    </Card>
  );
}

CardBacks.propTypes = {
  serial: PropTypes.string,
  destination: PropTypes.number,
  handleFlip: PropTypes.func,
  getHeight: PropTypes.func,
  // getWidth: PropTypes.func,
  index: PropTypes.number,
  handleDestination: PropTypes.func,
  isFlipped: PropTypes.bool
};

export default CardBacks;
