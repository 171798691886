/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";

import '../../../../App.css';
import grain_temp_svg from '../../../../assets/images/mc/grain-temp.svg';
import setpoint_svg from '../../../../assets/images/mc/setpoint.svg';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useMaterialUIController } from "context";

import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";

function CardBackCommingSoon({ index, handleFlip, destination }) {

    const [controller, dispatch] = useMaterialUIController();

    const {
        dryerData,
        darkMode
    } = controller;

    let arr = []
    return (
        <>
            <MDBox px={2}>
                <Grid container spacing={1} style={{ justifyContent: 'center', marginTop: '40%' }}>
                    <MDTypography style={{ textAlign: 'center' }}>Coming soon</MDTypography>
                </Grid>
            </MDBox>

        </>
    );
}

// Typechecking props for the CardBackMidGrain

CardBackCommingSoon.defaultProps = {
    index: 0,
};

CardBackCommingSoon.propTypes = {
    index: PropTypes.number,
    handleFlip: PropTypes.func,
    destination: PropTypes.number,
};

export default CardBackCommingSoon;
