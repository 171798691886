/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

import mctrax_logo from '../../../assets/images/mc/mctrax-logo.svg'
import mctrax_logo_white from '../../../assets/images/mc/mctrax-logo-white.svg'
import Badge from '@mui/material/Badge';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components

import NotificationItem from "examples/Items/NotificationItem";

import Card from "@mui/material/Card"

import multi_view from '../../../assets/images/mc/multi-view.svg'
import single_view from '../../../assets/images/mc/single-view.svg'
import list_view from '../../../assets/images/mc/list-view.svg'
import multi_view_dark from '../../../assets/images/mc/multi-view-dark.svg'
import single_view_dark from '../../../assets/images/mc/single-view-dark.svg'
import list_view_dark from '../../../assets/images/mc/list-view-dark.svg'
import multi_view_gray from '../../../assets/images/mc/multi-view-gray.svg'
import single_view_gray from '../../../assets/images/mc/single-view-gray.svg'
import list_view_gray from '../../../assets/images/mc/list-view-gray.svg'
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

import { useNavigate } from "react-router-dom"

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setDarkMode,
  setSidenavOpen
} from "context";
import { Grid } from "@mui/material";
import { setView } from "context";
import constants from "../../../constants";
import RemoteCard from "examples/Cards/RemoteCards/index";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
    view,
    dryerData,
    sideView,
    sidenavOpen,
    activeAlarms,
    maxAlarm,
    auth,
    dryerDataIndex
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  const navigate = useNavigate()

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    //window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleSidenavOpen = () => setSidenavOpen(dispatch, !sidenavOpen);
  const handleSidenavClose = () => setSidenavOpen(dispatch, false);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const [remoteEligible, setRemoteEligible] = useState(false);

  const handleModal = () => setOpen(!open);
  const [open, setOpen] = useState(false);
  const handleViewClick = () => {

    if (view == constants.REMOTE_VIEW || view == constants.ALERT_VIEW) {
      // if view is remote or alert and nav button is clicked
      // go back to the previous view instead of opening the side nav
      setView(dispatch, sideView)
    } else {
      handleSidenavOpen()
      handleMiniSidenav();

    }
  }
  let alertArr = [];

  // Render the notifications menu
  const renderMenu = () => (

    <Menu

      id={darkMode ? "alert-menu" : "alert-menu-light"}
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >

      {(() => {
        if (activeAlarms.length > 0) {
          Object.keys(activeAlarms).forEach(function (key) {
            alertArr.push(
              <NotificationItem className={darkMode ? "no-hover no-hover-dark" : "no-hover no-hover-light"} key={key} icon={<Icon style={{ color: activeAlarms[key]['Type'] == 3 ? '#ff0000' : activeAlarms[key]['Type'] == 2 ? '#ffaa00' : '#ffff00' }}>notifications</Icon>} title={activeAlarms[key]['Alarm Name']} />
            );
          });
          return alertArr;
        } else {
          alertArr.push(
            <NotificationItem className={darkMode ? "no-hover no-hover-dark" : "no-hover no-hover-light"} key={"key"} icon={<Icon>notifications</Icon>} title={"No active alarms"} />
          );
          return alertArr;
        }
      })()}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
    fontSize: 30,
  });

  let single = darkMode ? single_view : single_view_dark
  let multi = darkMode ? multi_view : multi_view_dark
  let list = darkMode ? list_view : list_view_dark

  const styles = {

    alertCount: {
      //position:'absolute', 
      // top:'0', 
      // left:'0', 
      paddingTop: '8px',
      marginRight: '12px',
      marginLeft: '-20px',
      fontSize: '12px',
      color: darkMode ? 'white' : 'black',
      fontWeight: 'bold'
      //backgroundColor:'white', 
      // /borderRadius:'50%', 
      //width:'10px'
    },

  };

  function checkRemoteEligible() {
    var str = dryerData[dryerDataIndex]?.PLC_Version.split(".");
    // return ((parseInt(str[0])>=18)&&(parseInt(str[2])>=1200)&&(parseInt(str[2])>=010))?true:false;
    //return (((parseInt(str[0])>=18)&&(parseInt(str[3])>=01))||(parseInt(str[0])>=19))?true:false;
    // does 01 == 0o1 ? octal
    if(str){
      return (((parseInt(str[0]) >= 18) && (parseInt(str[3]) >= 0o1)) || (parseInt(str[0]) >= 19)) ? true : false;	// Now support S7 200
    } else {
      return false;
    }
    
    // return true;
  }

  useEffect(() => {
    if (dryerData) {
      let remote_eligible = checkRemoteEligible();
      if (remote_eligible) {
        setRemoteEligible(true);
      }
    }
  }, [dryerData])

  const [hover, setHover] = useState(false)

  const handleMouseEnter = () => {
    setHover(true);
  }
  const handleMouseLeave = () => {
    setHover(false);
  }
  
  const navigateHome = () =>{
    if(view != constants.SINGLE_VIEW){
      navigate('/dashboard/' + constants.SINGLE_VIEW, { replace: false });
    }
    
  }
  return (
    <AppBar

      style={{ paddingBottom: '0', paddingTop: '0' }}
      position={"sticky"}
      //position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}

    >
      <Card id="dash-nav" className={darkMode ? "dark-nav" : "light-nav"}>
        <Toolbar sx={(theme) => navbarContainer(theme)} style={{ paddingLeft: '0', paddingRight: '0' }}>
          {/* <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox> */}
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {isMini ? null : (

                <MDBox color={light ? "white" : "inherit"} style={{ display: 'flex', justifyContent: 'start', height: '100%', paddingLeft: '16px' }}>
                  {dryerData.length > 1 ?
                    <>
                      <IconButton
                        id="IconId"
                        size="small"
                        disableRipple
                        color="inherit"
                        sx={navbarMobileMenu}
                        onClick={handleViewClick}
                      >
                        <Icon id="test-hover" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} sx={iconsStyle} style={{ color: darkMode ? "white" : "", display: 'flex' }} fontSize="medium">
                          {sideView == constants.SINGLE_VIEW ? <img src={hover ? single_view_gray : single} style={{ width: '24px' }} />
                            : sideView == constants.MULTI_VIEW ? <img src={hover ? multi_view_gray : multi} style={{ width: '24px' }} />
                              : sideView == constants.LIST_VIEW ? <img src={hover ? list_view_gray : list} style={{ width: '24px' }} />
                                : <img src={hover ? single_view_gray : single} style={{ width: '24px' }} />}
                        </Icon>
                      </IconButton>
                    </>
                    :
                    <></>
                  }
                  {auth.USER_TYPE_ID == 1 && remoteEligible && dryerData[dryerDataIndex]?.Dryer_Record_ID == '806' ?
                    <>
                      <IconButton
                        style={{ fontSize: '22px' }}
                        size="small"
                        disableRipple
                        sx={navbarIconButton}
                        aria-controls="notification-menu"
                        aria-haspopup="true"
                        variant="contained"
                        onClick={handleModal}
                      >
                        <div className="tooltip">
                          <Icon className="button-hover" sx={iconsStyle} style={{ color: darkMode ? 'white' : 'black' }}>settings_remote</Icon>
                          <span className="tooltiptext">Remote Control</span>
                        </div>
                      </IconButton>
                      <RemoteCard handleModal={handleModal} open={open} /></>
                    :
                    <></>}
                </MDBox>

              )}
            </Grid>

            <Grid item xs={4}>
              <MDBox style={{ display: 'flex', justifyContent: 'center', maxHeight: '50px', paddingTop: '8px', paddingBotom: '8px' }}>
                <MDBox component="img" onClick={navigateHome} src={darkMode ? mctrax_logo_white : mctrax_logo} style={{ cursor: 'pointer', objectFit: 'contain', width:'100px' }} />
              </MDBox>
            </Grid>
            <Grid item xs={4}>
              <MDBox style={{ display: 'flex', justifyContent: 'end', height: '100%', paddingRight: '16px' }}>

                {activeAlarms.length > 0 && (view == constants.SINGLE_VIEW || view == constants.ALERT_VIEW) ?
                  <>
                    <IconButton
                      style={{ fontSize: '22px', marginRight: '12px' }}
                      size="small"
                      disableRipple
                      sx={navbarIconButton}
                      aria-controls="notification-menu"
                      aria-haspopup="true"
                      variant="contained"
                      onClick={handleOpenMenu}
                    >
                      {/* <Badge badgeContent={activeAlarms.length} color="primary"> */}


                      <Icon sx={iconsStyle} className={maxAlarm == 3 ? "alert-icon-color-red" : maxAlarm == 2 ? "alert-icon-color-orange" : "alert-icon-color-yellow"} style={{}}>notifications</Icon>
                      {/* </Badge> */}
                    </IconButton>
                    <MDBox style={styles.alertCount}>
                      {activeAlarms.length}
                    </MDBox>
                    {renderMenu()}
                    <IconButton
                      size="small"
                      disableRipple
                      color="inherit"
                      sx={navbarIconButton}
                      onClick={handleConfiguratorOpen}
                    >
                      <Icon className="button-hover" sx={iconsStyle} style={{ color: darkMode ? "white" : "black" }} fontSize="medium">
                        {openConfigurator ? "menu" : "menu_open"}
                      </Icon>
                    </IconButton>
                  </>
                  : activeAlarms.length == 0 && (view == constants.SINGLE_VIEW || view == constants.ALERT_VIEW) ?
                    <>
                      <IconButton
                        style={{ fontSize: '22px', marginRight: '12px' }}
                        size="small"
                        disableRipple
                        sx={navbarIconButton}
                        aria-controls="notification-menu"
                        aria-haspopup="true"
                        variant="contained"
                        onClick={handleOpenMenu}
                      >
                        {/* <Badge badgeContent={activeAlarms.length} color="primary"> */}
                        <Icon sx={iconsStyle} style={{}}>notifications</Icon>
                        {/* </Badge> */}
                      </IconButton>
                      <MDBox style={styles.alertCount}>
                        {activeAlarms.length}
                      </MDBox>
                      {renderMenu()}
                      <IconButton
                        size="small"
                        disableRipple
                        color="inherit"
                        sx={navbarIconButton}
                        onClick={handleConfiguratorOpen}
                      >
                        <Icon className="button-hover" sx={iconsStyle} style={{ color: darkMode ? "white" : "black" }} fontSize="medium">
                          {openConfigurator ? "menu" : "menu_open"}
                        </Icon>
                      </IconButton>
                    </>
                    :
                    <>
                      <IconButton
                        size="small"
                        disableRipple
                        color="inherit"
                        sx={navbarIconButton}
                        onClick={handleConfiguratorOpen}
                      >
                        <Icon className="button-hover" sx={iconsStyle} style={{ color: darkMode ? "white" : "black" }} fontSize="medium">
                          {openConfigurator ? "menu" : "menu_open"}
                        </Icon>
                      </IconButton>
                    </>
                }
              </MDBox>
            </Grid>
          </Grid>
        </Toolbar>
      </Card>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
