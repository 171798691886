/**
=========================================================
* Material Dashboard 2  React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useMemo, useState } from "react";
// porp-types is a library for typechecking of props
import PropTypes from "prop-types";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useLogout from "hooks/useLogout";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CircularProgress from '@mui/material/CircularProgress';
import { useMaterialUIController, setOpenConfigurator } from "context";
import { LineChart } from '@mui/x-charts/LineChart';
import constants from "../../../../constants";

function DryerLineChart({ index, isFlipped }) {
  const [controller, dispatch] = useMaterialUIController();
  const [isVisible, setIsVisible] = useState(true);
  const [chartData, setchartData] = useState([]);
  const {
    dryerData,
    darkMode,
    auth,
    view,
  } = controller;

  const axiosPrivate = useAxiosPrivate();

  const [tf, setTf] = useState('180');
  const generateTimeSeries = (interval) => {
    let today = new Date();
    //console.log(today)
    // set first timestamp
    // Get current minute
    // if m < 15 round to 0
    // if 15 < m < 30 round to 15
    // if 30 < m < 45 round to 30
    // if 45 < m < 59 round to 45
    // months are 0-11 not 1-12

    let dateArray = [];

    let m = today.getMinutes();
    let first = '';
    //console.log(today.getMonth() + ' ' + today.getDate() + ', ' + today.getFullYear() + ' ' + today.getHours()+':00:00')
    if (m < 15) {
      //first = today.getMonth()+1 + '/' + today.getDate() + " " + today.getHours() + ":" + '00'
      first = new Date(today.getMonth() + ' ' + today.getDate() + ', ' + today.getFullYear() + ' ' + today.getHours() + ':00:00')
    } else if (m >= 15 && m < 30) {
      //first = today.getMonth()+1 + '/' + today.getDate() + " " + today.getHours() + ":" + '15' 
      first = new Date(today.getMonth() + ' ' + today.getDate() + ', ' + today.getFullYear() + ' ' + today.getHours() + ':15:00')
    } else if (m >= 30 && m < 45) {
      //first = today.getMonth()+1 + '/' + today.getDate() + " " + today.getHours() + ":" + '30' 
      first = new Date(today.getMonth() + ' ' + today.getDate() + ', ' + today.getFullYear() + ' ' + today.getHours() + ':30:00')
    } else {
      //first = today.getMonth()+1 + '/' + today.getDate() + " " + today.getHours() + ":" + '45' 
      first = new Date(today.getMonth() + ' ' + today.getDate() + ', ' + today.getFullYear() + ' ' + today.getHours() + ':45:00')
    }

    //console.log('first: ' + first)

    // now loop 15 time with last date -15m and save to array
    var MS_PER_MINUTE = 60000;
    let second = new Date(first - 15 * MS_PER_MINUTE)

    dateArray.push(first);

    let count = 0;
    // 15 for 3 hours
    // 30 for 6 hours
    // 60 for 12 hours
    // 120 for 24 hours
    while (dateArray.length < 15) {
      let date = new Date(dateArray[count] - 15 * MS_PER_MINUTE)
      dateArray.push(date);
      count++;
    }

    let timeSeries = []

    dateArray.forEach((date) => {
      let hour = date.getHours();
      let min = date.getMinutes() == 0 ? '00' : date.getMinutes();
      let time = hour + ':' + min;
      //console.log(time);
      timeSeries.unshift(time);
    })

    return timeSeries;
  }
  const generateData = (type, timeframe) => {
    let data = [];

    switch (type) {
      case 'Grain_Infeed_Moisture':
        //console.log('Grain_Infeed_Moisture')
        chartData.forEach((element) => {
          data.push(element.Grain_Infeed_Moisture);
        });
        break;
      case 'Grain_Discharge_Moisture':
        chartData.forEach((element) => {
          data.push(element.Grain_Discharge_Moisture);
        });
        break;
      case 'Discharge_Commanded':
        chartData.forEach((element) => {
          data.push(element.Discharge_Commanded);
        });
        break;
      case 'Disch_Moist_SP':
        chartData.forEach((element) => {
          data.push(element.Disch_Moist_SP);
        });
        break;
      case 'time':
        chartData.forEach((element) => {
          data.push(element.time);
        });
        break;
      case 'Mid_Tmp_SP':
        chartData.forEach((element) => {
          data.push(element.Mid_Tmp_SP);
        });
        break;
      case 'Grain_Average_Mid_Temp':
        chartData.forEach((element) => {
          data.push(element.Grain_Average_Mid_Temp);
        });
        break;
      default:
        break;

    }
    return data;
  }
  const logout = useLogout();
  const getChartData = async () => {

    let record_id = dryerData[index].Dryer_Record_ID;
    try {
      if (auth?.accessToken) {

        const response = await axiosPrivate.post('/getChartData', JSON.stringify({ record_id: record_id, timeframe: "180", }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          });
        setchartData(response.data)
      }

    } catch (e) {
      if (e.response.status == 403 || e.response.status == 401) {
        await logout();
      } else {
        console.log(e);
      }
    }
  };


  const checkVisible = (elm) => {
    var rect = elm.getBoundingClientRect();
    var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
  }

  const [firstPageLoad, setFirstPageLoad] = useState(true)

  useEffect(()=>{
    if(firstPageLoad && !isFlipped){
      getChartData();
      setFirstPageLoad(false);
    }
  },[])

  useEffect(() => {

    let serial = dryerData[index]?.Dryer_Serial_Name;

    let element = document.getElementById(serial + '-charts');

    //setIsVisible(checkVisible(element))

    if (!document.hidden) {
      if (dryerData[index]?.runData?.Op_Mode != "Stopped" && dryerData[index]?.runData?.Op_Mode != null) {
        if (view == constants.MULTI_VIEW) {

          //if the card is not flipped and the chart is visible
          //if (!isFlipped && isVisible && !firstPageLoad) {
          // if (!isFlipped && !firstPageLoad) {
          if (!firstPageLoad) {
          //if (!isFlipped) {
            getChartData();
          }

        }
      }
    }

  }, [dryerData])


  const chartsParams = {
    margin: { top: 20, bottom: 30, left: 30, right: 15 },
    height: 150,
  };

  // "& .MuiChartsLegend-series text": { fontSize: "0.5em !important",fill: darkMode ? "white !important" : "black !important"  },
  return (
    <MDBox id={dryerData[index]?.Dryer_Serial_Name + '-charts'}>

      {chartData.length <= 1 ?
        <>
          {/* <MDTypography style={{ width: '100%', height: '300px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
            No data to display
          </MDTypography> */}
          <MDBox style={{display:'flex', justifyContent:'center', padding:'24px'}}><CircularProgress /></MDBox>
        </>
        :
        <>
          {/* <MDTypography style={{ paddingTop: '15px', paddingLeft: '15px', marginBottom: '-24px' }}>Moisture Trending</MDTypography> */}
          <LineChart skipAnimation
            {...chartsParams}
            sx={{
              // y axis tick label color
              "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                fill: darkMode ? "white" : "black"
              },
              // x axis tick lable color
              "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                fill: darkMode ? "white" : "black"
              },
              // y axis tick color
              "& .MuiChartsAxis-left .MuiChartsAxis-tick": {
                stroke: darkMode ? "white" : "black"
              },
              // x axis tick color
              "& .MuiChartsAxis-bottom .MuiChartsAxis-tick": {
                stroke: darkMode ? "transparent" : "transparent"
              },
              // x axis line color
              "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                stroke: darkMode ? "white" : "black"
              },
              // y axis line color
              "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                stroke: darkMode ? "white" : "black"
              }
            }}
            yAxis={[
              {
                min: 0,
              },
            ]}
            xAxis={[{ scaleType: 'point', data: generateData('time') }]}
            slotProps={{
              legend: { hidden: true },
              popper: {
                placement: "top",
                sx: {
                  //border: '1px solid white',
                  // borderRadius: '20px',
                  // backgroundColor: 'red',
                  '& .MuiChartsTooltip-root': {
                    backgroundColor: darkMode ? '#232323' : '#D2D2D2',
                    border: darkMode ? '1px solid white' : '',
                    '& .MuiTypography-root': {
                      color: darkMode ? "#ffffff" : "#000000",
                    },
                  },
                },
              },
            }}
            series={[
              {
                label: 'Infeed Moisture',
                data: generateData('Grain_Infeed_Moisture'),
                showMark: false,
                color: '#3D81C7'
              },
              {
                label: 'Discharge Moisture',
                data: generateData('Grain_Discharge_Moisture'),
                showMark: false,
                color: "#CC3E3E"
              },
              {
                label: 'Mid Grain Avg Tem',
                data: generateData('Grain_Average_Mid_Temp'),
                showMark: false,
                color: "#EEEB18"
              },
            ]}
          />
          <LineChart skipAnimation
            {...chartsParams}
            sx={{
              // y axis tick label color
              "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
                fill: darkMode ? "white" : "black"
              },
              // x axis tick lable color
              "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                fill: darkMode ? "white" : "black"
              },
              // y axis tick color
              "& .MuiChartsAxis-left .MuiChartsAxis-tick": {
                stroke: darkMode ? "white" : "black"
              },
              // x axis tick color
              "& .MuiChartsAxis-bottom .MuiChartsAxis-tick": {
                stroke: darkMode ? "transparent" : "transparent"
              },
              // x axis line color
              "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                stroke: darkMode ? "white" : "black"
              },
              // y axis line color
              "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                stroke: darkMode ? "white" : "black"
              }
            }}
            yAxis={[
              {
                min: 0,
                max: 100
              },
            ]}
            xAxis={[{ scaleType: 'point', data: generateData('time') }]}
            slotProps={{
              legend: { hidden: true },
              popper: {
                placement: "top",
                sx: {
                  //border: '1px solid white',
                  // borderRadius: '20px',
                  // backgroundColor: 'red',
                  '& .MuiChartsTooltip-root': {
                    backgroundColor: darkMode ? '#232323' : '#D2D2D2',
                    border: darkMode ? '1px solid white' : '',
                    '& .MuiTypography-root': {
                      color: darkMode ? "#ffffff" : "#000000",
                    },
                  },
                },
              },
            }}
            series={[
              {
                label: 'Discharge Speed',
                data: generateData('Discharge_Commanded'),
                showMark: false,
                color: "#41C330"
              },
            ]}
          />
        </>
      }

    </MDBox>
  );
}

// Setting default values for the props of ReportsLineChart
DryerLineChart.defaultProps = {
  color: "info",
  description: "",
};

// Typechecking props for the ReportsLineChart
DryerLineChart.propTypes = {
  index: PropTypes.number.isRequired,
  isFlipped: PropTypes.bool,
  // color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  // title: PropTypes.string.isRequired,
  // description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // date: PropTypes.string.isRequired,
  // chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default DryerLineChart;
