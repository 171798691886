/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import AdminSidenav from "examples/AdminSidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import { routes, adminRoutes, testCreateSingleRoutes } from "routes";


import constants from "./constants";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

import PersistLogin from "components/PersistLogin";

import RequireAuth from "components/RequireAuth";

import Dashboard from "layouts/dashboard7";

import FeedbackCard from "examples/Cards/FeedbackCards";
import FeedbackView from "layouts/admin/components/FeedbackView";
import AlertView from "layouts/dashboard7/components/alertView"
import RemoteView from 'layouts/dashboard7/components/remoteView';

import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
  setView,
  setDarkMode
} from "context";
import MultiView from "layouts/dashboard7/components/multiView";
import SingleView from "layouts/dashboard7/components/singleView";
import TableView from "layouts/dashboard7/components/tableView";
import AccountView from "layouts/dashboard7/components/accountView";
import Reports from "layouts/reports";
import AdminDryerList from "layouts/admin/components/AdminDryerList";
import AdminUserList from "layouts/admin/components/AdminUserList";
import AdminView3 from "layouts/admin/components/AdminView3";
import AdminView4 from "layouts/admin/components/AdminView4";
import AdminView5 from "layouts/admin/components/AdminView5";
import Admin from "layouts/admin";
import ReportView from "layouts/dashboard7/components/reportView";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    isLoading,
    view,
    auth,
    serviceLogin
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const getView = () => localStorage.getItem('view');
  // localStorage.setItem('view', constants.);
  // for browser navigation
  useEffect(() => {
    if (view == "") {
      let path = location.pathname;
      if (path) {
        localStorage.setItem('view', path);
      } else {
        localStorage.setItem('view', constants.SINGLE_VIEW);
      }

    }

    switch (view) {
      case constants.SINGLE_VIEW: {
        if (location.pathname != "/dashboard/" + constants.SINGLE_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.SINGLE_VIEW);
          navigate('/dashboard/' + constants.SINGLE_VIEW, { replace: false });
        }

        break;
      }
      case constants.MULTI_VIEW: {
        if (location.pathname != "/dashboard/" + constants.MULTI_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.MULTI_VIEW);
          navigate('/dashboard/' + constants.MULTI_VIEW, { replace: false });
        }
        break;
      }
      case constants.LIST_VIEW: {
        if (location.pathname != "/dashboard/" + constants.LIST_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.LIST_VIEW);
          navigate('/dashboard/' + constants.LIST_VIEW, { replace: false });
        }
        break;
      }
      case constants.ALERT_VIEW: {
        if (location.pathname != "/dashboard/" + constants.ALERT_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.ALERT_VIEW);
          navigate('/dashboard/' + constants.ALERT_VIEW, { replace: false });
        }
        break;
      }
      case constants.REMOTE_VIEW: {
        if (location.pathname != "/dashboard/" + constants.REMOTE_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.REMOTE_VIEW);
          navigate('/dashboard/' + constants.REMOTE_VIEW, { replace: false });
        }
        break;
      }
      case constants.ACCOUNT_VIEW: {
        if (location.pathname != "/dashboard/" + constants.ACCOUNT_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.ACCOUNT_VIEW);
          navigate('/dashboard/' + constants.ACCOUNT_VIEW, { replace: false });
        }
        break;
      }
      case constants.REPORT_VIEW: {
        if (location.pathname != "/dashboard/" + constants.REPORT_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.REPORT_VIEW);
          navigate('/dashboard/' + constants.REPORT_VIEW, { replace: false });
        }
        break;
      }
      case constants.ADMIN_VIEW_1: {
        if (location.pathname != "/admin/" + constants.ADMIN_VIEW_1) {
          localStorage.setItem('view', "/admin/" + constants.ADMIN_VIEW_1);
          navigate('/admin/' + constants.ADMIN_VIEW_1, { replace: false });
        }
        break;
      }
      case constants.ADMIN_VIEW_2: {
        if (location.pathname != "/admin/" + constants.ADMIN_VIEW_2) {
          localStorage.setItem('view', "/admin/" + constants.ADMIN_VIEW_2);
          navigate('/admin/' + constants.ADMIN_VIEW_2, { replace: false });
        }
        break;
      }
      case constants.ADMIN_VIEW_3: {
        if (location.pathname != "/admin/" + constants.ADMIN_VIEW_3) {
          localStorage.setItem('view', "/admin/" + constants.ADMIN_VIEW_3);
          navigate('/admin/' + constants.ADMIN_VIEW_3, { replace: false });
        }
        break;
      }
      case constants.ADMIN_VIEW_4: {
        if (location.pathname != "/admin/" + constants.ADMIN_VIEW_4) {
          localStorage.setItem('view', "/admin/" + constants.ADMIN_VIEW_4);
          navigate('/admin/' + constants.ADMIN_VIEW_4, { replace: false });
        }
        break;
      }
      case constants.ADMIN_VIEW_5: {
        if (location.pathname != "/admin/" + constants.ADMIN_VIEW_5) {
          localStorage.setItem('view', "/admin/" + constants.ADMIN_VIEW_5);
          navigate('/admin/' + constants.ADMIN_VIEW_5, { replace: false });
        }
        break;
      }
      case constants.FEEDBACK_VIEW: {
        if (location.pathname != "/admin/" + constants.FEEDBACK_VIEW) {
          localStorage.setItem('view', "/admin/" + constants.FEEDBACK_VIEW);
          navigate('/admin/' + constants.FEEDBACK_VIEW, { replace: false });
        }
        break;
      }
      default: {
        let localView = getView();
        if (localView) {
          if (localView.includes("admin")) {
            if (location.pathname != localView) {
              navigate(localView, { replace: false });
            }
          } else {
            if (localView.includes("admin")) {
              if (location.pathname != "/dashboard/" + constants.SINGLE_VIEW) {
                navigate('/dashboard/' + constants.SINGLE_VIEW, { replace: false });
              }
            } else {
              if (location.pathname != localView) {
                navigate(localView, { replace: false });
              }
            }

          }

        } else {
          if (location.pathname != "/dashboard/" + constants.SINGLE_VIEW) {
            navigate('/dashboard/' + constants.SINGLE_VIEW, { replace: false });
          }
        }
        break;
      }
    }

  }, [view])



  useEffect(() => {
    switch (location.pathname) {
      case "/dashboard/" + constants.SINGLE_VIEW: {
        if (view != constants.SINGLE_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.SINGLE_VIEW);
          setView(dispatch, constants.SINGLE_VIEW);
        }
        break;
      }
      case "/dashboard/" + constants.MULTI_VIEW: {
        if (view != constants.MULTI_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.MULTI_VIEW);
          setView(dispatch, constants.MULTI_VIEW);
        }
        break;
      }
      case "/dashboard/" + constants.LIST_VIEW: {
        if (view != constants.LIST_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.LIST_VIEW);
          setView(dispatch, constants.LIST_VIEW);
        }
        break;
      }
      case "/dashboard/" + constants.ALERT_VIEW: {
        if (view != constants.ALERT_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.ALERT_VIEW);
          setView(dispatch, constants.ALERT_VIEW);
        }
        break;
      }
      case "/dashboard/" + constants.REMOTE_VIEW: {
        if (view != constants.REMOTE_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.REMOTE_VIEW);
          setView(dispatch, constants.REMOTE_VIEW);
        }
        break;
      }
      case "/dashboard/" + constants.ACCOUNT_VIEW: {
        if (view != constants.ACCOUNT_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.ACCOUNT_VIEW);
          setView(dispatch, constants.ACCOUNT_VIEW);
        }
        break;
      }
      case "/dashboard/" + constants.REPORT_VIEW: {
        if (view != constants.REPORT_VIEW) {
          localStorage.setItem('view', "/dashboard/" + constants.REPORT_VIEW);
          setView(dispatch, constants.REPORT_VIEW);
        }
        break;
      }
      case "/admin/" + constants.ADMIN_VIEW_1: {
        if (view != constants.ADMIN_VIEW_1) {
          localStorage.setItem('view', "/admin/" + constants.ADMIN_VIEW_1);
          setView(dispatch, constants.ADMIN_VIEW_1);
        }
        break;
      }
      case "/admin/" + constants.ADMIN_VIEW_2: {
        if (view != constants.ADMIN_VIEW_2) {
          localStorage.setItem('view', "/admin/" + constants.ADMIN_VIEW_2);
          setView(dispatch, constants.ADMIN_VIEW_2);
        }
        break;
      }
      case "/admin/" + constants.ADMIN_VIEW_3: {
        if (view != constants.ADMIN_VIEW_3) {
          localStorage.setItem('view', "/admin/" + constants.ADMIN_VIEW_3);
          setView(dispatch, constants.ADMIN_VIEW_3);
        }
        break;
      }
      case "/admin/" + constants.ADMIN_VIEW_4: {
        if (view != constants.ADMIN_VIEW_4) {
          localStorage.setItem('view', "/admin/" + constants.ADMIN_VIEW_4);
          setView(dispatch, constants.ADMIN_VIEW_4);
        }
        break;
      }
      case "/admin/" + constants.ADMIN_VIEW_5: {
        if (view != constants.ADMIN_VIEW_5) {
          localStorage.setItem('view', "/admin/" + constants.ADMIN_VIEW_5);
          setView(dispatch, constants.ADMIN_VIEW_5);
        }
        break;
      }
      case "/admin/" + constants.FEEDBACK_VIEW: {
        if (view != constants.FEEDBACK_VIEW) {
          localStorage.setItem('view', "/admin/" + constants.FEEDBACK_VIEW);
          setView(dispatch, constants.FEEDBACK_VIEW);
        }
        break;
      }
      default: {
        if (view != constants.SINGLE_VIEW) {
          setView(dispatch, constants.SINGLE_VIEW);
        }
        break;
      }
    }

  }, [location.pathname]);

  // fixes touch scrolling at the edge of the screen for mobile
  useEffect(() => {
    const onTouch = (e) => {
      // is not near edge of view, exit
      if (e.pageX > 10 && e.pageX < window.innerWidth - 10) return;

      // prevent swipe to navigate gesture
      e.preventDefault();
    }

    return () => {
      const element = document.querySelector('#app');
      element.addEventListener('touchstart', onTouch);
    }
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((testCreateSingleRoutes) => {
      if (testCreateSingleRoutes.collapse) {
        return getRoutes(testCreateSingleRoutes.collapse);
      }

      if (testCreateSingleRoutes.route) {
        return <Route exact path={testCreateSingleRoutes.route} element={testCreateSingleRoutes.component} key={testCreateSingleRoutes.key} />;
      }

      return null;
    });

  // follow os for dark mode or use selected mode if avalible
  const getMode = () => localStorage.getItem('darkMode');

  useEffect(() => {
    let dark = getMode();
    if (dark == 'false') {
      setDarkMode(dispatch, !darkMode);
    }
  }, [])

  const [open, setOpen] = useState(false);
  const handleModal = () => setOpen(!open);

  const feedbackButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor={darkMode ? "white" : "black"}
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="1.75rem"
      bottom="1rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleModal}
    >
      <Icon style={{ color: darkMode ? 'black' : 'white' }} fontSize="small" color="inherit">
        feedback
      </Icon>
    </MDBox>
  );

  let body = document.body;
  body.classList = darkMode ? "dark-scroll" : "light-scroll"

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            id="SidenavId"
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="MCTrax"
            // singleRoutes={singleRoutes}
            // multiRoutes={multiRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {!isLoading ? <>{feedbackButton}</> : <></>}

          <FeedbackCard handleModal={handleModal} open={open} />
        </>
      )}
      {layout === "admin" && (
        <>
          <AdminSidenav
            id="AdminSidenavId"
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="MCTrax"
            // singleRoutes={singleRoutes}
            // multiRoutes={multiRoutes}
            routes={adminRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            {/* {getRoutes(testCreateSingleRoutes(dryerData))} */}
            {/* {getRoutes(singleRoutes)} */}
            {/* {getRoutes(multiRoutes)} */}
            <Route path="/" element={<Navigate to={"dashboard"} />} />
            <Route path="/dashboard" element={<Dashboard />} >
              <Route
                path={"/dashboard/" + constants.ALERT_VIEW}
                element={<AlertView />}
              />
              <Route
                path={"/dashboard/" + constants.REMOTE_VIEW}
                element={<RemoteView />}
              />
              <Route
                path={"/dashboard/" + constants.SINGLE_VIEW}
                element={<SingleView />}
              />
              <Route
                path={"/dashboard/" + constants.MULTI_VIEW}
                element={<MultiView />}
              />
              <Route
                path={"/dashboard/" + constants.LIST_VIEW}
                element={<TableView />}
              />
              <Route
                path={"/dashboard/" + constants.ACCOUNT_VIEW}
                element={<AccountView />}
              />
              <Route
                path={"/dashboard/" + constants.REPORT_VIEW}
                element={<ReportView />}
              />
            </Route>

            <Route path="/admin" element={<Admin />} >
              <Route
                path={"/admin/" + constants.ADMIN_VIEW_1}
                element={<AdminDryerList />}
              // element={<AdminView1 />}
              />
              <Route
                path={"/admin/" + constants.ADMIN_VIEW_2}
                element={<AdminUserList />}
              // element={<AdminView2 />}
              />
              <Route
                path={"/admin/" + constants.ADMIN_VIEW_3}
                element={<AdminView3 />}
              // element={<AdminView3 />}
              />
              <Route
                path={"/admin/" + constants.ADMIN_VIEW_4}
                element={<AdminView4 />}
              // element={<AdminView4 />}
              />
              <Route
                path={"/admin/" + constants.ADMIN_VIEW_5}
                element={<AdminView5 />}
              // element={<AdminView5 />}
              />
              <Route
                path={"/admin/" + constants.FEEDBACK_VIEW}
                element={<FeedbackView />}
              />
            </Route>

            {/* <Route path="/feedback" element={<Dashboard />} action={() => {setView(dispatch, "feedback")}} /> */}
            {/* <Route path="/" element={<Navigate to={singleRoutes[0].route} />} /> */}
          </Route>
        </Route>
        {getRoutes(routes)}
        {/* <Route path="*" element={<Missing />} /> */}
      </Routes>
    </ThemeProvider>
  );
}
