/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import axios from "../../../../api/axios";
import plenum_temp_svg from '../../../../assets/images/mc/plenum-temp.svg';
import React, { useEffect, useState } from "react";

export default function data() {

  return {
    columns: [
      { Header: "p1", accessor: "p1", align: "left" },
      { Header: "p2", accessor: "p2", align: "center" },
      { Header: "p3", accessor: "p3", align: "center" },
      { Header: "p4", accessor: "p4", align: "center" },
      { Header: "p5", accessor: "p5", align: "center" },
      { Header: "p6", accessor: "p6", align: "center" },
      { Header: "p7", accessor: "p7", align: "center" },
      { Header: "p8", accessor: "p8", align: "center" },
    ],
    
    rows: [
        {
          time: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              08:12
            </MDTypography>
          ),
          speed: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              50.0
            </MDTypography>
          ),
          mode: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              Stopped
            </MDTypography>
          ),
          moist: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              54.0
            </MDTypography>
          ),
          sp: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              (N/A)
            </MDTypography>
          ),
          temp: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              57
            </MDTypography>
          ),
          moist1: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              54.0
            </MDTypography>
          ),
          temp1: (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              57
            </MDTypography>
          ),
        },
    ],

    plenums:[
        {
            temp: '208',
            mode: 'Heat',
            icon:(
                <img src={plenum_temp_svg} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
            )
        },
        {
            temp: '208',
            mode: 'Heat',
            icon:(
                <img src={plenum_temp_svg} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
            )
        },
        {
            temp: '208',
            mode: 'Heat',
            icon:(
                <img src={plenum_temp_svg} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
            )
        },
        {
            temp: '208',
            mode: 'Heat',
            icon:(
                <img src={plenum_temp_svg} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
            )
        },
        {
            temp: '208',
            mode: 'Heat',
            icon:(
                <img src={plenum_temp_svg} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
            )
        },
        {
            temp: '208',
            mode: 'Heat',
            icon:(
                <img src={plenum_temp_svg} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
            )
        },
        {
            temp: '208',
            mode: 'Heat',
            icon:(
                <img src={plenum_temp_svg} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
            )
        },
                {
            temp: '208',
            mode: 'Heat',
            icon:(
                <img src={plenum_temp_svg} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
            )
        }
    ],
  };
}
