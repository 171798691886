/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import '../../App.css';

// @mui material components
import Grid from "@mui/material/Grid";

import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography"
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React example components
import AdminLayout from "examples/LayoutContainers/AdminLayout";

import React, { useEffect, useState } from "react";

import PropTypes, { object } from 'prop-types';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import {
  useMaterialUIController,

} from "context";
import constants from "../../constants";
import { Outlet } from 'react-router-dom';


function Admin() {
  const axiosPrivate = useAxiosPrivate();

  const [controller, dispatch] = useMaterialUIController();
  const {
    auth,
    view,
    darkMode,
    serviceLogin,

  } = controller;

  const navigate = useNavigate();
  useEffect(()=>{
    if(auth.USER_TYPE_ID < serviceLogin){
      navigate('/dashboard/' + constants.SINGLE_VIEW, { replace: false });
    }
  },[])


  return (
    <AdminLayout>
      <DashboardNavbar />
        <>
          <Outlet />
        </>
      
    </AdminLayout>
  );
}

Admin.propTypes = {

};

export default Admin;
