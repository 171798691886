/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";

import '../../../../App.css';
import plenum_temp_svg from '../../../../assets/images/mc/plenum-temp.svg';
import setpoint_svg from '../../../../assets/images/mc/setpoint.svg'

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Grid } from "@mui/material";
import Icon from "@mui/material/Icon";

import plenumTableData from "../DryerCardData/DryerPlenumData";
import { useMaterialUIController } from "context";
import setpoint_svg_dark from '../../../../assets/images/mc/setpoint-dark.svg'
import plenum_svg_1 from '../../../../assets/images/mc/Plenum-1.svg'
import plenum_svg_2 from '../../../../assets/images/mc/Plenum-2.svg'
import plenum_svg_3 from '../../../../assets/images/mc/Plenum-3.svg'
import plenum_svg_4 from '../../../../assets/images/mc/Plenum-4.svg'
import plenum_svg_5 from '../../../../assets/images/mc/Plenum-5.svg'
import plenum_svg_6 from '../../../../assets/images/mc/Plenum-6.svg'
import plenum_svg_7 from '../../../../assets/images/mc/Plenum-7.svg'
import plenum_svg_8 from '../../../../assets/images/mc/Plenum-8.svg'

import plenum_svg_top from '../../../../assets/images/mc/plenum-top.svg'
import plenum_svg_bot from '../../../../assets/images/mc/plenum-bot.svg'

import CardBackCommingSoon from "./CardBackComingSoon";


function CardBackPlenum({ index, handleFlip, destination, comingSoon }) {

    const { columns, rows, plenums } = plenumTableData();
    const [controller, dispatch] = useMaterialUIController();
    const {
        dryerData,
        darkMode
    } = controller;

    let serial = dryerData[index]?.Dryer_Serial_Name;
    let plenum_temp_avg = dryerData[index]?.plenumData?.plenum_temp_avg;

    let online = dryerData[index]?.Online_Status;
    let sub = dryerData[index]?.Sub_Status;

    let setpoint_svg_color = darkMode ? setpoint_svg_dark : setpoint_svg;
    let text_color = "#BEBEBE"

    let plenumSP1 = dryerData[index]?.plenumData?.PlenumTempSP_01;
    let plenumSP2 = dryerData[index]?.plenumData?.PlenumTempSP_02;
    let plenumSP3 = dryerData[index]?.plenumData?.PlenumTempSP_03;
    let plenumSP4 = dryerData[index]?.plenumData?.PlenumTempSP_04;
    let plenumSP5 = dryerData[index]?.plenumData?.PlenumTempSP_05;
    let plenumSP6 = dryerData[index]?.plenumData?.PlenumTempSP_06;
    let plenumSP7 = dryerData[index]?.plenumData?.PlenumTempSP_07;
    let plenumSP8 = dryerData[index]?.plenumData?.PlenumTempSP_08;

    let modeTop = dryerData[index]?.plenumData ? dryerData[index]?.plenumData?.plenum_top > 125 ? "HEAT" : "COOL" : 'N/A'
    let modeBot = dryerData[index]?.plenumData ? dryerData[index]?.plenumData?.plenum_bot > 125 ? "HEAT" : "COOL" : 'N/A'

    let mode1 = dryerData[index]?.plenumData ? dryerData[index]?.plenumData?.plenum_1 > 125 ? "HEAT" : "COOL" : 'N/A'
    let mode2 = dryerData[index]?.plenumData ? dryerData[index]?.plenumData?.plenum_2 > 125 ? "HEAT" : "COOL" : 'N/A'
    let mode3 = dryerData[index]?.plenumData ? dryerData[index]?.plenumData?.plenum_3 > 125 ? "HEAT" : "COOL" : 'N/A'
    let mode4 = dryerData[index]?.plenumData ? dryerData[index]?.plenumData?.plenum_4 > 125 ? "HEAT" : "COOL" : 'N/A'
    let mode5 = dryerData[index]?.plenumData ? dryerData[index]?.plenumData?.plenum_5 > 125 ? "HEAT" : "COOL" : 'N/A'
    let mode6 = dryerData[index]?.plenumData ? dryerData[index]?.plenumData?.plenum_6 > 125 ? "HEAT" : "COOL" : 'N/A'
    let mode7 = dryerData[index]?.plenumData ? dryerData[index]?.plenumData?.plenum_7 > 125 ? "HEAT" : "COOL" : 'N/A'
    let mode8 = dryerData[index]?.plenumData ? dryerData[index]?.plenumData?.plenum_8 > 125 ? "HEAT" : "COOL" : 'N/A'

    const getModeAvg = () => {

        let modeAvg = '';

        if (dryerData[index]?.plenumData) {
            // top and bot logic
            if (dryerData[index]?.plenumData?.plenum_top != undefined) {
                let modeTop = dryerData[index]?.plenumData?.plenum_top > 125 ? "HEAT" : "COOL"
                let modeBot = dryerData[index]?.plenumData?.plenum_bot > 125 ? "HEAT" : "COOL"
                if (modeTop == 'HEAT' && modeBot == 'HEAT') {
                    modeAvg = 'HEAT'
                } else if (modeTop == 'COOL' && modeBot == 'COOL') {
                    modeAvg = 'COOL'
                } else {
                    modeAvg = 'HEAT + COOL'
                }
            } else if (dryerData[index]?.plenumData?.plenum_2 == undefined) {

                if (dryerData[index]?.plenumData?.plenum_1 != undefined) {
                    modeAvg = dryerData[index]?.plenumData?.plenum_1 > 125 ? 'HEAT' : 'COOL'
                }

            } else {
                // everything else logic
                let arr = []
                if (dryerData[index]?.plenumData?.plenum_1 != undefined) {
                    let p1 = dryerData[index]?.plenumData?.plenum_1 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p1);
                }
                if (dryerData[index]?.plenumData?.plenum_2 != undefined) {
                    let p2 = dryerData[index]?.plenumData?.plenum_2 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p2);
                }
                if (dryerData[index]?.plenumData?.plenum_3 != undefined) {
                    let p3 = dryerData[index]?.plenumData?.plenum_3 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p3);
                }
                if (dryerData[index]?.plenumData?.plenum_4 != undefined) {
                    let p4 = dryerData[index]?.plenumData?.plenum_4 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p4);
                }
                if (dryerData[index]?.plenumData?.plenum_5 != undefined) {
                    let p5 = dryerData[index]?.plenumData?.plenum_5 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p5);
                }
                if (dryerData[index]?.plenumData?.plenum_6 != undefined) {
                    let p6 = dryerData[index]?.plenumData?.plenum_7 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p6);
                }
                if (dryerData[index]?.plenumData?.plenum_7 != undefined) {
                    let p7 = dryerData[index]?.plenumData?.plenum_7 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p7);
                }
                if (dryerData[index]?.plenumData?.plenum_8 != undefined) {
                    let p8 = dryerData[index]?.plenumData?.plenum_8 > 125 ? 'ALL HEAT' : 'COOL'
                    arr.push(p8);
                }

                arr.forEach((element) => {
                    if (modeAvg != 'HEAT + COOL') {
                        if (modeAvg == '') {
                            modeAvg = element;
                        } else {
                            if (modeAvg != element) {
                                modeAvg = 'HEAT + COOL'
                            }
                        }
                    }
                })

            }

        } else {
            modeAvg = 'N/A'
        }

        return modeAvg;

    }

    return (
        <>
            <MDBox
                display={{ xs: "block" }}
                position="absolute"
                top={0}
                right={0}
                p={1.625}
                onClick={(e) => handleFlip(e, serial, destination, index)}
                sx={{ cursor: "pointer" }}
            >
                <MDTypography variant="h6" color="secondary" style={{ fontSize: '22px' }}>
                    <Icon className="button-hover" style={{ color: darkMode ? "white" : "black" }}>undo</Icon>
                </MDTypography>
            </MDBox>
            {online == 0 || sub == 0 ?
                <MDTypography style={{ width: '100%', height: '300px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                    {sub == 0 ? 'Subscription expired' : 'Dryer offline'}
                </MDTypography>
                :
                <>
                    <MDBox id={serial + '-header'} px={2} pt={3}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} style={{ display: "flex" }}>
                                <Grid item xs={6} style={{ display: 'flex' }}>
                                    <Grid item xs={6} style={{ display: 'flex' }}>
                                        <img src={plenum_temp_svg} style={{ width: '50px', height: 'auto', objectFit: 'contain' }} />
                                    </Grid>
                                    <Grid item xs={6} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                        {plenum_temp_avg == null ?
                                            <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="40px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                N/A
                                            </MDTypography>
                                            :
                                            <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="40px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                <span>{plenum_temp_avg}<span style={{ fontWeight: '400' }}>&deg;</span></span>
                                            </MDTypography>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} pb={'10px'} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                    <MDTypography component="p" variant="button" display="flex" fontSize="12px" style={{ whiteSpace: 'nowrap', fontWeight: '400', justifyContent: 'space-evenly', color: '#BEBEBE' }} >
                                        {plenum_temp_avg != null ? <span style={{ color: text_color }}>{getModeAvg()}</span> : <span style={{ color: text_color }}>N/A</span>}
                                    </MDTypography>
                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly', color: '#BEBEBE' }}>
                                        <span>MODE</span>
                                    </MDTypography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <hr className="dryer-card-divider-multi" />
                    {comingSoon ?
                        <>
                            <CardBackCommingSoon />
                        </>
                        :
                        <>
                            <MDBox px={2} pt={2}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} >
                                        {dryerData[index]?.plenumData?.plenum_top ?
                                            <Grid item xs={12} style={{ display: "flex" }}>
                                                <Grid item xs={6} style={{ display: 'flex' }}>
                                                    <Grid item xs={4} style={{ display: 'flex' }}>
                                                        <img src={plenum_svg_2} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
                                                    </Grid>
                                                    <Grid item xs={8} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                        <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="30px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                            {dryerData[index]?.plenumData?.plenum_top}&deg;
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        <span style={{ color: text_color }}>{plenumSP2}&deg;</span>
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>SETPOINT</span>
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        {dryerData[index]?.plenumData?.plenum_top != 'N/A' ? <span style={{ color: text_color }}>{modeTop}</span> : <span style={{ color: text_color }}>N/A</span>}
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>MODE</span>
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                            :
                                            ''
                                        }
                                        {dryerData[index]?.plenumData?.plenum_bot ?
                                            <Grid item xs={12} style={{ display: "flex" }}>
                                                <Grid item xs={6} style={{ display: 'flex' }}>
                                                    <Grid item xs={4} style={{ display: 'flex' }}>
                                                        <img src={plenum_svg_1} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
                                                    </Grid>
                                                    <Grid item xs={8} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                        <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="30px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                            {dryerData[index]?.plenumData?.plenum_bot}&deg;
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        <span style={{ color: text_color }}>{plenumSP1}&deg;</span>
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>SETPOINT</span>
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        {dryerData[index]?.plenumData?.plenum_bot != 'N/A' ? <span style={{ color: text_color }}>{modeBot}</span> : <span style={{ color: text_color }}>N/A</span>}
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>MODE</span>
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                            :
                                            ''
                                        }
                                        {dryerData[index]?.plenumData?.plenum_8 ?
                                            <Grid item xs={12} style={{ display: "flex" }}>
                                                <Grid item xs={6} style={{ display: 'flex' }}>
                                                    <Grid item xs={6} style={{ display: 'flex' }}>
                                                        <img src={plenum_svg_8} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
                                                    </Grid>
                                                    <Grid item xs={6} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                        <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="30px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                            {dryerData[index]?.plenumData?.plenum_8}&deg;
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        <span style={{ color: text_color }}>{plenumSP8}&deg;</span>
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>SETPOINT</span>
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        {dryerData[index]?.plenumData?.plenum_6 != 'N/A' ? <span style={{ color: text_color }}>{mode8}</span> : <span style={{ color: text_color }}>N/A</span>}
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>MODE</span>
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                            :
                                            ''
                                        }
                                        {dryerData[index]?.plenumData?.plenum_7 ?
                                            <Grid item xs={12} style={{ display: "flex" }}>
                                                <Grid item xs={6} style={{ display: 'flex' }}>
                                                    <Grid item xs={6} style={{ display: 'flex' }}>
                                                        <img src={plenum_svg_7} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
                                                    </Grid>
                                                    <Grid item xs={6} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                        <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="30px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                            {dryerData[index]?.plenumData?.plenum_7}&deg;
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        <span style={{ color: text_color }}>{plenumSP7}&deg;</span>
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>SETPOINT</span>
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        {dryerData[index]?.plenumData?.plenum_7 != 'N/A' ? <span style={{ color: text_color }}>{mode7}</span> : <span style={{ color: text_color }}>N/A</span>}
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>MODE</span>
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                            :
                                            ''
                                        }
                                        {dryerData[index]?.plenumData?.plenum_6 ?
                                            <Grid item xs={12} style={{ display: "flex" }}>
                                                <Grid item xs={6} style={{ display: 'flex' }}>
                                                    <Grid item xs={6} style={{ display: 'flex' }}>
                                                        <img src={plenum_svg_6} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
                                                    </Grid>
                                                    <Grid item xs={6} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                        <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="30px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                            {dryerData[index]?.plenumData?.plenum_6}&deg;
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        <span style={{ color: text_color }}>{plenumSP6}&deg;</span>
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>SETPOINT</span>
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        {dryerData[index]?.plenumData?.plenum_6 != 'N/A' ? <span style={{ color: text_color }}>{mode6}</span> : <span style={{ color: text_color }}>N/A</span>}
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>MODE</span>
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                            :
                                            ''
                                        }
                                        {dryerData[index]?.plenumData?.plenum_5 ?
                                            <Grid item xs={12} style={{ display: "flex" }}>
                                                <Grid item xs={6} style={{ display: 'flex' }}>
                                                    <Grid item xs={6} style={{ display: 'flex' }}>
                                                        <img src={plenum_svg_5} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
                                                    </Grid>
                                                    <Grid item xs={6} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                        <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="30px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                            {dryerData[index]?.plenumData?.plenum_5}&deg;
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        <span style={{ color: text_color }}>{plenumSP5}&deg;</span>
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>SETPOINT</span>
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        {dryerData[index]?.plenumData?.plenum_5 != 'N/A' ? <span style={{ color: text_color }}>{mode5}</span> : <span style={{ color: text_color }}>N/A</span>}
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>MODE</span>
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                            :
                                            ''
                                        }
                                        {dryerData[index]?.plenumData?.plenum_4 ?
                                            <Grid item xs={12} style={{ display: "flex" }}>
                                                <Grid item xs={6} style={{ display: 'flex' }}>
                                                    <Grid item xs={6} style={{ display: 'flex' }}>
                                                        <img src={plenum_svg_4} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
                                                    </Grid>
                                                    <Grid item xs={6} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                        <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="30px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                            {dryerData[index]?.plenumData?.plenum_4}&deg;
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        <span style={{ color: text_color }}>{plenumSP4}&deg;</span>
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>SETPOINT</span>
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        {dryerData[index]?.plenumData?.plenum_4 != 'N/A' ? <span style={{ color: text_color }}>{mode4}</span> : <span style={{ color: text_color }}>N/A</span>}
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>MODE</span>
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                            :
                                            ''
                                        }
                                        {dryerData[index]?.plenumData?.plenum_3 ?
                                            <Grid item xs={12} style={{ display: "flex" }}>
                                                <Grid item xs={6} style={{ display: 'flex' }}>
                                                    <Grid item xs={6} style={{ display: 'flex' }}>
                                                        <img src={plenum_svg_3} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
                                                    </Grid>
                                                    <Grid item xs={6} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                        <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="30px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                            {dryerData[index]?.plenumData?.plenum_3}&deg;
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        <span style={{ color: text_color }}>{plenumSP3}&deg;</span>
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>SETPOINT</span>
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        {dryerData[index]?.plenumData?.plenum_3 != 'N/A' ? <span style={{ color: text_color }}>{mode3}</span> : <span style={{ color: text_color }}>N/A</span>}
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>MODE</span>
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                            :
                                            ''
                                        }
                                        {dryerData[index]?.plenumData?.plenum_2 ?
                                            <Grid item xs={12} style={{ display: "flex" }}>
                                                <Grid item xs={6} style={{ display: 'flex' }}>
                                                    <Grid item xs={6} style={{ display: 'flex' }}>
                                                        <img src={plenum_svg_2} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
                                                    </Grid>
                                                    <Grid item xs={6} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                        <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="30px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                            {dryerData[index]?.plenumData?.plenum_2}&deg;
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        <span style={{ color: text_color }}>{plenumSP2}&deg;</span>
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>SETPOINT</span>
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        {dryerData[index]?.plenumData?.plenum_2 != 'N/A' ? <span style={{ color: text_color }}>{mode2}</span> : <span style={{ color: text_color }}>N/A</span>}
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>MODE</span>
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                            :
                                            ''
                                        }
                                        {dryerData[index]?.plenumData?.plenum_1 ?
                                            <Grid item xs={12} style={{ display: "flex" }}>
                                                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Grid item xs={6} style={{ display: 'flex' }}>
                                                        <img src={plenum_svg_1} style={{ width: '35px', height: 'auto', objectFit: 'contain' }} />
                                                    </Grid>
                                                    <Grid item xs={6} pl={1} pr={1} style={{ display: 'flex', paddingLeft: '0' }}>
                                                        <MDTypography component="p" variant="button" display="flex" fontWeight="bold" fontSize="30px" style={{ color: '#E1861A', fontWeight: '1000' }}>
                                                            {dryerData[index]?.plenumData?.plenum_1}&deg;
                                                        </MDTypography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        <span style={{ color: text_color }}>{plenumSP1}&deg;</span>
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>SETPOINT</span>
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={3} style={{ alignSelf: 'end', paddingLeft: '20px' }}>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="18px" style={{ fontWeight: '400', justifyContent: 'space-evenly' }} >
                                                        {dryerData[index]?.plenumData?.plenum_1 != 'N/A' ? <span style={{ color: text_color }}>{mode1}</span> : <span style={{ color: text_color }}>N/A</span>}
                                                    </MDTypography>
                                                    <MDTypography component="p" variant="button" display="flex" fontSize="10px" style={{ justifyContent: 'space-evenly' }}>
                                                        <span style={{ color: text_color }}>MODE</span>
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                            :
                                            ''
                                        }
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </>
                    }
                </>
            }
        </>
    );
}

// Typechecking props for the CardBackPlenum
CardBackPlenum.defaultProps = {
    index: 0,
};

CardBackPlenum.propTypes = {
    index: PropTypes.number,
    handleFlip: PropTypes.func,
    destination: PropTypes.number,
    comingSoon: PropTypes.bool,
};

export default CardBackPlenum;
